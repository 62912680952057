import React from 'react';

const Star = ({ htmlColor, width, height }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill={htmlColor || '#CCCCCC'}
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 12.3077L2.85714 16L5.14286 9.84615L0 6.15385H5.71429L8 0L10.2857 6.15385H16L10.8571 9.84615L13.1429 16L8 12.3077Z"
    />
  </svg>
);

export const HalfStar = ({ width, height }) => (
  <svg
    width={width || '16'}
    height={height || '16'}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.85714 16L8 12.3077V0L5.71429 6.15385H0L5.14286 9.84615L2.85714 16Z"
      fill="#FFB624"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.1429 16L8 12.3077V0L10.2857 6.15385H16L10.8571 9.84615L13.1429 16Z"
      fill="#CCCCCC"
    />
  </svg>
);

export default Star;
