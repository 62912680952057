// @ts-check
import React from 'react';
import styled from 'styled-components';
import basicTheme from '../../themes';

const BackgroundContainer = styled.div`
  background: #ebf7f7;
  padding: ${({ isXsmall }) => (isXsmall ? '32px 16px 8px 16px' : '56px 16px 24px 16px')};
  display: flex;
  justify-content: center;

  position: relative;
  overflow: hidden;
`;

const EllipseLeft1 = styled.div`
  box-sizing: border-box;
  position: absolute;
  border: 2px solid ${basicTheme.global.colors.anotherWhite};

  ${({ isXsmall }) =>
    !isXsmall &&
    `
		width: 746px;
		height: 744px;
		left: -298px;
		top: -476px;
		border-radius: 746px;
	`};
  ${({ isXsmall }) =>
    isXsmall &&
    `
		left: -233px;
    top: -357px;
    width: 432.154px;
    height: 431.135px;
    border-radius: 432.154px;
	`};
`;

const EllipseLeft2 = styled.div`
  box-sizing: border-box;
  position: absolute;
  border: 2px solid ${basicTheme.global.colors.anotherWhite};

  ${({ isXsmall }) =>
    !isXsmall &&
    `
		width: 848px;
		height: 845px;
		left: -464px;
		top: -515px;
		border-radius: 848px;
	`};
  ${({ isXsmall }) =>
    isXsmall &&
    `
		width: 492px;
    height: 490px;
    border-radius: 492px;
    left: -356px;
    top: -358px;
	`};
`;

const EllipseLeft3 = styled.div`
  box-sizing: border-box;
  position: absolute;
  border: 2px solid ${basicTheme.global.colors.anotherWhite};
  background: rgba(55, 191, 191, 0.15);
  filter: blur(56.5px);

  ${({ isXsmall }) =>
    !isXsmall &&
    `
		width: 564px;
		height: 561px;
		left: -217px;
		top: -275px;
		border-radius: 564px;
	`};
  ${({ isXsmall }) =>
    isXsmall &&
    `
		width: 327px;
    height: 325px;
    left: -184px;
    top: -248px;
		border-radius: 327px;
	`};
`;

const EllipseRight1 = styled.div`
  position: absolute;
  border: 2px solid ${basicTheme.global.colors.anotherWhite};

  ${({ isXsmall }) =>
    !isXsmall &&
    `
		width: 748px;
		height: 748px;
		border-radius: 748px;
		top: 59%;
    left: 67%;
	`};
  ${({ isXsmall }) =>
    isXsmall &&
    `
		width: 432px;
		height: 432px;
		border-radius: 432px;
		left: 22%;
		top: 78%;
	`};
`;

const EllipseRight2 = styled.div`
  position: absolute;
  border: 2px solid ${basicTheme.global.colors.anotherWhite};
  background: rgba(55, 192, 192, 0.25);
  filter: blur(56.5px);

  ${({ isXsmall }) =>
    !isXsmall &&
    `
		width: 748px;
		height: 747px ;
		border-radius: 748px;
		top: 68%;
    left: 70%;
	`};
  ${({ isXsmall }) =>
    isXsmall &&
    `
		width: 432px;
		height: 432px;
		border-radius: 432px;
		left: 28%;
		top: 87%;
	`};
`;

const ContainerChildren = styled.section`
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
`;

function DiscountsContainer({ isXsmall, children }) {
  return (
    <BackgroundContainer isXsmall={isXsmall}>
      <EllipseLeft1 isXsmall={isXsmall} />
      <EllipseLeft2 isXsmall={isXsmall} />
      <EllipseLeft3 isXsmall={isXsmall} />
      <EllipseRight1 isXsmall={isXsmall} />
      <EllipseRight2 isXsmall={isXsmall} />
      <ContainerChildren>{children}</ContainerChildren>
    </BackgroundContainer>
  );
}

export default DiscountsContainer;
