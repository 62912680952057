import React from 'react';

const Email = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.75 5H4.75C3.7875 5 3.00875 5.7875 3.00875 6.75L3 17.25C3 18.2125 3.7875 19 4.75 19H18.75C19.7125 19 20.5 18.2125 20.5 17.25V6.75C20.5 5.7875 19.7125 5 18.75 5ZM18.75 8.5L11.75 12.875L4.75 8.5V6.75L11.75 11.125L18.75 6.75V8.5Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default Email;
