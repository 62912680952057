import React, { forwardRef } from 'react';
import styled from 'styled-components';
import basicTheme from '../../../themes';
import BasicInput from '../../../Atoms/WithoutGroomet/Inputs/BasicInput';
import Error from '../../../Atoms/Icons/Error';

const StyledContainer = styled.div`
  span.${({ className }) => className} {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: normal;
    color: ${basicTheme.global.colors.slate};
    font-weight: bold;
    text-transform: none;
  }

  div.${({ className }) => className} {
    margin-top: 8px;
    color: ${basicTheme.global.colors.freetrial};
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
  }
`;

const StyledInputContainer = styled.div`
  position: relative;
`;

const StyledClearButton = styled(Error)`
  position: absolute;
  right: 0.8rem;
  top: 0.8rem;
  fill: ${basicTheme.global.colors.freetrial};
`;

const InputField = forwardRef(
  (
    {
      id,
      type,
      inputMode,
      placeholder,
      handleOnChange,
      value,
      name,
      handleOnClick,
      error,
      errors,
      handleOnBlur,
      label,
      disabled,
    },
    ref,
  ) => (
    <StyledContainer className={id}>
      {label && (
        <span className={id} htmlFor={id}>
          {label}
        </span>
      )}
      <StyledInputContainer>
        <BasicInput
          id={id}
          type={type}
          inputMode={inputMode}
          placeholder={placeholder}
          onChange={handleOnChange}
          value={value || ''}
          name={name}
          ref={ref}
          onClick={handleOnClick}
          error={error}
          onBlur={handleOnBlur}
          disabled={disabled}
        />
        {error && <StyledClearButton />}
      </StyledInputContainer>
      {error && <div className={id}>{errors.phoneNumber.message}</div>}
    </StyledContainer>
  ),
);

export default InputField;
