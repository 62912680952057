import { createContext } from 'react';

export const ProductPageContext = createContext({
  campaignRebateData: {
    campaignProgress: {
      loading: true,
      error: false,
      progressValue: -1,
    },
    rebate: {
      status: {
        validationStatus: null,
      },
    },
  },
  vars: {},
});
