import React from 'react';
import styled from 'styled-components';
import H from '../../../Atoms/Heading';
import FilterRadioButton from '../FilterRadioButton';

const FilterCountry = ({ name, categories }) => {
  if (!categories) return <></>;

  return (
    <Wrapper>
      <H level={4} transform="uppercase">
        {name}
      </H>
      {categories.map((category) => (
        <CheckBoxWrapper key={category.slug}>
          <FilterRadioButton
            key={category.slug}
            type={category.type}
            slug={category.slug}
            name={category.name}
            qty={category.qty}
          />
        </CheckBoxWrapper>
      ))}
    </Wrapper>
  );
};

export default FilterCountry;

const Wrapper = styled.div`
  margin-top: 32px;
`;

const CheckBoxWrapper = styled.div`
  margin-bottom: 8px;
`;
