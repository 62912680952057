/* eslint-disable import/prefer-default-export */
export async function sendEmail(email, productId) {
  const body = {
    SampleKitID: productId,
    Email: email,
  };

  const response = await fetch('/product/shareproduct', {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(body),
  });

  if (response.ok) return {};
  throw new Error(response.statusText);
}

export async function sendInvite(email) {
  const body = {
    Email: email,
  };

  const response = await fetch('/product/invite', {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(body),
  });

  if (response.ok) return {};
  throw new Error(response.statusText);
}
