// @ts-check
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Text, themes } from 'socialnature-ui';
import { ResponsiveContext } from 'grommet';
import { SecondaryButton } from 'socialnature-ui/build/components/Button';
import { useInstructionsModal } from '../../../hooks/useInstructionsModal';
import PrimaryButton from '../../../Atoms/Buttons/PrimaryButton';
import HowToGetCashBackButton from '../../../Atoms/Buttons/HowToGetCashbackButton';
import { campaignTypes } from '../../../utils/campaignTypes';
import { getInstructionSteps, views } from '../../../utils/instructions';
import { useWantIdFromUri } from '../../../hooks/useWantIdFromUri';
import { trackEvent as trackGTMEvent } from '../../../utils/gtm';
import { TrackingEventType } from '../../../utils/tracking';
import { useParticipantStoreModal } from '../../../hooks/useParticipantStoreModal';
import ErrorPage from '../../ErrorPage';
import LoadingSection from '../../../Molecules/LoadingSection';
import { useDataStorePickUpLocation } from '../../../hooks/useDataStorePickUpLocation';
import { useCampaignFromWant } from '../../../hooks/useCampaignFromWant';
import { isFeatureOn, SplitNames } from '../../../utils/splitio';
import { formatDateToMMMDDYYYY } from '../../../utils/formatDate';
import CampaignContextProvider from '../../../contexts/CampaignContext/provider';
import Hr from '../../../Atoms/Hr';
import Marker from '../../../Atoms/Icons/Marker';
import { concatStoreList } from '../../../utils/product';
import { useCampaignNearRetailers } from '../../../hooks/useCampaignNearStores';

const BoldPrimaryButton = styled(PrimaryButton)`
  font-weight: bold;
  &:focus {
    color: ${themes.global.colors.white};
  }
  ${({ isXsmall }) =>
    isXsmall
      ? `
    padding: 12px 0;
    margin-bottom: 8px;
  `
      : `
    margin-right: 24px
    padding: 16px 32px;
  `}
`;
const BoldSecondaryButton = styled(SecondaryButton)`
  font-weight: bold;
  &[disabled]:focus,
  &[disabled]:hover {
    background: ${themes.global.colors.silver};
    color: ${themes.global.colors.iron};
  }
  ${({ isXsmall }) =>
    isXsmall
      ? `
    padding: 12px 0;
    margin-bottom: 16px;
  `
      : 'padding: 16px 32px;'}
`;

const Wrapper = styled(Box)`
  ${({ isXsmall }) => (isXsmall ? 'text-align: center;' : '')}
`;

const ButtonsWrapper = styled(Box)`
  display: flex;
  ${({ isXsmall }) => `flex-direction: ${isXsmall ? 'column' : 'row'} `};
`;

const StyledSpan = styled.span`
  display: block;
`;

const WhereToFind = styled(Box)`
  ${({ isXsmall }) => (isXsmall ? 'text-align: center;' : '')}

  margin: 24px 0;

  .wfind__header-wrapper {
    display: flex;
    flex-direction: ${({ isXsmall }) => (isXsmall ? 'column' : 'row')};
    ${({ isXsmall }) => (isXsmall ? '' : 'margin-bottom: 8px;')}
    .wfind__header-title {
      font-family: Montserrat;
      ${({ isXsmall }) => (isXsmall ? 'margin: 8px 0' : 'margin: 0')};
    }

    .wfind__header-icon {
      ${({ isXsmall }) => (isXsmall ? 'margin: 0 auto' : 'margin-right: 4px')};
    }
  }
`;

const CashbackDescription = ({ productName }) => {
  const [wantId] = useWantIdFromUri('invitation');
  const instructionsSteps = getInstructionSteps(campaignTypes.REBATE, views.CONGRATS);
  const { InstructionsModal, open } = useInstructionsModal(instructionsSteps, false);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const { data, samplekit, userLocation, preferredLocation, handleFormSubmitFetch } =
    useDataStorePickUpLocation(wantId);

  const [campaign, campaignError] = useCampaignFromWant(wantId);
  const { properties, type: campaignType, id: campaignId } = campaign || {};

  const { LocationStoreModal, open: openStoreLocationModal } = useParticipantStoreModal(
    userLocation || '',
    false,
    samplekit,
    handleFormSubmitFetch,
    preferredLocation,
    campaignType,
  );

  const [rebateOfferDeadline, setRebateOfferDeadLine] = useState(false);
  const { offerDiscountPercentage = 100, aisle } = properties || {};

  const retailers = useCampaignNearRetailers(campaignId);

  const handleParticipantStores = () => {
    trackGTMEvent(TrackingEventType.PARTICIPANT_STORES_CLICK, {
      samplekit: productName,
    });

    openStoreLocationModal();
  };

  const handleOpenPopup = () => {
    trackGTMEvent(TrackingEventType.REBATE_FLOW.HOW_TO_CASHBACK, {
      instructionsType: views.CONGRATS,
      samplekit: productName,
    });

    return open();
  };

  useEffect(() => {
    isFeatureOn(SplitNames.rebateOfferDeadline).then(setRebateOfferDeadLine);
  }, []);

  if (data.queryError || campaignError) {
    return <ErrorPage detail={JSON.stringify(data.queryError)} />;
  }

  return data.loading ? (
    <LoadingSection />
  ) : (
    <>
      <CampaignContextProvider campaignInfo={campaign}>
        <InstructionsModal />
      </CampaignContextProvider>
      <Wrapper isXsmall={isXsmall}>
        <Text margin={{ top: isXsmall ? '16px' : '8px', bottom: '0' }}>
          {rebateOfferDeadline ? (
            <>
              <StyledSpan>
                Upload your receipt by{' '}
                <b>
                  {campaign && formatDateToMMMDDYYYY(campaign.completeByDate)} and before all offers
                  are claimed
                </b>{' '}
                to get cash back.
              </StyledSpan>
            </>
          ) : (
            `Shop at eligible stores and upload your receipt to get ${offerDiscountPercentage}% cash back. Don't
            forget to share your review to inspire others and receive more offers.`
          )}
        </Text>
        <HowToGetCashBackButton
          iconSize="20"
          onClick={handleOpenPopup}
          margin={{ top: '18px', bottom: '26px' }}
        />

        {retailers && retailers.length > 0 && (
          <>
            <Hr style={{ margin: '0' }} />
            <WhereToFind isXsmall={isXsmall}>
              <div className="wfind__header-wrapper">
                {isXsmall ? (
                  <Marker className="wfind__header-icon" />
                ) : (
                  <Marker width={25} height={24} className="wfind__header-icon" />
                )}
                <b className="wfind__header-title">WHERE TO FIND</b>
              </div>
              <div>
                Find{' '}
                {aisle ? (
                  <>
                    in <b>{aisle} aisle</b>
                  </>
                ) : (
                  'it'
                )}{' '}
                at <b>participating {concatStoreList(retailers)}.</b>
              </div>
            </WhereToFind>
          </>
        )}

        <ButtonsWrapper isXsmall={isXsmall}>
          <BoldPrimaryButton
            label="SEE ALL ELIGIBLE STORES"
            href=""
            onClick={handleParticipantStores}
            color="pink"
            gap="xsmall"
            isXsmall={isXsmall}
          />
          <BoldSecondaryButton
            label="ADD RECEIPT"
            href={`/uploadreceipt/${wantId}`}
            color="white"
            gap="xsmall"
            onClick={() =>
              trackGTMEvent(TrackingEventType.REBATE_FLOW.ADD_RECEIPT_LINK, {
                samplekit: productName,
              })
            }
            data-track-category="Rebate"
            data-track-action="Add a receipt button"
            data-track-label="Congrats page"
            data-track-samplekit-id={samplekit ? samplekit.id : ''}
          />
        </ButtonsWrapper>
      </Wrapper>
      {LocationStoreModal()}
    </>
  );
};

export default CashbackDescription;
