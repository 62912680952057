import gql from 'graphql-tag';
import { sampleKitFieldsFragment } from '../products';

export const getWantInformation = (wantId, userTrackId) => gql`
  ${sampleKitFieldsFragment}
  query getWantInformation {
    samplekits(where: { wants: { id: { _eq: ${wantId} } } }) {
      ...sampleKitFieldsFragment
      wants(where: { id: { _eq: ${wantId} } }) {
        id
        location_id
        campaign
      }
    }
    users(where: { user_tracking_id: { _eq: "${userTrackId}" } }) {
      address(path: "postCode")
    }
  }
`;

export const getWantFromUserAndProduct = (samplekitId, userId) => gql`
  query getWantFromUserAndProductAC {
    wants(where: {sample_kit: {_eq: ${samplekitId}}, user: {_eq: ${userId}}}) {
      id
      location_id
      campaign
    }
  }
`;

export const getUserAndProduct = (samplekitSlug, userTrackId) => gql`
  ${sampleKitFieldsFragment}
  query getUserAndProduct {
    users(where: {user_tracking_id: {_eq: "${userTrackId}"}}) {
      id
      address(path: "postCode")
    }
    samplekits(where: {slug: {_eq: "${samplekitSlug}"}}) {
      ...sampleKitFieldsFragment
    }
  }
`;
