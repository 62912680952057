// @ts-check
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box, Divider, TextLink } from 'socialnature-ui';
import { ResponsiveContext } from 'grommet';
import Container from '../../Atoms/Container';
import DiscountCarousel from '../Discounts/DiscountCarousel';
import DiscountsContainer from './DiscountsContainer';

const DiscountsWrapper = styled(Box)`
  ${({ isXsmall }) => (isXsmall ? 'text-align: center;' : '')}
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0;

  .discounts__header {
    display: flex;
    flex-direction: column;
    align-items: ${({ isXsmall }) => (isXsmall ? 'center' : 'flex-start')};

    .discounts__header-title {
      font-family: Montserrat;
      font-size: ${({ isXsmall }) => (isXsmall ? '24px' : '32px')};
      font-weight: 700;
      line-height: 32px;
      margin-bottom: 8px;
    }
    .discounts__header-caption {
      ${({ isXsmall }) => (isXsmall ? 'text-align: center;' : '')}
      font-family: Karla;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;

      .discounts__header-caption-my-product {
        color: #08adcd;
        font-family: Karla;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
      }
    }
  }
`;

function Discounts({ isXsmall, discounts }) {
  return (
    <DiscountsWrapper isXsmall={isXsmall}>
      <div className="discounts__header">
        <span className="discounts__header-title">Bonus offers unlocked!</span>
        <div className="discounts__header-caption">
          These are already activated and saved under{' '}
          <TextLink href="/account/products" label="My Products" fontWeight="bold" />.{' '}
          <strong>Please see product for offer details and to add a receipt.</strong>
        </div>
      </div>
      <DiscountCarousel discounts={discounts} />
    </DiscountsWrapper>
  );
}

const DiscountsSection = ({ discounts, disableBackground = false, disableDivider = false }) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  if (!discounts || !discounts.length) {
    return isXsmall || disableDivider ? (
      <></>
    ) : (
      <Container>
        <Divider />
      </Container>
    );
  }

  if (disableBackground) {
    return <Discounts isXsmall={isXsmall} discounts={discounts} />;
  }

  return (
    <DiscountsContainer isXsmall={isXsmall}>
      <Discounts isXsmall={isXsmall} discounts={discounts} />
    </DiscountsContainer>
  );
};

export default DiscountsSection;
