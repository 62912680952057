import React, { useCallback, useEffect, useState } from 'react';
import { Text, Heart as HeartIcon } from 'socialnature-ui';
import { Heading } from 'grommet';
import { Tooltip } from 'socialnature-ui/build/components/Tooltip';
import { Error as Question } from 'socialnature-ui/build/icons';
import { useHandleCampaignLocations } from '../../../hooks/useHandleCampaignLocations';
import { useTprRetailers } from '../../../hooks/useTprRetailers';
import StoreLocationMobile from './StoreLocationMobile';
import { StoreLocationDesktop } from './StoreLocationDesktop';
import { setProductMatchLocation } from '../../../utils/apis/products';
import { setCookie } from '../../../utils/cookie';
import { LocateStoresNearLocationByProductId } from '../../../utils/apis/campaigns';
import LocationIcon from '../../../Atoms/Icons/LocationIcons';
import { useSearchStoreCampaignLocations } from '../../../hooks/useSearchStoreCampaignLocations';
import { QuestionIconWrapper } from '../../../Molecules/Buttons/LocationSelectorButton';
import { tooltip as noSamplesInAreaToolTip } from '../../Products/NoSamplesInArea';
import PrimaryButton from '../../../Atoms/Buttons/PrimaryButton';
import { TrackingEventType } from '../../../utils/tracking';
import { trackEvent as trackGTMEvent } from '../../../utils/gtm';
import { CountryISO2FromPostalCode } from '../../../utils/formatCountry';
import { handleError } from '../../../utils/error';
import { campaignTypes, storeLocationCtaLabels } from '../../../utils/campaignTypes';

const headerDescriptionEnum = {
  [campaignTypes.IN_STORE]: (
    <Text color="#000000" margin={{ bottom: '24px' }} size="12px">
      Find in-store at eligible locations only.
    </Text>
  ),
  [campaignTypes.REBATE]: (
    <Text
      style={{
        padding: '8px 16px',
        backgroundColor: '#FCEBDB',
        borderRadius: '4px',
      }}
      color="#333"
      margin={{ bottom: '24px' }}
      size="14px"
    >
      Find in-store at eligible locations only; online receipts and other stores won&apos;t
      be accepted.
    </Text>
  ),
};

function Header({ campaignType }) {
  return (
    <>
      <Heading color="#000000" level={3} size="24px" margin={{ bottom: '10px' }}>
        Find an eligible store for this offer
      </Heading>
      {headerDescriptionEnum[campaignType]}
    </>
  );
}

function FormLabelWhenLoggedInUser(
  postalCodeWithNoLocations,
  tooltip,
  isXsmall,
  setIsRecheckingNearby,
  handleFormSubmitFetch,
  setPostalCodeWithNoLocations,
  setCampaignLocations,
) {
  const { handleFormSubmit } = useSearchStoreCampaignLocations(
    setIsRecheckingNearby,
    handleFormSubmitFetch,
    setPostalCodeWithNoLocations,
    setCampaignLocations,
  );

  useEffect(() => {
    handleFormSubmit({ Location: postalCodeWithNoLocations });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Text
      data-testid="StoresLabel"
      size="medium"
      weight="bold"
      icon={<LocationIcon color="pink" />}
    >
      Stores near&nbsp;
      <Text as="span" weight="bold" size="medium">
        {postalCodeWithNoLocations}
      </Text>
      <Tooltip content={tooltip} maxWidth={isXsmall ? 'calc(100vw - 32px)' : '280px'}>
        <QuestionIconWrapper>
          <Question width="20" height="20" color="launchpad" />
        </QuestionIconWrapper>
      </Tooltip>
    </Text>
  );
}

function CTAButton(selectedIndex, handleContinue, CTAlabel, isXsmall) {
  const handleClick = () => {
    trackGTMEvent(TrackingEventType.STORE_LOCATOR_FLOW.STORE_LOCATION_POPUP_CTA_CLICKED, {
      label: CTAlabel,
    });
    handleContinue(selectedIndex);
  };

  return (
    <PrimaryButton
      type="button"
      icon={
        CTAlabel === storeLocationCtaLabels[campaignTypes.IN_STORE] ? (
          <HeartIcon isOutline color={!selectedIndex ? 'iron' : 'white'} />
        ) : null
      }
      label={CTAlabel}
      onClick={handleClick}
      fill="horizontal"
      disabled={!selectedIndex}
      margin={isXsmall ? '' : { top: '24px' }}
    />
  );
}

export function StoreLocationLayout({
  product,
  postalCode,
  onClose: closeModal,
  onGeoCheckRequested,
  wantLink,
  isLoggedIn,
  isXsmall,
  CTAlabel,
  geocheckRef,
  campaignType,
  noHeader = false,
  noCTAButton = false,
  onStoreSelected,
  reset,
  useNewActivationFlow,
  handleStoreSelected,
  showTprDiscountLabel=false
}) {
  const [isRecheckingNearby, setIsRecheckingNearby] = useState(false);
  const [postalCodeWithNoLocations, setPostalCodeWithNoLocations] = useState(postalCode);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { campaignLocations, handleCampaignLocations } = useHandleCampaignLocations();
  const {list: tprRetailersList} = useTprRetailers(showTprDiscountLabel && isLoggedIn, product.campaigns); // Fetch TPRS only when logged

  useEffect(() => {
    if (reset) {
      setSelectedIndex(null);
    }
  }, [reset]);

  useEffect(() => {
    if (selectedIndex && onStoreSelected) {
      const campaignLocation = campaignLocations.find((e) => e.index === +selectedIndex);
      onStoreSelected(campaignLocation);
    }
  }, [selectedIndex, campaignLocations, onStoreSelected]);

  const handleFormSubmitFetch = useCallback(
    (location) => LocateStoresNearLocationByProductId(product.id, location),
    [product.id],
  );

  // see samples link appear when no locations and free samples near locations
  const seeSamplesAvailable = () => {
    // should not geoCheck on close modal when See Samples link is clicked
    // eslint-disable-next-line no-param-reassign
    geocheckRef.current = { shouldGeoCheck: false, postalCode: '' };
    setProductMatchLocation(postalCodeWithNoLocations);
    setIsRecheckingNearby(true);
    if (closeModal) {
      closeModal();
    }

    if (onGeoCheckRequested) {
      onGeoCheckRequested()
        .then(() => {
          const element = document.querySelector('[rel=GeoProductCarousel]');
          if (element) {
            const yOffset = -50;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
          }
        })
        .catch((error) => {
          handleError(error);
          closeModal();
        });
    }
  };

  const handleContinue = (index) => {
    // picks campaignId based on the index select by user
    const { campaign: campaignId, id: campaignLocationId } = campaignLocations.find(
      (e) => e.index === +index,
    );

    // sets location and country for registration flow
    window.localStorage.setItem('cn_location', postalCodeWithNoLocations);
    const country = CountryISO2FromPostalCode(postalCodeWithNoLocations);
    window.localStorage.setItem('cn_country', country);

    // redirects to want-boost page
    const storeLocationInfo = {
      campaignId,
      campaignLocationId,
    };
    const validCampaign = campaignId && product;
    if (validCampaign) {
      setCookie(product.slug, encodeURIComponent(JSON.stringify(storeLocationInfo)), 1);
      if (isLoggedIn && useNewActivationFlow) {
        handleStoreSelected(campaignId, product, campaignLocationId); // If user logged in - we activate the campaign
      } else if (wantLink) {
        window.location.href = `${wantLink}`;
      }
    }
  };

  if (isXsmall) {
    return (
      <StoreLocationMobile
        productId={product.id}
        campaignLocations={campaignLocations}
        setIsRecheckingNearby={setIsRecheckingNearby}
        setCampaignLocations={handleCampaignLocations}
        isXsmall={isXsmall}
        isRecheckingNearby={isRecheckingNearby}
        setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
        postalCodeWithNoLocations={postalCodeWithNoLocations}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        seeSamplesAvailable={seeSamplesAvailable}
        isLoggedIn={isLoggedIn}
        handleFormSubmitFetch={handleFormSubmitFetch}
        Header={!noHeader && <Header campaignType={campaignType} />}
        FormLabelWhenLoggedInUser={() =>
          FormLabelWhenLoggedInUser(
            postalCodeWithNoLocations,
            noSamplesInAreaToolTip,
            isXsmall,
            setIsRecheckingNearby,
            handleFormSubmitFetch,
            setPostalCodeWithNoLocations,
            handleCampaignLocations,
          )
        }
        CTAButton={!noCTAButton && CTAButton(selectedIndex, handleContinue, CTAlabel, isXsmall)}
        geocheckRef={geocheckRef}
        tprRetailersList={tprRetailersList}
      />
    );
  }
  return (
    <StoreLocationDesktop
      productId={product.id}
      campaignLocations={campaignLocations}
      setIsRecheckingNearby={setIsRecheckingNearby}
      setCampaignLocations={handleCampaignLocations}
      isXsmall={isXsmall}
      isRecheckingNearby={isRecheckingNearby}
      setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
      postalCodeWithNoLocations={postalCodeWithNoLocations}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      seeSamplesAvailable={seeSamplesAvailable}
      isLoggedIn={isLoggedIn}
      handleFormSubmitFetch={handleFormSubmitFetch}
      Header={!noHeader && <Header campaignType={campaignType} />}
      FormLabelWhenLoggedInUser={() =>
        FormLabelWhenLoggedInUser(
          postalCodeWithNoLocations,
          noSamplesInAreaToolTip,
          isXsmall,
          setIsRecheckingNearby,
          handleFormSubmitFetch,
          setPostalCodeWithNoLocations,
          handleCampaignLocations,
        )
      }
      CTAButton={!noCTAButton && CTAButton(selectedIndex, handleContinue, CTAlabel, isXsmall)}
      geocheckRef={geocheckRef}
      tprRetailersList={tprRetailersList}
    />
  );
}
