// @ts-check
import React from 'react';
import { PrimaryButton } from 'socialnature-ui';

/**
 * @typedef {{
 * color: 'blue' | 'pink',
 * href: string,
 * productId: number,
 * productSlug: string,
 * label: string,
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function WhereToBuy(props) {
  const { color, href, productId, productSlug, label } = props;
  return (
    <PrimaryButton
      label={label || "Where to buy"}
      href={href}
      color={color}
      size="small"
      gap="xsmall"
      target={label ? "_self" : "_blank"}
      rel="noopener"
      data-track-category="Product-Button"
      data-track-action="Where to buy"
      data-track-samplekit-id={productId}
      data-track-label={productSlug}
    />
  );
}
