export const discountTypes = {
  PERCENTAGE: 'PERCENTAGE',
  BOGO: 'BOGO',
};

export const getDiscountPriceValue = (discountPercentage, productPrice) => {
  // Remove the dollar sign
  const parsedPrice = productPrice.replace('$', '');
  // Convert the string to a number
  const numericPrice = parseFloat(parsedPrice);

  const discountPriceValue = (numericPrice * discountPercentage) / 100;
  return discountPriceValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });
};

export const getDiscountRedeemUnitsLabel = (discount) => {
  if (!discount.propertiesNavigation) {
    return 'Receipt must show at least 2 units';
  }
  if (discount.discountType !== 'BOGO') {
    return ``;
  }
  const bogo = discount.propertiesNavigation.bogo || { buy: 1, get: 1 };
  const unitsToBuy = bogo.buy;
  return `Receipt must show at least ${unitsToBuy + bogo.get} units`;
};

export const getDiscountBadge = (discount) => {
  if (!discount) {
    return null;
  }

  const { discountType, discountValue, propertiesNavigation } = discount;
  let babge = null;
  switch (discountType) {
    case discountTypes.PERCENTAGE:
      babge = {
        text: `${discountValue}% off`,
        color: 'pink',
      };
      break;
    case discountTypes.BOGO:
      if (propertiesNavigation.bogo && Object.keys(propertiesNavigation.bogo).length > 0) {
        babge = {
          text: propertiesNavigation.bogo.label.toUpperCase(),
          color: 'pink',
        };
      }
      break;
    default:
      break;
  }
  return babge;
};
