import React from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import { Grommet } from 'grommet';
import {
  SecondaryButton,
  Heart as HeartIcon,
  Boost as BoostIcon,
  themes as uiTheme,
} from 'socialnature-ui';
import { useCouponModal } from '../../hooks/useCouponModal';
import { getProductCTA, CTATypes, geoEnhanceProductCTA } from '../../utils/product';
import { CampaignInfo, BannerInfo, GeoInfo } from '../../Molecules/PerkInfo';

function PerkCard(props) {
  const {
    title,
    imageSrc,
    action,
    children,
    styleType,
    isLaunchpad,
    productId,
    productSlug,
    dataTrackCategory,
  } = props;

  const { label, isIcon } = action;

  return (
    <div className="module-perk">
      <PerkAnchor
        {...action}
        productId={productId}
        productSlug={productSlug}
        dataTrackCategory={dataTrackCategory}
      >
        <div className="layout-column">
          <PerkInner styleType={styleType} imageSrc={imageSrc} title={title} />
          <div className="nudge-md" />
          {children}
          <div className="nudge-md" />
          <div className="module-perk--bottom">
            <SecondaryButton
              icon={
                isIcon &&
                (isLaunchpad ? (
                  <BoostIcon isOutline color="blue" />
                ) : (
                  <HeartIcon isOutline color="pink" />
                ))
              }
              label={label}
              color={isLaunchpad ? 'blue' : 'pink'}
              size="small"
              gap="xsmall"
              fill="horizontal"
              as="div"
            />
          </div>
        </div>
      </PerkAnchor>
    </div>
  );
}

function PerkAnchor({
  href,
  onClick,
  targetBlank,
  children,
  label,
  productId,
  productSlug,
  dataTrackCategory,
}) {
  if (href) {
    return (
      <a
        className="module-perk--anchor"
        href={href}
        target={targetBlank && '_blank'}
        rel={targetBlank && 'noopener'}
        data-track-category={dataTrackCategory || 'Product-Button'}
        data-track-action={`${label} Card`}
        data-track-samplekit-id={productId}
        data-track-label={productSlug}
      >
        {children}
      </a>
    );
  }
  if (onClick) {
    return (
      <button
        type="button"
        className="module-perk--anchor"
        onClick={onClick}
        data-track-category={dataTrackCategory || 'Product-Button'}
        data-track-action={`${label} Card`}
        data-track-samplekit-id={productId}
        data-track-label={productSlug}
      >
        {children}
      </button>
    );
  }
  return null;
}

function PerkInner(props) {
  const { imageSrc, title, styleType } = props;
  const isRow = styleType === 'row';
  return (
    <div className={classNames('module-perk--inner', { 'module-perk--inner-row': isRow })}>
      <img src={imageSrc} alt={title} />
      {title.length > 16 ? <h1>{title}</h1> : <h1 className="h1-sm">{title}</h1>}
    </div>
  );
}

const ProductPerks = (props) => {
  const {
    details = {},
    coupon = {},
    campaign = {},
    isLoggedIn,
    openGeoModal,
    geoInfo,
    dataTrackCategory,
    showStoreLocatorFlow,
    openStoreLocationModal,
    userId,
  } = props;
  const {
    id: productId,
    name: productName,
    slug: productSlug,
    featuredImage,
    wantDisabled,
    hasWanted,
    countries,
    isLaunchpad,
  } = details;
  const { showBanner, bannerMessage } = coupon;
  const { CouponModal, open } = useCouponModal(
    { ...coupon, image: (featuredImage || {}).small },
    productId,
    productSlug,
    false,
    dataTrackCategory,
  );

  const openModal = (e) => {
    e.preventDefault();
    open();
  };

  const productLink = isLoggedIn
    ? `/${productSlug}/want-boost`
    : `/sign-in?ReturnUrl=/${productSlug}/want-boost`;
  const action = geoEnhanceProductCTA(
    {
      ...props,
    },
    getProductCTA(
      {
        isLaunchpad,
        hasWanted,
        productLink,
        showBanner,
        bannerMessage,
        wantDisabled,
        productId,
        showStoreLocatorFlow,
        openStoreLocationModal,
        ...coupon,
      },
      openModal,
    ),
  );

  const { type } = action;

  return (
    <Grommet theme={uiTheme}>
      {type === CTATypes.WANT && (
        <PerkCard
          title="FREE TRIAL"
          imageSrc={!R.isEmpty(featuredImage) && featuredImage.small}
          details={details}
          action={action}
          styleType="row"
          productId={productId}
          productSlug={productSlug}
          dataTrackCategory={dataTrackCategory}
        >
          <CampaignInfo campaign={campaign} />
          <GeoInfo
            geoInfo={geoInfo}
            openGeoModal={openGeoModal}
            productId={productId}
            campaignId={campaign.id}
            userId={userId}
          />
        </PerkCard>
      )}
      {(type === CTATypes.BANNER || (isLaunchpad && showBanner)) && (
        <PerkCard
          title={bannerMessage}
          imageSrc="images/coupon.png"
          details={details}
          action={action}
          productId={productId}
          productSlug={productSlug}
          isLaunchpad
          dataTrackCategory={dataTrackCategory}
        >
          <h2>{`Purchase ${productName} at an exclusive discount`}</h2>
          <BannerInfo countries={countries} />
        </PerkCard>
      )}
      <CouponModal />
    </Grommet>
  );
};

export default ProductPerks;
