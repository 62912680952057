import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Box, ResponsiveContext } from 'grommet';
import GET_REVIEWS from '../../../utils/apis/reviews';
import formatParam from '../../../utils/apis/formatParam';
import Container from '../../../Atoms/Container';
import H from '../../../Atoms/Heading';
import ReviewCard from '../../../Organisms/Reviews/ReviewCard';
import Cards from '../../../Atoms/Card/Cards';
import LoadingSection from '../../../Molecules/LoadingSection';
import PageAnchor from '../../../Templates/PageAnchor';

const ReviewSection = () => {
  const size = React.useContext(ResponsiveContext);
  const isSmall = size === 'small' || size === 'xsmall';

  const { loading, error, data } = useQuery(GET_REVIEWS, {
    variables: {
      param: formatParam({
        limit: 4,
      }),
    },
  });
  if (loading) return <LoadingSection />;
  if (error) return <></>;
  const { reviews } = data || {};

  if (!reviews) return <></>;

  return (
    <Box
      flex={false}
      pad={{ vertical: 'section' }}
      className="section-bg-gray"
      data-cy="reviews-container"
    >
      <PageAnchor id="reviews" />
      <Container>
        <Box margin={{ bottom: 'large' }}>
          <H level={2}>Recent reviews</H>
        </Box>
        {reviews && (
          <Cards>
            <Box
              gap={isSmall ? 'none' : 'small'}
              margin={{ top: 'cardWrapper' }}
              direction="row-responsive"
            >
              <Box width={isSmall ? { max: 'initial' } : { max: '50%' }}>
                {reviews.map((review, index) =>
                  index % 2 === 0 ? (
                    <Box margin={isSmall ? { bottom: 'large' } : 'none'} key={review.id}>
                      <ReviewCard review={review} />
                    </Box>
                  ) : null,
                )}
              </Box>
              <Box width={isSmall ? { max: 'initial' } : { max: '50%' }}>
                {reviews.map((review, index) =>
                  index % 2 !== 0 ? (
                    <Box margin={isSmall ? { bottom: 'large' } : 'none'} key={review.id}>
                      <ReviewCard review={review} />
                    </Box>
                  ) : null,
                )}
              </Box>
            </Box>
          </Cards>
        )}
      </Container>
    </Box>
  );
};

export default ReviewSection;
