import React, { useContext, useEffect, useState } from 'react';
import { Image, ResponsiveContext } from 'grommet';
import { Box, Heading, Container, Card, CardContent } from 'socialnature-ui';
import Loading from '../../Molecules/Loading';
import ErrorPage from '../ErrorPage';
import { campaignTypes } from '../../utils/campaignTypes';
import AddressForm from './AddressForm';
import { getCongratsPageData } from '../../utils/apis/campaigns';

export const AcceptInvitePage = (props) => {
  window.dataLayer.push({ invitation_flag: 'shipping_details_confirmation_needed' });

  const { data } = props;
  const { sampleKitImage, sampleKitName, sampleKitUrl, campaignType } = data;
  const size = useContext(ResponsiveContext);
  const isSmall = size === 'xsmall' || size === 'small';

  const promotion = campaignType === campaignTypes.IN_STORE ? 'coupon' : 'sample';

  return (
    <Container maxWidth="832px">
      <Heading level={2} textAlign="center" margin={{ top: 'xlarge', bottom: 'medium' }}>
        {`Confirm shipping details for your ${promotion} of ${sampleKitName}`}
      </Heading>

      <Box pad={{ bottom: 'xlarge' }}>
        <Card noPadding>
          <CardContent pad={isSmall ? 'medium' : 'large'}>
            <Box
              direction={isSmall ? 'column' : 'row-reverse'}
              gap="large"
              align={isSmall ? 'stretch' : 'start'}
            >
              {sampleKitImage && (
                <a href={sampleKitUrl}>
                  <Box
                    width={isSmall ? '100%' : '318px'}
                    height={isSmall ? '128px' : '286px'}
                    round="4px"
                    elevation="lightDrop"
                    pad="small"
                    background="#FFFFFF"
                  >
                    <Image
                      fit={isSmall ? 'contain' : 'cover'}
                      src={sampleKitImage}
                      alt={sampleKitName}
                    />
                  </Box>
                </a>
              )}
              <AddressForm data={data} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
};

export const AcceptInvitePageContainer = ({ wantId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [er, setError] = useState(null);

  useEffect(() => {
    getCongratsPageData(wantId)
      .then((res) => setData(res))
      .catch(() => {
        setError({ message: 'Failed to Load Invitation, Try again later!' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [wantId]);

  if (loading) return <Loading />;
  if (er) return <ErrorPage />;
  // Rendering AcceptInvitePage with API/Container
  return <AcceptInvitePage data={data} />;
};
