import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  padding: 0.75rem;
  color: #6b7280; // Neutral color
`;

const Text = styled.span`
  font-size: 1rem;
`;

const Logo = styled.img`
  position: relative;
  top: -8px;
  width: 114px;

  @media (min-width: 768px) {
    width: 136px;
  }
`;

const PoweredBy = () => {
  return (
    <Container>
      <Text>Powered by</Text>
      <Logo src="/images/sn-logo-horizontal.png" alt="Logo" />
    </Container>
  );
};

export default PoweredBy;
