import React from 'react';
import { Text } from 'grommet';

const Span = ({ color, textAlign, children, size, weight }) => (
  <Text
    color={color || 'inherit'}
    textAlign={textAlign}
    margin={{
      vertical: '0',
      horizontal: '0',
    }}
    size={size || 'medium'}
    weight={weight || 'normal'}
  >
    {children}
  </Text>
);

export default Span;
