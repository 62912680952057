import React from 'react';
import { Box, Text, Error, Heading, TextLink } from 'socialnature-ui';

export default function DuplicateAddress() {
  return (
    <Box>
      <Box direction="row" align="center" margin={{ bottom: 'medium' }} gap="xsmall">
        <Error color="red" />
        <Heading level={3}>Duplicate Address</Heading>
      </Box>
      <Text>
        {'According to our records, your address already exists for this campaign.'}
        <br />
        {'If you believe this is a mistake, please contact us at '}
        <TextLink href="mailto:hello@socialnature.com" label="hello@socialnature.com" />.
      </Text>
    </Box>
  );
}
