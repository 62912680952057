import React from 'react';
import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;
  max-width: 280px;
  text-align: left;

  span {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
  }

  p {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
  }
`;

const CashBackCard = ({ image, title, caption }) => (
  <Card>
    <img src={image} alt={title} width={64} height={64} />
    <div>
      <span>{title}</span>
      <p>{caption}</p>
    </div>
  </Card>
);

export default CashBackCard;
