export default function formatParam(params) {
  const param = Object.keys(params).reduce((str, p) => {
    if (params[p] || params[p] === 0) {
      if (p === 'filter') {
        return `${str}${params[p]}&`;
      }
      return `${str}${p}=${params[p]}&`;
    }
    return str;
  }, '');
  return param.length - 1 > 0 ? param.slice(0, -1) : '';
}
