import React from 'react';
import styled from 'styled-components';
import { Button, Layer } from 'grommet';
import ReactPlayer from 'react-player';
import TextButton from '../../Atoms/Buttons/TextButton';
import Play from '../../Atoms/Icons/Play';
import Cross from '../../Atoms/Icons/Cross';
import ReactPlayerContainer from '../../Templates/ReactPlayerContainer';

export default function LaunchpadLearnMore({ color }) {
  const [show, setShow] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);

  return (
    <>
      <TextButton
        icon={<Play color="launchpad" />}
        label="learn more"
        onClick={() => setShow(true)}
        transform="uppercase"
        weight="bold"
        color={color}
      />
      {show && (
        <Layer
          modal
          animation="fadeIn"
          responsive={false}
          position="center"
          onEsc={() => setShow(false)}
          onClickOutside={() => setShow(false)}
        >
          <VideoWrapper>
            <ReactPlayerContainer>
              <ReactPlayer
                playing={playing}
                onPlay={() => setPlaying(true)}
                width="100%"
                height="auto"
                url="https://vimeo.com/341684479?dnt=1"
                controls={true}
                className="reactPlayer"
                config={{
                  vimeo: {
                      playerOptions: {
                          dnt: true, // Do Not Track option
                      },
                  },
              }}
              />
            </ReactPlayerContainer>
            <CloseButton>
              <Button
                plain
                icon={<Cross color="white" titleAccess="close" />}
                hoverIndicator="transparent"
                onClick={() => setShow(false)}
              />
            </CloseButton>
          </VideoWrapper>
        </Layer>
      )}
    </>
  );
}

const VideoWrapper = styled.div`
  position: relative;
  width: 880px;
  max-width: 96vw;
  margin: 0 auto;
  padding: 8px;
`;

const CloseButton = styled.div`
  position: absolute;
  top: -32px;
  right: 0;
`;
