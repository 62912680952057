// @ts-check
import { useCallback, useState } from 'react';

/**
 * @param {number=} preferredLocation
 * @returns
 */

export const FormatData = (locations, preferredLocation) => {
  const formattedData = locations.reduce((prevFormattedLocations, currentLocation, index) => {
    const currentFormattedLocation = {
      index: index + 1,
      isPreferredLocation: preferredLocation ? preferredLocation === currentLocation.id : false,
      ...currentLocation,
    };
    if (currentFormattedLocation.isPreferredLocation) {
      // moves to the beginning of the array the prefferedLocation if exists and formats index accordingly
      const newCurrentFormattedLocations = [currentFormattedLocation, ...prevFormattedLocations];
      return newCurrentFormattedLocations.map((newCurrentFormattedLocation, i) => ({
        ...newCurrentFormattedLocation,
        index: i + 1,
      }));
    }
    return [...prevFormattedLocations, currentFormattedLocation];
  }, []);
  return formattedData;
};
export function useHandleCampaignLocations(preferredLocation) {
  const [campaignLocations, setCampaignLocations] = useState([]);
  const handleCampaignLocations = useCallback(
    (locations) => {
      if (Array.isArray(locations) && locations.length) {
        // format locations
        const formattedData = FormatData(locations, preferredLocation);
        setCampaignLocations(formattedData);
        return;
      }
      setCampaignLocations(locations);
    },
    [preferredLocation],
  );

  return { campaignLocations, handleCampaignLocations };
}
