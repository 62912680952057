import gql from 'graphql-tag';

export const GET_VALID_USER_PROMO_CODE = gql`
  query GetUserPromoCode($campaignId: Int!) {
    users {
      id
      address(path: "postCode")
    }
    promo_code(
      where: {
        campaign_id: { _eq: $campaignId }
        _and: { start_at: { _lte: "now()" }, end_at: { _gte: "now()" } }
      }
    ) {
      userPromoCodes {
        code
      }
      code
      end_at
      type
      url
      button_text
      channel_name
    }
  }
`;

export const GET_USER_PROMO_CODE = gql`
  query GetUserPromoCode($campaignId: Int!) {
    promo_code(where: { campaign_id: { _eq: $campaignId }, start_at: { _lte: "now()" } }) {
      userPromoCodes {
        code
      }
      end_at
      type
      url
      button_text
      channel_name
    }
  }
`;
