import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ResponsiveContext } from 'grommet';
import { Box, Container, Heading } from 'socialnature-ui';
import gql from 'graphql-tag';
import CatchCopy from './CatchCopy';
import DiscountCard from './DiscountCard';
import FacebookShareButton from '../../Molecules/SocialShare/Facebook/ShareButton';
import TwitterShareButton from '../../Molecules/SocialShare/Twitter/ShareButton';
import EmailShareButton from '../../Molecules/SocialShare/Email/ShareButtonWithOneEmail';
import PinterestShareButton from '../../Molecules/SocialShare/Pinterest/ShareButton';
import RelatedProducts from '../Sections/RelatedProducts';
import Loading from '../../Molecules/Loading';
import { sendEmail } from '../../utils/apis/shareReview';
import { useQueryUser } from '../../utils/apis/users';
import clientGraphql from '../../utils/apis/graphql';
import BonusOffersSection from './BonusOffersSection';

const GET_WANT_ID = (reviewId, userId) => gql`
query GetWantId {
  reviews(where: {id: {_eq: ${reviewId}}}) {
    samplekitData {
      wants(where: {user: {_eq: ${userId}}}) {
        id
      }
    }
  }
}
`;

export default function ShareReviewPage({ review, product, discountCode }) {
  const { id: reviewId } = review;
  const { id, slug, name, featuredImage, companyName, shareUrl, twitterMessage, pinterestMessage } =
    product;
  const size = useContext(ResponsiveContext);
  const [wantId, setWantId] = useState(null);
  const isXsmall = size === 'xsmall';
  const dataTrackCategory = 'Review';
  // NOTE: Received valid discount coupon only.
  const hasDiscount = !!discountCode;

  const handleSendEmail = useCallback((email) => sendEmail(reviewId, email), [reviewId]);

  const { loading, data } = useQueryUser();
  const { country, id: userId } = data || {};

  useEffect(() => {
    if (reviewId && userId) {
      clientGraphql
        .query({
          query: GET_WANT_ID(reviewId, userId),
        })
        .then((res) => {
          const {
            data: { reviews },
          } = res;
          const {
            samplekitData: { wants },
          } = reviews[0];
          const { id: wantID } = wants[0];
          setWantId(wantID);
        });
    }
  }, [reviewId, userId]);

  return (
    <>
      <Box
        background="linear-gradient(180deg, rgba(251, 251, 251, 0) 0%, rgba(55, 192, 192, 0.1) 100%)"
        pad={{ vertical: 'large' }}
      >
        <Container>
          <Box
            flex={false}
            align="center"
            margin={{ bottom: '16px' }}
            overflow={{ horizontal: 'hidden' }}
          >
            <CatchCopy copy="Thanks for your review!" />
          </Box>
          {hasDiscount ? (
            <>
              <Box width={{ max: '725px' }} margin={{ horizontal: 'auto' }}>
                <Heading level={2} textAlign="center">
                  {`Enjoy a discount for ${name} and share it with friends`}
                </Heading>
              </Box>
              <Box margin={{ top: 'medium', bottom: 'none' }}>
                <DiscountCard discountCode={discountCode} productSlug={slug} />
              </Box>
            </>
          ) : (
            <Heading level={2} textAlign="center">
              {'Share your review and inspire others to #trynatural'}
            </Heading>
          )}
          <Box
            margin={{ top: 'medium' }}
            direction="row"
            wrap
            justify={isXsmall ? 'around' : 'center'}
          >
            <FacebookShareButton
              data-track-action="Facebook"
              shareType="review"
              data-track-category={dataTrackCategory}
              data-track-label={slug}
              data-track-samplekit-id={id}
              name={name}
              companyName={companyName}
              shareUrl={shareUrl}
            />
            <EmailShareButton
              data-track-action="Email"
              shareType="review"
              data-track-category={dataTrackCategory}
              data-track-label={slug}
              data-track-samplekit-id={id}
              dataTrackCategory={dataTrackCategory}
              productSlug={slug}
              productId={id}
              title={`Have friends who might like ${name}?`}
              buttonLabel="Share review"
              emailLabel="Your friend's email"
              sendEmail={handleSendEmail}
            />
          </Box>
        </Container>
        {wantId && <BonusOffersSection wantId={wantId} isXsmall={isXsmall} />}
      </Box>
      {loading ? (
        <Loading />
      ) : (
        <RelatedProducts userCountry={country} dataTrackCategory={`${slug} ShareReview`} />
      )}
    </>
  );
}
