import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export function add(date, number, unit) {
  if (!date || !number || !unit) return '';
  if (!dayjs(date).isValid()) return '';
  return dayjs(date).utc().add(number, unit);
}

export function checkTodayIsAfter(date, unit = 'day') {
  if (!date || !dayjs(date).isValid()) return null;
  return dayjs().isAfter(dayjs(date, unit));
}
