import { handleError } from './error';

/**
 * @param {string} event
 * @param {Record<string, string>=} parameter
 */
export function trackEvent(event, parameter) {
  if (window.dataLayer) {
    window.dataLayer.push({ event, parameter });
  } else {
    handleError(`dataLayer is not defined, event: ${event} could not be tracked `);
  }
}
