import React, { useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { Modal, Box, Container, Text } from 'socialnature-ui';
import PrimaryButton from '../../Atoms/Buttons/PrimaryButton';
import PoweredBy from './PoweredBy';
import { getLocalStorageV2 } from '../../utils/localStorage';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  background-color: white;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
  padding: 16px;
  z-index: 1000;
`;

const StyledButton = styled(PrimaryButton)`
  text-align: center;
  width: 100%;
  height: 57px;
  border-radius: 4px;
`;

const ExploreSN = ({ isOpenModal = false, userTrackingID }) => {
    const size = useContext(ResponsiveContext);
    const isXsmall = size === 'xsmall';

    if (!isOpenModal) {
        return null;
    }

    return (
        <Wrapper isXsmall={isXsmall}>
            <Container>
                <Text margin={{ top: '10px' }} size="14px" style={{ textAlign: "center" }}>
                    Try more free or discounted better-for-you products
                </Text>
                <StyledButton
                    margin={{ top: '15px', bottom: '15px' }}
                    type="button"
                    label="JOIN SOCIAL NATURE"
                    fill="horizontal"
                    onClick={(e) => {
                        e.preventDefault();
                        const registrationURL = `sign-in/signup?snsource=direct&user-session=${userTrackingID}&postalcode=${getLocalStorageV2('cn_location')}`;
                        window.location.href = `${process.env.BASE_URL}${registrationURL}`;
                    }}
                />
                <PoweredBy />
            </Container>
        </Wrapper>
    );
};

export default ExploreSN;
