import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box, Image, ResponsiveContext } from 'grommet';
import Container from '../../../Atoms/Container';
import H from '../../../Atoms/Heading';
import HeadingDescription from '../../../Molecules/HeadingDescription';
import CTAButton from '../../../Atoms/Buttons/CTAButton';

const Background = styled.div`
  background: ${(props) => (props.isMedium ? 'none' : 'url(/images/hero.jpg)')};
  background-size: auto 100%;
  background-position: right bottom;
  background-color: rgb(241, 241, 241);
  background-repeat: no-repeat;
`;

const HeroSection = () => {
  const size = useContext(ResponsiveContext);
  const isMedium = size === 'medium' || size === 'small' || size === 'xsmall';

  return (
    <Background isMedium={isMedium}>
      {isMedium && (
        <Box height="56vw">
          <Image fit="cover" src="/images/hero-mobile.jpg" />
        </Box>
      )}
      <Container>
        <Box pad={{ vertical: 'xlarge' }} width={isMedium ? '100%' : '60%'}>
          <Box width={{ max: '694px' }}>
            <H level={1} size="hero" margin={{ bottom: 'medium' }}>
              Discover better products at stores near you!
            </H>
            <HeadingDescription>
              Social Nature helps you make the switch to better products with free samples and
              exclusive discounts in exchange for your honest review! Join our #trynatural community
              of over 1 million shoppers.
            </HeadingDescription>
            <Box margin={{ top: 'large' }} width={size === 'xsmall' ? '100%' : '177px'}>
              <CTAButton label="get started!" href="/sign-in/signup?snsource=get_started" />
            </Box>
          </Box>
        </Box>
      </Container>
    </Background>
  );
};

export default HeroSection;
