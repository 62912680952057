import React from 'react';
import createSvgIcon from './SvgIcon';

export default createSvgIcon(
  <>
    <circle cx="12" cy="12" r="12" fill="white" />
    <path
      d="M12.856 14.1847H16.632V17.1127H7.656V14.7927L11.704 11.0487C12.472 10.3287 12.584 9.89674 12.584 9.51274C12.584 8.96874 12.216 8.63274 11.496 8.63274C10.824 8.63274 10.184 9.00074 9.848 9.67274L7 8.24874C7.816 6.71274 9.432 5.65674 11.848 5.65674C14.504 5.65674 16.376 7.03274 16.376 9.16074C16.376 10.2647 16.088 11.2567 14.456 12.7287L12.856 14.1847Z"
      fill="#08ADCD"
    />
  </>,
  'StepTwo',
);
