import React, { useCallback, useContext, useState } from 'react';
import styled from 'styled-components';
import { Box, Card, CardContent, Heading, PromoCode, SecondaryButton, Text } from 'socialnature-ui';
import { ResponsiveContext } from 'grommet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import discountIconSrc from '../../images/ReviewSharePage/discount-icon.svg';
import Time from '../../Atoms/Icons/Time';
import { formatDate } from '../../utils/formatDate';

const DiscountIcon = styled.img`
  width: 32px;
  height: 32px;
  display: block;
  margin: 0 auto;
`;

// TODO: Remove the component when don't use site.css. It's only for overriding site.css
const PromoCodeWrapper = styled.div`
  input:not([type])[readonly] {
    margin: 0;
    background-color: #ffffff;
    box-shadow: none;
    height: 40px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 17px;
    padding-left: 16px;
    border: 1px dashed #cccccc;
    border-right: none;
  }
  button {
    padding: 8px 16px;
    flex-shrink: 0;
  }
  label {
    font-weight: normal;
    cursor: default;
  }
`;

const DiscountCard = ({ discountCode, productSlug }) => {
  const [copied, setCopied] = useState(false);
  const size = useContext(ResponsiveContext);
  const isXSmall = size === 'xsmall';

  const { productId, bannerExpiry, bannerMessage, bannerUrl, code } = discountCode || {};

  const handleCopied = useCallback(() => {
    if (!copied) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 1500);
    }
  }, [copied]);

  return (
    <Box width={{ max: '446px' }} margin={{ horizontal: 'auto' }}>
      <Card noPadding>
        <CardContent pad={{ vertical: 'medium', horizontal: isXSmall ? 'medium' : 'xlarge' }}>
          <Box
            direction="row"
            gap="xsmall"
            justify="start"
            align="center"
            margin={{ bottom: 'small' }}
          >
            <Box>
              <DiscountIcon src={discountIconSrc} alt="discount icon" />
            </Box>
            <Heading level={3}>{bannerMessage}</Heading>
          </Box>
          <CopyToClipboard text={code} onCopy={handleCopied}>
            <PromoCodeWrapper>
              <PromoCode
                value={code}
                copied={copied}
                data-track-samplekit-id={productId || null}
                data-track-category="Click"
                data-track-action="Copy Post-trial Promo Code"
                data-track-label={productSlug}
              />
            </PromoCodeWrapper>
          </CopyToClipboard>
          <Text size="small">Apply promo code on checkout.</Text>
          <SecondaryButton
            label="Redeem online"
            margin={{ top: 'medium', bottom: 'xsmall' }}
            href={bannerUrl}
            target="_blank"
            rel="noopener"
            data-track-samplekit-id={productId || null}
            data-track-category="Click"
            data-track-action="Redeem Post-trial Promo Code"
            data-track-label={productSlug}
          />
          <Box direction="row" justify="center" gap="xxsmall">
            <Time width="20" height="20" color="slate" />
            <Text color="slate">{`Offer expires ${formatDate(bannerExpiry, true)}`}</Text>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DiscountCard;
