import React, { useCallback, useMemo } from 'react';
import ReactDOMServer from 'react-dom/server';
import { MapWrapper, Map, Marker } from '../../Atoms/Map';
import { handleError } from '../../utils/error';
import { CENTER_NORTH_AMERICA } from '../../utils/apis/maps';
import BasicLabel from '../../Atoms/Labels/BasicLabel';

function LocationMapItem(name, street1, street2, city, state, isPreferredLocation) {
  // element needs to be returnet as string since this is how its receive by google Maps API
  let element = `<div style="font-weight: bold; font-size: 1.1em; margin-bottom: 4px;${
    isPreferredLocation && ' margin-top: 12px'
  }">${name}</div><div>${street1} ${street2}</div><div>${city}, ${state}</div>`;
  if (isPreferredLocation) {
    element = ReactDOMServer.renderToString(<BasicLabel label="Your Preferred Store" />) + element;
  }
  return element;
}
export function LocationMap({
  centerLatitude = CENTER_NORTH_AMERICA.lat,
  centerLongitude = CENTER_NORTH_AMERICA.lng,
  zoom = 2.8,
  locations,
  selectedIndex,
  setSelectedIndex,
  ...others
}) {
  const googleMapApiKey = process.env.GOOGLE_MAP_API_KEY;
  const handleMarkerClick = useCallback(
    (index) => (status) => {
      if (status === 'close') {
        setSelectedIndex(null);
      } else {
        const storeLocationPopupCTAButton = document.querySelector('#storeLocationPopupCTAButton');
        if (storeLocationPopupCTAButton)
          storeLocationPopupCTAButton.scrollIntoView({ behavior: 'smooth' });
        setSelectedIndex(index);
      }
    },
    [setSelectedIndex],
  );

  const markers = useMemo(
    () =>
      locations
        .map((l) => {
          const {
            index,
            name,
            street1,
            street2,
            city,
            state,
            longitude,
            latitude,
            isPreferredLocation,
            ...rest
          } = l || {};
          if (!longitude || !latitude) return null;
          return {
            ...rest,
            index,
            options: {
              position: { lat: latitude, lng: longitude },
              label: `${index}`,
              information: LocationMapItem(
                name,
                street1,
                street2,
                city,
                state,
                isPreferredLocation,
              ),
            },
            onClick: handleMarkerClick(index),
            selected: index === +selectedIndex,
          };
        })
        .filter((l) => l),
    [handleMarkerClick, locations, selectedIndex],
  );

  if (!googleMapApiKey) {
    handleError('Location Map: No Google Map API Key found');
    return null;
  }
  if (!Array.isArray(locations)) return null;

  return (
    <MapWrapper apiKey={googleMapApiKey}>
      <Map
        options={{
          center: {
            lat: centerLatitude,
            lng: centerLongitude,
          },
          zoom,
          ...others.options,
        }}
        styles={{
          ...others.styles,
        }}
      >
        {markers.map((marker) => (
          <Marker
            key={marker.id}
            options={marker.options}
            selected={marker.selected}
            onClick={marker.onClick}
          />
        ))}
      </Map>
    </MapWrapper>
  );
}
