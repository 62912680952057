// @ts-check
import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import * as R from 'ramda';
import { GET_PRODUCT as GET_PRODUCT_V1 } from '../../utils/apis/graphql/productPage/v1/productPage';
import { GET_PRODUCT as GET_PRODUCT_V2 } from '../../utils/apis/graphql/productPage/v2/productPage';
import { isNilOrEmpty } from '../../utils/customRamda';
import { getSocialShareData } from '../../utils/socialShare';
import hasExpired from '../../utils/expiryDate';
import { getTreatments, SplitNames } from '../../utils/splitio';
import { isOfferExpired } from '../../utils/rebates';
import { campaignTypes } from '../../utils/campaignTypes';
import { getHasuraUserId } from '../../utils/userId';

// TODO: refactor data type.
export function useProductPage(slug, reviewId) {
  const [formattedData, setFormattedData] = useState(null);
  const [error, setError] = useState(undefined);
  const [featureFlags, setFeatureFlags] = useState({
    isFeaturesLoaded: false,
    [SplitNames.removeIsFullFromProductPageGraphql]: true,
  });

  const {
    loading,
    error: queryError,
    data,
  } = useQuery(
    featureFlags[SplitNames.removeIsFullFromProductPageGraphql] ? GET_PRODUCT_V2 : GET_PRODUCT_V1,
    {
      variables: {
        slug,
        reviewId,
      },
      context: {
        clientName: 'hasura',
      },
      skip: !slug,
    },
  );

  useEffect(() => {
    getTreatments(
      [
        SplitNames.rebateOfferDeadline,
        SplitNames.useTargetActivations,
        SplitNames.removeIsFullFromProductPageGraphql,
        SplitNames.v2ActivationFeature,
      ],
      getHasuraUserId(),
    ).then((treatments) => {
      setFeatureFlags((prev) => ({
        ...prev,
        ...treatments,
        isFeaturesLoaded: true,
      }));
    });
  }, []);

  useEffect(() => {
    setError(queryError);
  }, [queryError]);

  useEffect(() => {
    if (!featureFlags.isFeaturesLoaded) return;
    if (data) {
      const { samplekits, users } = data;
      if (!samplekits.length) {
        setError({ message: 'URL Error: There is no product.' });
        return;
      }
      const product = !R.isEmpty(samplekits) ? samplekits[0] : {};
      const company = product.companyData || {};
      const images = product.featured_image ? [product.featured_image] : [];
      if (!R.isNil(product.images) && !R.isEmpty(product.images)) {
        // Images type is different between local(json) and staging/production(text)
        if (typeof product.images === 'string') {
          images.push(...JSON.parse(product.images));
        } else {
          images.push(...product.images);
        }
      }
      const qualities = !isNilOrEmpty(product.qualities)
        ? product.qualities.map((quality) => ({
            ...quality.quality,
          }))
        : [];
      const certifications = !isNilOrEmpty(product.certifications)
        ? product.certifications.map((certification) => ({
            ...certification.certification,
          }))
        : [];
      const {
        samplekits_stats: productStats,
        sharing_messages: sharingMessage,
        wants: userWants,
        campaigns,
        reviewsPublic,
      } = product || {};
      const {
        average_rating: averageRating = 0,
        wants: totalWants = 0,
        reviews: totalReviews = 0,
      } = !R.isEmpty(productStats) ? productStats[0] : {};

      const userId = users.length > 0 ? users[0].id : null;

      const socialShare = getSocialShareData(
        sharingMessage,
        product.id,
        product.slug,
        product.name,
        company.name,
        product.featured_image ? product.featured_image.medium : '',
        userId,
      );

      const showBanner =
        !R.isNil(product.banner_message) &&
        !R.isEmpty(product.banner_message) &&
        !hasExpired(product.banner_expiry);

      // Product want disabled toggle from CMS on samplekit page
      const productWantDisabledToggleFromCMS = product.want_disabled;

      // Users cannot apply to try if all campaigns are full or have expired
      let wantDisabled = true;

      // disable non-launchpad if campaigns have filled up
      if (!productWantDisabledToggleFromCMS && campaigns.length) {
        const campaignsAcceptingNewUsers = campaigns.filter((c) => {
          if (featureFlags[SplitNames.removeIsFullFromProductPageGraphql]) {
            if (c.campaign_type === 200) {
              // Rebate offer is expired
              if (isOfferExpired(c.completeByDate)) {
                return false;
              }

              return true;
            }

            return true;
            // eslint-disable-next-line no-else-return
          } else {
            if (c.campaign_type === 200) {
              if (
                featureFlags[SplitNames.rebateOfferDeadline] &&
                isOfferExpired(c.completeByDate)
              ) {
                // Rebate offer is expired
                return false;
              }

              if (featureFlags[SplitNames.useTargetActivations] && c.is_full) {
                // Target activations is reached
                return false;
              }

              return true;
            }

            return c.is_full !== true;
          }
        });

        wantDisabled = campaignsAcceptingNewUsers.length === 0;
      }

      const acceptedWant = userWants.find(
        (w) => w.accepted && campaigns.some((c) => c.id === w.campaign),
      );

      /** Picking the latest campaign - (Technically having highest id or defaults to 0-index) */
      const currentCampaign = (campaigns || []).reduce(
        (max, campaign) => (campaign.id > max.id && campaign.status === 'opened' ? campaign : max),
        campaigns?.[0] || {},
      );

      const isRetargetEnabled = featureFlags[SplitNames.v2ActivationFeature];

      const currentCampaignType = currentCampaign ? currentCampaign.campaign_type : null;
      let appliedWant = null;
      let hasWanted = false;
      if (currentCampaignType === campaignTypes.REBATE) {
        // For Retargeting - We should allow activating same sample-kit multiple times, even though they have redeemed in the past
        // hasWanted = false, User has not redeemed current campaign for the #SK.
        if (isRetargetEnabled && currentCampaign) {
          appliedWant = userWants.find((w) => {
            const appliedAlready = w?.campaign ? w?.campaign === currentCampaign.id : false;
            const isValid =
              appliedAlready && new Date(w.createdAt) > new Date(currentCampaign.startDate);
            return isValid;
          });
          hasWanted = !!appliedWant;
        } else {
          // Offer expired or Accepted in a campaign or applied for a current campaign.
          hasWanted = userWants.some(
            (w) =>
              !!w.campaign ||
              w.accepted ||
              campaigns.some((c) => new Date(w.createdAt) > new Date(c.startDate)),
          );
        }
      } else {
        hasWanted = userWants.length > 0;
      }

      const details = {
        id: product.id || '',
        name: product.name || '',
        slug: product.slug || '',
        featuredImage: product.featured_image || {},
        countries: product.countries || [],
        hasWanted,
        hasBeenAccepted: isRetargetEnabled ? Boolean(appliedWant?.accepted) : !!acceptedWant,
        companyName: company.name || '',
        companySlug: company.slug || '',
        companyEnableStorefront: company.enable_storefront || false,
        companyWebsite: company.website || '',
        qualities,
        certifications,
        totalWants,
        averageRating,
        totalReviews,
        wantDisabled: !product.is_launchpad ? wantDisabled : true,
        isLaunchpad: product.is_launchpad,
      };
      const content = {
        description: product.description || '',
        ingredients: product.ingredients || '',
        recipes: product.recipes || '',
        qualities,
        certifications,
      };
      const coupon = {
        showBanner,
        bannerExpiry: product.banner_expiry || '',
        bannerMessage: product.banner_message || '',
        bannerUrl: product.banner_url || '',
        popupEnabled: product.popup_enabled,
        popupDiscountCode: product.popup_discount_code || '',
        popupMessage: product.popup_message || '',
        popupUrl: product.popup_url || '',
        whereToBuy: product.whereToBuy,
        featuredImage: product.featured_image || [],
        countries: product.countries || [],
      };

      let variants;

      try {
        variants = JSON.parse(currentCampaign.variants);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        variants = [];
      }

      // TODO: If there is more than one campaign,
      // we define them as being of the same type and countries.

      // ENG-1828:
      // Since the pilot for rebate campaigns won’t have multiple campaigns on the same sample kit.
      const campaign =
        campaigns.length > 0
          ? {
              id: currentCampaign.id,
              shortSummary: product.short_summary || '',
              priceValue: product.price_value || '',
              campaignType: currentCampaign.campaign_type || 0,
              campaignCountry: currentCampaign.country || '',
              campaignId: currentCampaign.id || 0,
              completeByDate: currentCampaign.completeByDate,
              isOfferExpired: isOfferExpired(currentCampaign.completeByDate),
              properties: {
                offerDiscountPercentage: currentCampaign.offerDiscountPercentage,
                discountType: currentCampaign.discount_type,
                campaignBogo: JSON.parse(currentCampaign.campaign_bogo),
                moneyCashBackValue: parseFloat(currentCampaign.campaign_money_cashback_value || 0),
                aisle: currentCampaign.campaign_aisle,
              },
              variants,
            }
          : null;

      setFormattedData({
        product: {
          ...product,
          images,
          details,
          socialShare,
          coupon,
          content,
        },
        userId,
        userWant: isRetargetEnabled ? appliedWant : userWants?.[0] || {},
        reviewed: !R.isNil(product.reviews) && !R.isEmpty(product.reviews),
        campaign,
        featuredReview: !R.isEmpty(reviewsPublic) ? reviewsPublic[0] : {},
      });
    }
  }, [data, featureFlags]);

  return { loading, error, data: formattedData };
}
