import React, { useCallback, useContext, useRef, useState } from 'react';
import { ResponsiveContext } from 'grommet';
import { StoreLocationLayout } from '../../Organisms/Product/StoreLocationLayout/index';
import { useModal } from '../useModal';
import { getWantLink } from '../../utils/product/getCallToAction/getWantCallToAction';
import { setProductMatchLocation } from '../../utils/apis/products';

export function useLocationStoreModal(
  initPostalCode,
  product,
  isLoggedIn,
  initOpen,
  onGeoCheckRequested,
  initCTALabel,
  campaignType,
  useNewActivationFlow = false,
  onStoreSelected = () => {},
  guestSessionID = null,
  showTprDiscountLabel=false
) {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const { Modal, open, close } = useModal(initOpen);
  const [CTAlabel, setCTAlabel] = useState(initCTALabel || '');
  const [userSessionState, setUserSessionForModal] = useState({useNewActivationFlow, guestSessionID});

  // useRef needed to solve https://socialnature.atlassian.net/browse/ENG-1907?focusedCommentId=17487
  // see more information on ticket comments
  // shouldGeoCheck when no locations and no free samples and user closes the modal
  const geocheckRef = useRef({ shouldGeoCheck: false, postalCode: initPostalCode });

  const handleOpen = useCallback(
    (labelCTA) => {
      open();
      if (labelCTA) setCTAlabel(labelCTA);
    },
    [open],
  );

  const handleClose = useCallback(() => {
    if (geocheckRef.current.shouldGeoCheck) {
      setProductMatchLocation(geocheckRef.current.postalCode);
      onGeoCheckRequested();
    }
    return close();
  }, [close, onGeoCheckRequested]);

  const WANT_LINK = product && getWantLink(isLoggedIn, product.slug, product.id, userSessionState.useNewActivationFlow, userSessionState.guestSessionID);
  const LocationStoreModal = () => (
    <Modal
      style={{
        maxWidth: 'calc(100% - 32px)',
        width: '1200px',
        padding: isXsmall ? '48px 16px 0px' : '0',
        height: isXsmall ? '700px' : '',
        overflowY: 'scroll',
        display: isXsmall ? 'flex' : '',
        flexDirection: isXsmall ? 'column' : '',
      }}
      onClose={handleClose}
    >
      <StoreLocationLayout
        useNewActivationFlow={userSessionState.useNewActivationFlow}
        product={product}
        postalCode={initPostalCode}
        onClose={handleClose}
        onGeoCheckRequested={onGeoCheckRequested}
        wantLink={WANT_LINK}
        isLoggedIn={isLoggedIn}
        isXsmall={isXsmall}
        CTAlabel={CTAlabel}
        geocheckRef={geocheckRef}
        campaignType={campaignType}
        handleStoreSelected={onStoreSelected}
        showTprDiscountLabel={showTprDiscountLabel}
      />
    </Modal>
  );

  return { LocationStoreModal, open: handleOpen, close: handleClose, setUserSessionForModal };
}
