export function handleError(error, src) {
  // eslint-disable-next-line no-console
  console.error(error);
  if (src && typeof window.rg4js === 'function') {
    window.rg4js('send', {
      error: `${src}  ${error}`,
      tags: [src],
    });
  }
}
