import React from 'react';
import StorefrontItem from './StorefrontItem';
import LaunchpadItem from './LaunchpadItem';
import ReactPlayer from 'react-player';

function StorefrontSection(props) {
  const { title, body } = props;
  return (
    <React.Fragment>
      <div className="storefront-subheader">
        <h2>{title}</h2>
      </div>
      <div className="storefront-modules">{body}</div>
      <div className="nudge-lg"></div>
    </React.Fragment>
  );
}

export default class Storefront extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      videoPlaying: false,
    };
  }

  renderHeader({ banner, logo, name }) {
    return (
      <div className="storefront-header" style={banner && { backgroundImage: `url(${banner})` }}>
        <div className="flex-100 storefront-header-logo">
          {logo && <img src={logo} alt={name} />}
        </div>
      </div>
    );
  }

  handleOnPlay = () => {
    this.setState({
      videoPlaying: true,
    });
  };

  render() {
    const { products, ratings, launchpadProducts, userWantedSamples } = this.props;
    const company = this.props.company || {};
    const hasStory = !!(company && (company.description || company.videoUrl));

    return (
      <div className="storefront-container">
        {this.renderHeader({ ...company })}
        <div className="nudge-lg"></div>
        <div className="storefront-container-inner">
          {hasStory && (
            <StorefrontSection
              title="Our Story"
              body={
                <>
                  {company.videoUrl && (
                    <div className="storefront-story-video">
                      <ReactPlayer
                        playing={this.state.videoPlaying}
                        onPlay={this.handleOnPlay}
                        width="100%"
                        height="100%"
                        url={company.videoUrl}
                        controls={true}
                        className="reactPlayer"
                      />
                    </div>
                  )}
                  {company.description && (
                    <div className="storefront-story-description">
                      <p>{company.description}</p>
                    </div>
                  )}
                </>
              }
            />
          )}

          {products != null && products.length > 0 && (
            <StorefrontSection
              title="Discover better-for-you products"
              body={products.map(function (product, key) {
                const samplekitID = product.id;
                const wanted = userWantedSamples.indexOf(samplekitID) >= 0;

                return (
                  <div className="storefront-featured" key={key}>
                    <StorefrontItem sample={product} wanted={wanted} />
                  </div>
                );
              })}
            />
          )}
          {launchpadProducts != null && launchpadProducts.length > 0 && (
            <StorefrontSection
              title="Boost these innovative products"
              body={launchpadProducts.map(function (launchpadProduct, key) {
                const sampleId = launchpadProduct.sampleKit.id;
                const wanted = userWantedSamples.indexOf(sampleId) >= 0;
                return (
                  <div className="storefront-boost" key={key}>
                    <LaunchpadItem sample={launchpadProduct} wanted={wanted} />
                  </div>
                );
              })}
            />
          )}
          {ratings != null && ratings.length > 0 && (
            <StorefrontSection
              title="Read Reviews For These Products"
              body={ratings.map(function (rating, key) {
                return (
                  <div className="storefront-review" key={key}>
                    <StorefrontItem sample={rating} />
                  </div>
                );
              })}
            />
          )}
        </div>
      </div>
    );
  }
}
