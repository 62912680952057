import { SplitNames, isFeatureOn } from '../../splitio';
import { getCookie } from '../../cookie';
import { handleError } from '../../error';

const host = process.env.API_V2_URL;
const captchaSiteKey = process.env.CAPTCHA_SITE_KEY;
const rebateEndpoint = `${host}api/securitycheck`;

/**
 * @param {string} token
 * @param {string} action
 * @returns {Promise<{error: boolean}>}
 */
export const validateCaptcha = (token, action, version = 'v3', email = 'noemail') => {
  const data = {
    token,
    expectedAction: action,
    userAgent: navigator.userAgent,
    email,
  };

  const path = version === 'v3' ? 'assess' : 'assess-v2';

  return fetch(`${rebateEndpoint}/${path}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getCookie('User_Token')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.json();
    })
    .then((res) => ({ success: true, response: res }))
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });
};

/**
 * @param {string} action
 * @returns {Promise<string>}
 */
export const getCaptchaToken = (action) =>
  new Promise((resolve) => {
    grecaptcha.enterprise.ready(async () => {
      const token = await grecaptcha.enterprise.execute(captchaSiteKey, {
        action,
      });

      resolve(token);
    });
  });

/**
 * @param {string} action
 * @param {boolean} isFeatureOn
 * @returns {Promise<void>}
 */
export const validateCaptchaAction = async (action) => {
  const featureOn = await isFeatureOn(SplitNames.captchaValidation, 'user', { action });

  if (!featureOn) {
    return Promise.resolve({ success: true });
  }

  return getCaptchaToken(action).then((token) => validateCaptcha(token, action));
};

// TODO:
export const renderCaptcha = (elementId) => {
  grecaptcha.enterprise.ready(() => {
    grecaptcha.enterprise.render(elementId, {
      sitekey: captchaSiteKey,
      theme: 'light',
    });
  });
};
