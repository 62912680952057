import React, { useMemo } from 'react';
import { DateInput } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { isNil } from 'ramda';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { errorMessages } from '../../../utils/customQuestionTypes';

dayjs.extend(isBetween);

const DueDateInput = (props) => {
  const { name, error, question } = props;
  const { questionId, required = true, validate } = question || {};
  const { control } = useFormContext();

  const validations = useMemo(() => {
    const v = {
      valid: (value) => (value !== 'Invalid Date' ? true : errorMessages.INVALID_DATE),
    };
    if (validate && validate.allowFutureYear) {
      const start = dayjs().set('date', 1);
      const end = dayjs().add(validate.allowFutureYear, 'year').set('month', 11).set('date', 31);
      v.validDate = (value) =>
        dayjs(value).isBetween(start, end, 'date', '[]]') ? true : errorMessages.INVALID_DATE;
    }
    return v;
  }, [validate]);

  return (
    <Controller
      id={questionId}
      name={name}
      as={DateInput}
      control={control}
      error={!isNil(error)}
      rules={{
        required: required && errorMessages.REQUIRED,
        validate: { ...validations },
      }}
      noDay
    />
  );
};

export default DueDateInput;
