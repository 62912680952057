import React, { useMemo } from 'react';
import { DateInput } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { isNil } from 'ramda';
import { errorMessages } from '../../../utils/customQuestionTypes';

const CustomDateInput = (props) => {
  const { name, error, question } = props;
  const { required = true, validate } = question;
  const { control } = useFormContext();

  const validations = useMemo(() => {
    const v = {
      valid: (value) => (value !== 'Invalid Date' ? true : errorMessages.INVALID_DATE),
    };
    if (validate && validate.notFuture) {
      v.notFuture = (value) => (dayjs(value).isBefore(dayjs()) ? true : errorMessages.INVALID_DATE);
    }
    if (validate && validate.oldestYear) {
      // NOTE: didn't validate oldestYear format (e.g.'yyyy-mm-dd').
      // currently, it comes from profileQuestionTypes.js only (hardcoded)
      v.oldestYear = (value) =>
        !dayjs(value).isBefore(validate.oldestYear, 'year') ? true : errorMessages.INVALID_DATE;
    }

    if (validate && validate.olderThan) {
      const dateOlderThan = new Date();
      dateOlderThan.setFullYear(dateOlderThan.getFullYear() - validate.olderThan.value);

      v.olderThan = (value) =>
        !dayjs(value).isAfter(dateOlderThan) ? true : validate.olderThan.message;
    }
    return v;
  }, [validate]);

  return (
    <Controller
      name={name}
      as={DateInput}
      control={control}
      error={!isNil(error)}
      rules={{
        required: required && errorMessages.REQUIRED,
        validate: { ...validations },
      }}
    />
  );
};

export default CustomDateInput;
