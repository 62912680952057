import React from 'react';
import { ResponsiveContext } from 'grommet';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import Email from '../../../Atoms/Icons/sns/Email';
import { getDataset } from '../../../utils/socialShare';

const ShareButton = (props) => {
  const { subject, body, variant } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const dataAttributes = getDataset(props);

  return (
    <ShareButtonStyle
      isXsmall={isXsmall}
      href={`mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`}
      icon={<Email />}
      label="Email"
      {...dataAttributes}
      variant={variant}
    />
  );
};

export default ShareButton;
