import React, { useEffect, useState } from 'react';
import Loading from '../../Molecules/Loading';
import ErrorPage from '../ErrorPage';
import { deleteCookie, getCookie, getSelectedCampaignForSamplekit } from '../../utils/cookie';
import { activateCampaignApiV2 } from '../../utils/apis/campaigns';
import { getHasuraUserId } from '../../utils/userId';
import { doesUserPrequalifyForCampaign } from '../../utils/apis/qualificationengine';

/** THIS PAGE IS FOR USERS WHO LOGIN(Redirected to Signin Page) AFTER CLICKING ON ACTIVATE USING NEW ACTIVATION FLOW */
export const PendingActivationV2Page = ({ slug }) => {
  const [loading, setLoading] = useState(true);
  const [er, setError] = useState(null);

  useEffect(() => {
    const isActivationPending = getCookie('pending-activation');
    if (!isActivationPending) {
      window.location.href = '/'; // Home Page
    }

    (async () => {
      try {
        const campaignDetails = await getSelectedCampaignForSamplekit(slug);
        if (campaignDetails) {
          deleteCookie('pending-activation');
          /** Run a recruitment check before we activate for signin-redirected users */
          const { isQualified, campaignId } = await doesUserPrequalifyForCampaign(
            Number(getHasuraUserId()),
            campaignDetails.campaignId,
          );
          if (!isQualified || !campaignId) {
            window.location.href = `/invitepending/${slug}`;
            return;
          }
          await activateCampaignApiV2(
            campaignDetails.campaignId,
            { slug },
            campaignDetails.campaignLocationId,
          );
        } else {
          throw new Error('Invalid Campaign Details');
        }
      } catch (_) {
        setLoading(false);
        setError('error');
      }
    })();
  }, [slug]);

  if (loading) return <Loading />;
  if (er) return <ErrorPage />;

  return <></>;
};
