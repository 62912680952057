import React from 'react';
import { Box, Layer, ResponsiveContext } from 'grommet';
import FilterDesktop from './desktop/FilterDesktop';
import FilterButton from './FilterButton';
import FilterMobile from './mobile/FiltersMobile';
import FilterQueryContext from '../../contexts/FilterQueryContext';
import FilterSelect from './FilterSelect';

function getFilterCount(filterQueryMap) {
  let count = 0;
  filterQueryMap.forEach((value, key) => {
    if (key !== 'country') {
      count += value.size;
    }
  });
  return count !== 0 ? count : null;
}

const Filters = ({ filters, filterQueryMap, totalCount }) => {
  const [open, setOpen] = React.useState(false);
  const size = React.useContext(ResponsiveContext);

  if (!filters) return <></>;

  const categoryFilter = [...filters];
  let countryFilter = [];
  // Split into country filter and the other filter
  const countryIndex =
    categoryFilter && categoryFilter.findIndex((filter) => filter.name === 'Country');
  if (countryIndex > -1) {
    countryFilter = categoryFilter.splice(countryIndex, 1);
  }

  return (
    <FilterQueryContext.Provider value={filterQueryMap}>
      {size !== 'large' ? (
        <>
          <Box margin={{ bottom: 'small' }}>
            <FilterSelect filter={countryFilter[0]} />
          </Box>
          <FilterButton count={getFilterCount(filterQueryMap)} onClick={() => setOpen(true)} />
        </>
      ) : (
        <FilterDesktop countryFilter={countryFilter} filters={categoryFilter} />
      )}
      {open && (
        <Layer onEsc={() => setOpen(false)} onClickOutside={() => setOpen(false)} full>
          <Box background="body" height={{ min: '100%' }}>
            <FilterMobile
              filters={categoryFilter}
              close={() => setOpen(false)}
              totalCount={totalCount}
            />
          </Box>
        </Layer>
      )}
    </FilterQueryContext.Provider>
  );
};

export default Filters;
