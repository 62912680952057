import React from 'react';
import { Paragraph } from 'grommet';

const Description = ({ text }) => (
  <Paragraph size="small" color="black" margin="none">
    {text}
  </Paragraph>
);

export default Description;
