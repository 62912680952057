/**
 * Converts a numeric value into its cardinal representation.
 * For example, 1 becomes "one," 2 becomes "two," etc.
 * @param {number} value - The numeric value to convert to cardinal.
 * @returns {string} The cardinal representation of the input value.
 */
export function numberToCardinal(value) {
  // Special cases for numbers up to 19
  const special = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'fourteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];

  return special[value];
}

/**
 * Converts a numeric value into its ordinal representation.
 * For example, 1 becomes "first," 22 becomes "twenty-second," etc.
 * @param {number} value - The numeric value to convert to ordinal.
 * @returns {string} The ordinal representation of the input value.
 */
export function numberToOrdinal(value) {
  // Special cases for numbers up to 19
  const special = [
    'zeroth',
    'first',
    'second',
    'third',
    'fourth',
    'fifth',
    'sixth',
    'seventh',
    'eighth',
    'ninth',
    'tenth',
    'eleventh',
    'twelfth',
    'thirteenth',
    'fourteenth',
    'fifteenth',
    'sixteenth',
    'seventeenth',
    'eighteenth',
    'nineteenth',
  ];

  // Prefixes for numbers from 20 to 90 (excluding multiples of 10)
  const deca = ['twent', 'thirt', 'fort', 'fift', 'sixt', 'sevent', 'eight', 'ninet'];

  // Handling special cases for numbers less than 20
  if (value < 20) {
    return special[value];
  }

  // Handling multiples of 10
  if (value % 10 === 0) {
    return `${deca[Math.floor(value / 10) - 2]}ieth`;
  }

  // Handling other cases combining deca and special values
  const firstValue = deca[Math.floor(value / 10) - 2];
  const secondValue = special[value % 10];

  // Returning an empty string if either firstValue or secondValue is undefined
  if (!firstValue || !secondValue) {
    return '';
  }

  // Combining the deca and special values with a hyphen
  return `${firstValue}y-${secondValue}`;
}

export function formatToCountryPrefix(phoneNumber) {
  // Remove all non-digit characters from the phone number and trim whitespace
  if (!phoneNumber) {
    return phoneNumber;
  }
  const cleanedNumber = phoneNumber.replace(/\D/g, '').trim();

  // Apply the appropriate formatting based on the length of the number
  let formattedNumber;
  if (cleanedNumber.length === 10) {
    formattedNumber = cleanedNumber.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else if (cleanedNumber.length === 11) {
    formattedNumber = cleanedNumber.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '+$1 ($2) $3-$4');
  } else {
    formattedNumber = cleanedNumber; // Return as is if length is not 10 or 11
  }

  return formattedNumber;
}

export const formatPhoneNumber = (number) => {
  if (!number) return number; // Handle null or empty input

  // Remove all non-digit characters, but keep the leading '+'
  const cleaned = number.replace(/(?!^\+)\D/g, '');
  const length = cleaned.length;

  // Handle 10-digit numbers directly
  if (length === 10) {
    return `+1 (${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
  }

  // Handle 11-digit numbers starting with '1'
  else if (length === 11 && cleaned[0] === '1') {
    return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
  } else if (length > 11) {
    // If there's a leading +1 or '1', ignore it for formatting purposes
    if (cleaned.startsWith('+1')) {
      return formatPhoneNumber(cleaned.slice(2)); // Remove '+1' and reformat
    } else if (cleaned.startsWith('1')) {
      return formatPhoneNumber(cleaned.slice(1)); // Remove '1' and reformat
    }
  }

  return number;
};
