import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SimpleCheckBox from '../../Atoms/SimpleCheckBox';
import FilterQueryContext from '../../contexts/FilterQueryContext';
import { createQuery, deleteSlug } from '../../utils/productFilter';

const FilterCheckBox = (props) => {
  const { type, slug, name, qty } = props;
  const filterQueryMap = React.useContext(FilterQueryContext);
  const history = useHistory();
  const { qualitySlug } = useParams();

  const updateFilter = (curType, curSlug, event) => {
    const { checked } = event.currentTarget;

    if (curSlug === qualitySlug && !checked) {
      window.location.href = `/search?${createQuery(deleteSlug(filterQueryMap, curType, curSlug))}`;
    }

    if (checked) {
      window.snTrackEventsModule.trackEvent(
        '',
        'All Products',
        'Selected',
        window.location.search,
        null,
      );
      const value = filterQueryMap.get(curType) || new Set();
      filterQueryMap.set(curType, value.add(curSlug));
      history.push(`?${createQuery(filterQueryMap)}`);
    } else {
      history.push(`?${createQuery(deleteSlug(filterQueryMap, curType, curSlug))}`);
    }
  };

  const getChecked = (curType, curSlug) => {
    if (!filterQueryMap.has(curType)) {
      return false;
    }
    const value = filterQueryMap.get(curType);
    return value.has(curSlug);
  };

  return (
    <SimpleCheckBox
      id={`${type}-${slug}`}
      checked={getChecked(type, slug)}
      onChange={(event) => updateFilter(type, slug, event)}
      label={name}
      number={qty}
    />
  );
};

export default FilterCheckBox;
