import React, { useState, useMemo } from 'react';
import { ArrowDown, Box, CheckBoxGroup, TextLink } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { isNil } from 'ramda';
import { errorMessages } from '../../../utils/customQuestionTypes';

const LIMIT = 24;

const CheckBoxGroupWithLoadMore = (props) => {
  // Other option is not supported
  const { name, error, question } = props;
  const { choices: optionsProp, noneOption, required = true, loadMore } = question || {};
  const { control } = useFormContext();
  const maxLength = useMemo(() => (optionsProp || []).length, [optionsProp]);
  const [options, setOptions] = useState((optionsProp || []).slice(0, LIMIT));
  const onClick = () => {
    setOptions((optionsProp || []).slice(0, options.length + LIMIT));
  };

  return (
    <>
      <Controller
        name={name}
        as={CheckBoxGroup}
        control={control}
        options={options}
        noneOption={noneOption}
        error={!isNil(error)}
        length={LIMIT}
        rules={{
          required: errorMessages.REQUIRED,
          validate:
            required && ((value) => (value && value.length !== 0 ? true : errorMessages.REQUIRED)),
        }}
      />
      {options.length < maxLength && (
        <Box margin={{ top: '16px' }} align="center">
          <TextLink
            icon={<ArrowDown color="blue" />}
            iconRight
            label={loadMore}
            fontWeight="bold"
            onClick={onClick}
          />
        </Box>
      )}
    </>
  );
};

export default CheckBoxGroupWithLoadMore;
