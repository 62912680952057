import React, { useContext } from 'react';
import { Grommet, ResponsiveContext } from 'grommet';
import { Box, Text, TextLink, themes } from 'socialnature-ui';
import styled from 'styled-components';
import { Error as Question } from 'socialnature-ui/build/icons';
import { Tooltip } from 'socialnature-ui/build/components/Tooltip';
import { StyledEditIcon, StyledLocationText } from '../../Molecules/Buttons/LocationSelectorButton';
import theme from '../../themes';
import getUserId from '../../utils/userId';
import { campaignTypes, storeLocationCtaLabels } from '../../utils/campaignTypes';

const QuestionIconWrapper = styled.span`
  display: inline-block;
  line-height: 0;
  vertical-align: top;
  transform: rotate(180deg);
`;

function GetNoSampleMessage({ campaignType }) {
  if (campaignType === campaignTypes.IN_STORE || campaignType === campaignTypes.REBATE) {
    return (
      <>
        <span>Sorry! There are</span>
        <Text as="span" size="small" weight="bold">
          {' '}
          no eligible locations{' '}
        </Text>
        <span>to redeem this offer near you </span>
      </>
    );
  }
  return (
    <>
      <span>Sorry! There is</span>
      <Text as="span" size="small" weight="bold">
        {' '}
        no delivery available{' '}
      </Text>
      <span>for this sample near </span>
    </>
  );
}
export const tooltip =
  'We use the ZIP/Postal Code you provided when signing up. You can change it on the My Address page.';

const NoSamplesInArea = ({
  alternateGeoProducts,
  openGeoModal,
  location,
  campaignType,
  openStoreLocationModal,
}) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const userId = getUserId();

  const travelToSamples = (e) => {
    e.preventDefault();
    const element = document.querySelector('[rel=GeoProductCarousel]');
    if (element) {
      const yOffset = -50;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

  const openModal = (e) => {
    e.preventDefault();
    const storeLocatorCTALabel = storeLocationCtaLabels[campaignType];
    if (campaignType === campaignTypes.IN_STORE || campaignType === campaignTypes.REBATE) {
      openStoreLocationModal(storeLocatorCTALabel);
    } else {
      openGeoModal(location);
    }
  };

  if (!location) return null;

  return (
    <Grommet theme={themes}>
      <Box
        width={{ max: '798px' }}
        background={theme.global.colors.lightPink}
        round="8px"
        margin={{ horizontal: 'auto' }}
        pad={{
          top: isXsmall ? 'small' : 'xsmall',
          right: isXsmall ? 'large' : 'medium',
          bottom: isXsmall ? 'small' : 'xsmall',
          left: isXsmall ? 'large' : 'medium',
        }}
      >
        <Text size="small" textAlign="center" margin={{ top: '0', bottom: '0' }}>
          <GetNoSampleMessage campaignType={campaignType} />
          {userId ? (
            <span style={{ whiteSpace: 'nowrap' }}>
              <span fontWeight="bold" style={{ textTransform: 'uppercase' }}>
                {location}{' '}
              </span>
              <Tooltip content={tooltip} maxWidth={isXsmall ? 'calc(100vw - 32px)' : '280px'}>
                <QuestionIconWrapper>
                  <Question width="18" height="18" color="launchpad" />
                </QuestionIconWrapper>
              </Tooltip>
            </span>
          ) : (
            <>
              <StyledLocationText
                as="span"
                cursor="pointer"
                weight="bold"
                size="small"
                color="launchpad"
                onClick={openModal}
              >
                <>
                  {location}
                  <StyledEditIcon htmlColor="#08ADCD" />
                </>
              </StyledLocationText>
            </>
          )}
        </Text>
        {alternateGeoProducts && alternateGeoProducts.length > 0 && (
          <Text
            className="desktop"
            size={isXsmall ? 'small' : 'medium'}
            textAlign="center"
            margin={{ top: 'xsmall', bottom: '0' }}
          >
            <TextLink
              href="/products"
              onClick={travelToSamples}
              label="VIEW SAMPLES NEAR YOU &gt;"
              fontWeight="bold"
            />
          </Text>
        )}
      </Box>
    </Grommet>
  );
};

export default React.memo(NoSamplesInArea);
