import React from 'react';
import styled from 'styled-components';
import { Button, Box } from 'grommet';
import Cross from '../../../Atoms/Icons/Cross';
import CTAButton from '../../../Atoms/Buttons/CTAButton';
import Container from '../../../Atoms/Container';
import H from '../../../Atoms/Heading';
import FilterCategory from './FilterCategory';
import FilterClearButton from '../FilterClearButton';

const FilterMobile = ({ filters, close, totalCount }) => (
  <>
    <Header>
      <Title>FILTERS</Title>
      <CloseButton>
        <Button
          plain
          icon={<Cross color="text" titleAccess="close" />}
          onClick={close}
          hoverIndicator="white"
        />
      </CloseButton>
    </Header>
    <Scrollable>
      <Container mobile>
        <Box justify="between" direction="row" align="center" margin={{ top: 'medium' }}>
          <H level={2}>Filters</H>
          <FilterClearButton />
        </Box>
        <Box pad={{ bottom: '160px' }}>
          {filters &&
            filters.map((filter) => (
              <FilterCategory key={filter.name} name={filter.name} categories={filter.filters} />
            ))}
        </Box>
      </Container>
    </Scrollable>
    <Footer>
      <Container mobile>
        <Box align="center">
          <CTAButton label={`SEE ${totalCount} RESULTS`} fill="horizontal" onClick={close} />
        </Box>
      </Container>
    </Footer>
  </>
);

export default FilterMobile;

const Header = styled.header`
  background: #ffffff;
  padding: 8px 0;
  position: relative;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.05);
`;

const Footer = styled.footer`
  background: #ffffff;
  padding: 16px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -8px 32px rgba(0, 0, 0, 0.05);
`;

const Scrollable = styled.div`
  overflow-y: scroll;
  margin-bottom: 32px;
`;

const Title = styled.p`
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 16px;
  margin: 0;
`;

const CloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 0;
  height: 100%;
  display: flex;
`;
