import React, { useState } from 'react';
import styled from 'styled-components';
import { Avatar, Box, Card, CardContent, Text, SecondaryButton } from 'socialnature-ui';
import PersonalInformationForm from './PersonalInformationForm';
import { getAvatarUrl } from '../../../utils/reviews';
import { getCookie } from '../../../utils/cookie';

const EmailText = styled(Text)`
  overflow: hidden;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PersonalInformation = ({ data: dataProp, submitCallback }) => {
  const { avatar, email, firstName, lastName, postalCode, ...other } = dataProp;
  const [edit, setEdit] = useState(false);
  const [userInfo, setUserInfo] = useState({
    avatar,
    email,
    firstName,
    lastName,
    postalCode,
  });

  const handleOpen = () => {
    setEdit(true);
  };

  const handleEditAddress = () => {
    window.location.href = '/account/address';
  };

  const tmpFirstName = userInfo.firstName;
  const isCookieConsent = getCookie('cookies-marketing') === 'true';

  return (
    <Card noPadding>
      <CardContent pad="medium">
        {edit ? (
          <PersonalInformationForm
            {...other}
            {...userInfo}
            setUserInfo={setUserInfo}
            setEdit={setEdit}
            submitCallback={submitCallback}
          />
        ) : (
          <>
            <Box direction="row" gap="medium">
              <Avatar
                size="64px"
                alt={tmpFirstName}
                src={getAvatarUrl(userInfo.avatar, isCookieConsent)}
                fallback="/images/default-user.png"
              />
              <Box justify="center">
                <Text weight="bold">{`
                  ${tmpFirstName.charAt(0).toUpperCase() + tmpFirstName.slice(1).toLowerCase()} ${userInfo.lastName ? userInfo.lastName.charAt(0).toUpperCase() : ''}
                `}</Text>
                <EmailText margin={{ top: '4px', bottom: '0px' }}>{userInfo.email}</EmailText>
              </Box>
            </Box>
            <Box margin={{ top: 'medium' }}>
              <Text weight="bold">Password</Text>
              <Text margin={{ top: '8px' }}>******</Text>
              <Box align="start">
                <SecondaryButton
                  label="edit personal info"
                  fill="horizontal"
                  onClick={handleOpen}
                  data-track-category="Account Profile"
                  data-track-action="Edit Personal Info"
                  />
                <SecondaryButton
                  margin={{ top: '8px' }}
                  label="edit address"
                  fill="horizontal"
                  onClick={handleEditAddress}
                  data-track-category="Account Profile"
                  data-track-action="edit address"
                />
              </Box>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default PersonalInformation;
