import React from 'react';
import { Box } from 'grommet';
import Count from '../../Molecules/ProductCard/Count';
import ProgressBar from '../../Atoms/ProgressBar';
import hasExpired from '../../utils/expiryDate';
import getPercentage from '../../utils/boostPercentage';
import { CompanyName, ProductName } from '../../Molecules/ProductCard/Name';
import Card from '../../Atoms/Card/Card';
import CardAction from '../../Atoms/Card/CardAction';
import CardBody from '../../Atoms/Card/CardBody';
import CardImage from '../../Atoms/Card/CardImage';
import CardContent from '../../Atoms/Card/CardContent';
import CardFooter from '../../Atoms/Card/CardFooter';
import CardBadge from '../../Atoms/Card/CardBadge';
import Description from '../../Molecules/ProductCard/Description';

const LaunchpadCard = (props) => {
  const { product, width, height, dataTrackCategory, index } = props;

  const getBadge = ({ bannerMessage, bannerExpiry }) => {
    // If wantDisabled is false, the product is in Campaign (people can want it)
    // Don't need to show free trial badge so far
    // if (!wantDisabled) return 'FREE TRIAL';
    if (bannerMessage && !hasExpired(bannerExpiry)) return bannerMessage;
    return null;
  };

  const { name, slug, companyName, shortDescription, featuredImage, totalWants } = product;
  const productLink = `/${slug}`;
  const badge = getBadge(product);

  return (
    <Card width={width} height={height}>
      <CardAction
        href={productLink}
        data-track-category={dataTrackCategory}
        data-track-action={dataTrackCategory && `Suggested ${index + 1}`}
        data-track-label={dataTrackCategory && slug}
      >
        <CardBody>
          <CardImage src={featuredImage} />
          <CardContent>
            <CompanyName>{companyName}</CompanyName>
            <ProductName>{name}</ProductName>
            {shortDescription && <Description text={shortDescription} />}
          </CardContent>
          <CardFooter>
            <Box>
              <Count type="launchpad" value={totalWants} />
              <ProgressBar value={getPercentage(totalWants)} />
            </Box>
          </CardFooter>
        </CardBody>
        {badge && <CardBadge text={badge} color="blue" />}
      </CardAction>
    </Card>
  );
};

export default LaunchpadCard;
