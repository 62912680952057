import { add } from './date';

export const receiptValidationStatusEnum = {
  IN_PROGRESS: 'IN PROGRESS',
  SUCCESS: 'SUCCESS',
  REJECTED: 'REJECTED',
  MANUAL_CHECK: 'MANUAL CHECK',
  PENDING: 'PENDING',
};

export const redemptionCashbackEmailStatusEnum = {
  EXECUTED: 'EXECUTED',
};

export const getOfferExpiredDate = (createdAt, wantProperties) => {
  const activations = (wantProperties && wantProperties.activations) || [];

  if (activations.length > 0) {
    const latestActivation = activations[activations.length - 1];
    return add(latestActivation, 7, 'day');
  }

  return add(createdAt, 7, 'day');
};

export const parseRebateReceiptError = (errorString) => {
  if (!errorString) {
    return {};
  }
  try {
    return JSON.parse(errorString);
  } catch (e) {
    return {};
  }
};

export const isOfferExpired = (expiryDate) => {
  const DATE_REGEX = /(\d{4})-(\d{2})-(\d{2})/;
  const matches = DATE_REGEX.exec(expiryDate) || [];

  if (matches.length > 2) {
    const [, year, month, date] = matches;
    // Creating date deadline in PST time
    // Adding 1 day to completeByDate in order to have offer active until 11:59:59pm PST
    const deadline = new Date(Date.UTC(year, Number(month) - 1, Number(date) + 1, 8));
    const offerExpired = new Date() >= deadline;

    return offerExpired;
  }

  return false;
};

export const REBATE_PROCESSING_DELAY_HOURS = 48;
