import React from 'react';
import Modal from 'react-modal';
import { Box, Text } from 'socialnature-ui';
import ProductSocialShare from './ProductSocialShare';
import { CTATypes } from '../../utils/product/index';
import { Search } from '../../Atoms/Icons/Search';
import BellIcon from '../../Atoms/Icons/Bell'
import { CTAMessage } from './ProductCTAButton';

export default class ProductMobileFooter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  }

  closeModal(e) {
    e.preventDefault();
    this.setState({ modalIsOpen: false });
  }

  render() {
    const { modalIsOpen } = this.state;
    const { socialShare, ProductCTAButton, campaignStatus, ctaType } = this.props;
    const customStyles = {
      overlay: {
        zIndex: '50000',
        background: 'transparent',
        outline: 'none',
      },
    };

    return (
      <div className="footer-boost">
        {
          ctaType === CTATypes.SIGNUP_FOR_UPDATES &&
          <CTAMessage message='Be the first to know about new offers' icon={<BellIcon width={18} />} />
        }

        {
          ctaType === CTATypes.WHERE_TO_BUY &&
          <CTAMessage message='This offer is no longer available' icon={null} />
        }
        <Box direction="row" justify="center" width="100%">{this.props.ReceiptLoadingCopy}</Box>
        <Box direction="row" width="100%" pad={{ vertical: '8px', horizontal: '16px' }}>
          {ProductCTAButton}
          <div className="react-tooltip-trigger">
            <button type="button" className="link-share" onClick={this.openModal}>
              <i className="fas fa-share-alt" />
            </button>
          </div>
        </Box>
        {campaignStatus && (
          <Box
            direction="row"
            align="center"
            justify="center"
            gap="xsmall"
            background="launchpad"
            width="100%"
            pad={{ vertical: '8px' }}
          >
            {ctaType === CTATypes.ADD_A_RECEIPT && <Search color="#FFFFFF" />}
            <Text textAlign="center" weight="bold" size="small" color="white">
              {campaignStatus}
            </Text>
          </Box>
        )}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={this.closeModal}
          style={customStyles}
          className="react-modal slide-up mobile"
          ariaHideApp={false}
        >
          <ProductSocialShare viewModel={socialShare} />
        </Modal>
      </div>
    );
  }
}

