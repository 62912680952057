import React from 'react';

const Twitter = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.0311 5.2586C19.3368 5.6642 18.5672 5.95813 17.7486 6.11653C17.0941 5.42953 16.1603 5 15.1262 5C13.1423 5 11.5335 6.58257 11.5335 8.53541C11.5335 8.81232 11.5655 9.08114 11.6258 9.33981C8.63979 9.19281 5.99238 7.78571 4.21998 5.64718C3.91098 6.169 3.73315 6.7763 3.73315 7.42455C3.73315 8.6505 4.36875 9.73176 5.3322 10.3652C4.74386 10.347 4.1892 10.1886 3.70467 9.9243C3.70467 9.93795 3.70467 9.9539 3.70467 9.96873C3.70467 11.68 4.94338 13.1085 6.58751 13.4343C6.28543 13.5151 5.96845 13.5585 5.64008 13.5585C5.40859 13.5585 5.18396 13.5357 4.96394 13.4947C5.4223 14.8984 6.74828 15.9204 8.32051 15.9488C7.0903 16.8969 5.54194 17.4619 3.8574 17.4619C3.56666 17.4619 3.28158 17.4448 3 17.4129C4.59108 18.4156 6.48028 19 8.50862 19C15.1182 19 18.7313 13.6154 18.7313 8.94443C18.7313 8.79176 18.729 8.63909 18.7222 8.48755C19.4234 7.98966 20.0334 7.36641 20.5145 6.65772C19.8704 6.93913 19.1783 7.12831 18.4509 7.21374C19.1931 6.77624 19.7621 6.08463 20.0311 5.2586Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default Twitter;
