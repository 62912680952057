// @ts-check
import React from 'react';
import { PrimaryButton } from 'socialnature-ui';

// Have not checked if the product is for different country from user
/**
 * @typedef {{
 * color: 'blue' | 'pink',
 * label: string,
 * href: string,
 * onClick: Function,
 * productId: number,
 * productSlug: string,
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function Banner(props) {
  const { color, label, href, onClick, productId, productSlug } = props;

  return (
    <PrimaryButton
      label={label}
      href={href}
      onClick={onClick}
      color={color}
      size="small"
      gap="xsmall"
      target="_blank"
      rel="noopener"
      data-track-category="Product-Button"
      data-track-action={label}
      data-track-samplekit-id={productId}
      data-track-label={productSlug}
    />
  );
}
