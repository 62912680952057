import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { Form, ResponsiveContext } from 'grommet';
import { Box, Text, TextInput, themes } from 'socialnature-ui';
import InlineMessage from '../../../Atoms/Form/InlineMessage';
import Position from '../../../Atoms/Position';
import PrimaryButton from '../../../Atoms/Buttons/PrimaryButton';

const SendButton = styled(PrimaryButton)`
  height: 37.6px;
  ${(props) =>
    !props.isXsmall
      ? `
			padding-top: 10px;
			padding-bottom: 10px;
      height: 41.6px;
		`
      : `height: 37.6px;`}
`;

// TODO: Wrapper style is to overlap site.css
const Wrapper = styled.div`
  position: relative;
  input {
    width: 100%;
    box-shadow: none;
    font-weight: normal;
    font-size: 16px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid ${themes.global.colors.silver};
    margin: 0;
    height: auto;
    padding: 7px 16px;
    &:focus {
      background: #fff;
      border: 1px solid ${themes.global.colors.launchpad};
    }
  }
`;

const ShareEmailForm = ({
  productId,
  productSlug,
  buttonLabel,
  emailLabel,
  dataTrackCategory,
  dataTrackAction,
  dataTrackLabel,
  sendEmail,
  onClick,
}) => {
  const [email, setEmail] = useState('');
  const [transmission, setTransmission] = useState(false);
  const [error, setError] = useState(false);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  // eslint-disable-next-line no-useless-escape
  const regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setEmail(value);
    if (transmission) setTransmission(false);
    if (error) setError(false);
  };

  if (typeof sendEmail !== 'function') {
    return null;
  }

  const handelSendEmail = async () => {
    try {
      await sendEmail(email);
      setEmail('');
      setTransmission(true);
    } catch (err) {
      console.error(err); // eslint-disable-line no-console
      setError(true);
    }
  };

  return (
    <Form
      data-track-action={dataTrackAction || 'Click Email Share Popup'}
      data-track-category={dataTrackCategory || 'Social Share Modal'}
      data-track-label={dataTrackLabel || productSlug}
      data-track-samplekit-id={productId}
      onSubmit={handelSendEmail}
    >
      <Box
        direction={isXsmall ? 'column' : 'row'}
        gap="small"
        justify="between"
        align={isXsmall ? 'stretch' : 'end'}
      >
        <Box flex="grow">
          {emailLabel && (
            <Text as="label" htmlFor="email" margin={{ bottom: 'xsmall' }}>
              {emailLabel}
            </Text>
          )}
          <Wrapper>
            <TextInput
              data-name="emailAddress"
              id="email"
              name="email"
              type="email"
              placeholder="Enter email address"
              value={email}
              onChange={handleChange}
            />
          </Wrapper>
        </Box>
        <Box width={{ min: '154px' }}>
          <SendButton
            label={buttonLabel || 'SEND INVITE'}
            type="submit"
            disabled={!email.match(regexEmail)}
            isXsmall={isXsmall}
            size="small"
            onClick={onClick}
          />
          {transmission && (
            <Position left="0" bottom="-24px">
              <InlineMessage msg="Invite sent!" />
            </Position>
          )}
        </Box>
      </Box>
      {error && (
        <Text color="red" margin={{ top: 'small', bottom: 'none' }}>
          Failed to send an email. Please try again.
        </Text>
      )}
    </Form>
  );
};

export default ShareEmailForm;
