import gql from 'graphql-tag';

export const GET_PROFILE_QUESTIONS_PRE_ETA_QUERY = gql`
  query GetProfileQuestions($userId: Int!) {
    questions(where: { profile: { _eq: true } }, order_by: { rank: asc }) {
      ...questionFields
    }
    stores: search_stores_near_user(args: { distance_kms: 30, userid: $userId }) {
      name
    }
    answeredProfileQuestionCount: questions_aggregate(
      where: { profile: { _eq: true }, answers: { id: { _is_null: false } } }
    ) {
      aggregate {
        count(distinct: false)
      }
    }
    user: users_by_pk(id: $userId) {
      userName: first_name
      last_name
      postCode: address(path: "postCode")
    }
  }
  fragment questionFields on questions {
    questionId: id
    questionText: title
    questionType: type
    choices
    other
    dependsOnAnswer: depends_on_answer
    dependsOnQuestion: depends_on_question
    properties
    answers {
      id
      string_value
      list_value
      number_value
    }
  }
`;

// This should be kept in sync with sn-web-app-v2/src/modules/data-collection/redux/endpoints/graphql/profileQuestion.graphql.ts
export const GET_PROFILE_QUESTIONS_QUERY = gql`
  query GetProfileQuestions($userId: Int!) {
    questions(where: { profile: { _eq: true }, properties: { _contains: { isEnable: true } } }, order_by: { rank: asc }) {
      ...questionFields
    }
    stores: search_stores_near_user(
      args: { distance_kms: 49, userid: $userId }
    ) {
      name
    }
    answeredProfileQuestionCount: questions_aggregate(
      where: { profile: { _eq: true }, answers: { id: { _is_null: false } } }
    ) {
      aggregate {
        count(distinct: false)
      }
    }
    user: users_by_pk(id: $userId) {
      userName: first_name
      last_name
      postCode: address(path: "postCode")
    }
  }
  fragment questionFields on questions {
    questionId: id
    questionText: title
    questionType: type
    choices
    other
    dependsOnAnswer: depends_on_answer
    dependsOnQuestion: depends_on_question
    properties
    answers {
      id
      string_value
      list_value
      number_value
    }
  }
`;
