// @ts-check
import React, { useState, useEffect, useCallback } from 'react';
import Sticky from 'react-stickynode';
import { HashLink as Link } from '../../Atoms/HashLink';
import { isNilOrEmpty } from '../../utils/customRamda';

export default function ProductMenu(props) {
  const [showBoost, setShowBoost] = useState(false);

  const handleScroll = useCallback(() => {
    const windowHeight = window.innerHeight;
    const windowTop = windowHeight + window.pageYOffset;

    if (windowTop >= 1070) {
      setShowBoost(true);
    } else {
      setShowBoost(false);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  });

  const { content, ProductCTAButton } = props;
  const { qualities, certifications, ingredients, recipes } = content;

  return (
    <Sticky enabled top={60}>
      <div className="product-menu" onScroll={handleScroll}>
        <div className="product-menu-contain">
          <div className="layout-row">
            <Link to="#story" smooth className="menu">
              Description
            </Link>

            {(!isNilOrEmpty(qualities) || !isNilOrEmpty(certifications)) && (
              <Link to="#qualities" smooth className="menu">
                Values
              </Link>
            )}

            {ingredients && (
              <Link to="#ingredients" smooth className="menu">
                Ingredients
              </Link>
            )}

            {recipes && (
              <Link to="#recipes" smooth className="menu">
                Recipes
              </Link>
            )}
            <div>
              <Link to="#reviews" smooth className="menu">
                Reviews
              </Link>
            </div>

            <div className="product-cta flex-right">
              {showBoost && <span className="fade-in">{ProductCTAButton}</span>}
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  );
}
