import React from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import FilterCheckBox from '../FilterCheckBox';
import ArrowUp from '../../../Atoms/Icons/ArrowUp';
import ArrowDown from '../../../Atoms/Icons/ArrowDown';
import FilterQueryContext from '../../../contexts/FilterQueryContext';

function createTags(categories, filterQueryMap) {
  const tags = [];
  categories.forEach((category) => {
    const { type, slug, name } = category;
    const query = filterQueryMap.get(type);

    if (query && query.has(slug)) {
      tags.push(name);
    }
  });

  return tags;
}

const FilterCategory = ({ name, categories }) => {
  const [open, setOpen] = React.useState(false);
  const filterQueryMap = React.useContext(FilterQueryContext);

  if (!categories) return <></>;

  const tags = createTags(categories, filterQueryMap);

  return (
    <Wrapper>
      <Box
        round="radius"
        background="white"
        height="100%"
        border={{
          color: `${open ? 'launchpad' : 'silver'}`,
        }}
        elevation="none"
        pad={{
          top: 'small',
          bottom: 'none',
          right: 'medium',
          left: 'medium',
        }}
      >
        <ClickableArea
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Box direction="row" align="center" justify="between">
            <Box>
              <FilterName>{name}</FilterName>
            </Box>
            <Box>
              {open ? (
                <ArrowUp color="black" width="16" height="16" />
              ) : (
                <ArrowDown color="black" width="16" height="16" />
              )}
            </Box>
          </Box>
          <Box
            margin={{
              bottom: 'xsmall',
            }}
          >
            {tags && tags.map((tag) => <FilterTag key={tag}>{tag}</FilterTag>)}
          </Box>
        </ClickableArea>
        {open && (
          <Box margin={{ top: 'small' }}>
            {categories.map((category) => (
              <CheckBoxWrapper key={category.slug}>
                <FilterCheckBox
                  key={category.slug}
                  type={category.type}
                  slug={category.slug}
                  name={category.name}
                  qty={category.qty}
                />
              </CheckBoxWrapper>
            ))}
          </Box>
        )}
      </Box>
    </Wrapper>
  );
};

export default FilterCategory;

const Wrapper = styled.div`
  margin-top: 16px;
`;

const CheckBoxWrapper = styled.div`
  margin-bottom: 8px;
`;

const FilterName = styled.div`
  font-size: 17px;
`;

const FilterTag = styled.div`
  font-size: 12px;
`;

const ClickableArea = styled.div`
  cursor: pointer;
`;
