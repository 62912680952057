// @ts-check
import React from 'react';
// @ts-ignore
import { PrimaryButton, Boost as BoostIcon } from 'socialnature-ui';

/**
 * @typedef {{
 * href: string,
 * productId: number,
 * productSlug: string,
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function Boost(props) {
  const { href, productId, productSlug } = props;
  return (
    <PrimaryButton
      icon={<BoostIcon isOutline color="white" />}
      label="Boost It"
      data-cy="Boost It"
      href={href}
      color="blue"
      size="small"
      gap="xsmall"
      data-track-category="Product-Button"
      data-track-action="Boost It"
      data-track-samplekit-id={productId}
      data-track-label={productSlug}
    />
  );
}
