import React from 'react';
import styled from 'styled-components';

const PageAnchor = ({ id }) => (
  <Offset>
    <AnchorPoint id={id} />
  </Offset>
);

const Offset = styled.div`
  position: relative;
`;

const AnchorPoint = styled.a`
  position: absolute;
  top: -62px;
`;

export default PageAnchor;
