import React, { useEffect, useState } from 'react';
import { isNil } from 'ramda';
import { useFormContext } from 'react-hook-form';
import CustomQuestions from '../CustomQuestions';
import ErrorPage from '../../Pages/ErrorPage';
import { errorMessages } from '../../utils/customQuestionTypes';
import { hasAnswered } from '../../utils/formatCustomQuestion';
import { handleError } from '../../utils/error';
import { TrackingEventType } from '../../utils/tracking';
import { getCookie } from '../../utils/cookie';

const ProfileQuestionForm = (props) => {
  const {
    product,
    questions,
    submitCallback,
    modelType: modelTypeProps,
    submitButtonValue,
  } = props;
  const modelType = modelTypeProps || 'register-step2';
  const { slug: productSlug } = product || {};
  const { handleSubmit, register, reset, setError } = useFormContext();
  const [showErrorPage, setShowErrorPage] = useState(false);

  useEffect(() => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: TrackingEventType.REGISTRATION_FLOW.PROFILE_QUESTIONS_PAGEVIEW,
        parameter: {
          provider: getCookie('signin_provider'),
          signinType: getCookie('signin_type'),
        },
      });
    }
  }, []);

  const onSubmit = (data) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: TrackingEventType.REGISTRATION_FLOW.PROFILE_QUESTIONS_SUBMISSION,
        parameter: {
          provider: getCookie('signin_provider'),
          signinType: getCookie('signin_type'),
        },
      });
    }

    const { CustomQuestions: customQuestions, ...rest } = data;
    const formData = customQuestions
      ? {
          ...rest,
          CustomQuestions: [
            ...customQuestions
              .map((q) =>
                hasAnswered(q.Value) // To remove unanswered multiple text type question
                  ? {
                      AnswerId: q.AnswerId,
                      Index: Number(q.Index),
                      QuestionId: Number(q.QuestionId),
                      QuestionType: q.QuestionType,
                      [q.AnswerType]: q.Value,
                    }
                  : undefined,
              )
              .filter((q) => !isNil(q)),
          ],
        }
      : { ...rest };

    fetch('/account/submitanswersjson', {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((response) => {
        reset({ ...data });
        if (!isNil(submitCallback)) {
          submitCallback();
          return;
        }
        if (productSlug) {
          window.location.href = `/${productSlug}/want-boost`;
        }
        if (!isNil(response.url)) {
          window.location.href = response.url;
        } else {
          window.location.href = '/';
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          error.json().then((err) => {
            Object.entries(err).forEach(([, v]) => {
              const { key, errors: serverError } = v;
              setError(`CustomQuestions[${key}].Value`, {
                message: serverError[0] ? serverError[0].errorMessage : errorMessages.DEFAULT_ERROR,
              });
            });
          });
          return;
        }
        handleError('Profile Question:', error);
        setShowErrorPage(true);
      });
  };

  const onError = () => {
    window.scrollTo({ top: 0 });
  };

  if (showErrorPage) return <ErrorPage />;

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)}>
      <input type="hidden" name="ModelType" value={modelType} ref={register} />
      <input type="hidden" name="ModelId" ref={register} />
      <CustomQuestions questions={questions} submitButtonValue={submitButtonValue} />
    </form>
  );
};

export default ProfileQuestionForm;
