import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, ResponsiveContext } from 'grommet';
import { useQuery } from '@apollo/react-hooks';
import ReactPlayer from 'react-player';
import LaunchpadCard from './LaunchpadCard';
import ProductTitle from '../../Organisms/Products/ProductTitle';
import { GET_LAUNCHPADS_FILTERS, GET_LAUNCHPADS } from '../../utils/apis/products';
import getUserId from '../../utils/userId';
import client from '../../utils/apis';
import formatParam from '../../utils/apis/formatParam';
import ErrorPage from '../ErrorPage';
import Loading from '../../Molecules/Loading';
import TwoColumns, { Side, Main } from '../../Templates/TwoColumns';
import ProductHeader from '../../Organisms/Products/ProductHeader';
import Container from '../../Atoms/Container';
import Filters from '../../Organisms/Filters/Filters';
import ProductList from '../../Templates/ProductList';
import FilterTags from '../../Organisms/Filters/FilterTags';
import LoadMoreButton from '../../Molecules/Buttons/LoadMoreButton';
import H from '../../Atoms/Heading';
import P from '../../Atoms/Paragraph';
import ReactPlayerContainer from '../../Templates/ReactPlayerContainer';

function getTitle(isLogin) {
  return isLogin ? 'Boost these products into stores near you' : 'Boost these innovative products';
}

function createQueryMap(query) {
  const queryMap = new Map();

  query.forEach((value, key) => {
    queryMap.set(key, new Set(value.split(',')));
  });
  return queryMap;
}

// TODO: need to refactor
function showTags(filterQueryMap) {
  if (!filterQueryMap) return false;
  if (filterQueryMap.size === 0) return false;
  if (filterQueryMap.size > 1) return true;
  return !filterQueryMap.has('country');
}

export default function LaunchpadPage() {
  const [products, setProducts] = React.useState(null);
  const [moreLoading, setMoreLoading] = React.useState(false);
  const [playing, setPlaying] = React.useState(false);
  // TODO: Create class about filter query like using on FilterCheckBox
  function useQueryParams() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQueryParams();
  const filterQueryMap = createQueryMap(query);

  const userId = getUserId();
  const size = React.useContext(ResponsiveContext);
  const isDesktop = size === 'large';
  const width = {
    xsmall: '50%',
    small: '50%',
    medium: '33.333%',
    large: '33.333%',
  };
  const { loading, error, data } = useQuery(GET_LAUNCHPADS_FILTERS, {
    variables: {
      param: formatParam({
        limit: 24,
        offset: 0,
        filter: query.toString(),
        userid: userId,
      }),
    },
  });

  function loadMore(offset) {
    setMoreLoading(true);
    client
      .query({
        query: GET_LAUNCHPADS,
        variables: {
          param: formatParam({
            limit: 24,
            offset,
            filter: query.toString(),
            userid: userId,
          }),
        },
      })
      .then((response) => {
        const {
          data: {
            launchpads: { products: newProducts },
          },
        } = response;
        if (newProducts) {
          setProducts([...products, ...newProducts]);
        }
        setMoreLoading(false);
      });
  }

  React.useEffect(() => {
    const { products: launchpads } = (data && data.data) || {};
    if (launchpads) {
      setProducts(launchpads);
    }
    return () => setProducts(null);
  }, [data]);

  let loadingPage;
  if (loading) {
    loadingPage = <Loading />;
  } else {
    loadingPage = null;
  }
  if (error) return <ErrorPage />;
  const { totalCount, filtergroups: filterGroups } = (data && data.data) || {};
  const isLeadMore = products && products.length < totalCount;

  return (
    <>
      <ProductHeader
        title="New product Launchpad!"
        description="Boost these new products and you’ll be the first to know when they’re available at a store near you."
        learnMoreContent={
          <>
            <ReactPlayerContainer>
              <ReactPlayer
                playing={playing}
                onPlay={() => setPlaying(true)}
                width="100%"
                height="auto"
                url="https://vimeo.com/341684479?dnt=1"
                controls={true}
                className="reactPlayer"
                config={{
                  vimeo: {
                      playerOptions: {
                          dnt: true, // Do Not Track option
                      },
                  },
              }}
              />
            </ReactPlayerContainer>
            {/* eslint-disable max-len */}
            <H level={3} margin={{ top: 'medium', bottom: 'small' }}>
              Be the first to discover up-and-coming, innovative products!
            </H>
            <P margin={{ bottom: 'small' }}>
              Did you know? Retailers want to know that customers are interested in trying a product
              before they will put it on shelves. Which means growing brands need fans like you to
              BOOST their product in order to help them get into stores!
            </P>
            <P>
              By boosting launchpad products you are helping more innovative, better-for-you
              products make it on shelves, and helping us change the world! You might even earn some
              perks along the way.
            </P>
            {/* eslint-disable max-len */}
          </>
        }
        eyeCatch={<img src="/images/launchpad-white.svg" alt="" role="presentation" />}
      />
      <Container>
        <Box margin={{ bottom: 'huge' }}>
          <TwoColumns>
            <Side>
              <Filters
                filters={filterGroups || []}
                filterQueryMap={filterQueryMap}
                totalCount={totalCount}
              />
            </Side>
            <Main>
              {isDesktop && showTags(filterQueryMap) && (
                <FilterTags filters={filterGroups || []} filterQueryMap={filterQueryMap} />
              )}
              <div>
                <>
                  <ProductTitle title={getTitle(!!userId)} number={totalCount} />
                  <ProductList>
                    {products &&
                      products.map((product) => (
                        <LaunchpadCard key={product.slug} product={product} width={width[size]} />
                      ))}
                  </ProductList>
                  {isLeadMore && (
                    <Box margin={{ top: 'large' }} align="center">
                      <LoadMoreButton
                        onClick={() => {
                          loadMore(products.length);
                        }}
                        loading={moreLoading}
                      />
                    </Box>
                  )}
                </>
              </div>
            </Main>
          </TwoColumns>
        </Box>
      </Container>
      {loadingPage}
    </>
  );
}
