import React from 'react';
import createSvgIcon from './SvgIcon';

export default createSvgIcon(
  <>
    <circle cx="12" cy="12" r="12" fill="white" />
    <path d="M9 6.65674H14.696V17.8567H10.92V9.50474H9V6.65674Z" fill="#08ADCD" />
  </>,
  'StepOne',
);
