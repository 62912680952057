import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Text, Error } from 'socialnature-ui';
import { handleError } from '../../../utils/error';
import { errorMessages, reverseGeocode, validPostCodePattern } from '../../../utils/apis/maps';
import LoadingSection from '../../../Molecules/LoadingSection';
import { SearchField } from '../../Inputs/SearchField';
import { useSearchStoreCampaignLocations } from '../../../hooks/useSearchStoreCampaignLocations';

// Gets stores location for specific campaign near location
/**
 * @typedef {{
 * setIsRecheckingNearby: Function,
 * setCampaignLocations: Function,
 * isXsmall: boolean,
 * setPostalCodeWithNoLocations: Function,
 * postalCodeWithNoLocations: string,
 * handleFormSubmitFetch: Function,
 * geocheckRef?:  React.MutableRefObject<{
 *  shouldGeoCheck: boolean;
 *  postalCode: any;
 * }>
 * }} Props
 * @param {Props} props
 * @returns
 */
export function SearchStoreCampaignLocationsForm({
  setIsRecheckingNearby,
  setCampaignLocations,
  isXsmall,
  setPostalCodeWithNoLocations,
  postalCodeWithNoLocations,
  handleFormSubmitFetch,
  geocheckRef,
}) {
  const locationSupported = navigator.geolocation;
  const [isCheckingCurrentPosition, setIsCheckingCurrentPosition] = useState(false);
  const currentLocation = useMemo(
    () => postalCodeWithNoLocations,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const oldCoordsRef = useRef({ coords: { latitude: null, longitude: null }, location: null });
  const { errors, handleSubmit, setValue, getValues, register } = useForm({
    mode: 'onChange',
    defaultValues: {
      Location: currentLocation,
    },
  });

  const { handleFormSubmit } = useSearchStoreCampaignLocations(
    setIsRecheckingNearby,
    handleFormSubmitFetch,
    setPostalCodeWithNoLocations,
    setCampaignLocations,
  );

  const storeLocationHandleFormSubmit = useCallback(
    (data) => {
      // shouldGeoCheck every time users inputs new postalcode and closes modal so product page gets updated
      if (geocheckRef) {
        // eslint-disable-next-line no-param-reassign
        geocheckRef.current = { shouldGeoCheck: true, postalCode: data.Location };
      }
      return handleFormSubmit(data);
    },
    [geocheckRef, handleFormSubmit],
  );

  useEffect(() => {
    storeLocationHandleFormSubmit({ Location: currentLocation });
  }, [currentLocation, storeLocationHandleFormSubmit]);

  // sets position and gets campaign locations
  const geolocationSuccess = async (position) => {
    const { latitude, longitude } = position.coords;
    if (latitude && longitude) {
      try {
        // if coords were already used before won't fire reverseGeocode request
        const location =
          oldCoordsRef.current.coords.latitude !== latitude ||
          oldCoordsRef.current.coords.longitude !== longitude
            ? await reverseGeocode(latitude, longitude)
            : {
                location: oldCoordsRef.current.location,
                success: true,
              };
        if (location && location.success && location.location) {
          setValue('Location', location.location);
          storeLocationHandleFormSubmit({ Location: location.location });
          oldCoordsRef.current = { coords: position.coords, location: location.location };
        }
        setIsCheckingCurrentPosition(false);
      } catch (ex) {
        handleError(ex);
      }
    }
  };

  const handleLocateClicked = (e) => {
    e.preventDefault();
    setIsCheckingCurrentPosition(true);
    navigator.geolocation.getCurrentPosition(geolocationSuccess, () => {
      setIsCheckingCurrentPosition(false);
    });
  };

  return (
    <form onSubmit={handleSubmit(storeLocationHandleFormSubmit)}>
      <Box data-testid="SearchStoreCampaignLocationsForm" align="stretch">
        <Box direction="row" align="start">
          <Box width="100%" margin={{ right: '4px' }}>
            <SearchField
              className="zipCodeInput"
              placeholder="Zip/Postal Code"
              a11yTitle="write your Zip/Postal Code"
              inputName="Location"
              autoFocus={!isXsmall}
              errorText={
                !!errors &&
                !!errors.Location &&
                errors.Location.message &&
                getValues('Location') && (
                  <Text
                    icon={<Error color="red" />}
                    color="red"
                    size="xsmall"
                    margin={{ bottom: '4px' }}
                  >
                    {errors.Location.message}
                  </Text>
                )
              }
              ref={(ref) =>
                register(ref, {
                  required: true,
                  pattern: {
                    value: validPostCodePattern,
                    message: errorMessages.POSTCODE_ERROR,
                  },
                })
              }
              showErrorIcon={false}
              handleOnClear={() => setValue('Location', '')}
              handleOnSearch={() =>
                storeLocationHandleFormSubmit({ Location: getValues('Location') })
              }
            />
          </Box>
          {locationSupported && (
            <Box
              margin={{ left: '0.65rem' }}
              height={{ max: '54px' }}
              width={{ max: '50px', min: '50px' }}
            >
              {isCheckingCurrentPosition ? (
                <LoadingSection />
              ) : (
                <button
                  style={{ padding: '6px' }}
                  className="sample__browser-locate"
                  type="button"
                  onClick={handleLocateClicked}
                >
                  <i style={{ verticalAlign: 'middle' }} className="material-icons">
                    gps_fixed
                  </i>
                </button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </form>
  );
}
