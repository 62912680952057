import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import Container from '../../../Atoms/Container';
import basicTheme from '../../../themes';

const BannerBox = styled(Box)`
  background: rgb(220, 239, 239);
  background: linear-gradient(
    90deg,
    rgba(230, 249, 249, 1) 40%,
    rgba(250, 223, 197, 0.31416316526610644) 56%,
    rgba(229, 188, 207, 1) 100%
  );
  padding: 0;
  overflow: hidden;
`;

const ContentBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 40px 0;
`;

const StyledContainer = styled(Container)`
  background: url(images/home/desktop-gradient-bg.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-height: 472px;
  overflow: hidden;
`;

const SubjectImg = styled.img`
  height: 100%;
  max-height: 641px;
  margin-top: -70px;
  margin-right: -40px;

  @media only screen and (max-width: 1100px) {
    height: 100%;
    max-height: 459px;
    margin-bottom: -81px;
  }

  @media only screen and (max-width: 915px) {
    height: 447px;
    margin-left: -150px;
  }
`;

const ElipseLeft = styled.div`
  box-sizing: border-box;
  position: absolute;
  width: 602px;
  height: 602px;
  left: -300px;
  top: -541px;
  border: 2px solid #ffffff;
  border-radius: 50%;

  @media only screen and (min-width: 1024px) {
    left: -280px;
    top: -521px;
  }
`;

const GreenPointLeft = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  left: 50px;
  top: 50px;
  background: ${basicTheme.global.colors.brand};
  border-radius: 50%;

  @media only screen and (min-width: 1024px) {
    left: 60px;
    top: 72px;
  }
`;

const ElipseRight = styled.div`
  position: absolute;
  width: 602px;
  height: 602px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  overflow-x: hidden;
  left: 87%;
  top: 74%;
`;

const PinkPointRight = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background: #db2573;
  border-radius: 50%;
  left: 94%;
  top: 72%;
`;

const DesktopBackground = ({ children }) => (
  <BannerBox pad={{ vertical: 'large' }}>
    <ElipseLeft />
    <GreenPointLeft />
    <ElipseRight />
    <PinkPointRight />

    <StyledContainer style={{ textAlign: 'left' }}>
      <ContentBox>{children}</ContentBox>
      <SubjectImg src="images/home/desktop-subject.png" alt="Home banner animated subject" />
    </StyledContainer>
  </BannerBox>
);

export default DesktopBackground;
