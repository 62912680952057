import React from 'react';
import { Button, ThemeContext, ResponsiveContext } from 'grommet';
import basicTheme from '../../themes';

const CTAButton = (props) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  return (
    <ThemeContext.Extend
      value={{
        button: {
          color: { light: 'white' },
          primary: {
            color: 'cta-primary',
          },
          padding: {
            vertical: '16px',
            horizontal: '8px',
          },
          extend: (grommet) => `
					${basicTheme.button.extend}
					border: none;
					font-weight: bold;
					text-transform: uppercase;
					text-align: center;
					width: 100%;
					max-width: ${isXsmall ? '100%' : '270px'};
					&:hover, &:focus {
						background: ${grommet.theme.global.colors['cta-primary-hover']};
						color: ${grommet.theme.global.colors.white};
					}
					&[disabled] {
						color: ${grommet.theme.global.colors.iron};
						background: ${grommet.theme.global.colors.silver};
						&:hover, &:focus {
							color: ${grommet.theme.global.colors.iron};
							background: ${grommet.theme.global.colors.silver};
						}
					}
				`,
        },
      }}
    >
      <Button {...props} primary hoverIndicator />
    </ThemeContext.Extend>
  );
};

export default CTAButton;
