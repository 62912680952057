import React from 'react';
import { Text, Box, themes } from 'socialnature-ui';
import H from '../../../Atoms/Heading';

export function InstructionStep({ title, description, image }) {
  return (
    <Box>
      <img src={image} alt={title} />
      <H
        level={3}
        size="14px"
        color={themes.global.colors.pink}
        margin={{ top: '32px', bottom: '0' }}
      >
        TUTORIAL
      </H>
      <H level={2} size="24px">
        {title}
      </H>
      <Text>{description}</Text>
    </Box>
  );
}
