import React from 'react';

const BellIcon = ({ width }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width={width || 24}
        height={width || 24}
        fill="currentColor"
    >
        <path d="M12 2a7 7 0 00-7 7v4.5l-1.5 3h17l-1.5-3V9a7 7 0 00-7-7zm1 17h-2a2 2 0 104 0z" />
    </svg>
);

export default BellIcon;