import { getCookie } from '../cookie';
import { handleError } from '../error';

const SN_V2_HOST = process.env.API_V2_URL;

export const getTprRetailersByCampaign = (campaignID) => fetch(
    `${SN_V2_HOST}api/tpr/campaign/${campaignID}/stores`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('User_Token')}`,
      },
    },
  )
    .then((response) => {
      if (!response.ok) {
        handleError(response);
  
        return {
          error: true,
        };
      }
  
      return response.json();
    }).catch((error) => {
      handleError(error);
  
      return {
        error: true,
      };
    });