import React from 'react';
import styled from 'styled-components';
import { Box, Button } from 'grommet';
import Container from '../../../Atoms/Container';
import H from '../../../Atoms/Heading';
import Waving from '../../../Atoms/Icons/Waving';
import AboutTryNatural from './AboutTryNatural';
import Cross from '../../../Atoms/Icons/Cross';

const HomeBanner = ({ userName }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    // After closing a banner, it shows up again after closing the browser.
    const isOpen = window.sessionStorage.getItem(['is_open_home_banner']);
    if (isOpen === null) {
      window.sessionStorage.setItem(['is_open_home_banner'], [true]);
      setOpen(true);
    } else {
      setOpen(isOpen === 'true');
    }
  }, []);

  const closeBanner = () => {
    window.sessionStorage.setItem(['is_open_home_banner'], [false]);
    setOpen(false);
  };

  return (
    <>
      {open ? (
        <Box>
          <AboutTryNatural />
          <CloseButton>
            <Button
              plain
              icon={<Cross width="36" height="36" color="freetrial" titleAccess="close" />}
              onClick={closeBanner}
              hoverIndicator="#FFFFFF"
            />
          </CloseButton>
        </Box>
      ) : (
        <Box pad={{ vertical: 'large' }} background="#FFFFFF">
          <Container>
            <Box direction="row" align="center" gap="xsmall">
              <Waving />
              <H level={2} color="brand">{`Hi ${
                userName || ''
              }, it’s a great day to #trynatural!`}</H>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
};

export default HomeBanner;

const CloseButton = styled.div`
  position: absolute;
  right: 30px;
  top: 30px;
`;
