import React from 'react';
import { useHistory } from 'react-router-dom';
import SimpleRadioButton from '../../Atoms/SimpleRadioButton';
import FilterQueryContext from '../../contexts/FilterQueryContext';
import { createQuery } from '../../utils/productFilter';

const FilterRadioButton = (props) => {
  const { type, slug, name, qty } = props;
  const filterQueryMap = React.useContext(FilterQueryContext);
  const history = useHistory();
  // TODO: (allValue) temporary fix to redirect with country code
  // Be added 'country=all' if user doesn't have country.
  // so don't need to hide country code even if 'all'.
  const allValue = 'noslug';

  const updateFilter = (curType, curSlug) => {
    if (curSlug !== allValue) {
      // This type's slug is always only one because of radio button so we can set new slug directly
      filterQueryMap.set(curType, new Set([curSlug]));
      history.push(`?${createQuery(filterQueryMap)}`);
    } else {
      filterQueryMap.delete(curType);
      history.push(`?${createQuery(filterQueryMap)}`);
    }
  };

  const getChecked = (curType, curSlug) => {
    if (!filterQueryMap.has(curType)) {
      return curSlug === allValue;
    }
    const value = filterQueryMap.get(curType);
    return value.has(curSlug);
  };

  return (
    <SimpleRadioButton
      id={`${type}-${slug}`}
      name={type}
      value={slug}
      checked={getChecked(type, slug)}
      onChange={(event) => updateFilter(type, slug, event)}
      label={name}
      number={qty}
    />
  );
};

export default FilterRadioButton;
