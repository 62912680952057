/**
 * @typedef {{
 *  acceptedPercentage: number | null,
 * }} Campaigns
 * @typedef {{
 *  campaigns: Campaigns[],
 * }} Data
 * @param {Data} data
 * @returns {number} - Return 1 =< value <=99, -1 is wrong result.
 */
export function getValue(data) {
  const { campaigns } = data || {};
  if (!campaigns || !campaigns.length) {
    return -1;
  }
  const { acceptedPercentage } = campaigns[0] || {};
  if (acceptedPercentage === null) {
    return 0;
  }

  if (acceptedPercentage < 1 && acceptedPercentage > 0) {
    return 1;
  }
  if (acceptedPercentage > 99 && acceptedPercentage < 100) {
    return 99;
  }

  if (acceptedPercentage >= 100) {
    return 100;
  }
  return acceptedPercentage;
}
