import dayjs from 'dayjs';

export const getReviewerName = (firstName, lastName) => {
  if (!firstName) return 'Anonymous';
  if (!lastName) return firstName;
  return `${firstName} ${lastName[0]}.`;
};

export const getAvatarUrl = (avatar, isCookieConsent) => {
  const defaultAvatar = '/images/default-user.png';
  const facebookDomain = 'https://graph.facebook.com/';
  const googleDomain = 'https://lh3.googleusercontent.com/';

  if (avatar?.startsWith(facebookDomain) || avatar?.startsWith(googleDomain)) {
    return isCookieConsent ? avatar : defaultAvatar;
  }
    return avatar || defaultAvatar;
};

/**
 * Get review overdue date from shipping date(ShippedAt)
 * *
 * @param {string | Date} shippedAt
 * @returns {Date | null}
 */
export function getReviewOverdueDate(shippedAt) {
  if (!shippedAt || !dayjs(shippedAt).isValid()) return null;

  return dayjs(shippedAt).add(8, 'week');
}

/**
 * Check if review is overdue
 * *
 * @param {string | Date} reviewOverdueDate
 * @returns {Date | null}
 */
export function isReviewOverdue(reviewOverdueDate) {
  if (!reviewOverdueDate || !dayjs(reviewOverdueDate).isValid()) return false;

  return dayjs().isAfter(dayjs(reviewOverdueDate));
}
