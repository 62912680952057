import gql from 'graphql-tag';

const GET_REVIEWS = gql`
  query GetReviews($productId: Int!, $offset: Int!, $limit: Int!) {
    reviews_public(
      where: { sample_kit: { _eq: $productId } }
      offset: $offset
      limit: $limit
      order_by: { createdAt: desc }
    ) {
      id
      avatar(path: "Small")
      createdAt
      description
      firstName: first_name
      lastName: last_name
      rating
      title
      variety
    }
  }
`;

export default GET_REVIEWS;
