// @ts-check
import React from 'react';
import { CTATypes } from '../../../utils/product/CTATypes';
import ProductCTAButtonBase from '../../../Molecules/ProductCTAButton';
import { trackEvent as trackGTMEvent } from '../../../utils/gtm';
import { validateCaptchaAction } from '../../../utils/apis/fraud/googleCaptcha';
import { userCaptchaActions } from '../../../utils/apis/fraud/actions';

export function ProductCTAButton({ productCTAProps }) {
  const { CTAType, onClick, event, parameter } = productCTAProps || {};
  if (
    CTAType === CTATypes.WANT ||
    CTAType === CTATypes.ACTIVATE_OFFER ||
    CTAType === CTATypes.ADD_A_RECEIPT
  ) {
    const handleClick = () => {
      validateCaptchaAction(userCaptchaActions.activate_offer);
      trackGTMEvent(event, parameter);
      if (onClick) {
        onClick();
      }
    };
    return (
      <div className="launch-cta">
        <ProductCTAButtonBase {...productCTAProps} onClick={handleClick} />
      </div>
    );
  }

  return (
    <div className="launch-cta">
      <ProductCTAButtonBase {...productCTAProps} />
    </div>
  );
}

export function CTAMessage({ message, icon }) {
  return <div style={{ color: "#333333", fontSize: 'sm', fontWeight: '700', paddingTop: "10px" }}>
    <div style={{ display: "inline", position: "relative", top: "4px" }}>
      {icon}
    </div>

    <span>&nbsp;{message}</span>
  </div>;
}
