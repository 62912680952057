import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import Cross from '../../Atoms/Icons/Cross';
import FilterQueryContext from '../../contexts/FilterQueryContext';
import { createQuery, deleteSlug } from '../../utils/productFilter';

const FilterTag = ({ type, slug, label }) => {
  const filterQueryMap = React.useContext(FilterQueryContext);
  const history = useHistory();
  const { qualitySlug } = useParams();

  const updateFilter = (curType, curSlug) => {
    if (curSlug === qualitySlug) {
      window.location.href = `/search?${createQuery(deleteSlug(filterQueryMap, curType, curSlug))}`;
    }

    history.push(`?${createQuery(deleteSlug(filterQueryMap, curType, curSlug))}`);
  };

  return (
    <Tag onClick={() => updateFilter(type, slug)}>
      <Label>{label}</Label>
      <Cross width="16" height="16" color="text" titleAccess="clear" />
    </Tag>
  );
};

export default FilterTag;

const Tag = styled.button`
  display: inline-flex;
  color: #333333;
  background: #ffffff;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 16px;
  font-weight: bold;
  margin-right: 16px;
  line-height: 20px;
  align-items: center;
  outline: none;
  &:hover,
  &:focus {
    background-color: inherit;
    color: inherit;
    border-color: #08adcd;
    cursor: pointer;
  }
`;

const Label = styled.span`
  margin-right: 1em;
`;
