import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, ResponsiveContext, Image } from 'grommet';
import { useQuery } from '@apollo/react-hooks';
import ProductTitle from '../../Organisms/Products/ProductTitle';
import FreeTrialCard from './FreeTrialCard';
import { GET_FREETRIALS_FILTERS, GET_FREETRIALS } from '../../utils/apis/products';
import getUserId from '../../utils/userId';
import client from '../../utils/apis';
import formatParam from '../../utils/apis/formatParam';
import ErrorPage from '../ErrorPage';
import Loading from '../../Molecules/Loading';
import TwoColumns, { Side, Main } from '../../Templates/TwoColumns';
import ProductHeader from '../../Organisms/Products/ProductHeader';
import Container from '../../Atoms/Container';
import Filters from '../../Organisms/Filters/Filters';
import ProductList from '../../Templates/ProductList';
import FilterTags from '../../Organisms/Filters/FilterTags';
import LoadMoreButton from '../../Molecules/Buttons/LoadMoreButton';
import H from '../../Atoms/Heading';
import P from '../../Atoms/Paragraph';
import NoFreeTrial from '../../Organisms/Products/NoFreeTrial';
import PreQualify from '../../Organisms/Products/PreQualify';
import { handleError } from '../../utils/error';
import { isFeatureOn, SplitNames } from '../../utils/splitio';

function getTitle() {
  return 'Discover better-for-you products';
}

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

function createQueryMap(query) {
  const queryMap = new Map();

  query.forEach((value, key) => {
    queryMap.set(key, new Set(value.split(',')));
  });
  return queryMap;
}

// TODO: need to refactor
function showTags(filterQueryMap) {
  if (!filterQueryMap) return false;
  if (filterQueryMap.size === 0) return false;
  if (filterQueryMap.size > 1) return true;
  return !filterQueryMap.has('country');
}

export default function FreeTrialPage() {
  const [products, setProducts] = React.useState(null);
  const [moreLoading, setMoreLoading] = React.useState(false);
  const [hasFreeTrial, setHasFreeTrial] = React.useState(false);
  const [collectionsFilteredByPostalCodeLoggedOutFF, setCollectionsFilteredByPostalCodeLoggedOutFF] = useState(false);
  
  useEffect(() => {
    isFeatureOn(SplitNames.collectionsFilteredByPostalCodeLoggedOut, 'user').then(setCollectionsFilteredByPostalCodeLoggedOutFF);
  }, []);



  // TODO: Create class about filter query like using on FilterCheckBox
  const query = useQueryParams();
  const filterQueryMap = createQueryMap(query);

  const userId = getUserId();
  const size = React.useContext(ResponsiveContext);

  const getStoredPostalCode = () => window.localStorage.getItem('cn_location') || '';
  // Set postalCode only if feature flag is enabled and user is logged out
  const postalCode = collectionsFilteredByPostalCodeLoggedOutFF
    ? !userId && getStoredPostalCode()
    : '';
    
  const isDesktop = size === 'large';
  const width = {
    xsmall: '50%',
    small: '50%',
    medium: '33.333%',
    large: '33.333%',
  };
  const { loading, error, data } = useQuery(GET_FREETRIALS_FILTERS, {
    variables: {
      param: formatParam({
        limit: 24,
        offset: 0,
        filter: query.toString(),
        userid: userId,
        zip: postalCode,
      }),
    },
  });

  function loadMore(offset) {
    setMoreLoading(true);
    client
      .query({
        query: GET_FREETRIALS,
        variables: {
          param: formatParam({
            limit: 24,
            offset,
            filter: query.toString(),
            userid: userId,
            zip: postalCode,
          }),
        },
      })
      .then((response) => {
        const {
          data: {
            freetrials: { products: newProducts },
          },
        } = response;
        if (newProducts) {
          setProducts([...products, ...newProducts]);
        }
        setMoreLoading(false);
      });
  }

  React.useEffect(() => {
    const { products: freetrials } = (data && data.data) || {};
    if (freetrials) {
      setProducts(freetrials);
      if (Array.isArray(freetrials) && freetrials.length) {
        // If a user pre-qualifies to at least one product,
        // the first product is always free trial product.
        const { wantDisabled, hasWanted } = freetrials[0];
        setHasFreeTrial(!wantDisabled && !hasWanted);
      }
    }
    return () => setProducts(null);
  }, [data]);

  let loadingPage;
  if (loading) {
    loadingPage = <Loading />;
  } else {
    loadingPage = null;
  }
  if (error) {
    const { message } = error || {};
    handleError(error);
    return <ErrorPage detail={message} />;
  }
  const { totalCount, filtergroups: filterGroups } = (data && data.data) || {};
  const isLeadMore = products && products.length < totalCount;

  return (
    <>
      <ProductHeader
        title="Try new products"
        description="Try free or discounted natural products and earn cash back - all for sharing your honest review!"
        eyeCatch={<img src="/images/freetrial-white.svg" alt="" role="presentation" />}
        steps
      />
      <Container>
        <Box margin={{ bottom: 'huge' }}>
          <TwoColumns>
            <Side>
              <Filters
                filters={filterGroups || []}
                filterQueryMap={filterQueryMap}
                totalCount={totalCount}
              />
            </Side>
            <Main>
              {products && userId && (
                <Box flex={false} pad={{ bottom: 'large' }} background="white">
                  {hasFreeTrial ? <PreQualify width="100%" /> : <NoFreeTrial width="100%" />}
                </Box>
              )}
              {isDesktop && showTags(filterQueryMap) && (
                <FilterTags filters={filterGroups || []} filterQueryMap={filterQueryMap} />
              )}
              <div>
                <ProductTitle title={getTitle()} number={totalCount} />
                <ProductList>
                  {products &&
                    products.map((product) => (
                      <FreeTrialCard key={product.slug} product={product} width={width[size]} />
                    ))}
                </ProductList>
                {isLeadMore && (
                  <Box margin={{ top: 'large' }} align="center">
                    <LoadMoreButton
                      onClick={() => {
                        loadMore(products.length);
                      }}
                      loading={moreLoading}
                    />
                  </Box>
                )}
              </div>
            </Main>
          </TwoColumns>
        </Box>
      </Container>
      {loadingPage}
    </>
  );
}
