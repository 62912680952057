import React, { useCallback, useContext } from 'react';
import { ResponsiveContext, Heading } from 'grommet';
import { Text } from 'socialnature-ui';
import StorePickUpLocationLayout from '../../Organisms/ReportIssue/StorePickUpLocationLayout';
import { useModal } from '../useModal';
import { campaignTypes } from '../../utils/campaignTypes';

const headerLabelEnum = {
  [campaignTypes.IN_STORE]: 'Eligible stores',
  [campaignTypes.REBATE]: 'Find an eligible store for this offer',
};

const headerDescriptionEnum = {
  [campaignTypes.IN_STORE]: (
    <Text color="#000000" margin={{ bottom: '24px' }} size="12px">
      Purchases must be made in-person from a eligible store.
    </Text>
  ),
  [campaignTypes.REBATE]: (
    <Text
      style={{
        padding: '8px 16px',
        backgroundColor: '#FCEBDB',
        borderRadius: '4px',
      }}
      color="#333"
      margin={{ bottom: '24px' }}
      size="14px"
    >
      Find in-store at eligible locations only; online receipts and other stores won&apos;t
      be accepted.
    </Text>
  ),
};

function Header({ campaignType }) {
  return (
    <>
      <Heading color="#000000" level={3} size="24px" margin={{ bottom: '10px' }}>
        {headerLabelEnum[campaignType]}
      </Heading>
      {headerDescriptionEnum[campaignType]}
    </>
  );
}

export function useParticipantStoreModal(
  initPostalCode,
  initOpen,
  samplekit,
  handleFormSubmitFetch,
  preferredLocation,
  campaignType,
) {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const { Modal, open, close } = useModal(initOpen);

  const handleOpen = useCallback(() => open(), [open]);

  const handleClose = useCallback(() => close(), [close]);

  const LocationStoreModal = () => (
    <Modal
      style={{
        maxWidth: 'calc(100% - 32px)',
        width: '1200px',
        padding: isXsmall ? '48px 16px 0px' : '0',
        height: isXsmall ? '700px' : '',
        overflowY: 'scroll',
        display: isXsmall ? 'flex' : '',
        flexDirection: isXsmall ? 'column' : '',
      }}
    >
      <StorePickUpLocationLayout
        sampleKitName={samplekit && samplekit.name}
        postalCode={initPostalCode}
        handleFormSubmitFetch={handleFormSubmitFetch}
        Header={<Header campaignType={campaignType} />}
        preferredLocation={preferredLocation}
      />
    </Modal>
  );

  return { LocationStoreModal, open: handleOpen, close: handleClose };
}
