import React from 'react';
import InviteFriendModal from './InviteFriendModal';

const InviteFriendPopup = ({ userId, product, isAccepted }) => {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <InviteFriendModal
      open={open}
      userId={userId}
      product={product}
      isAccepted={isAccepted}
      onClose={() => setOpen(false)}
    />
  );
};

export default InviteFriendPopup;
