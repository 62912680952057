// @ts-check
import React from 'react';
import { Box, Divider, Text } from 'socialnature-ui';
import { SearchStoreCampaignLocationsForm } from '../../CampaignLocation/SearchStoreCampaignLocationsForm';
import { LocationList } from '../../CampaignLocation/LocationList';
import { LocationMap } from '../../CampaignLocation/LocationMap';
import LoadingSection from '../../../Molecules/LoadingSection';
import { CENTER_NORTH_AMERICA } from '../../../utils/apis/maps';

/**
 * @typedef {{
 * isLoading: boolean,
 * locations: Array,
 * errorMessage: string,
 * }} StoreListProps
 * @param {StoreListProps} StoreListProps
 * @returns
 */
function StoreList({ isLoading, locations, errorMessage }) {
  if (isLoading) {
    return (
      <Box margin={{ top: '16px' }}>
        <LoadingSection />
      </Box>
    );
  }
  if (!locations.length) {
    return (
      <Box margin={{ vertical: '16px' }}>
        <Text>{errorMessage}</Text>
      </Box>
    );
  }
  return (
    <>
      <Divider />
      <LocationList locations={locations} />
    </>
  );
}

/**
 * @typedef {{
 * campaignLocations: Array,
 * setIsRecheckingNearby: Function,
 * setCampaignLocations: Function,
 * isXsmall: boolean,
 * isRecheckingNearby: boolean,
 * errorMessage: string,
 * setPostalCodeWithNoLocations: Function,
 * postalCodeWithNoLocations: string,
 * selectedIndex: number,
 * setSelectedIndex: Function,
 * handleFormSubmitFetch: Function,
 * Header?: Function,
 * }} Props
 * @param {Props} props
 * @returns
 */
export function StorePickUpLocationDesktop({
  campaignLocations,
  setIsRecheckingNearby,
  setCampaignLocations,
  isXsmall,
  isRecheckingNearby,
  errorMessage,
  setPostalCodeWithNoLocations,
  postalCodeWithNoLocations,
  selectedIndex,
  setSelectedIndex,
  handleFormSubmitFetch,
  Header,
}) {
  return (
    <>
      <Box margin={{ top: Header ? '' : '16px' }} elevation="smallImage" round="8px">
        <Box direction="row">
          <Box direction="column" width="100%">
            <LocationMap
              locations={campaignLocations}
              centerLatitude={
                campaignLocations.length ? campaignLocations[0].latitude : CENTER_NORTH_AMERICA.lat
              }
              centerLongitude={
                campaignLocations.length ? campaignLocations[0].longitude : CENTER_NORTH_AMERICA.lng
              }
              zoom={(!campaignLocations.length && 2.8) || 10}
              styles={{ borderRadius: '8px 0px 0px 8px', height: '535px' }}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </Box>
          <Box direction="column" margin={{ horizontal: '24px', vertical: '24px' }} width="352px">
            {Header}
            <SearchStoreCampaignLocationsForm
              setIsRecheckingNearby={setIsRecheckingNearby}
              setCampaignLocations={setCampaignLocations}
              isXsmall={isXsmall}
              setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
              postalCodeWithNoLocations={postalCodeWithNoLocations}
              handleFormSubmitFetch={handleFormSubmitFetch}
            />
            <StoreList
              isLoading={isRecheckingNearby}
              locations={campaignLocations}
              errorMessage={errorMessage}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
