// @ts-check
import { campaignTypes } from '../../campaignTypes';
import { CTATypes } from '../CTATypes';

/**
 * @typedef {{
 * isLoggedIn: boolean,
 * isLaunchpad: boolean,
 * wantDisabled: boolean,
 * hasWanted: boolean,
 * isValidBanner: boolean,
 * whereToBuyUrl: string,
 * hasBeenAccepted: boolean,
 * hasCampaignsOpen: boolean
 * campaignType: number,
 * offerRedeemed: boolean,
 * campaignProgress: number,
 * isOfferExpired: boolean,
 * isPrequalifyCheckPassed: boolean
 * }} Option
 * @param {Option} options
 */
export async function getProductCTAType({
  isLoggedIn,
  isLaunchpad,
  wantDisabled,
  hasWanted,
  isValidBanner,
  whereToBuyUrl,
  hasBeenAccepted,
  hasCampaignsOpen,
  campaignType,
  offerRedeemed,
  campaignProgress,
  isOfferExpired,
  isPrequalifyCheckPassed = false,
}) {
  const prequalify = campaignType === campaignTypes.REBATE ? isPrequalifyCheckPassed : true;

  if (!isLoggedIn) {
    if(hasCampaignsOpen && !wantDisabled) {
      return CTATypes.SIGNUP_TO_ACTIVATE
    }
    return CTATypes.SIGNUP_FOR_UPDATES
  };
  

  if (!hasWanted && prequalify) {
    // TODO: launchpad doesn't affect wantDisabled??
    if (isLaunchpad) return CTATypes.BOOST;
    if (!wantDisabled && campaignType !== undefined) {
      if (campaignType === campaignTypes.REBATE) {
        if (campaignProgress < 100 && !isOfferExpired) {
          return CTATypes.ACTIVATE_OFFER;
        }
      } else {
        return CTATypes.WANT;
      }
    }
  } else if (hasBeenAccepted) {
    if (campaignType === campaignTypes.REBATE) {
      if (offerRedeemed) {
        return CTATypes.OFFER_REDEEMED;
      }

      if (campaignProgress < 100 && !isOfferExpired) {
        return CTATypes.ADD_A_RECEIPT;
      }
    } else if (hasCampaignsOpen) {
      return CTATypes.APPLIED;
    }
  } else if (hasCampaignsOpen) {
    return CTATypes.WHERE_TO_BUY;
  }

  if (isValidBanner && campaignType !== campaignTypes.REBATE) return CTATypes.BANNER;
  // where to buy is required field so we never seen acted label
  if (whereToBuyUrl) return CTATypes.WHERE_TO_BUY;

  return CTATypes.DISABLED;
}
