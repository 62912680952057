import React, { useState, useLayoutEffect, useMemo } from 'react';
import styled from 'styled-components';
import { Meter } from 'grommet';
import { Check, Text, themes as uiThemes } from 'socialnature-ui';
import { useFormContext } from 'react-hook-form';
import { isNilOrEmpty } from '../../utils/customRamda';
import { hasAnswered } from '../../utils/formatCustomQuestion';

const Wrapper = styled.div`
  position: relative;
  padding: 10px 0;
`;

const CheckIcon = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  background: ${uiThemes.global.colors.blue};
  border: 2px solid ${uiThemes.global.colors.cloud};
  box-sizing: border-box;
  border-radius: 50%;
`;

const ProgressBar = ({ questions }) => {
  const [percentage, setPercentage] = useState(0);
  const { watch, errors } = useFormContext();
  const { CustomQuestions: customQuestion } = watch();
  const { CustomQuestions: customQuestionErrors = [] } = errors;

  const questionRequiredByIdMap = useMemo(() => {
    const map = new Map();
    questions.forEach((q) => {
      map.set(q.questionId.toString(), q.required);
    });
    return map;
  }, [questions]);

  useLayoutEffect(() => {
    if (!isNilOrEmpty(customQuestion)) {
      const questionsCount = customQuestion.filter(
        (v) => v && questionRequiredByIdMap.get(v.QuestionId),
      ).length;
      const answered = customQuestion.filter(
        (v, index) =>
          questionRequiredByIdMap.get(v.QuestionId) &&
          hasAnswered(v.Value) &&
          !customQuestionErrors[index],
      ).length;
      const newPercentage =
        questionsCount > 0 ? Math.ceil((answered / questionsCount) * 100) : percentage;
      if (newPercentage !== percentage) {
        setPercentage(Math.ceil((answered / questionsCount) * 100));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customQuestion, customQuestionErrors]);

  return (
    <>
      <Text weight="bold">
        {percentage < 100
          ? `Your preferences are ${percentage}% complete`
          : '🎉 Good job! Your preferences are 100% complete!'}
      </Text>
      <Wrapper>
        <Meter
          type="bar"
          background="silver"
          round
          thickness="small"
          size="large"
          values={[{ value: percentage }]}
        />
        {percentage === 100 && (
          <CheckIcon>
            <Check color="white" />
          </CheckIcon>
        )}
      </Wrapper>
    </>
  );
};

export default ProgressBar;
