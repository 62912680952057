import React from 'react';
import styled from 'styled-components';
import { Box, ResponsiveContext } from 'grommet';
import H from '../../Atoms/Heading';
import P from '../../Atoms/Paragraph';
import theme from '../../themes';
import CopyLink from '../../Molecules/SocialShare/CopyLink/CopyLink';
import ShareEmailForm from '../../Molecules/SocialShare/Email/ShareEmailForm';
import FacebookShareButton from '../../Molecules/SocialShare/Facebook/ShareButton';
import TwitterShareButton from '../../Molecules/SocialShare/Twitter/ShareButton';
import { getInviteUrl, getReferralLink } from '../../utils/socialShare';
import { sendInvite } from '../../utils/apis/shareProduct';
import OrDivider from '../../Atoms/SignInPage/OrDivider';

const copies = {
  off: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a <span className="highlight">$100 Gift Card!</span>
      </>
    ),
    body: 'More friends means more chances to win! Get an entry for each friend who signs up using your referral link. One winner will be randomly selected monthly.',
    linkTsCs: 'https://sites.google.com/socialnature.com/refer-a-friend/terms-conditions',
  },
  earthDay: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a <span className="highlight">Stanley Tumbler</span>{' '}
        for you and a friend!
      </>
    ),
    body: 'Win a Reusable Stanley Tumbler for you and a friend by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry.',
    linkTsCs: 'https://sites.google.com/socialnature.com/contest-tc-earthmonth/terms-conditions',
  },
  backToSchool1: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a{' '}
        <span className="highlight">Free2B Chocolate Prize Package!</span>
      </>
    ),
    body: 'Celebrate back to school season with a better-for-you chocolate prize package! More friends means more chances to win - get an entry for each friend who signs up using your referral link. One winner will be randomly selected. Runs August 30 - September 5.',
    linkTsCs: 'https://mailchi.mp/socialnature/free2b-backtoschool-giveaway',
  },
  backToSchool2: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a{' '}
        <span className="highlight">$150 GnuSanté Back to School Prize!</span>
      </>
    ),
    body: 'Make back to school even better with this awesome prize package from GnuSanté! More friends means more chances to win - get an entry for each friend who signs up using your referral link. One winner will be randomly selected. Runs September 6 - September 12.',
    linkTsCs: 'https://mailchi.mp/socialnature/gnusante-backtoschool-giveaway',
  },
  backToSchool3: {
    headerTitle: (
      <>
        Invite your friends for a chance to win{' '}
        <span className="highlight">Bob’s Red Mill Classroom Essentials!</span>
      </>
    ),
    body: 'Score some A+ goodies with this delicious and spectacular prize bundle from Bob’s Red Mill (est. value $150)! More friends means more chances to win - get an entry for each friend who signs up using your referral link. One winner will be randomly selected. Runs to September 19th.',
    linkTsCs: 'https://mailchi.mp/socialnature/bobsredmill-backtoschool-giveaway',
  },
  featureAlaffia: {
    headerTitle: (
      <>
        Invite your friends for a chance to win an{' '}
        <span className="highlight">Alaffia Beauty Package!</span>
      </>
    ),
    body: 'More friends means more chances to win this $75 prize package! Get an entry for each friend who signs up using your referral link. One winner will be randomly selected.',
    linkTsCs: 'https://mailchi.mp/socialnature/alaffia_beauty_package',
  },
  veganuaryFabalishVeganMayo: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">vegan Fabalish prize package!</span>
      </>
    ),
    body: 'Celebrate Veganuary by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry for a $90 vegan prize package from Fabalish.',
    linkTsCs:
      'https://sites.google.com/socialnature.com/fabalish-veganuary-giveaway/terms-conditions',
  },
  veganuaryRitterSport: {
    headerTitle: (
      <>
        Win a vegan <span className="highlight">Ritter Sport Chocolate package</span> by inviting
        your friends!
      </>
    ),
    body: 'The more, the merrier! Every friend who signs up through your unique link gets you another chance at winning a $100 vegan chocolate prize package. Winner will be randomly selected - it could be you!',
    linkTsCs:
      'https://sites.google.com/socialnature.com/ritter-veganuary-giveaway/terms-conditions',
  },
  walmart: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a $200{' '}
        <span className="highlight">Walmart Shopping Spree</span>!
      </>
    ),
    body: 'Win BIG at Walmart by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry for a $200 Walmart Shopping Spree.',
    linkTsCs: 'https://sites.google.com/socialnature.com/referfriends-walmart/terms-conditions',
  },
  springCleaning: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$100 Spring Cleaning Prize Package!</span>
      </>
    ),
    body: 'Win a $100 Spring Cleaning Prize Package by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry.',
    linkTsCs: 'https://sites.google.com/socialnature.com/contest-tc-template/terms-conditions',
  },
  picnicSnackPack: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$100 Picnic Snack Pack!</span>
      </>
    ),
    body: 'Win this $100 Picnic Snack Pack by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry.',
    linkTsCs: 'https://sites.google.com/socialnature.com/contest-tc-template/terms-conditions',
  },
  birthdayPack: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$160 Birthday Prize Pack!</span>
      </>
    ),
    body: (
      <>
        We’re celebrating our 9th birthday by giving you a chance to WIN!
        <img alt="cake" width="20px" src="/images/congratsPage/cake.png" /> Invite your friends to
        Social Nature using your unique referral link. Each friend that signs up equals another
        entry.
      </>
    ),
    linkTsCs:
      'https://sites.google.com/socialnature.com/tscs-birthday2024giveaway/terms-conditions',
  },
  holidayRelaxDec2024: {
    headerTitle: (
      <>
         Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$100 Holiday Relaxation Prize Pack!</span>
      </>
    ),
    body: 'Win a $100 Holiday Relaxation Prize Package by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry. Cozy up with an Owala water bottle, soothing candle, face masks, and more—everything you need for a relaxing holiday season!',
    linkTsCs: 'https://sites.google.com/socialnature.com/tscs-holiday-2024-giveaway/terms-conditions',
  },
  referFriendFeb2025: {
    headerTitle: (
      <>
         Invite your friends for a chance to WIN a{' '}
        <span className="highlight">better-for-you Mystery Box!</span>
      </>
    ),
    body: <>WIN a box full of better-for-you products by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry to win.  <img alt="heart" width="20px" src="/images/congratsPage/heart.png" /></>,
    linkTsCs: 'https://sites.google.com/socialnature.com/tscs-galentines-giveaway-refer/terms-conditions',
  }
};

const popupCopies = {
  off: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a <span className="highlight">$100 Gift Card!</span>
      </>
    ),
    body: 'Get an entry for each friend who signs up using your referral link. One winner will be randomly selected monthly.',
  },
  earthDay: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a <span className="highlight">Stanley Tumbler</span>{' '}
        for you and a friend!
      </>
    ),
    body: 'Win a Reusable Stanley Tumbler for you and a friend by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry.',
    linkTsCs: 'https://sites.google.com/socialnature.com/contest-tc-earthmonth/terms-conditions',
  },
  backToSchool1: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a{' '}
        <span className="highlight">Free2B Chocolate Prize Package!</span>
      </>
    ),
    body: 'Celebrate back to school season with a better-for-you chocolate prize package! Get an entry for each friend who signs up using your referral link. One winner will be randomly selected.',
  },
  backToSchool2: {
    headerTitle: (
      <>
        Invite your friends for a chance to win a{' '}
        <span className="highlight">$150 GnuSanté Back to School Prize!</span>
      </>
    ),
    body: 'Make back to school even better with this awesome prize package from GnuSanté! Get an entry for each friend who signs up using your referral link. One winner will be randomly selected.',
  },
  backToSchool3: {
    headerTitle: (
      <>
        Invite your friends for a chance to win{' '}
        <span className="highlight">Bob’s Red Mill Classroom Essentials!</span>
      </>
    ),
    body: 'Score some A+ goodies with this delicious and spectacular prize bundle from Bob’s Red Mill (est. value $150)! Get an entry for each friend who signs up using your referral link. One winner will be randomly selected.',
  },
  featureAlaffia: {
    headerTitle: (
      <>
        Invite your friends for a chance to win an{' '}
        <span className="highlight">Alaffia Beauty Package!</span>
      </>
    ),
    body: 'More friends means more chances to win this $75 prize package! Get an entry for each friend who signs up using your referral link. One winner will be randomly selected.',
  },
  veganuaryFabalishVeganMayo: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">vegan Fabalish prize package!</span>
      </>
    ),
    body: 'Celebrate Veganuary by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry for a $90 vegan prize package from Fabalish.',
  },
  veganuaryRitterSport: {
    headerTitle: (
      <>
        Win a vegan <span className="highlight">Ritter Sport Chocolate package</span> by inviting
        your friends!
      </>
    ),
    body: 'The more, the merrier! Every friend who signs up through your unique link gets you another chance at winning a $100 vegan chocolate prize package. Winner will be randomly selected - it could be you!',
  },
  walmart: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a $200{' '}
        <span className="highlight">Walmart Shopping Spree</span>!
      </>
    ),
    body: 'Win BIG at Walmart by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry for a $200 Walmart Shopping Spree.',
    linkTsCs: 'https://sites.google.com/socialnature.com/referfriends-walmart/terms-conditions',
  },
  springCleaning: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$100 Spring Cleaning Prize Package!</span>
      </>
    ),
    body: 'Win a $100 Spring Cleaning Prize Package by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry.',
    linkTsCs: 'https://sites.google.com/socialnature.com/contest-tc-template/terms-conditions',
  },
  picnicSnackPack: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$100 Picnic Snack Pack!</span>
      </>
    ),
    body: 'Win this Picnic Snack Pack by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry.',
    linkTsCs: 'https://sites.google.com/socialnature.com/contest-tc-template/terms-conditions',
  },
  birthdayPack: {
    headerTitle: (
      <>
        Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$160 Birthday Prize Pack!</span>
      </>
    ),
    body: (
      <>
        We’re celebrating our 9th birthday by giving you a chance to WIN!
        <img alt="cake" width="20px" src="/images/congratsPage/cake.png" /> Invite your friends to
        Social Nature using your unique referral link. Each friend that signs up equals another
        entry.
      </>
    ),
    linkTsCs:
      'https://sites.google.com/socialnature.com/tscs-birthday2024giveaway/terms-conditions',
  },
  holidayRelaxDec2024: {
    headerTitle: (
      <>
         Invite your friends for a chance to WIN a{' '}
        <span className="highlight">$100 Holiday Relaxation Prize Pack!</span>
      </>
    ),
    body: 'Win a $100 Holiday Relaxation Prize Package by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry. Cozy up with an Owala water bottle, soothing candle, face masks, and more—everything you need for a relaxing holiday season!',
    linkTsCs: 'https://sites.google.com/socialnature.com/tscs-holiday-2024-giveaway/terms-conditions',
  },
  referFriendFeb2025: {
    headerTitle: (
      <>
         Invite your friends for a chance to WIN a{' '}
        <span className="highlight">better-for-you Mystery Box!</span>
      </>
    ),
    body: <>WIN a box full of better-for-you products by inviting your friends to Social Nature! Each friend who signs up using your referral link gives you an entry to win. <img alt="heart" width="20px" src="/images/congratsPage/heart.png" /></>,
    linkTsCs: 'https://sites.google.com/socialnature.com/tscs-galentines-giveaway-refer/terms-conditions',
  }
};

const StyledH = styled(H)`
  .highlight {
    color: ${() => theme.global.colors.pink};
  }
`;

const InviteFriendContent = ({
  userId,
  dataTrackCategory,
  samplekitId,
  trackShare,
  shareUrl,
  companyName,
  twitter,
  sampleKitName,
  productSlug,
  showTermsAndConditions,
  isPopUp = false,
  customCampaignRAFGiveaways,
}) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  const copy = copies[customCampaignRAFGiveaways] || copies.off;
  const popupCopy = popupCopies[customCampaignRAFGiveaways] || popupCopies.off;
  const headerTitle = isPopUp ? popupCopy.headerTitle : copy.headerTitle;

  return (
    <Box width={{ max: '450px' }} margin={{ horizontal: 'auto' }}>
      <StyledH level={3} size="24px" margin={{ bottom: 'small' }} textAlign="center">
        {headerTitle}
      </StyledH>
      <P textAlign="center">{isPopUp ? popupCopy.body : copy.body}</P>
      <Box margin={isXsmall ? { top: 'large' } : { top: 'medium' }}>
        <P weight="bold" textAlign="center" margin={{ bottom: 'small' }}>
          Share your referral link
        </P>
        <CopyLink
          dataTrackCategory={dataTrackCategory || 'Friend Referral'}
          dataTrackAction="Copy Link"
          dataTrackLabel={productSlug || getReferralLink(userId, 'link')}
          dataTrackSamplekitId={samplekitId || ''}
          shareUrl={shareUrl || getInviteUrl(userId, 'link')}
          onClick={() => trackShare && trackShare('copy')}
        />
      </Box>
      <OrDivider />
      <Box>
        <P weight="bold" textAlign="center" margin={{ bottom: 'small' }}>
          Invite friends by email or social
        </P>
        <ShareEmailForm
          dataTrackCategory={dataTrackCategory || 'Friend Referral'}
          dataTrackAction="Email"
          dataTrackLabel={productSlug || getReferralLink(userId, 'email')}
          dataTrackSamplekitId={samplekitId || ''}
          productSlug={productSlug || ''}
          productId={samplekitId}
          sendEmail={sendInvite}
          onClick={() => trackShare && trackShare('share-email-form')}
        />
      </Box>
      <Box margin={{ top: '24px' }}>
        <Box direction="row" wrap justify={isXsmall ? 'around' : 'center'}>
          <FacebookShareButton
            data-track-action="Facebook"
            data-track-category={dataTrackCategory || 'Friend Referral'}
            data-track-label={productSlug || getReferralLink(userId, 'facebook')}
            data-track-samplekit-id={samplekitId || ''}
            shareUrl={shareUrl || getInviteUrl(userId, 'facebook')}
            onClick={() => trackShare && trackShare('facebook')}
            companyName={companyName || ''}
            name={sampleKitName || ''}
          />
          <TwitterShareButton
            data-track-action="Twitter"
            data-track-category={dataTrackCategory || 'Friend Referral'}
            data-track-label={productSlug || getReferralLink(userId, 'twitter')}
            data-track-samplekit-id={samplekitId || ''}
            shareUrl={shareUrl || getInviteUrl(userId, 'twitter')}
            onClick={() => trackShare && trackShare('twitter')}
            message={twitter && twitter[0]}
          />
        </Box>
      </Box>
      {(showTermsAndConditions ||
        (customCampaignRAFGiveaways === `${copies.earthDay}` && !isPopUp)) && (
        <Box>
          <P textAlign="center" margin={{ top: '30px' }}>
            View complete terms & conditions
            <a href={copy.linkTsCs} target="_blank" rel="noopener noreferrer">
              &nbsp;here
            </a>
            .
          </P>
        </Box>
      )}
    </Box>
  );
};

export default InviteFriendContent;
