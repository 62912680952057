// https://developers.google.com/maps/documentation/javascript/react-map
import React from 'react';
import { Wrapper } from '@googlemaps/react-wrapper';
import { Text } from 'socialnature-ui';

export function MapWrapper({ apiKey, children }) {
  if (!apiKey) return null;

  const render = (status) => <Text>{status}</Text>;

  return (
    <Wrapper apiKey={apiKey} render={render}>
      {children}
    </Wrapper>
  );
}
