export const CTATypes = {
  WANT: 'want',
  BOOST: 'boost',
  BANNER: 'banner',
  WHERE_TO_BUY: 'where to buy',
};

export function geoEnhanceProductCTA(options, action) {
  const { geoInfo, isLaunchpad, isLoggedIn, openGeoModal, wantDisabled } = options;

  // No geo enhancements for launchpad boosts
  if (wantDisabled || isLaunchpad) {
    return action;
  }

  if (openGeoModal && geoInfo && !isLoggedIn && geoInfo.loaded && !geoInfo.location) {
    // TODO: let's refactor!
    // eslint-disable-next-line no-param-reassign
    action.onClick = (function handleActionClick(href) {
      return (e) => {
        e.preventDefault();
        openGeoModal('', `${href}&geo=nearby`);
      };
    })(action.href);
    // TODO: let's refactor!
    // eslint-disable-next-line no-param-reassign
    delete action.href;
  }

  return action;
}

export function getProductCTA(product, openModal) {
  const {
    isLaunchpad,
    hasWanted,
    productLink,
    showBanner,
    popupEnabled,
    bannerMessage,
    bannerUrl,
    whereToBuy,
    wantDisabled,
    isFarAndLoggedOutLink = false,
    productId,
    showStoreLocatorFlow,
    openStoreLocationModal,
  } = product;

  let actedLabel = isLaunchpad
    ? { label: 'Boosted', href: null, disabled: true }
    : { label: 'Applied', href: null, disabled: true };

  if (!hasWanted && productLink) {
    if (isLaunchpad) {
      return { label: 'Boost It', href: productLink, isIcon: true, type: CTATypes.BOOST };
    }
    if (!wantDisabled) {
      actedLabel = {
        label: 'Apply To Try',
        href: `${productLink}&productId=${productId}&campaignId=399&snsource=apply_to_try`,
        isIcon: true,
        type: CTATypes.WANT,
      };

      return showStoreLocatorFlow
        ? {
            ...actedLabel,
            href: null,
            onClick: openStoreLocationModal,
          }
        : actedLabel;
    }
  }

  if (showBanner && bannerMessage) {
    if (popupEnabled) {
      return { label: bannerMessage, href: null, onClick: openModal, type: CTATypes.BANNER };
    }
    if (bannerUrl) {
      return { label: bannerMessage, href: bannerUrl, targetBlank: true, type: CTATypes.BANNER };
    }
  }

  // where to buy is required field so we never seen acted label
  if (whereToBuy) {
    if (isFarAndLoggedOutLink)
      return { label: 'Sign Up', href: isFarAndLoggedOutLink, target: false, rel: false };
    return {
      label: 'Where To Buy',
      href: whereToBuy,
      targetBlank: true,
      type: CTATypes.WHERE_TO_BUY,
    };
  }

  return actedLabel;
}

export function cleanupValue(priceValue) {
  return Number(priceValue.replace(/[^0-9.]/g, ''));
}

export function getPrecisionValueUpto2(value) {
  const precision = 2;
  const roundedValue = Number(value * 100) / 100;

  return roundedValue.toFixed(precision).toLocaleString('en-US');
}

export function concatStoreList(stores) {
  const uniqueStoreNames = new Set();
  stores.forEach((store) => {
    const { name } = store;
    const formattedName = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    uniqueStoreNames.add(formattedName);
  });

  const uniqueStoreNamesArray = [...uniqueStoreNames];
  if (uniqueStoreNamesArray.length === 1) {
    return uniqueStoreNamesArray[0];
  }
  const lastStore = uniqueStoreNamesArray.pop();
  const concatenatedNames = `${uniqueStoreNamesArray.join(', ')} and ${lastStore}`;
  return concatenatedNames;
}

export function concatStringList(items) {
  const formatList = items.map((name) => ({ name }));
  return concatStoreList(formatList);
}

export async function hashData(data) {
  if (!data) {
    return undefined;
  }
  const buffer = new TextEncoder().encode(data);
  const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashedData = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashedData;
}

export const USER_VALIDATION_FEATURE_ACTIVE_DATE = new Date('2024-06-02T00:00:00Z');
export const getUploadCTA = (activationDate, isValid) => {
  const activatnDateObj =
    typeof activationDate === 'string' ? new Date(activationDate) : activationDate;
  if (activatnDateObj < USER_VALIDATION_FEATURE_ACTIVE_DATE) {
    // Old Wants(activated before user-validation)
    return 'Add a Receipt';
  }
  if (!isValid) {
    return 'Complete Activation';
  }
  return 'Add a Receipt';
};

/** For Old Wants(activated before user-validation), we will show the old layout */
export const showCTAIfValid = (activationDate, isValid) => {
  const activatnDateObj =
    typeof activationDate === 'string' ? new Date(activationDate) : activationDate;
  return activatnDateObj < USER_VALIDATION_FEATURE_ACTIVE_DATE || isValid;
};

export const getCashBackFormattedPrice = (moneyCashBackValue = 0) => {
  if (moneyCashBackValue < 1) {
    return moneyCashBackValue.toFixed(2);
  }
  return moneyCashBackValue;
};
