import React from 'react';
import { ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import H from '../../Atoms/Heading';
import P from '../../Atoms/Paragraph';

const ProductTitle = ({ title, number, margin }) => {
  const size = React.useContext(ResponsiveContext);
  const isDesktop = size === 'large';
  let bottomMargin = (margin && margin.bottom) || '24px';
  let topMargin = (margin && margin.top) || 'inherit';
  if (typeof margin === 'string') {
    bottomMargin = margin;
    topMargin = margin;
  }

  const Wrapper = styled.div`
		margin-top: ${topMargin}
		margin-bottom: ${bottomMargin};
		&::after {
			content: "";
			clear: both;
			display: table;
		}
	`;

  return (
    <Wrapper>
      <div>
        <Title>
          <H level={2}>{title}</H>
        </Title>
        {isDesktop && number && (
          <ResultNumber>
            <P>{`${number} Results`}</P>
          </ResultNumber>
        )}
      </div>
    </Wrapper>
  );
};

export default ProductTitle;

const Title = styled.div`
  display: inline-block;
`;

const ResultNumber = styled.div`
  float: right;
  padding-top: 1em;
`;
