import React, { useEffect } from 'react';
import { Box, Text, TextLink } from 'socialnature-ui';
import styled from 'styled-components';
import LoadingSection from '../../../../Molecules/LoadingSection';
import ErrorPage from '../../../../Pages/ErrorPage';
import { useFreeSample } from '../../../../hooks/useFreeSample';
import getUserId from '../../../../utils/userId';
import { CountryISO2FromPostalCode } from '../../../../utils/formatCountry';
import { TrackingEventType } from '../../../../utils/tracking';
import { trackEvent as trackGTMEvent } from '../../../../utils/gtm';

const Wrapper = styled.div`
  margin: 16px 0;
`;

export default function StoreLocationNoSamplesAvailable({
  seeSamplesAvailable,
  postalCodeWithNoLocations,
  geocheckRef,
}) {
  const userId = getUserId();
  const country = CountryISO2FromPostalCode(postalCodeWithNoLocations);
  const { loading, error, data } = useFreeSample(postalCodeWithNoLocations, country, userId);

  useEffect(() => {
    // shouldGeoCheck on close modal when no locations and no free samples near location
    if (data && data.freetrials && !data.freetrials.products) {
      // eslint-disable-next-line no-param-reassign
      geocheckRef.current = { shouldGeoCheck: true, postalCode: postalCodeWithNoLocations };
    }
  }, [data, postalCodeWithNoLocations, geocheckRef]);

  if (loading) {
    return (
      <Box margin={{ top: '16px' }}>
        <LoadingSection />
      </Box>
    );
  }

  if (error) {
    return <ErrorPage detail={JSON.stringify(error)} />;
  }

  const handlesSeeSamplesAvailable = (e) => {
    e.preventDefault();
    trackGTMEvent(TrackingEventType.STORE_LOCATOR_FLOW.SEE_SAMPLES_AVAILABLE_POPUP, {
      label: 'SEE SAMPLES AVAILABLE',
    });
    seeSamplesAvailable();
  };

  return (
    <Wrapper data-testid="NoParticipatingStores">
      <span>
        Sorry! There are
        <Text as="span" weight="bold">
          {' '}
          no eligible locations{' '}
        </Text>
        to redeem this offer near you.
      </span>
      {/* should show see samples link when no locations and free trials available near postalcode */}
      {data && data.freetrials && data.freetrials.products && data.freetrials.products.length > 0 && (
        <Text size="medium" textAlign="start" margin={{ top: 'xsmall', bottom: '0' }}>
          <TextLink
            href="/products"
            onClick={handlesSeeSamplesAvailable}
            label="SEE SAMPLES AVAILABLE &gt;"
            fontWeight="bold"
          />
        </Text>
      )}
    </Wrapper>
  );
}
