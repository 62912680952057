// @ts-check
import React, { useEffect, useState } from 'react';
import Container from '../../../Atoms/Container';
import ProductHeader from '../../../Organisms/Product/ProductHeader';
import { useWantIdFromUri } from '../../../hooks/useWantIdFromUri';
import { useDataStorePickUpLocation } from '../../../hooks/useDataStorePickUpLocation';
import { useCampaignFromWant } from '../../../hooks/useCampaignFromWant';
import { SplitNames, isFeatureOn } from '../../../utils/splitio';
import RebateDescription from './RebateDescription';
import WhereToFindSection from './WhereToFindSection';
import DiscountsSection from '../../../Organisms/DiscountsSection';
import CashbackDescription from './CashbackDescription';
import LoadingSection from '../../../Molecules/LoadingSection';
import { useAvailableDiscounts } from '../../../hooks/useAvailableDiscounts';

const RebateInviteAccepted = ({ showUserFlow, userId, product, title }) => {
  const [wantId] = useWantIdFromUri('invitation');
  const [isDiscountFeatureEnable, setIsDiscountFeatureEnable] = useState(false);
  const [campaign, campaignError] = useCampaignFromWant(wantId);
  const dataStore = useDataStorePickUpLocation(wantId);

  useEffect(() => {
    isFeatureOn(SplitNames.discountsV1).then(setIsDiscountFeatureEnable);
  }, []);

  const displayAdditionInfoContainer =
    isDiscountFeatureEnable &&
    !dataStore.data.loading &&
    !dataStore.data.queryError &&
    campaign &&
    !campaignError;

  const headerDescription = isDiscountFeatureEnable ? (
    <RebateDescription
      productName={product.name}
      campaign={campaign}
      campaignError={campaignError}
      dataStore={dataStore}
    />
  ) : (
    <CashbackDescription productName={product.name} />
  );

  return (
    <>
      <Container>
        <ProductHeader
          userId={userId}
          product={product}
          title={title}
          description={headerDescription}
          isAccepted
          isDescriptionAsContainer
          showSNSButtons={!isDiscountFeatureEnable}
          dataTrackCategory="Congrats"
          campaign={campaign}
        />
      </Container>
      {displayAdditionInfoContainer && (
        <RebateDiscountsSection
          wantId={wantId}
          product={product}
          campaign={campaign}
          dataStore={dataStore}
          showUserFlow={showUserFlow}
        />
      )}
    </>
  );
};

const RebateDiscountsSection = ({ wantId, product, campaign, dataStore, showUserFlow }) => {
  const { isLoading, discounts } = useAvailableDiscounts(wantId);

  if (isLoading) {
    return <LoadingSection />;
  }

  return (
    <>
      <DiscountsSection discounts={discounts} />
      <Container>
        <WhereToFindSection
          discounts={discounts}
          product={product}
          campaign={campaign}
          dataStore={dataStore}
          showUserFlow={showUserFlow}
        />
      </Container>
    </>
  );
};

export default RebateInviteAccepted;
