import React from 'react';

export const Search = ({ color, width, height } = {}) => (
  <svg
    width={width || '15'}
    height={height || '16'}
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9667 8.80503H10.3343L10.1102 8.58891C10.8946 7.67639 11.3669 6.49171 11.3669 5.20297C11.3669 2.32933 9.03755 0 6.16391 0C3.29027 0 0.960938 2.32933 0.960938 5.20297C0.960938 8.07661 3.29027 10.4059 6.16391 10.4059C7.45265 10.4059 8.63732 9.93368 9.54984 9.14923L9.76597 9.37336V10.0057L13.7683 14L14.9609 12.8073L10.9667 8.80503ZM6.16391 8.80503C4.17077 8.80503 2.56185 7.19611 2.56185 5.20297C2.56185 3.20983 4.17077 1.60091 6.16391 1.60091C8.15705 1.60091 9.76597 3.20983 9.76597 5.20297C9.76597 7.19611 8.15705 8.80503 6.16391 8.80503Z"
      fill={color || '#333333'}
    />
  </svg>
);
