import React from 'react';
import { ResponsiveContext } from 'grommet';
import { Text } from 'socialnature-ui';
import ProductTitle from '../../../Organisms/Products/ProductTitle';

export function GeoProductCarouselTitle(props) {
  const { location } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const isSmall = size === 'small';

  return (
    <ProductTitle
      margin={{ top: '32px', bottom: '8px' }}
      title={
        <>
          Free samples available
          {location && (
            <>
              &nbsp;near&nbsp;
              <Text
                as="span"
                weight="bold"
                color="launchpad"
                style={{ textTransform: 'uppercase' }}
                size={isXsmall || isSmall ? 'large' : 'xxlarge'}
              >
                {location}
              </Text>
            </>
          )}
        </>
      }
    />
  );
}
