import { useCallback, useEffect, useState } from 'react';
import * as R from 'ramda';
import getUserId from '../../utils/userId';
import clientGraphql from '../../utils/apis/graphql';
import { getSampleKitInformationFromSlug } from '../../utils/apis/products';
import {
  getWantInformation,
  getWantFromUserAndProduct,
  getUserAndProduct,
} from '../../utils/apis/graphql/locateMyProduct';
import {
  locateStoresNearLocationByCampaignIdPostalCode,
  LocateStoresNearLocationByProductId,
} from '../../utils/apis/campaigns';

export function useDataStorePickUpLocation(wantId, sampleKitSlug) {
  const [data, setData] = useState({
    userLocation: undefined,
    samplekit: undefined,
    campaignId: undefined,
    wantId: undefined,
    preferredLocation: undefined,
    queryError: undefined,
    loading: true,
  });

  useEffect(() => {
    const userTrackId = getUserId();
    if (!userTrackId) {
      // When user is NOT logged in, get locations By Product
      clientGraphql
        .query({
          query: getSampleKitInformationFromSlug(sampleKitSlug),
        })
        .then((res) => {
          const {
            data: { samplekits },
          } = res;
          if (!R.isEmpty(samplekits)) {
            setData({
              userLocation: undefined,
              samplekit: samplekits[0],
              campaignId: undefined,
              wantId: undefined,
              preferredLocation: undefined,
              queryError: undefined,
              loading: false,
            });
          }
        })
        .catch((e) =>
          setData({
            ...data,
            queryError: e,
            loading: false,
          }),
        );
    } else if (wantId) {
      // When user is logged in and wantId is provided, get locations by campaign
      clientGraphql
        .query({
          query: getWantInformation(wantId, userTrackId),
        })
        .then((res) => {
          const {
            data: { samplekits, users },
          } = res;
          if (!R.isEmpty(samplekits) && !R.isEmpty(users)) {
            const want = samplekits[0].wants[0];
            setData({
              userLocation: users[0].address,
              samplekit: samplekits[0],
              campaignId: want.campaign,
              wantId,
              preferredLocation: want.location_id,
              queryError: undefined,
              loading: false,
            });
          } else {
            setData((prev) => ({
              ...prev,
              queryError: 'Login with the account that tried this product.',
              loading: false,
            }));
          }
        })
        .catch((e) =>
          setData({
            ...data,
            queryError: e,
            loading: false,
          }),
        );
    } else {
      // When user is logged in and NOT wantId is provided, get locations by campaign
      clientGraphql
        .query({
          query: getUserAndProduct(sampleKitSlug, userTrackId),
        })
        .then((sampleKitRes) => {
          const {
            data: { samplekits, users },
          } = sampleKitRes;
          if (!R.isEmpty(samplekits) && !R.isEmpty(users)) {
            const samplekit = samplekits[0];
            const user = users[0];

            clientGraphql
              .query({
                query: getWantFromUserAndProduct(samplekit.id, user.id),
              })
              .then((wantRes) => {
                const {
                  data: { wants },
                } = wantRes;
                if (!R.isEmpty(wants)) {
                  const want = wants[0];
                  setData({
                    userLocation: user.address,
                    samplekit,
                    campaignId: want.campaign,
                    wantId: want.id,
                    preferredLocation: want.location_id,
                    queryError: undefined,
                    loading: false,
                  });
                }
              })
              .catch((e) =>
                setData({
                  ...data,
                  queryError: e,
                  loading: false,
                }),
              );
          }
        })
        .catch((e) =>
          setData({
            ...data,
            queryError: e,
            loading: false,
          }),
        );
    }
    // Data is not used as dependency to avoid inifinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantId, sampleKitSlug]);

  const { samplekit, preferredLocation } = data;
  const userLocation =
    (data.userLocation && data.userLocation) || window.localStorage.getItem('cn_location');

  const handleFormSubmitFetch = useCallback(
    (location) =>
      data.wantId && data.campaignId
        ? locateStoresNearLocationByCampaignIdPostalCode(data.campaignId, location)
        : samplekit && LocateStoresNearLocationByProductId(samplekit.id, location),
    [data, samplekit],
  );

  return { data, samplekit, userLocation, preferredLocation, handleFormSubmitFetch };
}
