import React from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import { CarouselProvider } from 'pure-react-carousel';
import Carousel from '../Products/Carousel';
import DiscountCard from '../../Molecules/Discounts/DiscountCard';

const CarouselWrapper = styled.div`
  text-align: left;
  .carousel__navigation {
    margin-top: 16px;
    margin-bottom: 8px;
  }
`;

const DiscountCarousel = ({ discounts, dataTrackCategory = null }) => {
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);
  const size = React.useContext(ResponsiveContext);
  const slides = {
    xsmall: 2,
    small: 2,
    medium: 3,
    large: 4,
  };

  if (!discounts) {
    return <></>;
  }

  const discountList = discounts.map((item) => ({ ...item, slug: item.id }));

  return (
    <CarouselProvider
      naturalSlideWidth={Math.round(width)}
      naturalSlideHeight={Math.round(height) + 32}
      totalSlides={discountList.length}
      lockOnWindowScroll
      step={slides[size]}
      visibleSlides={slides[size]}
    >
      <CarouselWrapper>
        <Carousel
          products={discountList}
          size={size}
          measuredRef={measuredRef}
          renderSlide={(discount, index) => (
            <DiscountCard
              discount={discount}
              width="100%"
              height="100%"
              index={index}
              dataTrackCategory={dataTrackCategory}
            />
          )}
        />
      </CarouselWrapper>
    </CarouselProvider>
  );
};

export default DiscountCarousel;
