import React, { useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import Twitter from '../../../Atoms/Icons/sns/Twitter';
import { getDataset } from '../../../utils/socialShare';

const ShareButton = (props) => {
  const { shareUrl, message, variant, onClick } = props;
  const dataAttributes = getDataset(props);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <ShareButtonStyle
      isXsmall={isXsmall}
      href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
        shareUrl.replace(/\{0\}/g, 'twitter'),
      )}&text=${encodeURIComponent(message || '')}`}
      target="_blank"
      data-track-label={shareUrl.replace(/\{0\}/g, 'twitter')}
      icon={<Twitter />}
      label="Tweet"
      {...dataAttributes}
      variant={variant}
      onClick={onClick}
    />
  );
};

export default ShareButton;
