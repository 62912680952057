// @ts-check
import { handleReactivation } from '../../apis/rebates';
import { handleError } from '../../error';

export function getWantLink(isLoggedIn, productSlug, productId, useNewActivationFlow = false, guestSessionID = null) {
  const SIGNIN_REDIRECT_FOR_NEW_ACTIVATION = `/sign-in?ReturnUrl=/pending-activation/${productSlug}`;
  const WANT_BOOST_URL = `/${productSlug}/want-boost`;
  const APPLY_2TRY_REDIRECT = `/sign-in?ReturnUrl=/${productSlug}/want-boost&productId=${productId}&snsource=apply_to_try`;
  const GUEST_REGISTRATION_PREFIX = `/sign-in/signup?ReturnUrl=/${productSlug}/want-boost&productId=${productId}&snsource=direct&snsource=apply_to_try`;

  if (!isLoggedIn && useNewActivationFlow) {
    if (guestSessionID) {
      return `${GUEST_REGISTRATION_PREFIX}&user-session=${guestSessionID}`; // Redirect to Registration/Password prompt 
    }
    return SIGNIN_REDIRECT_FOR_NEW_ACTIVATION;
  }
  return isLoggedIn ? WANT_BOOST_URL : APPLY_2TRY_REDIRECT;
}

export function getWantCallToAction(isLoggedIn, postalCode, productId, productSlug, options, useNewActivationFlow = false, guestSessionID = null) {
  const wantLink = getWantLink(isLoggedIn, productSlug, productId, useNewActivationFlow, guestSessionID);
  if (options && options.reactivateOffer) {
    return {
      href: null,
      onClick: () => handleReactivation(options.wantId),
    };
  }

  if (options && options.showStoreLocatorFlow) {
    return {
      href: null,
      onClick: options.openStoreLocationModal,
    };
  }
  // If user have not entered postal code, open the geo modal
  if (!postalCode) {
    if (options) {
      return {
        href: null,
        onClick: options.openGeoModal,
      };
    }
    handleError('JS error at getWantCallToAction: options is invalid.');
    return {
      href: `/sign-in?ReturnUrl=/${productSlug}/want-boost&productId=${productId}&snsource=apply_to_try`,
    };
  }

  return {
    href: wantLink,
  };
}
