import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box, ResponsiveContext } from 'grommet';

const BannerBox = styled(Box)`
  padding: 0;
  background-image: url(images/home/mobile-gradient-bg.png), url(images/home/mobile-subject.jpg);
  background-size: 100% 100%, 100%;
  background-repeat: no-repeat;

  ${({ isXsmall }) =>
    isXsmall
      ? `
			height: 574px;
			background-image: url(images/home/mobile-gradient-bg.png), url(images/home/mobile-subject.jpg);
			`
      : `
			height: 750px;
			background-image: url(images/home/tablet-gradient-bg.png), url(images/home/tablet-subject.jpg);
			`}
  @media only screen and (max-width: 360px) {
    height: 511px;
  }
`;

const ContentBox = styled.div`
  height: 100%;
  padding: 0 16px 32px 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const MobileBackground = ({ children }) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <BannerBox pad={{ vertical: 'large' }} isXsmall={isXsmall}>
      <ContentBox>{children}</ContentBox>
    </BannerBox>
  );
};

export default MobileBackground;
