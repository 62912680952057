import { Box, Image, ResponsiveContext } from 'grommet';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Card, CardContent } from 'socialnature-ui';
import { getHasuraUserId } from '../../utils/userId';
import Container from '../../Atoms/Container';
import InviteFriendContent from '../../Organisms/InviteFriend/InviteFriendContent';
import FriendsSocialSrc from '../../images/InviteFriendsPage/friends-social.svg';
import BgLeftSrc from '../../images/InviteFriendsPage/bg-left.svg';
import BgRightSrc from '../../images/InviteFriendsPage/bg-right.svg';
import { getFeatureTreatment, SplitNames } from '../../utils/splitio';
import Loading from '../../Molecules/Loading';

// To have the background image touch the footer, need to set the min-height of the content
const HEADER_HEIGHT_PC = '5.28571rem';
const FOOTER_HEIGHT_PC = '250px';

const BackgroundImage = styled.div`
  ${(props) =>
    !props.isSmallOrLess &&
    `
			background: url('${BgLeftSrc}'), url('${BgRightSrc}');
			background-repeat: no-repeat, no-repeat;
			background-position: bottom left, bottom right;
			background-size: 363px auto, 686px auto;
			min-height: calc(100vh - ${HEADER_HEIGHT_PC} - ${FOOTER_HEIGHT_PC});
			display: flex;
			flex-direction: column;
			justify-content: center;
		`}
`;

const imageSrcs = {
  off: {
    src: FriendsSocialSrc,
  },
  earthDay: {
    src: '/images/congratsPage/earthday-2024.png',
  },
  backToSchool1: {
    src: '/images/congratsPage/back-to-school1.svg',
  },
  backToSchool2: {
    src: '/images/congratsPage/back-to-school2.svg',
  },
  backToSchool3: {
    src: '/images/congratsPage/back-to-school3.svg',
  },
  featureAlaffia: {
    src: '/images/congratsPage/alaffia-giveaway-items.png',
  },
  veganuaryFabalishVeganMayo: {
    src: '/images/congratsPage/veganuary-fabalish-vegan-mayo.png',
  },
  veganuaryRitterSport: {
    src: '/images/congratsPage/veganuary-ritter-sport.png',
  },
  walmart: {
    src: '/images/congratsPage/walmart-give-away.png',
  },
  springCleaning: {
    src: '/images/congratsPage/spring-cleaning.png',
  },
  picnicSnackPack: {
    src: '/images/congratsPage/picnic-snack-pack.png',
  },
  birthdayPack: {
    src: '/images/congratsPage/bday-giveaway-flat-lay.png',
  },
  holidayRelaxDec2024: {
    src: '/images/congratsPage/holiday_relax_dec_2024.png'
  },
  referFriendFeb2025: {
    src: '/images/congratsPage/raf-galentine-giveaway-flat-lay.png'
  }
};

export default function InvitePendingPage() {
  const size = React.useContext(ResponsiveContext);
  const isSmallOrLess = size === 'small' || size === 'xsmall';
  const userId = getHasuraUserId();
  const [customCampaignRAFGiveaways, setCustomCampaignRAFGiveaways] = useState('off');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFeatureTreatment(SplitNames.customCampaignRAFGiveaways).then((value) => {
      setCustomCampaignRAFGiveaways(value);
      setLoading(false);
    });
  }, [userId]);

  if (loading) return <Loading />;

  const src =
    (imageSrcs[customCampaignRAFGiveaways] && imageSrcs[customCampaignRAFGiveaways].src) ||
    imageSrcs.off.src;

  return (
    <BackgroundImage isSmallOrLess={isSmallOrLess}>
      <Container>
        <Box
          pad={{ top: 'large', bottom: 'xlarge' }}
          direction={isSmallOrLess ? 'column' : 'row'}
          justify="between"
          align="center"
        >
          <Box
            width={isSmallOrLess ? 'auto' : { max: '590px', min: '590px' }}
            style={isSmallOrLess ? { order: 2 } : {}}
          >
            <Card>
              <CardContent
                pad={{
                  top: 'large',
                  right: 'small',
                  bottom: 'xsmall',
                  left: 'small',
                }}
              >
                <InviteFriendContent
                  userId={userId}
                  showTermsAndConditions
                  customCampaignRAFGiveaways={customCampaignRAFGiveaways}
                />
              </CardContent>
            </Card>
          </Box>
          <Box
            width={isSmallOrLess ? { max: '168px' } : { max: '490px' }}
            flex="shrink"
            pad={{ bottom: 'medium' }}
            margin={isSmallOrLess ? {} : { left: 'medium' }}
            style={isSmallOrLess ? { order: 1 } : {}}
          >
            <Image src={src} alt="" role="presentation" />
          </Box>
        </Box>
      </Container>
    </BackgroundImage>
  );
}
