import React from 'react';

const LocationIcon = () => (
  <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.6 0C2.504 0 0 2.504 0 5.6C0 9.8 5.6 16 5.6 16C5.6 16 11.2 9.8 11.2 5.6C11.2 2.504 8.696 0 5.6 0ZM5.6 7.6C4.496 7.6 3.6 6.704 3.6 5.6C3.6 4.496 4.496 3.6 5.6 3.6C6.704 3.6 7.6 4.496 7.6 5.6C7.6 6.704 6.704 7.6 5.6 7.6Z"
      fill="#DB2573"
    />
  </svg>
);
export default LocationIcon;
