export const customQuestionTypes = {
  TEXT: 'text',
  DATE: 'date',
  LIKERT: 'likert',
  SLIDER: 'slider',
  LIST: 'list',
  MULTIPLE: 'multiple',
  AGERANGE: 'agerange',
  MULTIPLE_TEXT: 'multipletext',
  DUE_DATE: 'duedate',
  CHECKBOX: 'checkbox',
};

export const answerTypes = {
  STRING: 'StringAnswer',
  NUMBER: 'NumberAnswer',
  LIST: 'ListAnswer',
};

export const customQuestionAnswerTypes = {
  [customQuestionTypes.TEXT]: answerTypes.STRING,
  [customQuestionTypes.DATE]: answerTypes.STRING,
  [customQuestionTypes.LIKERT]: answerTypes.NUMBER,
  [customQuestionTypes.SLIDER]: answerTypes.NUMBER,
  [customQuestionTypes.LIST]: answerTypes.STRING,
  [customQuestionTypes.MULTIPLE]: answerTypes.LIST,
  [customQuestionTypes.AGERANGE]: answerTypes.LIST,
  [customQuestionTypes.MULTIPLE_TEXT]: answerTypes.LIST,
  [customQuestionTypes.DUE_DATE]: answerTypes.STRING,
};

export const errorMessages = {
  FORM_ERROR: 'Please check your answers and try again.',
  SUBMIT_ERROR:
    'One of the required fields is empty or contains invalid data. Please check the errors above and try again.',
  REQUIRED: 'Answer required',
  INVALID_DATE: 'Invalid Date',
  INVALID_YEAR: 'Invalid Year',
  FUTURE_DATE: 'Invalid Date',
  MAX_LENGTH: 'Maximum limit of characters allowed',
  DEFAULT_ERROR: 'Invalid Answer',
  SERVER_ERROR: 'Something went wrong. Please check your answers and try again.',
  CONSENT_REQUIRED: 'Consent required',
};

export const messages = {
  FORM_SUCCESS: 'Changes were saved successfully!',
};
