import React from 'react';
import { Box } from 'grommet';

const Section = ({ size, children }) => (
  <Box flex={false} margin={{ vertical: size || 'section' }} align="center">
    {children}
  </Box>
);

export default Section;
