import React, { useEffect, useState, useCallback, useMemo } from 'react';
import * as R from 'ramda';
import { useLocation, useParams } from 'react-router-dom';
import { Box } from 'socialnature-ui';
import { handleError } from '../../utils/error';
import ProductAssetsGallery from './ProductAssetsGallery';
import ProductDetails from './ProductDetails';
import ProductReviews from './ProductReviews';
import ProductMenu from './ProductMenu';
import ProductMobileFooter from './ProductMobileFooter';
import GeoProductCarousel from './GeoProductCarousel';
import { checkIfProductNearby, getProductMatchLocation } from '../../utils/apis/products';
import ProductFeaturedReview from './ProductFeaturedReview';
import { TrackingEventType } from '../../utils/tracking';
import { useProductPage } from '../../hooks/useProductPage';
import { useUserGeoModal } from '../../hooks/useUserGeoModal';
import { CTATypes } from '../../utils/product/index';
import {
  campaignTypes,
  getCampaignStatusByProductPageData,
  storeLocationCtaLabels,
} from '../../utils/campaignTypes';
import { useProductCTA } from '../../hooks/useProductCTA';
import { useCouponModal } from '../../hooks/useCouponModal';
import { ProductCTAButton } from './ProductCTAButton';
import Loading from '../../Molecules/Loading';
import ErrorPage from '../ErrorPage';
import { useLocationStoreModal } from '../../hooks/useLocationStoreModal';
import LoadingSection from '../../Molecules/LoadingSection';
import { isFeatureOn, SplitNames } from '../../utils/splitio';

export default function ProductPage() {
  const { productSlug } = useParams();
  const { search } = useLocation();
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);
  const reviewId = searchParams.get('review') || -1;

  const [geoInfo, setGeoInfo] = useState({
    loaded: false,
    location: null,
    country: null,
    isNearby: false,
    success: [],
    failed: [],
  });

  const [reactivateOfferIsOn, setReactivateOfferIsOn] = useState(false);
  const [rebateOfferDeadline, setRebateOfferDeadline] = useState(false);

  const { loading, error, data } = useProductPage(productSlug, reviewId);
  const { product, userId, featuredReview, campaign, userWant } = data || {};
  const {
    id,
    slug,
    images,
    featured_video: featuredVideo,
    details,
    socialShare,
    coupon,
    content,
  } = product || {};
  const { totalReviews, wantDisabled } = details || {};

  // checks current product location, freetrials, discounts and launchpads near location
  const performGeoCheck = useCallback(
    () =>
      new Promise((resolve, reject) => {
        try {
          setGeoInfo({
            loaded: false,
            ...geoInfo,
          });
          checkIfProductNearby(userId, id)
            .then((result) => {
              setGeoInfo({
                loaded: true,
                ...result,
              });

              if (result.location && result.country) {
                if (!result.isNearby) {
                  window.dataLayer.push({ event: TrackingEventType.USER_LOCATION_NOMATCH });
                } else {
                  window.dataLayer.push({ event: TrackingEventType.USER_LOCATION_MATCH });
                }
              }
              resolve(result);
            })
            .catch((e) => {
              handleError(e);
              reject(e);
            });
        } catch (geoException) {
          handleError(geoException);
          reject(geoException);
        }
      }),
    // geoInfo needs to be excluded since it will fire an inifite render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id, userId],
  );

  const isLoggedIn = !!userId;

  const campaignType = campaign && campaign.campaignType;

  const userNotNearLocations =
    geoInfo.success && (!geoInfo.success.includes(id) || !geoInfo.location);
  const showStoreLocatorFlow =
    (campaignType === campaignTypes.IN_STORE || campaignType === campaignTypes.REBATE) &&
    (!isLoggedIn || (isLoggedIn && !userNotNearLocations));
  const { LocationStoreModal, open: openStoreLocationModal } = useLocationStoreModal(
    geoInfo.location || '',
    product,
    isLoggedIn,
    false,
    performGeoCheck,
    storeLocationCtaLabels[campaignTypes.IN_STORE],
  );

  const { GeoModal, open: openGeoModal } = useUserGeoModal(
    id,
    '',
    false,
    performGeoCheck,
    campaignType,
  );
  const { CouponModal, open: openCouponModal } = useCouponModal(
    { ...coupon, image: ((coupon || {}).featuredImage || {}).small },
    id,
    slug,
    false,
  );
  const { CTAType, productCTAProps } = useProductCTA(data, geoInfo, {
    openGeoModal,
    openCouponModal,
    openStoreLocationModal,
  });

  useEffect(() => {
    if (data) {
      // On product page load, if user location is missing
      if (!userId && !getProductMatchLocation()) {
        window.dataLayer.push({ event: TrackingEventType.USER_LOCATION_REQUIRED });
      }
      performGeoCheck();
    }
  }, [data, performGeoCheck, userId]);

  useEffect(() => {
    isFeatureOn(SplitNames.reactivateRebateOffer, userId).then(setReactivateOfferIsOn);
    isFeatureOn(SplitNames.rebateOfferDeadline, userId).then(setRebateOfferDeadline);
  }, [userId]);

  const campaignStatus = useMemo(
    () => getCampaignStatusByProductPageData(data, reactivateOfferIsOn, rebateOfferDeadline),
    [data, reactivateOfferIsOn, rebateOfferDeadline],
  );

  if (loading) return <Loading />;
  if (error) {
    const { message } = error || {};
    handleError(error);
    return <ErrorPage detail={message} />;
  }

  if (!product || R.isEmpty(product)) return <></>;

  return (
    <>
      <div className="contain" itemScope itemType="https://schema.org/Product">
        {!R.isEmpty(featuredReview) && <ProductFeaturedReview featuredReview={featuredReview} />}
        <ProductAssetsGallery
          ctaType={CTAType}
          ProductCTAButton={<ProductCTAButton productCTAProps={productCTAProps} />}
          campaignStatus={campaignStatus}
          assets={images}
          video={featuredVideo}
          details={details}
          coupon={coupon}
          socialShare={socialShare}
          openGeoModal={openGeoModal}
          geoInfo={geoInfo}
          campaign={campaign}
          isLoggedIn={!!userId}
          userId={userId}
          userWant={userWant}
          reactivateOfferIsOn={reactivateOfferIsOn}
          openStoreLocationModal={(CTALabel) => openStoreLocationModal(CTALabel)}
        />

        {!geoInfo.loaded ? (
          <Box margin={{ top: '16px' }}>
            <LoadingSection />
          </Box>
        ) : (
          !wantDisabled &&
          geoInfo.location &&
          !geoInfo.isNearby && (
            <div className="sample-geoalternatives" rel="GeoProductCarousel">
              <GeoProductCarousel postalCode={geoInfo.location} country={geoInfo.country} />
            </div>
          )
        )}
        <ProductMenu
          content={content}
          ProductCTAButton={<ProductCTAButton productCTAProps={productCTAProps} />}
        />
        <div className="launch-content">
          <div className="layout-row-sm">
            <div className="flex-65">
              <ProductDetails content={content} />
              <ProductReviews
                productId={product.id}
                totalReviews={totalReviews}
                slug={product.slug}
              />
            </div>

            {CTAType !== CTATypes.SIGN_UP && (
              <ProductMobileFooter
                socialShare={socialShare}
                ProductCTAButton={<ProductCTAButton productCTAProps={productCTAProps} />}
                campaignStatus={campaignStatus}
                ctaType={CTAType}
              />
            )}
          </div>
        </div>
      </div>
      <GeoModal />
      <CouponModal />
      {showStoreLocatorFlow && LocationStoreModal()}
    </>
  );
}
