import React from 'react';
import { Box, Image, Grommet } from 'grommet';
import styled from 'styled-components';
import { deepMerge } from 'grommet/utils';
import basicTheme from '../../themes';

const CardImage = ({ src }) => {
  const customStyle = deepMerge(basicTheme, {
    box: {
      extend: () => {
        const style = `
					position: relative;
					width: 100%;
					height: 0;
					padding-bottom: 100%;
				`;
        return style;
      },
    },
  });

  return (
    <Grommet theme={customStyle}>
      <Box
        round="xsmall"
        background="transparent"
        elevation="image"
        height="0"
        width="100%"
        pad={{
          bottom: '100%',
        }}
      >
        <ImageWrapper>
          <Image fit="contain" src={src} />
        </ImageWrapper>
      </Box>
    </Grommet>
  );
};

export default CardImage;

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 40px 16px 16px;
`;
