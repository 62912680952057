import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form-7.43.7';
import styled from 'styled-components';
import { CheckBox } from 'socialnature-ui';
import basicTheme from '../../../themes';
import BasicInput from '../../WithoutGroomet/Inputs/InputField';
import PrimaryButton from '../../../Atoms/Buttons/PrimaryButton';
import { isPhoneNumberFn, PHONE_COUNTRY_CODE } from '../../../Pages/SignInPage/PhoneConsent';
import useDelayInputValidation from '../../../hooks/useDelayInputValidation';
import { trackEvent } from '../../../utils/gtm';
import { TrackingEventType } from '../../../utils/tracking';
import { getCookie } from '../../../utils/cookie';
import BasicLink from '../../../Atoms/WithoutGroomet/Link/BasicLink';
import { updatePhoneAndConsent } from '../../../utils/apis/users';
import LoadingSection from '../../../Molecules/LoadingSection';
import SMSDoubleOptinTsCs from '../../../Molecules/SmsConsent/SMSDoubleOptinTsCs';
import { copiesSMSOptIn } from '../../../Pages/SignInPage/PhoneConsent/copiesSmsOptIn';
import { formatPhoneNumber } from '../../../utils/number';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  input[type="text"] {
    border-color: #ccc;
    width:100%;
  }
  input[type="text"]:disabled {
    background-color: #DDDDDD;
  }
`;

const StyledImg = styled.img`
  width: 248px;
`;

const StyledH3 = styled.h3`
  font-style: normal;
  font-weight: 700;
  font-size: ${({ fontSize }) => fontSize || '24px'};
  line-height: ${({ lineHeight }) => lineHeight || 'normal'};
  color: ${basicTheme.global.colors.black};
  margin: ${({ margin }) => margin || '16px 0px 8px'};
  text-align: ${({ textAlign, isXsmall }) => textAlign || (isXsmall ? 'left' : 'center')};
  min-width: 100%;
`;

const StyledP = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  color: ${basicTheme.global.colors.black};
  margin-bottom: ${({ marginBottom }) => marginBottom || '16px'};
  text-align: ${({ textAlign, isXsmall }) => textAlign || (isXsmall ? 'left' : 'justify')};
`;

const CheckBoxWrapper = styled.div`
  padding-top: 16px;
  label {
    span {
      line-height: 16px;
      font-size: 14px;
      font-weight: 400;
      color: ${basicTheme.global.colors.black};
    }
  }

  label {
    display: flex;
    align-items: flex-start;
  }
  label > div:first-child {
    flex-direction: row;
    margin-top: 3px;
  }
  label p {
    text-align: left;
    font-size: 16px;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  height: ${({ height }) => height || '56px'};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  width: ${({ width }) => width && width};
  padding: ${({ padding }) => padding && padding};
  margin-top: ${({ marginTop }) => marginTop || '24px'};
`;

const StyledSkipContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export function SmsOptinPopupForm({
  handleAction,
  isXsmall,
  notificationCallback,
  showMaybeLaterSection,
  trackEventLabelType,
  userId,
  userPhoneNumber,
  optinType,
  handleOnSubmit,
  showImage = true,
  imageSrc,
  stylesTitle,
  stylesP,
  stylesButton,
}) {
  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, errors },
    clearErrors,
    trigger,
    setError,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      smsConsent: undefined,
      phoneNumber: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userPhoneNumber && userPhoneNumber.length) {
      setValue('phoneNumber', userPhoneNumber, { shouldValidate: true });
    }
  }, [setValue, userPhoneNumber]);

  const setSmsConsent = useCallback(
    (phoneNumber, smsConsent) =>
      updatePhoneAndConsent(userId, phoneNumber, smsConsent, trackEventLabelType).then(
        (response) => {
          setIsLoading(false);

          if (!response.successful) {
            setError('phoneNumber', {
              type: 'custom',
              message: response.error,
            });

            return;
          }

          handleOnSubmit();
        },
      ),
    [handleOnSubmit, setError, userId, trackEventLabelType],
  );

  const onSubmit = ({ phoneNumber, smsConsent }) => {
    setIsLoading(true);
    setSmsConsent(phoneNumber, smsConsent);
    // if popup is opened from notification a notification callback will be provided to dismiss the notification
    if (notificationCallback) notificationCallback();

    trackEvent(TrackingEventType.SMS_OPTIN[optinType].SMS_OPTIN_COMPLETED, {
      signinType: getCookie('signin_type'),
    });
  };

  const { handleOnChange, unregisterValidation } = useDelayInputValidation(
    errors,
    clearErrors,
    trigger,
    'phoneNumber',
  );

  useEffect(() => unregisterValidation, [unregisterValidation]);

  useEffect(() => {
    if (window.dataLayer) {
      trackEvent(TrackingEventType.SMS_OPTIN[optinType].SMS_OPTIN_VIEW, {
        signinType: getCookie('signin_type'),
      });
    }
  }, [optinType]);

  const phoneNumberHasError = !!errors.phoneNumber && !!errors.phoneNumber.message;

  return (
    <Container>
      {showImage && (
        <StyledImg src={imageSrc || '/images/smsoptin/sms-optin-popup.png'} alt="sms-optin-popup" />
      )}
      <StyledH3 isXsmall={isXsmall} {...stylesTitle}>
        {copiesSMSOptIn.TITLE}
      </StyledH3>
      <StyledP isXsmall={isXsmall} {...stylesP}>
        {copiesSMSOptIn.MESSAGE}
      </StyledP>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="phoneNumber"
          defaultValue=""
          rules={{
            required: true,
            validate: {
              isPhoneNumber: isPhoneNumberFn,
            },
          }}
          render={({ field: { value, ref } }) => (
            <BasicInput
              id="phoneNumber"
              type="text"
              inputMode="numeric"
              disabled={isLoading || !!userPhoneNumber}
              placeholder={copiesSMSOptIn.INPUT_PLACEHOLDER}
              handleOnChange={(e) => {
                const phoneWithMask = formatPhoneNumber(e.target.value);
                // Cannot set value with onChange from controller.render since it would fire the validation without the timeout
                setValue('phoneNumber', phoneWithMask);
                handleOnChange(phoneWithMask);
              }}
              value={value || ''}
              ref={ref}
              error={phoneNumberHasError}
              errors={errors}
              handleOnBlur={(e) => {
                if (e.target.value === PHONE_COUNTRY_CODE) {
                  // if input has country-code and no phone number it gets clear when onBlur
                  setValue('phoneNumber', '');
                }
              }}
            />
          )}
        />

        <CheckBoxWrapper>
          <Controller
            name="smsConsent"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <CheckBox
                id="smsConsent"
                onBlur={onBlur}
                onChange={(e) => onChange(e.target.checked)}
                checked={value || false}
                label={<SMSDoubleOptinTsCs action={copiesSMSOptIn.ACTION_TS_CS} />}
                ref={ref}
              />
            )}
            control={control}
          />
        </CheckBoxWrapper>

        {isLoading ? (
          <LoadingSection />
        ) : (
          <StyledPrimaryButton
            type="submit"
            margin={{ bottom: '16px' }}
            disabled={!isValid}
            label={copiesSMSOptIn.LABEL_BUTTON}
            fill="horizontal"
            {...stylesButton}
          />
        )}
      </form>
      {showMaybeLaterSection && (
        <>
          {!isLoading && (
            <StyledSkipContainer>
              <BasicLink
                label="Maybe later"
                onClick={handleAction}
                styles={{
                  color: basicTheme.global.colors.black,
                  hoverColor: basicTheme.global.colors.black,
                }}
              />
            </StyledSkipContainer>
          )}
        </>
      )}
    </Container>
  );
}
