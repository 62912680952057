import { useQuery } from '@apollo/react-hooks';
import { useEffect, useState } from 'react';
import { GET_PROFILE_QUESTIONS_PRE_ETA_QUERY, GET_PROFILE_QUESTIONS_QUERY} from '../../utils/apis/graphql/profileQuestion';
import { isFeatureOn, SplitNames } from '../../utils/splitio';
import { isNilOrEmpty } from '../../utils/customRamda';
import { formatCustomQuestions } from '../../utils/formatCustomQuestion';
import { getHasuraUserId } from '../../utils/userId';

const useProfileQuestions = () => {
  const [ newProfileFlow, setNewProfileFlow ] = useState(null);
  useEffect(() => { isFeatureOn(SplitNames.newProfileQuestionFlow).then(setNewProfileFlow); }, []);

  const query = newProfileFlow ? GET_PROFILE_QUESTIONS_QUERY : GET_PROFILE_QUESTIONS_PRE_ETA_QUERY;

  const userId = Number(getHasuraUserId());
  const { loading, error, data } = useQuery(query, {
    variables: { userId },
    skip: newProfileFlow == null, // we don't want to run the query until we know the value of the feature flag
  });

  if (data && newProfileFlow != null) {
    const { questions: allQuestions, stores, user, answeredProfileQuestionCount } = data;
    const { postCode, userName } = user || {};
    const questions = formatCustomQuestions(allQuestions, postCode, 'profile', stores);
    const { aggregate } = answeredProfileQuestionCount || {};
    if (isNilOrEmpty(questions)) {
      return {
        loading,
        error: 'Profile Question: Failed to format custom questions',
      };
    }
    return {
      loading,
      error,
      questions,
      answeredQuestionCount: aggregate ? aggregate.count : null,
      userName,
    };
  }
  return { loading, error, questions: [] };
};

export default useProfileQuestions;
