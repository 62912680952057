import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import H from '../../Atoms/Heading';
import P from '../../Atoms/Paragraph';
import Container from '../../Atoms/Container';
import TextButton from '../../Atoms/Buttons/TextButton';
import ArrowRight from '../../Atoms/Icons/ArrowRight';
import SimpleModal from '../../Molecules/SimpleModal';
import Steps from '../../Molecules/Steps';

const ProductHeader = ({ title, description, learnMoreContent, eyeCatch, steps = false }) => {
  const [open, setOpen] = React.useState(false);
  const size = React.useContext(ResponsiveContext);
  const isDesktop = size === 'large';
  const isXsmall = size === 'xsmall';
  const maxWidth = '620px'; // depending on design

  return (
    <>
      {title && (
        <HeaderBackground isDesktop={isDesktop}>
          <Container>
            <Box direction={isXsmall ? 'column' : 'row'} align="center" gap="medium">
              <Box width={isXsmall ? { max: '80px' } : { min: 'auto', max: '174px' }}>
                {eyeCatch}
              </Box>
              <Box justify="start" gap={isXsmall ? 'medium' : 'xsmall'} width={{ max: maxWidth }}>
                <Box
                  direction={isDesktop ? 'row' : 'column'}
                  gap={isDesktop ? 'medium' : 'xsmall'}
                  align={isXsmall ? 'center' : 'baseline'}
                >
                  <H
                    level={2}
                    color="white"
                    margin="none"
                    textAlign={isXsmall ? 'center' : 'start'}
                    size={isXsmall && '32px'}
                  >
                    {title}
                  </H>
                  {learnMoreContent && (
                    <>
                      <TextButton
                        icon={<ArrowRight width="16" height="16" color="white" />}
                        label="Learn more"
                        onClick={() => setOpen(true)}
                        transform="uppercase"
                        weight="bold"
                        color="white"
                      />
                      <SimpleModal open={open} onClose={() => setOpen(false)}>
                        {learnMoreContent}
                      </SimpleModal>
                    </>
                  )}
                </Box>
                <P color="white" textAlign={isXsmall ? 'center' : 'start'}>
                  {description}
                </P>
              </Box>
              {steps && <Steps isXsmall={isXsmall} />}
            </Box>
          </Container>
        </HeaderBackground>
      )}
    </>
  );
};

export default ProductHeader;

const HeaderBackground = styled.div`
  background: linear-gradient(180deg, #19a2a2 0%, #008faf 100%);
  padding: 32px 0;
  margin-bottom: ${(props) => (props.isDesktop ? '56px' : '16px')};
`;
