// @ts-check
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box, Text, themes } from 'socialnature-ui';
import { ResponsiveContext } from 'grommet';
import ErrorPage from '../../ErrorPage';
import LoadingSection from '../../../Molecules/LoadingSection';
import { useInstructionsModal } from '../../../hooks/useInstructionsModal';
import PrimaryButton from '../../../Atoms/Buttons/PrimaryButton';
import MagnifyingGlass from '../../../Atoms/Icons/MagnifyingGlass';
import HowToGetCashBackButton from '../../../Atoms/Buttons/HowToGetCashbackButton';
import { campaignTypes } from '../../../utils/campaignTypes';
import { getInstructionSteps, views } from '../../../utils/instructions';
import { useWantIdFromUri } from '../../../hooks/useWantIdFromUri';
import { trackEvent as trackGTMEvent } from '../../../utils/gtm';
import { TrackingEventType } from '../../../utils/tracking';
import { isFeatureOn, SplitNames } from '../../../utils/splitio';
import { formatDateToMMMDDYYYY } from '../../../utils/formatDate';
import CampaignContextProvider from '../../../contexts/CampaignContext/provider';

const Wrapper = styled(Box)`
  ${({ isXsmall }) => (isXsmall ? 'text-align: center;' : '')}
`;

const BoldPrimaryButton = styled(PrimaryButton)`
  font-weight: bold;
  &:focus {
    color: ${themes.global.colors.white};
  }
  &:hover {
    color: ${themes.global.colors.white};
  }
  ${({ isXsmall }) =>
    isXsmall
      ? `
    padding: 12px 0;
  `
      : `
    padding: 16px 32px;
  `}
`;

const ButtonsWrapper = styled(Box)`
  display: flex;
  ${({ isXsmall }) => `flex-direction: ${isXsmall ? 'column' : 'row'} `};
`;

const StyledSpan = styled.span`
  display: block;
`;

const Aisle = styled.div`
  display: flex;
  column-gap: 4px;
  justify-content: ${({ isXsmall }) => (isXsmall ? 'center' : 'start')};
  align-items: center;
  margin-top: 16px;
  font-size: 14px;
  line-height: 115%;
`;

const RebateDescription = ({ productName, campaign, campaignError, dataStore }) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [wantId] = useWantIdFromUri('invitation');
  const [rebateOfferDeadline, setRebateOfferDeadLine] = useState(false);
  const instructionsSteps = getInstructionSteps(campaignTypes.REBATE, views.CONGRATS);
  const { InstructionsModal, open } = useInstructionsModal(instructionsSteps, false);

  const { data, samplekit } = dataStore;
  const {
    properties: { aisle, offerDiscountPercentage = 100 },
  } = campaign || { properties: {} };

  useEffect(() => {
    isFeatureOn(SplitNames.rebateOfferDeadline).then(setRebateOfferDeadLine);
  }, []);

  const handleOpenPopup = () => {
    trackGTMEvent(TrackingEventType.REBATE_FLOW.HOW_TO_CASHBACK, {
      instructionsType: views.CONGRATS,
      samplekit: productName,
    });
    return open();
  };

  if (data.queryError || campaignError) {
    return <ErrorPage detail={JSON.stringify(data.queryError)} />;
  }

  return data.loading ? (
    <LoadingSection />
  ) : (
    <Wrapper isXsmall={isXsmall}>
      <>
        <CampaignContextProvider campaignInfo={campaign}>
          <InstructionsModal />
        </CampaignContextProvider>

        <Text margin={{ top: isXsmall ? '1px' : '6px', bottom: '0' }}>
          {rebateOfferDeadline ? (
            <>
              <StyledSpan>
                Upload your receipt by{' '}
                <b>
                  {campaign && formatDateToMMMDDYYYY(campaign.completeByDate)} and before all offers
                  are claimed
                </b>{' '}
                to get cash back.
              </StyledSpan>
            </>
          ) : (
            `Shop at eligible stores and upload your receipt to get ${offerDiscountPercentage}% cash back. Don't
            forget to share your review to inspire others and receive more offers.`
          )}
        </Text>

        {aisle && (
          <Aisle isXsmall={isXsmall}>
            <MagnifyingGlass />
            <span>Find in {aisle} aisle</span>
          </Aisle>
        )}

        <HowToGetCashBackButton
          iconSize="20"
          onClick={handleOpenPopup}
          margin={{ top: '14px', bottom: isXsmall ? '26px' : '18px' }}
        />
        <ButtonsWrapper isXsmall={isXsmall}>
          <BoldPrimaryButton
            label="ADD A RECEIPT"
            href={`/uploadreceipt/${wantId}`}
            color="pink"
            gap="xsmall"
            isXsmall={isXsmall}
            onClick={() =>
              trackGTMEvent(TrackingEventType.REBATE_FLOW.ADD_RECEIPT_LINK, {
                samplekit: productName,
              })
            }
            data-track-category="Rebate"
            data-track-action="Add a receipt button"
            data-track-label="Congrats page"
            data-track-samplekit-id={samplekit ? samplekit.id : ''}
          />
        </ButtonsWrapper>
      </>
    </Wrapper>
  );
};

export default RebateDescription;
