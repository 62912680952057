import React from 'react';
import { Box, Text } from 'grommet';
import styled from 'styled-components';
import Boost from '../../Atoms/Icons/Boost';
import Heart from '../../Atoms/Icons/Heart';
import basicTheme from '../../themes';
import StarRating from '../../Atoms/StarRating';

const Count = ({ type, value, averageRating }) => {
  switch (type) {
    case 'launchpad':
      return (
        <NumberWrapper>
          <Boost color="launchpad" />
          <Text size="small">
            <Num>{value}</Num>
            <span>Boosts</span>
          </Text>
        </NumberWrapper>
      );
    case 'freeTrial':
      return (
        <NumberWrapper>
          <Heart color="freetrial" />
          <Text size="small">
            <Num>{value}</Num>
            <span>Want to try it</span>
          </Text>
        </NumberWrapper>
      );
    case 'review':
      return (
        <NumberWrapper>
          <Box margin={{ right: '5px' }}>
            <StarRating rating={averageRating} />
          </Box>
          <Text size="small">
            <Num>{value}</Num>
            <span>Reviews</span>
          </Text>
        </NumberWrapper>
      );
    default:
      return <></>;
  }
};

const NumberWrapper = styled.div`
	color: ${basicTheme.global.colors.black}
	display: flex;
	align-items: center;
`;

const Num = styled.span`
  margin: 0 0.25em 0 0.25em;
`;

export default Count;
