import React from 'react';
import styled from 'styled-components';
import StepOne from '../Atoms/Icons/StepOne';
import StepTwo from '../Atoms/Icons/StepTwo';
import StepThree from '../Atoms/Icons/StepThree';

const StyledStepOne = styled(StepOne)`
  display: unset !important;
`;
const StyledStepTwo = styled(StepTwo)`
  display: unset !important;
`;
const StyledStepThree = styled(StepThree)`
  display: unset !important;
`;

function Steps({ isXsmall }) {
  const steps = [
    { id: 1, icon: <StyledStepOne width="24" height="24" alt="Step One" />, text: 'Shop in-\nstore' },
    { id: 2, icon: <StyledStepTwo width="24" height="24" alt="Step Two" />, text: 'Upload receipt' },
    { id: 3, icon: <StyledStepThree width="24" height="24" alt="Step Three" />, text: 'Get cash\n back' },
  ];

  return (
    <Stepper isXsmall={isXsmall}>
      {steps.map((step, index) => (
        <Step key={step.id}>
          {step.icon}
          <StepText>{step.text}</StepText>
          {index < steps.length - 1 && <Line />}
        </Step>
      ))}
    </Stepper>
  );
}

export default Steps;

const Stepper = styled.section`
  display: flex;
  min-width: 230px;
  width: 100%;
  max-width: 320px;
  padding-top: ${({ isXsmall }) => isXsmall ? '': '20px'};
`;

const Step = styled.div`
  flex: 1;
  text-align: center;
  position: relative;
`;

const Line = styled.div`
  border: 1.3px solid #FFFFFF
  height: 0px;
  position: absolute;
  top: calc(50% - 20px);
  left: calc(50% + 15px);
  width: 74px;
  min-width: 20px;
  max-width: 78px;
`;

const StepText = styled.p`
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: 700;
  margin: 0;
`;
