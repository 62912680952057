import React, { useContext, useState } from 'react';
import { isNil } from 'ramda';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import {
  Box,
  TextInput,
  TextLink,
  Plus as PlusIcon,
  Minus as MinusIcon,
  themes as uiThemes,
} from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { errorMessages } from '../../../utils/customQuestionTypes';

const MAX_LENGTH = 6;
// TODO:
// It’s currently only used in profile questions,
// so we potentially could just custom made those components.
const staticProps = {
  maxWidth: '292px',
  maxWidthMobile: '100%',
  addButtonLabel: 'Add another store',
  removeButtonLabel: 'Remove store',
  maxLength: 50,
  placeholder: 'Store Name',
};

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -8px 0 0 -8px;
  width: calc(100% + 8px);
  & > div {
    margin: 8px 0 0 8px;
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 8px;
  a[disabled] {
    color: inherit;
    cursor: default;
  }
`;
// TODO: Remove the component when don't use site.css. It's only for overriding site.css
const CustomQuestionWrapper = styled.div`
  input[type='text'],
  input[type='number'] {
    ${(props) => (props.error ? `border-color: ${uiThemes.global.colors.red};` : '')}
    border-radius: 4px;
    font-size: 17px;
    height: auto;
    margin: 0;
    &:focus {
      border-color: ${uiThemes.global.colors.blue};
      background: #ffffff;
    }
  }
`;

const MultipleTextInput = (props) => {
  const { name, error, question } = props;
  const { maxWidth, maxWidthMobile, addButtonLabel, removeButtonLabel, maxLength, placeholder } =
    staticProps;
  const { control } = useFormContext();
  const windowSize = useContext(ResponsiveContext);
  const isXsmall = windowSize === 'xsmall';
  const { listAnswer, required = true } = question || {};
  const [size, setSize] = useState((listAnswer || []).length > 0 ? listAnswer.length : 1);

  const addList = () => {
    if (size >= MAX_LENGTH) return;
    setSize(size + 1);
  };

  const removeList = () => {
    if (size < 2) return;
    setSize(size - 1);
  };

  return (
    <>
      <TextInputWrapper>
        {[...Array(size)].map((_, index) => (
          <Box
            width={isXsmall ? maxWidthMobile : maxWidth}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <Controller
              name={`${name}.Value[${index}]`}
              control={control}
              defaultValue="" // To become controlled input
              error={!isNil(error)}
              rules={{
                required: required && errorMessages.REQUIRED,
                maxLength: {
                  value: maxLength,
                  message: errorMessages.MAX_LENGTH,
                },
              }}
              render={(renderProps) => (
                <CustomQuestionWrapper error={error && error.Value[index]}>
                  <TextInput
                    {...renderProps}
                    type="text"
                    error={error && error.Value[index]}
                    errorText={error && error.Value[index] && error.Value[index].message}
                    showErrorIcon={false}
                    maxLength={maxLength}
                    placeholder={placeholder}
                  />
                </CustomQuestionWrapper>
              )}
            />
          </Box>
        ))}
      </TextInputWrapper>
      <ButtonWrapper>
        <Box direction="row" gap="medium">
          <TextLink
            icon={<PlusIcon color="blue" />}
            label={addButtonLabel || 'add'}
            onClick={addList}
            color="black"
            disabled={size >= MAX_LENGTH}
          />
          <TextLink
            icon={<MinusIcon color="red" />}
            label={removeButtonLabel || 'remove'}
            onClick={removeList}
            color="black"
            disabled={size < 2}
          />
        </Box>
      </ButtonWrapper>
    </>
  );
};

export default MultipleTextInput;
