import formatParam from './apis/formatParam';
import { getLocalStorageV2 } from './localStorage';
import getUserId from './userId';

export const IS_DEVELOPMENT = process.env.NODE_ENV != 'production';

export const isMultiChannelDomain = () => {
  const { hostname } = window.location;
  return (
    hostname.startsWith('offers') || (hostname === 'localhost' && window.location.port === '5172')
  ); // TODO Better handling
};

// returns sn.com/slug?user-session&postalcode=12345 (or) sn.com/slug
export const getProductNavigation = (product) => {
  const isMultiChannel = isMultiChannelDomain();
  const qparams = formatParam({
    'user-session': getUserId(),
    'postalcode': getLocalStorageV2('cn_location')
  });
  const redirectDomain = process.env.BASE_URL;
  return isMultiChannel ? `${redirectDomain}${product.slug}?${qparams}` : `/${product.slug}`;
};

export const getSupportLinkRedirection = () => {
  const qparams = formatParam({
    'user-session': getUserId(),
    'postalcode': getLocalStorageV2('cn_location')
  });
  const redirectDomain = process.env.BASE_URL;
  return isMultiChannelDomain() ? `${redirectDomain}sign-in/signup?ReturnUrl=/contact-support&snsource=direct&${qparams}` : `${process.env.BASE_URL}contact-support`;
};
