import React from 'react';
import { ResponsiveContext } from 'grommet';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import Email from '../../../Atoms/Icons/sns/Email';
import SocialShareModal from '../../../Organisms/Product/SocialShareModal';
import { getDataset } from '../../../utils/socialShare';

const ShareButtonPopup = (props) => {
  const { userId, product, isAccepted, dataTrackCategory, variant } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [open, setOpen] = React.useState(null);
  const dataAttributes = getDataset(props);

  return (
    <>
      <ShareButtonStyle
        isXsmall={isXsmall}
        onClick={() => setOpen(true)}
        icon={<Email />}
        label="Email"
        {...dataAttributes}
        variant={variant}
      />
      {open && (
        <SocialShareModal
          open={open}
          userId={userId}
          product={product}
          isAccepted={isAccepted}
          onClose={() => setOpen(false)}
          dataTrackCategory={dataTrackCategory}
        />
      )}
    </>
  );
};

export default ShareButtonPopup;
