import React, { useContext, useMemo, useState, useEffect } from 'react';
import { ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { isEmpty } from 'ramda';
import { PrimaryButton as PrimaryButtonUI, Box, Text, themes } from 'socialnature-ui';
import { useFormContext } from 'react-hook-form';
import CustomQuestion from './CustomQuestion';
import { errorMessages } from '../../utils/customQuestionTypes';
import Consent from './Fields/Consent';
import { campaignTypes } from '../../utils/campaignTypes';
import PrimaryButton from '../../Atoms/Buttons/PrimaryButton';
import { isFeatureOn, SplitNames } from '../../utils/splitio';
import { usePretrialPage } from '../../contexts/PretrialPageContext/usePretrialPage';

const StyledLink = styled.a`
  text-decoration: underline;

  &,
  &:hover,
  :visited {
    color: ${themes.global.colors.blue};
  }
`;

const CustomQuestions = (props) => {
  const { questions, submitButtonValue = 'submit', campaignInfo } = props;
  const {
    errors,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useFormContext();
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const { featureFlags, user } = usePretrialPage();
  const { id: userId, properties } = user || {};
  const { termsAndConditionsAccepted } = properties || {};

  useEffect(() => {
    isFeatureOn(SplitNames.disableCustomQuestionsSubmitButton, userId).then(setDisableSubmitButton);
  }, [userId]);

  const questionIdMap =
    useMemo(() => {
      const map = new Map();
      questions.forEach((question) => {
        const { questionId: id, index } = question;
        map.set(id, index);
      });
      return map;
    }, [questions]) || undefined;

  return (
    <>
      <div data-cy="questions-wrapper">
        {questions.map((question) => {
          const { index } = question;
          const { CustomQuestions: error } = errors;

          return (
            <CustomQuestion
              key={index}
              name={`CustomQuestions[${index}]`}
              question={question}
              error={error && error[index]}
              questionIdMap={questionIdMap}
            />
          );
        })}
      </div>
      {featureFlags[SplitNames.showTermsAndConditionsConsent]
        ? !termsAndConditionsAccepted && (
            <Consent
              name="termsAndConditionsConsent"
              error={errors.termsAndConditionsConsent}
              required
              label={
                <>
                  I have read and agree to the{' '}
                  <StyledLink href="/terms-of-service" target="_blank">
                    terms of service
                  </StyledLink>{' '}
                  and{' '}
                  <StyledLink href="/privacy-policy" target="_blank">
                    privacy policy
                  </StyledLink>{' '}
                  *
                </>
              }
            />
          )
        : campaignInfo &&
          campaignInfo.offersActivatedByCurrentUser <= 0 &&
          campaignInfo.type === campaignTypes.REBATE && (
            <Consent
              name="rebateConsent"
              error={errors.rebateConsent}
              required
              label={
                <>
                  I have read and agree to the{' '}
                  <StyledLink href="https://mailchi.mp/socialnature/rewardsoffers" target="_blank">
                    terms of service
                  </StyledLink>{' '}
                  *
                </>
              }
            />
          )}

      <Box align="center">
        <Box width={{ max: '100%', min: isXsmall ? '100%' : '234px' }}>
          {disableSubmitButton ? (
            <PrimaryButton
              type="submit"
              label={submitButtonValue}
              disabled={isSubmitSuccessful || isSubmitting}
            />
          ) : (
            <PrimaryButtonUI type="submit" label={submitButtonValue} />
          )}
        </Box>
        {!isEmpty(errors) && (
          <Box width={{ max: '290px' }}>
            <Text color="red" size="small" margin={{ top: '16px' }} textAlign="center">
              {errorMessages.SUBMIT_ERROR}
            </Text>
          </Box>
        )}
      </Box>
    </>
  );
};

export default CustomQuestions;
