import React, { useContext } from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import { CompanyName, ProductName } from '../ProductCard/Name';
import Card from '../../Atoms/Card/Card';
import CardAction from '../../Atoms/Card/CardAction';
import CardBody from '../../Atoms/Card/CardBody';
import CardImage from '../../Atoms/Card/CardImage';
import CardContent from '../../Atoms/Card/CardContent';
import CardFooter from '../../Atoms/Card/CardFooter';
import CardBadge from '../../Atoms/Card/CardBadge';
import Chip from '../../Atoms/Chip';
import Gift2 from '../../Atoms/Icons/Gift2';
import { getDiscountBadge } from '../../utils/discountUtils';

const ChipWraper = styled.div`
  margin-top: ${({ isXsmall }) => (isXsmall ? '2px' : '1px')};
`;

const DiscountCard = ({ discount, width, height, index, dataTrackCategory }) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const { name, companyName, featuredImage } = discount.samplekit;
  const slug = `discount/${discount.id}`;
  const productLink = `/${slug}`;
  const badge = getDiscountBadge(discount);

  return (
    <Card width={width} height={height}>
      <CardAction
        href={productLink}
        data-track-category={dataTrackCategory}
        data-track-action={dataTrackCategory && `Suggested ${index + 1}`}
        data-track-label={dataTrackCategory && slug}
      >
        <CardBody>
          <CardImage src={featuredImage.thumbnail} />
          <CardContent>
            <CompanyName>{companyName || 'asd asd as'}</CompanyName>
            <ProductName>{name}</ProductName>
          </CardContent>
          <CardFooter>
            <ChipWraper isXsmall={isXsmall}>
              <Chip
                label="Bonus offer"
                icon={<Gift2 width="12" height="12" alt="Gift" />}
                color="#0f9494"
                background="#edf7f7"
              />
            </ChipWraper>
          </CardFooter>
        </CardBody>
        <CardBadge text={badge.text} color={badge.color} />
      </CardAction>
    </Card>
  );
};

export default DiscountCard;
