export const reverseGeocode = async (latitude, longitude) => {
  const latLng = [latitude, longitude].join(',');
  const url = `/account/geocode?latlng=${encodeURIComponent(latLng)}`;
  const results = await fetch(url);
  if (!results.ok) {
    throw new Error('Unable to reverse encode coordinates.');
  }
  // eslint-disable-next-line no-return-await
  return await results.json();
};

export const validPostCodePattern =
  /^[0-9]{5}(?:-?[0-9]{4})?|^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1}\s*\d{1}[A-Za-z]{1}\d{1}$/;

export const errorMessages = {
  REQUIRED: 'ZIP/Postal Code required',
  POSTCODE_ERROR: 'Not a valid ZIP/Postal Code',
};

export const CENTER_NORTH_AMERICA = { lat: 48.166666, lng: -100.166666 };
