// @ts-check
import React from 'react';
import { Box, Container, Heading } from 'socialnature-ui';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import SurveyProductHeader from '../../Organisms/shared/SurveyProductHeader';
import LoadingSection from '../../Molecules/LoadingSection';
import StorePickUpLocationLayout from '../../Organisms/ReportIssue/StorePickUpLocationLayout';
import ErrorPage from '../ErrorPage';
import { useDataStorePickUpLocation } from '../../hooks/useDataStorePickUpLocation';
import { getCampaignTypeFromWant } from '../../utils/apis/campaigns';
import { campaignTypes } from '../../utils/campaignTypes';
import { getQueryParams } from '../../utils/queryParams';

const StyledSpan = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;

const RebateWarning = styled.div`
  padding: 8px 16px;
  background-color: #fcebdb;
  border-radius: 4px;
  color: #333;
  font-size: 14px;
  line-height: 16px;
  margin-top: 8px;
  font-weight: 400;
`;

const headerDescriptionEnum = {
  [campaignTypes.IN_STORE]: (
    <StyledSpan> Find in-store at eligible locations only.</StyledSpan>
  ),
  [campaignTypes.REBATE]: (
    <RebateWarning>
      Find in-store at eligible locations only; online receipts and other stores won&apos;t
      be accepted.
    </RebateWarning>
  ),
};

/**
 * @typedef {{
 *  sampleKitSlug: string,
 *  wantId: number,
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function LocateMyProduct({ sampleKitSlug, wantId }) {
  const { data, samplekit, userLocation, preferredLocation, handleFormSubmitFetch } =
    useDataStorePickUpLocation(wantId, sampleKitSlug);
  const { data: wantsData, error } = useQuery(getCampaignTypeFromWant(wantId), {
    context: {
      clientName: 'hasura',
    },
  });

  const isDiscount = getQueryParams('isDiscount');
  const want = wantsData && wantsData.wants ? wantsData.wants[0] : {};

  if (data.queryError || error) {
    return <ErrorPage detail={JSON.stringify(data.queryError || error.message)} />;
  }

  return data.loading ? (
    <LoadingSection />
  ) : (
    <Box pad={{ bottom: '120px' }}>
      {isDiscount == null && (
        <SurveyProductHeader
          productImageSrc={samplekit.featured_image}
          productName={samplekit.name}
          companyName={samplekit.companyData.name}
          maxWidthSize="992px"
        />
      )}
      <Box pad={{ top: '48px' }}>
        <Container maxWidth="1024px">
          <Heading level={2}>Find an eligible store for this offer</Heading>
          {headerDescriptionEnum[want.campaign.type]}
          <Box margin={{ top: 'medium' }}>
            <StorePickUpLocationLayout
              sampleKitName={samplekit.name}
              postalCode={userLocation}
              handleFormSubmitFetch={handleFormSubmitFetch}
              preferredLocation={preferredLocation}
            />
          </Box>
        </Container>
      </Box>
    </Box>
  );
}
