// @ts-check
export function getBannerCallToAction(openBannerPopup, bannerUrl) {
  if (typeof openBannerPopup === 'function') {
    return {
      href: null,
      onClick: openBannerPopup,
    };
  }
  return {
    href: bannerUrl,
  };
}
