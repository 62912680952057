import React from 'react';
import styled from 'styled-components';
import basicTheme from '../../themes';

const StyledLink = styled.a`
  font-weight: 700;
  &:hover {
    text-decoration: underline;
  }
  &,
  &:focus,
  &:hover {
    color: ${basicTheme.global.colors.launchpad};
  }
`;

const StyledDiv = styled.div`
  .checbox-label {
    font-weight: ${({ fontWeight }) => fontWeight};
    color: ${({ fontColor }) => fontColor};
    margin-bottom: 0px;
    line-height: normal;
  }
`;

const SMSDoubleOptinTsCs = ({
  optional,
  fontColor = basicTheme.global.colors.slate,
  fontWeight = 400,
  action = 'signing up',
}) => (
  <StyledDiv fontColor={fontColor} fontWeight={fontWeight}>
    <p className="checbox-label">
      By checking this box, I consent to enter into an agreement to receive recurring automated
      promotional text messages from Social Nature about free natural products at the cell number
      provided when signing up. Messages and data rates may apply. Msg frequency will vary. Text
      STOP to cancel/withdraw your consent. Consent to receive text messages is not a condition of
      purchase. By continuing I agree to our{' '}
      <StyledLink
        href="https://www.socialnature.com/terms-of-service"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms
      </StyledLink>{' '}
      and{' '}
      <StyledLink
        href="https://www.socialnature.com/privacy-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </StyledLink>
      .{' '}
      <StyledLink
        href="https://www.socialnature.com/contact-us"
        target="_blank"
        rel="noopener noreferrer"
      >
        Contact us here
      </StyledLink>{' '}
      for more details.
      {optional}
    </p>
  </StyledDiv>
);

export default SMSDoubleOptinTsCs;
