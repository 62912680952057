// @ts-check
import { useCallback, useRef } from 'react';
import { handleError } from '../../utils/error';

export function useSearchStoreCampaignLocations(
  setIsRecheckingNearby,
  handleFormSubmitFetch,
  setPostalCodeWithNoLocations,
  setCampaignLocations,
) {
  const oldDataRef = useRef({ result: null, location: null });

  const handleFormSubmit = useCallback(
    async (data) => {
      try {
        setIsRecheckingNearby(true);
        const location = data.Location;
        // if location was already used uses saved data
        let { result } = oldDataRef.current;
        if (location) {
          if (location !== oldDataRef.current.location) {
            result = await handleFormSubmitFetch(location);
            if (!result || !result.lenght) {
              setPostalCodeWithNoLocations(location);
            }
            oldDataRef.current = { result, location };
          }
          setCampaignLocations(result);
        }
        setTimeout(() => setIsRecheckingNearby(false));
      } catch (error) {
        handleError(error);
      }
    },
    [
      handleFormSubmitFetch,
      setCampaignLocations,
      setIsRecheckingNearby,
      setPostalCodeWithNoLocations,
    ],
  );
  return { handleFormSubmit };
}
