import React, { useEffect, useState } from 'react';
import { Box } from 'grommet';
import styled from 'styled-components';
import Hr from '../../Atoms/Hr';
import InviteFriendsPopUp from '../../Molecules/SocialShare/Share/InviteFriendsPopUp';
import { getFeatureTreatment, SplitNames } from '../../utils/splitio';
import Loading from '../../Molecules/Loading';

const StyledSpan = styled.span`
  font-weight: 700;
`;

const StyledP = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-align: ${({ isXsmall }) => (isXsmall ? 'center' : 'start')};
  margin-bottom: ${({ marginBottom }) => marginBottom || '16px'};
`;

const copies = {
  off: {
    body: 'a $100 Gift Card',
  },
  earthDay: {
    body: 'a Stanley Tumbler',
  },
  backToSchool1: {
    body: 'a Free2B Chocolate Prize Package',
  },
  backToSchool2: {
    body: 'a $150 GnuSanté Back to School Prize',
  },
  backToSchool3: {
    body: 'Bob’s Red Mill Classroom Essentials - value up to $150',
  },
  featureAlaffia: {
    body: 'an Alaffia Beauty Package - $75 value',
  },
  veganuaryFabalishVeganMayo: {
    body: 'a vegan prize package from Fabalish, valued up to $90',
  },
  veganuaryRitterSport: {
    body: 'vegan Ritter Sport Chocolate package, valued up to $100',
  },
  walmart: {
    body: 'a $200 Walmart Shopping Spree',
  },
  springCleaning: {
    body: 'a $100 Spring Cleaning Prize Package',
  },
  picnicSnackPack: {
    body: 'a $100 Picnic Snack Pack',
  },
  birthdayPack: {
    body: 'a $160 Birthday Prize Pack!',
  },
  holidayRelaxDec2024: {
    body: 'a $100 Holiday Relaxation Prize Pack!'
  },
  referFriendFeb2025: {
    body: 'better-for-you Mystery Box!'
  }
};

const ProductHeaderFooter = ({
  isXsmall,
  userId,
  product,
  isAccepted,
  dataTrackCategory,
  marginBottom = '',
}) => {
  const [customCampaignRAFGiveaways, setCustomCampaignRAFGiveaways] = useState('off');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFeatureTreatment(SplitNames.customCampaignRAFGiveaways).then((value) => {
      setCustomCampaignRAFGiveaways(value);
      setLoading(false);
    });
  }, []);

  if (loading) return <Loading />;

  const body =
    (copies[customCampaignRAFGiveaways] && copies[customCampaignRAFGiveaways].body) ||
    copies.off.body;

  return (
    <>
      <Hr margin={isXsmall ? '24px 0px' : '16px 0px'} />
      <Box align={isXsmall ? 'center' : 'start'}>
        <StyledP isXsmall={isXsmall} marginBottom={marginBottom}>
          Have friends who might be interested in trying this too? Invite them for a chance to win{' '}
          <StyledSpan>{body}!</StyledSpan>
        </StyledP>
        <InviteFriendsPopUp
          userId={userId}
          product={product}
          isAccepted={isAccepted}
          dataTrackCategory={dataTrackCategory}
        />
      </Box>
    </>
  );
};

export default React.memo(ProductHeaderFooter);
