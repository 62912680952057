import React from 'react';
import {
  Text,
  StrokeStar as StrokeStarIcon,
  Dollar as DollarIcon,
  Location as LocationIcon,
} from 'socialnature-ui';
import styled from 'styled-components';
import { campaignTypes } from '../utils/campaignTypes';
import { getCompanyName } from '../utils/formatCountry';
import LocationSelectorButton from './Buttons/LocationSelectorButton';
import ReceiptIcon from '../Atoms/Icons/Receipt';
import { formatDateToMMMDDYYYY } from '../utils/formatDate';
import { cleanupValue, getCashBackFormattedPrice, getPrecisionValueUpto2 } from '../utils/product';
import Variants from './Variants';
import { numberToCardinal, numberToOrdinal } from '../utils/number';
import { discountTypes } from '../utils/discountTypes';

/**
 * @typedef {{
 * children: (string | Element)[],
 * size: string,
 * icon: Element,
 * }} Props
 * @param {Props} props
 * @returns
 */
export const CampaignInfoText = React.memo(({ children, size, ...others }) => (
  <div>
    <Text size={size || 'small'} {...others}>
      {children}
    </Text>
  </div>
));

const FlavorsWrapper = styled.div`
  margin-left: 32px;
  &,
  li {
    font-size: 16px;
    line-height: normal;
  }

  ul {
    margin-left: 20px;
  }

  .flavors-toggle > span {
    font-weight: 700;
    text-decoration-line: underline;
    margin-bottom: 6px;
  }
`;

export const CampaignInfoSeparator = () => <hr style={{ margin: '1rem 0 1rem 0' }} />;

export const GeoInfo = ({
  geoInfo,
  openGeoModal,
  productId,
  campaignType,
  hasBeenAccepted,
  slug,
  openStoreLocationModal,
  campaignId,
  userId,
  reactivateOfferIsOn,
  hasWanted,
  storesInfo,
}) => {
  if (storesInfo.listValue.length === 0 && (!geoInfo.loaded || geoInfo.failed.includes(productId)))
    return null;

  const providedLocation = geoInfo.location && geoInfo.location.length ? geoInfo.location : null;

  return (
    <LocationSelectorButton
      allowEditLocation={geoInfo.anonymous}
      openGeoModal={openGeoModal}
      providedLocation={providedLocation}
      campaignType={campaignType}
      hasBeenAccepted={hasBeenAccepted}
      slug={slug}
      openStoreLocationModal={openStoreLocationModal}
      campaignId={campaignId}
      userId={userId}
      reactivateOfferIsOn={reactivateOfferIsOn}
      hasWanted={hasWanted}
      storesInfo={storesInfo}
    />
  );
};

const BogoDescription = React.memo(({ campaign }) => {
  const { shortSummary = '', properties } = campaign || {};
  const discountOrdinal = numberToOrdinal(
    properties?.campaignBogo?.buy + properties?.campaignBogo?.get,
  );
  const bogoBuyCardinal = numberToCardinal(properties?.campaignBogo?.buy);
  return (
    <>
      Buy {bogoBuyCardinal} ({properties?.campaignBogo?.buy}) of the {shortSummary} and get the{' '}
      {discountOrdinal} one free
    </>
  );
});

const MoneyCashbackDescription = React.memo(({ campaign }) => {
  const {
    shortSummary = '',
    properties: { moneyCashBackValue },
  } = campaign || {};
  return (
    <>
      ${getCashBackFormattedPrice(moneyCashBackValue)} off one (1) {shortSummary}
    </>
  );
});

/**
 * @param {bool} campaign?
 * @param {bool} size?
 * @param {bool} height?
 * @param {bool} color?
 * @param {bool} isChooseProduct?
 */
export const CampaignInfo = ({ campaign, size, height, color, isChooseProduct = false }) => {
  const {
    shortSummary = '',
    priceValue = '',
    campaignType,
    completeByDate,
    properties,
    variants,
  } = campaign || {};
  const { offerDiscountPercentage, moneyCashBackValue } = properties || {
    offerDiscountPercentage: 100,
    moneyCashBackValue: 0,
  }; // Default:100
  const isRebateCampaign = campaignType === campaignTypes.REBATE;
  const hasVariants = variants && variants.length > 0;

  const discountType = (campaign.properties?.discountType || '').toUpperCase();
  const isBogo = discountType === discountTypes.BOGO;
  const isMoneyCashback = discountType === discountTypes.MONEY_CASH_BACK;
  const isPercentage = !isBogo && !isMoneyCashback;

  if (!campaign) {
    return null;
  }

  return (
    <div className="launch-campaign-info">
      <div data-cy="what-you-will-get">
        <CampaignInfoText
          icon={<StrokeStarIcon color="black" />}
          size={size}
          height={height}
          color={color}
        >
          <strong>What you&apos;ll get</strong> <br />
        </CampaignInfoText>
        <Text margin={{ left: '32px', bottom: hasVariants ? '12px' : '20px' }} size={size}>
          {isBogo && <BogoDescription campaign={campaign} />}
          {isMoneyCashback && <MoneyCashbackDescription campaign={campaign} />}
          {isPercentage && shortSummary}
        </Text>

        {hasVariants && (
          <FlavorsWrapper>
            <Variants variants={[...variants]} />
          </FlavorsWrapper>
        )}
      </div>
      {!isChooseProduct && <CampaignInfoSeparator />}

      {priceValue && !isChooseProduct && (
        <>
          <div data-cy="sample-value">
            <CampaignInfoText icon={<DollarIcon color="black" />} size={size}>
              <strong>{isRebateCampaign ? 'Offer' : 'Sample'} value</strong> <br />
            </CampaignInfoText>
            <Text margin={{ left: '32px' }} size={size}>
              {isRebateCampaign
                ? `Up to $${getPrecisionValueUpto2(
                  (cleanupValue(priceValue) * offerDiscountPercentage) / 100,
                )}, excluding taxes`
                : `Up to ${priceValue}`}
            </Text>
          </div>
        </>
      )}

      {isRebateCampaign && !isChooseProduct && (
        <div data-cy="how-to-redeem">
          <CampaignInfoText
            icon={<ReceiptIcon viewBox="0 0 32 32" />}
            size={size}
            height={height}
            color={color}
          >
            <strong>How to redeem</strong> <br />
          </CampaignInfoText>
          <Text margin={{ left: '32px' }} size={size}>
            {isBogo && (
              <>
                Upload a receipt displaying at least{' '}
                <b>
                  {numberToCardinal(
                    campaign.properties?.campaignBogo?.buy + campaign.properties?.campaignBogo?.get,
                  )}{' '}
                  ({campaign.properties?.campaignBogo?.buy + campaign.properties?.campaignBogo?.get}
                  ) eligible items purchased
                </b>
                , and we&apos;ll reimburse you the cost of one item within 48 hours.{' '}
                <b>Offer ends {formatDateToMMMDDYYYY(completeByDate)}</b>. Online purchases are not
                eligible.
              </>
            )}

            {isMoneyCashback && (
              <>
                Upload receipt and we&apos;ll reimburse you{' '}
                <b>${getCashBackFormattedPrice(moneyCashBackValue)}</b> via Venmo(US Only), PayPal, direct
                deposit or a gift card within 48hrs.{' '}
                <b>Offer ends {formatDateToMMMDDYYYY(completeByDate)}</b>. Online purchases are not
                eligible.
              </>
            )}

            {isPercentage && (
              <>
                Upload receipt and we&apos;ll reimburse you <b>{offerDiscountPercentage}%</b> via
                Venmo(US Only), PayPal, Prepaid digital Visa card, direct deposit or a gift card within 48hrs.{' '}
                <b>Offer ends {formatDateToMMMDDYYYY(completeByDate)}</b>. Online purchases are not
                eligible.
                <br /><br />
                The reimbursement is calculated after any eligible in-store discounts and coupons have been applied.
              </>
            )}
          </Text>
        </div>
      )}
    </div>
  );
};

export const BannerInfo = ({ countries }) => {
  const bannerCountries = getCompanyName(countries);
  if (!bannerCountries) {
    return null;
  }

  return (
    <div className="launch-campaign-info">
      <CampaignInfoText icon={<LocationIcon color="black" />}>
        <span>Offer available to residents of </span>
        <Text as="span" weight="bold" size="small">
          {bannerCountries}
        </Text>
      </CampaignInfoText>
    </div>
  );
};
