import React from 'react';
import styled from 'styled-components';
import { Box } from 'socialnature-ui';
import { SearchStoreCampaignLocationsForm } from '../../CampaignLocation/SearchStoreCampaignLocationsForm';
import { Tab, Tabs } from '../../../Atoms/Tabs';
import { LocationListSelectable } from '../../CampaignLocation/LocationListSelectable';
import LoadingSection from '../../../Molecules/LoadingSection';
import { LocationMap } from '../../CampaignLocation';
import { CENTER_NORTH_AMERICA } from '../../../utils/apis/maps';
import StoreLocationNoSamplesAvailable from './StoreLocationNoSamplesAvailable';

function NoPostalCodeorCampaignLocations({
  postalCodeWithNoLocations,
  seeSamplesAvailable,
  isXsmall,
  geocheckRef,
}) {
  if (!postalCodeWithNoLocations) {
    return <></>;
  }
  return (
    <StoreLocationNoSamplesAvailable
      seeSamplesAvailable={seeSamplesAvailable}
      isXsmall={isXsmall}
      postalCodeWithNoLocations={postalCodeWithNoLocations}
      geocheckRef={geocheckRef}
    />
  );
}

export default function StoreLocationMobile({
  campaignLocations,
  setIsRecheckingNearby,
  setCampaignLocations,
  isXsmall,
  isRecheckingNearby,
  setPostalCodeWithNoLocations,
  postalCodeWithNoLocations,
  selectedIndex,
  setSelectedIndex,
  seeSamplesAvailable,
  isLoggedIn,
  handleFormSubmitFetch,
  Header,
  FormLabelWhenLoggedInUser,
  CTAButton,
  geocheckRef,
  tprRetailersList = []
}) {
  return (
    <>
      <WrapperHeader data-testid="StoreLocationMobile" margin={{ bottom: '24px' }}>
        {Header}
        {isLoggedIn ? (
          FormLabelWhenLoggedInUser()
        ) : (
          <SearchStoreCampaignLocationsForm
            setIsRecheckingNearby={setIsRecheckingNearby}
            setCampaignLocations={setCampaignLocations}
            isXsmall={isXsmall}
            setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
            postalCodeWithNoLocations={postalCodeWithNoLocations}
            handleFormSubmitFetch={handleFormSubmitFetch}
            geocheckRef={geocheckRef}
          />
        )}
      </WrapperHeader>
      {!Array.isArray(campaignLocations) || !campaignLocations.length ? (
        <NoPostalCodeorCampaignLocations
          postalCodeWithNoLocations={postalCodeWithNoLocations}
          seeSamplesAvailable={seeSamplesAvailable}
          isXsmall={isXsmall}
          geocheckRef={geocheckRef}
        />
      ) : (
        <>
          <Tabs>
            <Tab data-testid="ListViewTab" title="LIST VIEW">
              <Box margin={{ top: '16px', bottom: '32px' }}>
                {isRecheckingNearby ? (
                  <LoadingSection />
                ) : (
                  <LocationListSelectable
                    locations={campaignLocations}
                    selectedIndex={selectedIndex}
                    setSelectedIndex={setSelectedIndex}
                    tprRetailersList={tprRetailersList}
                  />
                )}
              </Box>
            </Tab>
            <Tab data-testid="MapViewTab" title="MAP VIEW">
              <Box data-testid="Map" margin={{ top: '16px' }}>
                <LocationMap
                  locations={campaignLocations}
                  centerLatitude={
                    campaignLocations.length
                      ? campaignLocations[0].latitude
                      : CENTER_NORTH_AMERICA.lat
                  }
                  centerLongitude={
                    campaignLocations.length
                      ? campaignLocations[0].longitude
                      : CENTER_NORTH_AMERICA.lng
                  }
                  zoom={(!campaignLocations.length && 2.8) || 10}
                  styles={{ height: '374px', margin: '0 -16px' }}
                  selectedIndex={selectedIndex}
                  setSelectedIndex={setSelectedIndex}
                />
              </Box>
            </Tab>
          </Tabs>
          <WrapperButton>{CTAButton && <DivStyled>{CTAButton}</DivStyled>}</WrapperButton>
        </>
      )}
    </>
  );
}

const WrapperButton = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
`;

const DivStyled = styled.div`
  border-radius: 0px 0px 8px 8px;
  margin: 0 -16px;
  background: #ffffff;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.05);
  padding: 16px;
`;

const WrapperHeader = styled(Box)`
  min-height: initial;
`;
