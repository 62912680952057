// @ts-check
import React, { useCallback, useState } from 'react';
import { campaignTypes } from '../../utils/campaignTypes';
// TODO: move to Molecules folder
import LocationSelector from '../../Pages/ProductPage/LocationSelector';
import { useModal } from '../useModal';

export function useUserGeoModal(productId, initPostalCode, initOpen, callback, campaignType) {
  const { Modal, open, close } = useModal(initOpen);
  const [postalCode, setPostalCode] = useState(initPostalCode || '');
  const [successUri, setSuccessUri] = useState('');

  const handleOpen = useCallback(
    (pc, uri) => {
      open();
      setPostalCode(pc);
      setSuccessUri(uri);
    },
    [open],
  );

  const handleClose = useCallback(() => {
    close();
  }, [close]);

  const GeoModal = () => (
    <Modal data-testid="geo-modal">
      <LocationSelector
        defaultLocation={postalCode}
        onClose={handleClose}
        onGeoCheckRequested={callback}
        productId={productId}
        successUri={successUri}
        isRebate={campaignType === campaignTypes.REBATE}
      />
    </Modal>
  );

  return { GeoModal, open: handleOpen, close: handleClose };
}
