import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { GET_WANTS_COUNT } from '../../../utils/apis/graphql/campaign';
import { handleError } from '../../../utils/error';
import { CampaignProgressBar as Bar } from '../../../Organisms/Product/CampaignProgressBar';
import { getValue } from './utils';

export default function CampaignProgressBar({ campaignId }) {
  const { loading, error, data } = useQuery(GET_WANTS_COUNT, {
    variables: {
      campaignId,
    },
    context: {
      clientName: 'hasura',
    },
    skip: !campaignId,
  });

  const value = useMemo(() => getValue(data), [data]);

  if (loading) {
    return <></>;
  }
  if (error) {
    handleError(error);
    return <></>;
  }

  if (value < 0) {
    handleError(
      `ValueError: Value is under 0 at CampaignProgressBar.(campaign: ${campaignId}, value: ${value})`,
    );
    return <></>;
  }

  return <Bar value={value} />;
}
