import hasExpired from '../../expiryDate';

// TODO: check if there are valid data, e.g. coupon code, description etc...
/**
 * @typedef {{
 * bannerMessage?: string,
 * bannerExpiry?: string,
 * }} Coupon
 * @param {Coupon | null} coupon
 * @returns {boolean}
 */
export function showBanner(coupon) {
  if (!coupon) return false;
  const { bannerMessage, bannerExpiry } = coupon;
  if (!bannerMessage) return false;
  if (hasExpired(bannerExpiry)) return false;
  return true;
}
