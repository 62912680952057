import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import { themes } from 'socialnature-ui';
import { SecondaryButton } from 'socialnature-ui/build/components/Button';
import H from '../../Atoms/Heading';
import PrimaryButton from '../../Atoms/Buttons/PrimaryButton';
import HomeBannerBackground from './HomeBannerBackground';
import { TrackingEventType } from '../../utils/tracking';
import { trackEvent as trackGTMEvent } from '../../utils/gtm';
import { SplitNames, isFeatureOn } from '../../utils/splitio';

const StyledH = styled(H)`
  margin: 0;
  z-index: 1;

  ${({ isXsmall }) =>
    isXsmall
      ? `
		    font-size: 24px;
    		line-height: 32px;
			`
      : ``}
`;

const LoggedOutTitle = styled(StyledH)`
  width: 400px;
  ${({ isSmall }) => (isSmall ? `width: auto;` : ``)}
  ${({ isXsmall }) => (isXsmall ? `width: 322px;` : ``)}
`;

const P = styled.p`
  margin-top: ${({ isXsmall }) => (isXsmall ? '8px' : '16px')};
  margin-bottom: ${({ isXsmall }) => (isXsmall ? '24px' : '32px')};
  font-size: ${({ isXsmall }) => (isXsmall ? '18px' : '20px')};
  line-height: ${({ isXsmall }) => (isXsmall ? '22.5px' : '28px')};
  width: ${({ isXsmall }) => (isXsmall ? '280px' : 'auto')};
  text-wrap: ${({ isXsmall }) => (isXsmall ? 'wrap' : 'nowrap')};
`;

const BoldPrimaryButton = styled(PrimaryButton)`
  font-weight: bold;
  padding: 12px 32px;

  &:hover {
    color: ${themes.global.colors.white};
  }

  ${({ isXsmall }) =>
    isXsmall
      ? `
				width: 100%;
				margin-bottom: 8px
			`
      : `
				margin-right: 16px
  		`}
`;

const BoldSecondaryButton = styled(SecondaryButton)`
  font-weight: bold;
  padding: 12px 32px;
  ${({ isXsmall }) => (isXsmall ? `width: 100%;` : ``)}
`;

const HomeBanner = ({ userName, loggedIn = false }) => {
  const size = useContext(ResponsiveContext);
  const isSmall = size === 'small';
  const isXsmall = size === 'xsmall';
  const [isEtaOffersPageOn, setIsEtaOffersPageOn] = useState(false);

  useEffect(() => {
    isFeatureOn(SplitNames.etaOffersPage).then(setIsEtaOffersPageOn);
  }, []);

  return (
    <HomeBannerBackground>
      {userName ? (
        <StyledH level={1} isXsmall={isXsmall}>
          Hi {userName || ''}, {!isSmall && <br />}
          it’s a great day
          {!isSmall && <br />} to #trynatural!
        </StyledH>
      ) : (
        <LoggedOutTitle level={1} isSmall={isSmall} isXsmall={isXsmall}>
          Make the switch to better-for-you groceries
        </LoggedOutTitle>
      )}

      <P isXsmall={isXsmall}>Try free or discounted natural products</P>
      <div>
        {userName ? (
          <BoldPrimaryButton
            label="VIEW OFFERS"
            href={isEtaOffersPageOn ? '/offers?snsource=home' : '/products'}
            color="pink"
            gap="xsmall"
            isXsmall={isXsmall}
            onClick={() =>
              trackGTMEvent(TrackingEventType.HOME.VIEW_OFFERS_CLICKED, {
                loggedIn,
                source: TrackingEventType.HOME.SOURCES.BANNER,
              })
            }
          />
        ) : (
          <BoldPrimaryButton
            label="GET STARTED"
            href="/sign-in/signup?snsource=direct"
            color="pink"
            gap="xsmall"
            isXsmall={isXsmall}
            onClick={() =>
              trackGTMEvent(TrackingEventType.HOME.GET_STARTED_CLICKED, {
                loggedIn,
                source: TrackingEventType.HOME.SOURCES.BANNER,
              })
            }
          />
        )}

        <BoldSecondaryButton
          label="HOW IT WORKS"
          href="/how-it-works"
          color="white"
          gap="xsmall"
          isXsmall={isXsmall}
          onClick={() =>
            trackGTMEvent(TrackingEventType.HOME.HOW_IT_WORKS_CLICKED, {
              loggedIn,
              source: TrackingEventType.HOME.SOURCES.BANNER,
            })
          }
        />
      </div>
    </HomeBannerBackground>
  );
};

export default HomeBanner;
