import { Store as StoreIcon, Deliver as DeliverIcon } from 'socialnature-ui';
import hasExpired from './expiryDate';
import { formatPeriodDate, formatShipDate, formatDateToMMMDDYYYY } from './formatDate';
import { getOfferExpiredDate, isOfferExpired } from './rebates';

export const campaignTypes = {
  IN_STORE: 0,
  SAMPLE_DIRECT: 10,
  DTC: 100,
  REBATE: 200,
  DIGITAL_COUPON: 300,
};

export const campaignStatus = {
  OPENED: 'opened',
  SHIPPED: 'shipped',
  COMPLETED: 'completed',
  SUSPENDED: 'suspended',
};

export const SELECT_THIS_STORE = 'Select this store';

export const storeLocationCtaLabels = {
  [campaignTypes.IN_STORE]: 'Apply to try',
  [campaignTypes.REBATE]: 'Activate Offer',
};

const RedeemType = {
  PICK_UP: {
    icon: StoreIcon,
    text: 'Pick up at a store near you',
  },
  DELIVER: {
    icon: DeliverIcon,
    text: 'Free home delivery',
  },
  DTC: {
    icon: DeliverIcon,
    text: 'Redeem online for home delivery',
  },
  REBATE: {
    icon: StoreIcon,
    text: 'Pick up at',
  },
  DIGITAL_COUPON: {
    icon: StoreIcon,
    text: 'DIGITAL_COUPON',
  },
};

export const RedeemInfo = {
  [campaignTypes.IN_STORE]: RedeemType.PICK_UP,
  [campaignTypes.SAMPLE_DIRECT]: RedeemType.DELIVER,
  [campaignTypes.DTC]: RedeemType.DTC,
  [campaignTypes.REBATE]: RedeemType.REBATE,
  [campaignTypes.DIGITAL_COUPON]: RedeemType.DIGITAL_COUPON,
};

export const ShippingInfo = {
  [campaignTypes.DTC]: 'Get it delivered to your home',
};

// Same logic as _AccountWant.cshtml, except for DTC
export function getCampaignStatus(
  shipDate,
  accepted,
  hasShipped,
  campaingType,
  channelName,
  hasWanted,
  userWantedAt,
  userWantProperties,
  reactivateOfferIsOn,
  rebateOfferDeadline,
  completeByDate,
  offerRedeemed,
  isNearby,
  wantDisabled,
  campaignProgress,
  aisle,
) {
  if (campaingType === campaignTypes.REBATE && isNearby) {
    if (reactivateOfferIsOn) {
      if (!accepted && hasWanted) {
        const expiredDate = getOfferExpiredDate(userWantedAt, userWantProperties);

        return `Offer expired on ${formatDateToMMMDDYYYY(expiredDate, true)}`;
      }
    } else if (rebateOfferDeadline) {
      if (offerRedeemed) {
        return 'You already redeemed this offer';
      }

      if (
        // Activations target reach and user has not activated
        (wantDisabled && !hasWanted) ||
        // Offer is expired
        isOfferExpired(completeByDate) ||
        // Offer is fully redeemed
        campaignProgress >= 100
      ) {
        return 'Offer is no longer available';
      }
    }

    if (accepted) {
      return aisle ? `find in ${aisle} aisle` : '';
    }

    return '';
  }

  if (!accepted && campaingType !== campaignTypes.REBATE) {
    return 'Waitlisted';
  }

  if (campaingType === campaignTypes.DTC) {
    return channelName ? `Check on ${channelName}` : 'Check my product page';
  }

  if (shipDate) {
    if (hasShipped) {
      const ship = hasExpired(shipDate) ? 'Shipped' : 'Shipping';
      return `${ship} on ${formatShipDate(shipDate, true)}`;
    }
    return `Shipping ${formatPeriodDate(shipDate)}`;
  }

  if (campaingType === campaignTypes.SAMPLE_DIRECT || campaingType === campaignTypes.IN_STORE) {
    return 'Shipment pending';
  }

  return '';
}

export function getCampaignStatusByProductPageData(
  data,
  reactivateOfferIsOn,
  rebateOfferDeadline,
  offerRedeemed,
  isNearby,
  campaignProgress,
) {
  if (data) {
    const { userWant, product, campaign } = data;
    const {
      completeByDate,
      campaignType,
      properties: { aisle = '' },
    } = campaign || { properties: {} };
    const { details } = product || {};
    const { wantDisabled, hasWanted, hasBeenAccepted } = details || {};

    if ((wantDisabled || !hasWanted) && campaignType !== campaignTypes.REBATE) {
      return null;
    }

    const {
      ShippedAt: userShipDate,
      campaignData: userCampaignData,
      createdAt: userWantedAt,
      properties: userWantProperties,
    } = userWant || {};
    const { ship_date: userCampaignShipDate, promo_codes: userPromoCodes = [] } =
      userCampaignData || {};
    const { channel_name: channelName = '' } = userPromoCodes.length > 0 ? userPromoCodes[0] : {};

    return getCampaignStatus(
      userShipDate || userCampaignShipDate,
      hasBeenAccepted,
      !!userShipDate,
      campaignType,
      channelName,
      hasWanted,
      userWantedAt,
      userWantProperties,
      reactivateOfferIsOn,
      rebateOfferDeadline,
      completeByDate,
      offerRedeemed,
      isNearby,
      wantDisabled,
      campaignProgress,
      aisle,
    ).toUpperCase();
  }
  return null;
}

export const isRebateCampaign = (campaignType) => {
  if (!campaignType) {
    return false;
  }
  return campaignType === campaignTypes.REBATE;
};
