export function createQuery(filterQueryMap) {
  const query = [];

  filterQueryMap.forEach((value, key) => {
    query.push(`${key}=${Array.from(value).join(',')}`);
  });

  // TODO: add url encoding
  return query.join('&');
}

export function deleteSlug(filterQueryMap, type, slug) {
  const value = filterQueryMap.get(type) || null;

  if (value) {
    value.delete(slug);
    if (value.size < 1) {
      filterQueryMap.delete(type);
    } else {
      filterQueryMap.set(type, value);
    }
  }

  return filterQueryMap;
}
