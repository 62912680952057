import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import * as R from 'ramda';
import { useQuery, ApolloProvider } from '@apollo/react-hooks';
import { Box, Anchor } from 'grommet';
import { Text, themes as uiTheme } from 'socialnature-ui';
import { useParams } from 'react-router-dom';
import client from '../../utils/apis';
import { GET_VALID_USER_PROMO_CODE } from '../../utils/apis/graphql/userPromoCode';
import Container from '../../Atoms/Container';
import ProductHeader from '../../Organisms/Product/ProductHeader';
import ProductHeaderWithFB from '../../Organisms/Product/ProductHeaderWithFB';
import Span from '../../Atoms/Span';
import RelatedProducts from '../Sections/RelatedProducts';
import Loading from '../../Molecules/Loading';
import ErrorPage from '../ErrorPage';
import { campaignTypes, ShippingInfo } from '../../utils/campaignTypes';
import { promoCodeTypes } from '../../utils/promoCodeTypes';
import DtcSection from './DtcSection';
import { CountryISO2FromPostalCode } from '../../utils/formatCountry';
import ProductPerks from '../ProductPage/ProductPerks';
import hasExpired from '../../utils/expiryDate';
import InviteFriendPopup from '../../Organisms/InviteFriend/InviteFriendPopup';
import { TrackingEventType } from '../../utils/tracking';
import { openHotjarSurveyForAccepted } from '../../utils/hotjar';
import { trackEvent as trackGTMEvent } from '../../utils/gtm';
import { isFeatureOn, SplitNames } from '../../utils/splitio';
import { getHasuraUserId } from '../../utils/userId';
import { useCheckYourTextModal } from '../../hooks/useCheckYourTextModal';
import { getCookie, setCookie } from '../../utils/cookie';
import RebateInviteAccepted from './RebateInviteAccepted';
import { getCongratsPageData } from '../../utils/apis/campaigns';
import { getUserFromTrackingId } from '../../utils/apis/users';
import getUserId from '../../utils/userId';
import clientGraphql from '../../utils/apis/graphql';
import '../../smsOptinPopup.jsx';

const DtcDivider = styled.hr`
  heigh: 0;
  border-color: ${uiTheme.global.colors.smoke};
  border-width: 4px 0 0;
  margin: 0;
`;

const ProductHeaderContainer = ({
  showUserFlow,
  wantId,
  userId,
  campaignId,
  product,
  headerTitle,
  headerDescription,
  campaignType,
}) => {
  const isWithFB = Number(process.env.CONGRATS_PAGE_WITH_FB_CAMPAIGN_ID) === campaignId;

  return (
    <Container>
      {isWithFB ? (
        <ProductHeaderWithFB
          showSNSButtons={showUserFlow}
          wantId={wantId}
          userId={userId}
          product={product}
          title={headerTitle}
          description={headerDescription}
          isEmailForm
          isAccepted
          dataTrackCategory="Congrats"
        />
      ) : (
        <ProductHeader
          showSNSButtons={showUserFlow}
          userId={userId}
          product={product}
          title={headerTitle}
          description={headerDescription}
          isAccepted
          dataTrackCategory="Congrats"
        />
      )}
      {campaignType === campaignTypes.DIGITAL_COUPON && (
        <Anchor
          href={`https://irxcm.com/RevTrax/demos.jsp?programId=108917479&uid=${userId}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          <Span weight="bold" color="launchpad">
            See digital coupon
          </Span>
        </Anchor>
      )}
    </Container>
  );
};

export function InviteAcceptedPage(props) {
  const disabledInviteFriendPopup = true; // ENG-1070

  const { product, shippingTime, campaignId, campaignType } = props;
  const isDtcCampaign = campaignType === campaignTypes.DTC;
  const isRebateCampaign = campaignType === campaignTypes.REBATE;
  const { wantId } = useParams();
  // show Share buttons and related products list
  const [showUserFlow, setShowUserFlow] = useState(!isDtcCampaign);
  const { CheckYourTextModal, open } = useCheckYourTextModal();

  const { loading, error, data } = useQuery(GET_VALID_USER_PROMO_CODE, {
    variables: {
      campaignId,
    },
  });

  const [rebateOfferDeadline, setRebateOfferDeadLine] = useState(false);

  useEffect(() => {
    window.dataLayer.push({ event: TrackingEventType.APPLICATION_COMPLETE_CONGRATS });
    window.dataLayer.push({ invitation_flag: 'success' });
  }, []);

  useEffect(() => {
    const showCheckYourTextModal = getCookie('showCheckYourTextModal');
    if (showCheckYourTextModal) {
      open();
      // removing the showCheckYourTextModal cookie
      setCookie('showCheckYourTextModal', false, -1);
    }
  }, [open]);

  useEffect(() => {
    if (isRebateCampaign) {
      trackGTMEvent(TrackingEventType.REBATE_FLOW.OFFER_ACTIVATED, {
        samplekit: product && product.name,
      });
    }
  }, [isRebateCampaign, product]);

  const redeemCode = () => {
    setShowUserFlow(true);
  };

  // MEMO: The valid code checker is temporary. we don't use campaign promo code so far.
  const getValidCode = (promoCode) => {
    const {
      userPromoCodes,
      code: campaignPromoCode,
      type: promoCodeType,
    } = (promoCode && promoCode[0]) || {};
    const { code } = (userPromoCodes && userPromoCodes[0]) || {};
    const isDtcPromoCodeType = promoCodeType === promoCodeTypes.DTC;

    if (isDtcPromoCodeType && code) return code;
    if (!isDtcPromoCodeType && campaignPromoCode) return campaignPromoCode;

    return null;
  };

  useEffect(() => {
    if (isDtcCampaign) {
      if (data) {
        const { promo_code: promoCode } = data || {};
        setShowUserFlow(!getValidCode(promoCode));
      }
    }
  }, [data, isDtcCampaign]);

  useEffect(() => {
    const userId = getHasuraUserId();
    isFeatureOn(SplitNames.rebateOfferDeadline, userId).then(setRebateOfferDeadLine);
  }, []);

  if (loading) return <Loading />;
  if (error) return <ErrorPage />;

  const { users, promo_code: promoCode } = data || {};

  if (isDtcCampaign && R.isEmpty(promoCode)) return <ErrorPage />;

  const { id: userId, address: postCode } = (users && users[0]) || {};
  const {
    end_at: promoEndDate,
    url: productUrl,
    button_text: buttonText,
    channel_name: channelName,
  } = (promoCode && promoCode[0]) || {};

  const countryISO2 = CountryISO2FromPostalCode(postCode);
  const validCode = getValidCode(promoCode);

  const headerTitle = (() => {
    if (isDtcCampaign) {
      if (validCode) {
        return `Successful match! You can now redeem ${product.name} online`;
      }
      return 'Successful match! Your free promo code will be sent via email in a few days';
    }
    if (campaignType === campaignTypes.IN_STORE) {
      return `Successful match! Your voucher for a free ${product.name} will be shipped to you ${shippingTime}`;
    }
    if (campaignType === campaignTypes.SAMPLE_DIRECT) {
      return `Successful match! Your free sample of ${product.name} will be shipped to you ${shippingTime}`;
    }
    if (isRebateCampaign) {
      return rebateOfferDeadline
        ? `Offer activated!`
        : `Offer activated! Now find ${product.name} in store and get cash back`;
    }
    if (campaignType === campaignTypes.DIGITAL_COUPON) {
      return `Digital Coupon Offer activated! Use it to redeem your product ${product.name}!`;
    }
    return `Successful match! ${product.name} will be shipped to you ${shippingTime}`;
  })();

  const headerDescription = (() => {
    if (isDtcCampaign) {
      if (validCode) {
        return (
          <>
            <Text as="span">We’re looking forward to your review!</Text>
          </>
        );
      }
      return (
        <>
          <Text as="span">
            Keep an eye on your emails. We’ll send an email with instructions on how to redeem your
            free trial online in a few days.
          </Text>
        </>
      );
    }
    return (
      <>
        <Span>We’re looking forward to your review! Check </Span>
        <Anchor
          href="/account/products"
          data-track-category="Congrats Page"
          data-track-action="Click My Products"
          data-track-label={product.slug}
        >
          <Span weight="bold" color="launchpad">
            My Products
          </Span>
        </Anchor>
        <Span> for updates.</Span>
      </>
    );
  })();

  const { bannerExpiry, bannerMessage } = product || {};

  const showBanner =
    !R.isNil(bannerMessage) &&
    !R.isEmpty(bannerMessage) &&
    !R.isNil(bannerExpiry) &&
    !hasExpired(bannerExpiry);

  openHotjarSurveyForAccepted(campaignType, userId);

  return (
    <>
      <Box background="smoke">
        {isRebateCampaign ? (
          <RebateInviteAccepted
            showUserFlow={showUserFlow}
            userId={userId}
            product={product}
            title={headerTitle}
          />
        ) : (
          <ProductHeaderContainer
            showUserFlow={showUserFlow}
            wantId={wantId}
            userId={userId}
            campaignId={campaignId}
            product={product}
            headerTitle={headerTitle}
            headerDescription={headerDescription}
            campaignType={campaignType}
          />
        )}
      </Box>
      {isDtcCampaign && validCode && (
        <DtcSection
          product={product}
          promoCode={validCode}
          productUrl={productUrl}
          promoExpiry={promoEndDate}
          shippingInfo={ShippingInfo[campaignTypes.DTC] || ''}
          buttonText={buttonText}
          channelName={channelName}
          redeemCode={redeemCode}
        />
      )}
      {showUserFlow && isDtcCampaign && <DtcDivider />}
      {showUserFlow && (
        <>
          {showBanner && (
            <Box margin={{ vertical: 'large' }}>
              <Container>
                <ProductPerks
                  details={{
                    ...product,
                    hasWanted: true,
                    wantDisabled: false,
                    isLaunchpad: false,
                  }}
                  coupon={{ ...product, showBanner }}
                  isLoggedIn={!!userId}
                  userId={userId}
                  dataTrackCategory="Congrats"
                />
              </Container>
            </Box>
          )}
          <ApolloProvider client={client}>
            <RelatedProducts userCountry={countryISO2} campaignType={campaignType} />
            {!disabledInviteFriendPopup && (
              <InviteFriendPopup
                userId={userId}
                product={product}
                isAccepted
                dataTrackCategory="Congrats Share Popup"
              />
            )}
          </ApolloProvider>
        </>
      )}
      {CheckYourTextModal()}
    </>
  );
}

export const InviteAcceptedPageContainer = ({ wantId }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [er, setError] = useState(null);
  // assumes the message has been sent (until getUserFromTrackingId loads) to avoid rendering the popup when not required
  const [smsOptinMessageSent, setsmsOptinMessageSent] = useState(true);
  const userTrackingId = getUserId();
  

  useEffect(() => {
    if (userTrackingId) {
      clientGraphql
        .query({
          query: getUserFromTrackingId(userTrackingId),
        })
        .then(({ data }) => {
          const { users } = data;
          if (users[0]) {
            const user = users[0];
            const { sms_optin: optin } = user;
            // only null value indicates an user has never tried to optin or optout
            setsmsOptinMessageSent(optin === null ? false : true);
          }
        });
    }
  }, [userTrackingId]);

  const openSmsOptInPopUp = () => {
    window.renderSmsOptInPopUp(() => setsmsOptinMessageSent(true));
  }
  useEffect(() => {
    if (!smsOptinMessageSent) {
      setTimeout(() => {
        openSmsOptInPopUp();
      }, 3000);
    }
  }, [openSmsOptInPopUp, smsOptinMessageSent]);

  useEffect(() => {
    getCongratsPageData(wantId)
      .then((res) => setData(res))
      .catch(() => {
        setError({ message: 'Failed to Load Invitation, Try again later!' });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [wantId]);

  if (loading) return <Loading />;
  if (er) return <ErrorPage />;

  const { product, shippingTime, campaignId, campaignType } = data;
  // Rendering InviteAcceptedPage with API/Container
  return (
    <InviteAcceptedPage
      product={product}
      shippingTime={shippingTime}
      campaignId={campaignId}
      campaignType={campaignType}
    />
  );
};
