export const validateEmail = (email) => {
  if (!email) return false;

  email = email.toLowerCase();

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const blockedPattern = /^multichannel-.*@socialnature\.com$/;

  return emailRegex.test(email) && !blockedPattern.test(email);
}

export const validatePostCode = (postCode) =>
  postCode &&
  postCode.match(
    /(^\d{5}(-\d{4})?$)|(^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1} *\d{1}[A-Za-z]{1}\d{1}$)/,
  );

export const validatePhoneNumber = (phoneNumber) =>
  phoneNumber && phoneNumber.match(/^\(\d{3}\) \d{3}-\d{4}$/);

export function maskEmail(email) {
  if (!email || !email.includes('@')) return '';

  const [username, domain] = email.split('@');

  const firstChar = username[0];
  const lastChar = username[username.length - 1];
  const maskLength = Math.min(username.length - 2, 10); // Mask length max 10
  const maskedUsername = `${firstChar}${'*'.repeat(maskLength)}${lastChar}`;

  return `${maskedUsername}@${domain}`;
}
