import { useEffect, useState } from 'react';
import { getTprRetailersByCampaign } from '../../utils/apis/tpr';
import { campaignTypes } from '../../utils/campaignTypes';

export function useTprRetailers(fetchList = false, campaigns) {
    const [state, setState] = useState({ isLoading: true, list: [] });

    useEffect(() => {
        if (!fetchList || !state.isLoading) return;

        const filteredCampaigns = campaigns.filter(campaign => campaign.campaign_type === campaignTypes.REBATE); // Only Rebate
        const uniqueRetailers = new Set();
        const TPRRetailersByCampaigns = filteredCampaigns.map(campaign => getTprRetailersByCampaign(campaign.id));
        
        Promise.all(TPRRetailersByCampaigns)
            .then(responses => {
                responses.forEach(response => {
                    if (Array.isArray(response)) {
                        response.forEach(retailer => {
                            uniqueRetailers.add(retailer);
                        });
                    }
                });
                setState({ list: Array.from(uniqueRetailers), isLoading: false });
            })
            .catch(() => {
                setState({ isLoading: false, list: [] });
            });
    }, [fetchList, campaigns, state.isLoading]);

    return state;
}
