import React from 'react';
import styled from 'styled-components';
import { Box, ResponsiveContext } from 'grommet';
import SimpleModal from '../../Molecules/SimpleModal';
import H from '../../Atoms/Heading';
import theme from '../../themes';
import InviteFriendContent from './InviteFriendContent';

const InviteFriendModal = ({ open, userId, isAccepted, onClose }) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      full
      position="top"
      margin={isXsmall ? {} : { top: '5%' }}
    >
      <Box fill>
        <Box flex={false} align="center" margin={{ bottom: '16px' }}>
          <CatchCopy>
            <H textAlign="center" level={5} color="launchpad" transform="uppercase">
              {isAccepted ? 'Yay, you got in!' : "Thank you! You're on the waitlist"}
            </H>
          </CatchCopy>
        </Box>
        <BackgroundImage isXsmall={isXsmall}>
          <InviteFriendContent userId={userId} />
        </BackgroundImage>
      </Box>
    </SimpleModal>
  );
};

export default InviteFriendModal;

const CatchCopy = styled.div`
  display: inline-block;
  padding: 0 8px;
  border-bottom: 3px solid ${theme.global.colors.launchpad};
`;

const BackgroundImage = styled.div`
  ${(props) =>
    !props.isXsmall &&
    `
			background: url('/images/bg-left-decoration.svg'), url('/images/bg-right-decoration.svg');
			background-repeat: no-repeat, no-repeat;
			background-position: top left, top right;
			background-size: contain, contain;
		`}
`;
