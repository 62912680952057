import React, { useCallback, useState } from 'react';
import { useFreeSample } from '../../../hooks/useFreeSample';
import { handleError } from '../../../utils/error';
import { GeoProductCarouselTitle } from './GeoProductCarouselTitle';
import FreeTrialList from '../../../Organisms/Products/FreeTrialList';
import LaunchpadSection from '../../HomePage/Sections/LaunchpadSection';
import LoadingSection from '../../../Molecules/LoadingSection';
import getUserId from '../../../utils/userId';

export default function GeoProductCarousel({ postalCode, country }) {
  const userId = getUserId();
  const { loading, error, data } = useFreeSample(postalCode, country, userId);


  if (loading) return <LoadingSection />;
  if (error) {
    handleError(error);
    return <></>;
  }

  const { freetrials } = data || {};
  const { products } = freetrials || {};

  if (!products || !products.length) {
    return (
      <LaunchpadSection userCountry={country} userId={userId} />
    );
  }

  return (
    <div className="sample-geoalternatives-content">
      <FreeTrialList
        userId // To hide country on a card
        title={<GeoProductCarouselTitle location={postalCode} />}
        description={null}
        products={products}
        dataTrackCategory="Suggestion Free Sample"
      />
    </div>
  );
}
