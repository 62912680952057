import { useState } from 'react';

export default function useDelayInputValidation(errors, clearErrors, trigger, inputName) {
  const [token, setToken] = useState(null);

  const handleOnChange = () => {
    if (token) {
      clearTimeout(token);
    }

    if (!!errors[inputName] && !!errors[inputName].message) {
      clearErrors(inputName);
    }

    const newToken = setTimeout(() => trigger([inputName]), 500);

    setToken(newToken);
  };

  const unregisterValidation = () => clearTimeout(token);

  return { handleOnChange, unregisterValidation };
}
