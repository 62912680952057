import styled from 'styled-components';
import { Text, CheckBox } from 'socialnature-ui';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ResponsiveContext } from 'grommet';
import { useHistory } from 'react-router-dom';
import PrimaryButton from '../../../Atoms/Buttons/PrimaryButton';
import H from '../../../Atoms/Heading';
import { useSignIn } from '../../../contexts/SignInContext/useSignIn';
import Loading from '../../../Molecules/Loading';
import Layout from '../../../Organisms/SignInPage/Layout';
import { updatePhoneAndConsent, wantProduct } from '../../../utils/apis/users';
import { TrackingEventType } from '../../../utils/tracking';
import { getCookie, setCookie } from '../../../utils/cookie';
import { validatePhoneNumber } from '../../../utils/validations';
import BasicLink from '../../../Atoms/WithoutGroomet/Link/BasicLink';
import basicTheme from '../../../themes';
import { trackEvent } from '../../../utils/gtm';
import { isFeatureOn, SplitNames } from '../../../utils/splitio';
import useDelayInputValidation from '../../../hooks/useDelayInputValidation';
import BasicInput from '../../../Organisms/WithoutGroomet/Inputs/InputField';
import SMSDoubleOptinTsCs from '../../../Molecules/SmsConsent/SMSDoubleOptinTsCs';
import { copiesSMSOptIn } from './copiesSmsOptIn';
import CheckYourText from '../../../Organisms/SmsConsentAnnouncement/CheckYourText';
import { trackUserSignUp } from '../../../utils/apis/analytics/shopermedia';
import { trackTiktokCampaign } from '../../../utils/apis/analytics/tiktok';
import { formatToCountryPrefix } from '../../../utils/number';

const CheckBoxWrapper = styled.div`
  padding-top: 16px;
  label {
    span {
      line-height: 16px;
      font-size: 14px;
      font-weight: 400;
    }
  }

  label {
    display: flex;
    align-items: flex-start;
  }
  label > div:first-child {
    flex-direction: row;
    margin-top: 3px;
  }
`;

const StyledSkipContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
`;

export const PHONE_COUNTRY_CODE = '+1 ';

// Sets phone number mask and validates only numbers
export const formatPhoneNumber = (value) => {
  const phone = value.substring(3);
  const phoneGroups = phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/) || [];
  const [, areaCode, phonePrefix, lineNumber] = phoneGroups;
  const formattedPhone = !phonePrefix
    ? areaCode
    : `(${areaCode}) ${phonePrefix}${lineNumber ? `-${lineNumber}` : ''}`;
  return `${PHONE_COUNTRY_CODE}${formattedPhone}`;
};

export const isPhoneNumberFn = (phone) => {
  if (!validatePhoneNumber(phone.substring(3))) {
    return 'Please enter a valid phone number';
  }
  return true;
};

export default function PhoneConsent() {
  const { user, setUser, vars } = useSignIn();
  const size = useContext(ResponsiveContext);
  const history = useHistory();
  const isXsmall = size === 'xsmall';
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(true);

  const {
    handleSubmit,
    setError,
    errors,
    formState: { isValid },
    control,
    watch,
    setValue,
    trigger,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      phoneNumber: '',
      smsConsent: false,
    },
  });
  const { handleOnChange, unregisterValidation } = useDelayInputValidation(
    errors,
    clearErrors,
    trigger,
    'phoneNumber',
  );

  useEffect(() => {
    trackUserSignUp();
    if (user.id) {
      trackTiktokCampaign({ email: user.email, id: user.id });
    }
  }, [user.id, user.email]);

  useEffect(() => {
    isFeatureOn(SplitNames.SMS_CONSENT, user.id).then((flag) => {
      if (!flag) {
        history.push('/postcode');
      }
    });
  }, [history, user.id]);

  useEffect(() => {
    if (user.id && user.phoneNumber) {
      setValue('phoneNumber', formatToCountryPrefix(user.phoneNumber));
      trigger('phoneNumber');
    }
  }, [setValue, trigger, user.id, user.phoneNumber]);

  useEffect(() => {
    if (document.body.classList.contains('mobile-bg')) {
      document.body.classList.remove('mobile-bg');
    }

    if (!document.body.classList.contains('signin')) {
      document.body.classList.add('signin');
    }
  });

  const redirection = useCallback(() => {
    // when user is signin-up popup shoul not be shown for the next day
    setCookie('showSmsOptinPopup2', 'false', 1);
    if (!user.postCode) {
      history.push('/postcode');
    } else if (vars.returnUrl) {
      window.location.href = vars.returnUrl;
    } else {
      history.push('/chooseproduct');
    }
  }, [history, user.postCode, vars.returnUrl]);

  const skipButton = () => {
    if (window.dataLayer) {
      trackEvent(TrackingEventType.REGISTRATION_FLOW.PHONENUMBER_SKIP, {
        provider: vars.provider,
        signinType: getCookie('signin_type'),
      });
    }
    redirection();
  };

  const setSmsConsent = useCallback(
    (phoneNumber, smsConsent) =>
      updatePhoneAndConsent(user.id, phoneNumber, smsConsent, 'signup').then((response) => {
        setIsLoading(false);

        if (!response.successful) {
          setError('phoneNumber', {
            type: 'custom',
            message: response.error,
          });

          return;
        }

        setUser((prev) => ({
          ...prev,
          phoneNumber,
          smsConsent,
        }));
        setShowForm(false);
      }),
    [user.id, setUser, setError],
  );

  const onSubmit = ({ phoneNumber, smsConsent }) => {
    setIsLoading(true);
    if (window.dataLayer) {
      trackEvent(TrackingEventType.REGISTRATION_FLOW.PHONENUMBER_COMPLETED, {
        provider: vars.provider,
        signinType: getCookie('signin_type'),
      });
    }
    setSmsConsent(phoneNumber, smsConsent);
  };

  useEffect(() => {
    if (window.dataLayer) {
      trackEvent(TrackingEventType.REGISTRATION_FLOW.PHONENUMBER_PAGEVIEW, {
        provider: vars.provider,
        signinType: getCookie('signin_type'),
      });
    }
  }, [vars.provider]);

  useEffect(() => unregisterValidation, [unregisterValidation]);

  if (isLoading) {
    return <Loading />;
  }

  const phoneNumberHasError = !!errors.phoneNumber && !!errors.phoneNumber.message;

  return (
    <Layout>
      {showForm ? (
        <>
          <H level={3} size="16px" margin={{ top: '0px', bottom: '24px' }}>
            {copiesSMSOptIn.TITLE}
          </H>
          <Text margin={{ bottom: '32px' }} size={isXsmall ? '14px' : '16px'}>
            {copiesSMSOptIn.MESSAGE}
          </Text>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="phoneNumber"
              defaultValue=""
              rules={{
                required: true,
                validate: {
                  isPhoneNumber: isPhoneNumberFn,
                },
              }}
              render={({ value, name, ref }) => (
                <BasicInput
                  id="phoneNumber"
                  label="Mobile number"
                  type="text"
                  inputMode="numeric"
                  placeholder={copiesSMSOptIn.INPUT_PLACEHOLDER}
                  handleOnChange={(e) => {
                    const phoneWithMask = formatPhoneNumber(e.target.value);
                    // Cannot set value with onChange from controller.render since it would fire the validation without the timeout
                    setValue('phoneNumber', phoneWithMask);
                    handleOnChange(phoneWithMask);
                  }}
                  value={value || ''}
                  name={name}
                  ref={ref}
                  handleOnClick={(e) => {
                    if (e.target.value.length === 0) {
                      // if input is empty add +1 country-code to input value
                      setValue('phoneNumber', PHONE_COUNTRY_CODE);
                    }
                  }}
                  pattern="\+1 \(\d{3}\) \d{3}-\d{4}"
                  error={phoneNumberHasError}
                  errors={errors}
                  handleOnBlur={(e) => {
                    if (e.target.value === PHONE_COUNTRY_CODE) {
                      // if input has country-code and no phone number it gets clear when onBlur
                      setValue('phoneNumber', '');
                    }
                  }}
                />
              )}
            />

            <CheckBoxWrapper>
              <Controller
                name="smsConsent"
                render={({ onChange, onBlur, value, name, ref }) => (
                  <CheckBox
                    id="smsConsent"
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value || false}
                    name={name}
                    label={<SMSDoubleOptinTsCs action={copiesSMSOptIn.ACTION_TS_CS} />}
                    ref={ref}
                  />
                )}
                control={control}
                rules={{ required: true, validate: { isChecked: (value) => value } }}
              />
            </CheckBoxWrapper>

            <PrimaryButton
              type="submit"
              margin={{ bottom: '24px', top: '24px' }}
              disabled={!isValid || !watch('smsConsent')}
              label={copiesSMSOptIn.LABEL_BUTTON}
              fill="horizontal"
            />
          </form>
          <StyledSkipContainer isXsmall={isXsmall}>
            <BasicLink
              label="Skip for now"
              onClick={skipButton}
              styles={{
                color: basicTheme.global.colors.black,
                hoverColor: basicTheme.global.colors.black,
              }}
            />
          </StyledSkipContainer>
        </>
      ) : (
        <CheckYourText height="100%">
          <PrimaryButton
            data-cy="check-your-text-button"
            onClick={redirection}
            margin={{ bottom: isXsmall ? '8px' : '32px', top: '24px' }}
            label="next"
            fill="horizontal"
          />
        </CheckYourText>
      )}
    </Layout>
  );
}
