import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(utc);
dayjs.extend(advancedFormat);
dayjs.extend(relativeTime);

export function formatDate(dateStr, showYear) {
  if (!dateStr || !dayjs(dateStr).isValid()) return '';
  // MEMO: ignore a time zone
  return showYear ? dayjs(dateStr).format('MMM Do YYYY') : dayjs(dateStr).format('MMM Do');
}

export function getTimeAgo(date) {
  if (!date || !dayjs(date).isValid()) return '';
  return dayjs(date).fromNow();
}

export function formatPeriodDate(date) {
  if (!date || !dayjs(date).isValid()) return '';
  const d = dayjs(date).get('date');
  const m = dayjs(date).format('MMMM');
  if (d < 10) {
    return `Early ${m}`;
  }
  if (d < 20) {
    return `Mid ${m}`;
  }
  return `Late ${m}`;
}

export function formatShipDate(dateStr) {
  if (!dateStr || !dayjs(dateStr).isValid()) return '';
  return dayjs(dateStr).format('MMM DD, YYYY');
}

export function formatDateToMMMDDYYYY(date) {
  if (!date) return '';
  return dayjs(date).format('MMM D, YYYY');
}
