import React from 'react';
import styled from 'styled-components';
import { Box, Divider } from 'socialnature-ui';
import LoadingSection from '../../../Molecules/LoadingSection';
import { LocationMap } from '../../CampaignLocation';
import { LocationListSelectable } from '../../CampaignLocation/LocationListSelectable';
import { SearchStoreCampaignLocationsForm } from '../../CampaignLocation/SearchStoreCampaignLocationsForm';
import { CENTER_NORTH_AMERICA } from '../../../utils/apis/maps';
import StoreLocationNoSamplesAvailable from './StoreLocationNoSamplesAvailable';

const DivStyled = styled.div`
  display: flex;
  flex-direction: column;
  flex: auto;
  justify-content: flex-end;
`;

const WrapperMap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .LocationMap {
    @media (min-height: 700px) {
      min-height: 663px;
    }
  }
`;

function StoreList({
  isLoading,
  locations,
  isXsmall,
  selectedIndex,
  setSelectedIndex,
  CTAButton,
  seeSamplesAvailable,
  postalCodeWithNoLocations,
  geocheckRef,
  tprRetailersList=[]
}) {
  if (isLoading) {
    return (
      <Box data-testid="LoadingSection" margin={{ top: '16px' }}>
        <LoadingSection />
      </Box>
    );
  }

  if (!postalCodeWithNoLocations) {
    return <></>;
  }

  if (!locations.length) {
    return (
      <StoreLocationNoSamplesAvailable
        seeSamplesAvailable={seeSamplesAvailable}
        isXsmall={isXsmall}
        postalCodeWithNoLocations={postalCodeWithNoLocations}
        geocheckRef={geocheckRef}
      />
    );
  }
  return (
    <>
      <Divider />
      <LocationListSelectable
        tprRetailersList={tprRetailersList}
        locations={locations}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      {CTAButton && <DivStyled id="storeLocationPopupCTAButton">{CTAButton}</DivStyled>}
    </>
  );
}

export function StoreLocationDesktop({
  campaignLocations,
  setIsRecheckingNearby,
  setCampaignLocations,
  isXsmall,
  isRecheckingNearby,
  errorMessage,
  setPostalCodeWithNoLocations,
  postalCodeWithNoLocations,
  selectedIndex,
  setSelectedIndex,
  handleContinue,
  seeSamplesAvailable,
  isLoggedIn,
  handleFormSubmitFetch,
  Header,
  FormLabelWhenLoggedInUser,
  CTAButton,
  geocheckRef,
  tprRetailersList = []
}) {
  return (
    <>
      <Box data-testid="StoreLocationDesktop" elevation="smallImage" round="8px">
        <Box direction="row">
          <WrapperMap>
            <LocationMap
              locations={campaignLocations}
              centerLatitude={
                campaignLocations.length ? campaignLocations[0].latitude : CENTER_NORTH_AMERICA.lat
              }
              centerLongitude={
                campaignLocations.length ? campaignLocations[0].longitude : CENTER_NORTH_AMERICA.lng
              }
              zoom={(!campaignLocations.length && 2.8) || 10}
              styles={{ borderRadius: '8px 0px 0px 8px', height: '100%' }}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
            />
          </WrapperMap>
          <Box
            direction="column"
            margin={{ horizontal: '24px', top: '40px', bottom: '24px' }}
            width="470px"
          >
            {Header}
            {isLoggedIn ? (
              FormLabelWhenLoggedInUser()
            ) : (
              <SearchStoreCampaignLocationsForm
                setIsRecheckingNearby={setIsRecheckingNearby}
                setCampaignLocations={setCampaignLocations}
                isXsmall={isXsmall}
                setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
                postalCodeWithNoLocations={postalCodeWithNoLocations}
                handleFormSubmitFetch={handleFormSubmitFetch}
                geocheckRef={geocheckRef}
              />
            )}
            <StoreList
              tprRetailersList={tprRetailersList}
              isLoading={isRecheckingNearby}
              locations={campaignLocations}
              errorMessage={errorMessage}
              selectedIndex={selectedIndex}
              setSelectedIndex={setSelectedIndex}
              handleContinue={handleContinue}
              isXsmall={isXsmall}
              seeSamplesAvailable={seeSamplesAvailable}
              postalCodeWithNoLocations={postalCodeWithNoLocations}
              CTAButton={CTAButton}
              geocheckRef={geocheckRef}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
}
