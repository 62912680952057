import themeFontFamily from './Fonts';
import basicTheme from './Grommet';

export const fontFamily = themeFontFamily;

export const rowWidth = '1200px'; // rem-calc(1200)
export const rowWidthMobile = '480px';
export const headerMargin = `${4.28571 - 5.28571}rem`;

export default basicTheme;
