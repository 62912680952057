/*  eslint-disable jsx-a11y/anchor-is-valid */
// @ts-check

import { useQuery } from '@apollo/react-hooks';
import React from 'react';
import styled from 'styled-components';
import { getWantFromCampaignUser } from '../utils/apis/campaigns';
import { storeLocationCtaLabels } from '../utils/campaignTypes';
import { trackEvent as trackGTMEvent } from '../utils/gtm';
import { TrackingEventType } from '../utils/tracking';

const StoreLocatorLinkStyled = styled.a`
  font-weight: 600;
  font-size: 16px !important;
  margin-top: 4px;
  display: block;
`;

function StoreLocatorLink({
  hasBeenAccepted,
  openStoreLocatorPopup,
  slug,
  campaignType,
  campaignId,
  userId,
  reactivateOfferIsOn,
  hasWanted,
}) {
  const { data: wantsData } = useQuery(getWantFromCampaignUser(campaignId, userId), {
    context: {
      clientName: 'hasura',
    },
  });

  const want = wantsData && wantsData.wants ? wantsData.wants[0] : {};
  const handleClick = () => {
    trackGTMEvent(TrackingEventType.STORE_LOCATOR_FLOW.CHOOSE_PREFERRED_LOCATION_CLICKED);
    openStoreLocatorPopup(storeLocationCtaLabels[campaignType]);
  };

  return (
    <>
      {hasBeenAccepted || (hasWanted && reactivateOfferIsOn) ? (
        <StoreLocatorLinkStyled
          className="link-site"
          href={`/${slug}/locate?wantId=${want ? want.id : ''}`}
        >
          Find an eligible store
        </StoreLocatorLinkStyled>
      ) : (
        <StoreLocatorLinkStyled className="link-site" onClick={handleClick}>
          Find an eligible store
        </StoreLocatorLinkStyled>
      )}
    </>
  );
}

export default StoreLocatorLink;
