import React from 'react';
import TextButton from '../../Atoms/Buttons/TextButton';
import ArrowDown from '../../Atoms/Icons/ArrowDown';
import LoadingSection from '../LoadingSection';

const LoadMoreButton = ({ onClick, loading }) =>
  loading ? (
    <LoadingSection />
  ) : (
    <TextButton
      onClick={onClick}
      icon={<ArrowDown color="launchpad" />}
      label="load more"
      weight="bold"
      transform="uppercase"
    />
  );

export default LoadMoreButton;
