import { hotjar } from 'react-hotjar';
import { campaignTypes } from './campaignTypes';
import { handleError } from './error';
import { SplitNames, usingFeatureFlag } from './splitio';

// eslint-disable-next-line prefer-destructuring
const HOTJAR_ID = process.env.HOTJAR_ID;
// eslint-disable-next-line prefer-destructuring
const HOTJAR_SV = process.env.HOTJAR_SV;

const triggerHotjar = (eventName) => {
  try {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SV);

    if (hotjar.initialized()) {
      hotjar.event(eventName);
      // eslint-disable-next-line no-console
      console.log(`hotjar survey triggered. event name: ${eventName}`);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(`HOTJAR ERROR: hotjar survey trigger. name: ${eventName}. Error: ${e}`);
  }
};

const HOTJAR_EVENTS = {
  voucher_congrats: 'voucher-congrats',
  dtc_congrats: 'dtc-congrats',
  sample_congrats: 'sample-congrats',
  rebate_congrats: 'rebate-congrats',
  voucher_waitlisted: 'voucher-waitlisted',
  dtc_waitlisted: 'dtc-waitlisted',
  sample_waitlisted: 'sample-waitlisted',
  rebate_waitlisted: 'rebate-waitlisted',
};

const openHotjarSurveyForWaitlisted = (campaignType, userId) => {
  usingFeatureFlag(
    SplitNames.hotjarCampaignSurvey,
    userId,
    () => {
      if (campaignType === campaignTypes.IN_STORE) {
        triggerHotjar(HOTJAR_EVENTS.voucher_waitlisted);
      }
      if (campaignType === campaignTypes.DTC) {
        triggerHotjar(HOTJAR_EVENTS.dtc_waitlisted);
      }
      if (campaignType === campaignTypes.SAMPLE_DIRECT) {
        triggerHotjar(HOTJAR_EVENTS.sample_waitlisted);
      }
      if (campaignType === campaignTypes.REBATE) {
        triggerHotjar(HOTJAR_EVENTS.rebate_waitlisted);
      }
    },
    () => {
      // eslint-disable-next-line no-console
      console.log(`Split name: ${SplitNames.hotjarCampaignSurvey} is OFF`);
    },
  );
};

const openHotjarSurveyForAccepted = (campaignType, userId) => {
  usingFeatureFlag(
    SplitNames.hotjarCampaignSurvey,
    userId,
    () => {
      if (campaignType === campaignTypes.IN_STORE) {
        triggerHotjar(HOTJAR_EVENTS.voucher_congrats);
      }
      if (campaignType === campaignTypes.DTC) {
        triggerHotjar(HOTJAR_EVENTS.dtc_congrats);
      }
      if (campaignType === campaignTypes.SAMPLE_DIRECT) {
        triggerHotjar(HOTJAR_EVENTS.sample_congrats);
      }
      if (campaignType === campaignTypes.REBATE) {
        triggerHotjar(HOTJAR_EVENTS.rebate_congrats);
      }
    },
    () => {
      handleError(`Split name: ${SplitNames.hotjarCampaignSurvey} is OFF`);
    },
  );
};

export { openHotjarSurveyForWaitlisted, openHotjarSurveyForAccepted };
