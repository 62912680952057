import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import { Box, TextInput, TextLink, Plus as PlusIcon, Minus as MinusIcon } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';
import { errorMessages } from '../../../utils/customQuestionTypes';
import CustomQuestionWrapper from '../CustomQuestionWrapper';

const MAX_LENGTH = 8;
const NUMBERING = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th'];

const TextInputWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: -8px 0 0 -8px;
  width: calc(100% + 8px);
  & > div {
    margin: 8px 0 0 8px;
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  margin-top: 8px;
  a[disabled] {
    color: inherit;
    cursor: default;
  }
`;

const AgeRangeInput = (props) => {
  const { name, error, question } = props;
  const { control } = useFormContext();
  const windowSize = useContext(ResponsiveContext);
  const isXsmall = windowSize === 'xsmall';
  const { listAnswer, validate, required = true } = question || {};
  const [size, setSize] = useState((listAnswer || []).length > 0 ? listAnswer.length : 1);

  const validations = useMemo(() => {
    const v = {};

    if (validate && validate.notFuture) {
      const currentYear = dayjs().year();
      v.notFuture = (value) => {
        if (value === '') return true;
        return Number(value) <= currentYear ? true : errorMessages.INVALID_YEAR;
      };
    }

    if (validate && validate.oldestYear) {
      // NOTE: didn't validate oldestYear format (e.g.'yyyy-mm-dd').
      // currently, it comes from profileQuestionTypes.js only (hardcoded)
      const oldestYear = validate.oldestYear ? dayjs(validate.oldestYear).year() : '';
      v.oldestYear = (value) => {
        if (value === '') return true;
        return Number(value) >= oldestYear ? true : errorMessages.INVALID_YEAR;
      };
    }
    return v;
  }, [validate]);

  // TODO:
  // The AgeRange type of question currently only used in profile questions,
  // so we potentially could just custom made those components.
  const label = 'Birth year of %s child';
  const addButtonLabel = 'Add another child';
  const removeButtonLabel = 'Remove child';

  const addList = () => {
    if (size >= MAX_LENGTH) return;
    setSize(size + 1);
  };

  const removeList = () => {
    if (size < 2) return;
    setSize(size - 1);
  };

  return (
    <>
      <TextInputWrapper>
        {[...Array(size)].map((_, index) => (
          <Box
            width={isXsmall ? 'calc(50% - 8px)' : '138px'}
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <Controller
              name={`${name}.Value[${index}]`}
              control={control}
              defaultValue="" // To become controlled input
              rules={{
                required: required && errorMessages.REQUIRED,
                pattern: {
                  value: /^[0-9]{4}$/,
                  message: 'Your entry must contain 4 numbers',
                },
                maxLength: {
                  value: 4,
                  message: errorMessages.MAX_LENGTH,
                },
                validate: { ...validations },
              }}
              render={(renderProps) => (
                <CustomQuestionWrapper error={error && error.Value[index]}>
                  <TextInput
                    {...renderProps}
                    type="text"
                    label={label && `${label.replace('%s', NUMBERING[index])}`}
                    error={error && error.Value[index]}
                    errorText={error && error.Value[index] && error.Value[index].message}
                    showErrorIcon={false}
                    placeholder="YYYY"
                    maxLength="4"
                  />
                </CustomQuestionWrapper>
              )}
            />
          </Box>
        ))}
      </TextInputWrapper>
      <ButtonWrapper>
        <Box direction="row" gap="medium">
          <TextLink
            icon={<PlusIcon color="blue" />}
            label={addButtonLabel || 'add'}
            onClick={addList}
            color="black"
            disabled={size >= MAX_LENGTH}
          />
          <TextLink
            icon={<MinusIcon color="red" />}
            label={removeButtonLabel || 'remove'}
            onClick={removeList}
            color="black"
            disabled={size < 2}
          />
        </Box>
      </ButtonWrapper>
    </>
  );
};

export default AgeRangeInput;
