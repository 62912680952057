import React, { useLayoutEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { getTreatments, SplitNames } from '../../utils/splitio';
import GET_USER from '../../utils/apis/users';
import Loading from '../../Molecules/Loading';
import HomeBanner from '../../Organisms/Home/HomeBanner';
import CashBackSection from '../../Organisms/Home/CashBackSection';
import Sections from '../../Templates/Sections';
import HomeBannerSection from './Sections/HomeBanner';
import FreeTrialSection from './Sections/FreeTrialSection';
import LaunchpadSection from './Sections/LaunchpadSection';
import ReviewSection from './Sections/ReviewSection';
import TopRatedSection from './Sections/TopRatedSection';

export default function LoggedInHomePage({ userId }) {
  const [featureFlags, setFeatureFlags] = useState({
    [SplitNames.rebateBannerHome]: undefined,
    [SplitNames.launchpadSectionHome]: undefined,
    [SplitNames.discountsSectionHome]: undefined,
    isLoading: true,
  });
  const { loading, data } = useQuery(GET_USER, {
    variables: {
      id: userId,
    },
  });

  useLayoutEffect(() => {
    getTreatments([
      SplitNames.rebateBannerHome,
      SplitNames.launchpadSectionHome,
      SplitNames.discountsSectionHome,
    ]).then((treatments) => {
      setFeatureFlags((prev) => ({ ...prev, ...treatments, isLoading: false }));
    });
  }, [setFeatureFlags]);

  const isRebateHomeOn = featureFlags[SplitNames.rebateBannerHome];
  const isLaunchpadHomeOn = featureFlags[SplitNames.launchpadSectionHome];
  const isDiscountsHomeOn = featureFlags[SplitNames.discountsSectionHome];

  if (loading || isRebateHomeOn === undefined || featureFlags.isLoading) {
    return <Loading />;
  }

  const { user } = data || {};
  const { firstName, country } = user || {};

  return (
    <>
      {isRebateHomeOn ? (
        <HomeBanner userName={firstName} loggedIn />
      ) : (
        <HomeBannerSection userName={firstName} />
      )}
      <Sections>
        {isRebateHomeOn && <CashBackSection loggedIn />}
        <FreeTrialSection userId={userId} userCountry={country} />
        {isLaunchpadHomeOn && <LaunchpadSection userId={userId} userCountry={country} />}
        <TopRatedSection userId={userId} userCountry={country} />
        <ReviewSection />
      </Sections>
    </>
  );
}
