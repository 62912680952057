import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { RestLink } from 'apollo-link-rest';
import { ApolloLink } from 'apollo-link';
import { hasuraLink } from './graphql';

const link = new RestLink({
  uri: process.env.API_URL,
  responseTransformer: async (response) => response.json().then((data) => data),
});

const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});

/**
 * The multiple client supports two endpoints, Hasura and Neo4j(Golang) API.
 * - Hasura supports GraphQL query
 * - Neo4j is Restful API
 *
 * To connect with Hasura:
 * NOTE: If you user Hasura client directly instead of multipleClient, you don't need to add context.
 *
 * const { loading, error, data } = useQuery(GET_USER_PROMO_CODE, {
 * variables: {
 *   campaignId,
 * },
 * context: {
 *   clientName: 'hasura',
 * },
 * skip: !campaignId,
 * });
 *
 * To connect with Neo4j API (don't need to set up context):
 * const { loading, error, data } = useQuery(GET_FREETRIALS_FILTERS, {
 *   variables: {
 *     param: formatParam({
 *       limit: 24,
 *       offset: 0,
 *       filter: query.toString(),
 *       userid: userId, // must be user tracking ID instead of user ID
 *     }),
 *   },
 * });
 *
 * How to use with Apollo Provider:
 * <ApolloProvider client={multiClient}>
 */
export const multiClient = new ApolloClient({
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'hasura',
    hasuraLink,
    link,
  ),
  cache: new InMemoryCache(),
});

export default client;
