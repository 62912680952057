import React, { useCallback, useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import CheckYourText from '../../Organisms/SmsConsentAnnouncement/CheckYourText';
import { useModal } from '../useModal';

export function useCheckYourTextModal(initOpen) {
  const { Modal, open, close } = useModal(initOpen);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  const handleOnClose = useCallback(() => {
    close();
  }, [close]);

  const CheckYourTextModal = () => (
    <Modal
      animation="slide"
      style={{
        maxWidth: isXsmall ? 'calc(100% - 32px)' : '344px',
        width: isXsmall ? '360px' : '344px',
        padding: isXsmall ? '40px 16px' : '40px 24px 104px',
      }}
      onClose={handleOnClose}
    >
      <CheckYourText height="100%" />
    </Modal>
  );

  return { CheckYourTextModal, open, handleOnClose };
}
