import React, { useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import DesktopBackground from '../../Molecules/Home/Banner/DesktopBackground';
import MobileBackground from '../../Molecules/Home/Banner/MobileBackground';

const HomeBannerBackground = ({ children }) => {
  const size = useContext(ResponsiveContext);
  const isSmall = size.includes('small', 'xsmall');
  const Background = isSmall ? MobileBackground : DesktopBackground;
  return <Background>{children}</Background>;
};

export default HomeBannerBackground;
