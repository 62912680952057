import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import { Box, PrimaryButton, TextInput, themes } from 'socialnature-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import InlineMessage from '../../../Atoms/Form/InlineMessage';
import Position from '../../../Atoms/Position';

const CopyButton = styled(PrimaryButton)`
  ${(props) =>
    !props.isXsmall
      ? `
			padding-top: 10px;
			padding-bottom: 10px;
		`
      : null}
`;

// TODO: Wrapper style is to overlap site.css
const Wrapper = styled.div`
  position: relative;
  input {
    width: 100%;
    box-shadow: none;
    font-weight: normal;
    font-size: 16px;
    border-radius: 4px;
    background: #fff;
    border: 1px solid ${themes.global.colors.silver};
    margin: 0;
    height: auto;
    padding: 7px 16px;
    &:focus {
      background: #fff;
      border: 1px solid ${themes.global.colors.launchpad};
    }
  }
`;

const CopyLink = ({
  dataTrackCategory,
  dataTrackAction,
  dataTrackLabel,
  shareUrl,
  dataTrackSamplekitId,
  onClick,
}) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [copied, setCopied] = useState(false);
  const handleCopy = () => {
    if (!copied) {
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 2000);
    }
  };

  return (
    <Box
      direction={isXsmall ? 'column' : 'row'}
      gap="small"
      justify="between"
      align={isXsmall ? 'stretch' : 'end'}
    >
      <Box flex="grow">
        <Wrapper>
          <TextInput
            name="share url"
            type="text"
            value={shareUrl.replace(/\{0\}/g, 'link')}
            readonly
          />
        </Wrapper>
      </Box>
      <Box width={{ min: '154px' }}>
        <CopyToClipboard text={shareUrl.replace(/\{0\}/g, 'link')} onCopy={handleCopy}>
          <CopyButton
            label="COPY LINK"
            isXsmall={isXsmall}
            size="small"
            data-track-category={dataTrackCategory}
            data-track-action={dataTrackAction}
            data-track-label={dataTrackLabel}
            data-track-samplekit-id={dataTrackSamplekitId}
            onClick={onClick}
          />
        </CopyToClipboard>
        {copied && (
          <Position right="0" bottom="-1.3em">
            <InlineMessage msg="Copied!" />
          </Position>
        )}
      </Box>
    </Box>
  );
};

export default CopyLink;
