// This file will be deleted when profile questions is defined on DB
import { customQuestionTypes } from './customQuestionTypes';

export const questionText = {
  CATEGORY: 'Which categories are you most interested in upgrading to better-for-you products?',
  ORGANIC_PERCENTAGE: 'What percentage of your grocery basket is natural/organic?',
  GROCERY_STORE: "Where do you usually shop? We'll help you find free natural products at your favorite retailers!",
  INDEPENDENT_LOCAL_GROCER: 'What Independent Local Grocer do you shop at?',
  INDEPENDENT_HEALTH_STORE: 'What Independent Health Store do you shop at?',
  ONLINE_STORE: 'Where do you do most of your online shopping?',
  AMAZON_PRIME: 'Are you an Amazon Prime member?',
  QUALITY: 'Do you have any diet preferences?',
  NO_QUALITY: 'Which products do you never buy?',
  NUT: 'What types of nuts would you never buy?',
  HEALTH_GOAL: 'What are your health goals?',
  SHOP_FOR: 'Who do you normally shop for?',
  CHILDREN_AGE: 'How old are your children?',
  BABY: 'Are you expecting a baby?',
  BABY_DUE_DATE: "Congrats! What's the expected due date?",
  GENDER: "How do you identify?",
  BOD: "When's your birthday?",
};

// Map old answers to new answers
export const oldAnswerMap = {
  [questionText.SHOP_FOR]: {
    'my pet': [ 'My Cat', 'My Dog' ]
  },
  [questionText.HEALTH_GOAL]: {
    'be happier': [ 'Boost happiness' ],
    'cut back on alcohol': [ 'Cut back on alcohol' ],
    'eat healthier': [ 'Eat healthier' ],
    'enhance digestion': [ 'Improve digestion' ],
    'get better sleep': [ 'Improve sleep' ],
    'improve digestive health': [ 'Improve digestion' ],
    'improve mental & cognitive performance': [ 'Sharpen mental performance' ],
    'improve mindfulness': [ 'Improve mindfulness' ],
    'increase energy levels': [ 'Increase energy' ],
    'increase exercise & fitness': [ 'Get fit' ],
    'lose weight': [ 'Lose weight' ],
    'prevent illness & injury': [ 'Prevent illness' ],
    'quit smoking': [ 'Quit smoking' ],
    'reduce physical pain': [ 'Reduce pain' ],
    'reduce stress': [ 'Manage stress' ],
    'restore emotional balance': [ 'Restore emotional balance' ],
  }
};

export const disableWhyWeAskText = {
  [questionText.BOD]: true,
};

export const questionTooltip = {
  [questionText.CATEGORY]: 'So we can recommend products that will help you make the switch',
  [questionText.ORGANIC_PERCENTAGE]:
    "We'd love to hear if you’re just starting out, or a savvy better-for-you shopper",
  [questionText.GROCERY_STORE]:
    'So we can help you discover better-for-you products at stores near you',
  [questionText.ONLINE_STORE]: 'So we can share perks with you at your favorite online retailers',
  [questionText.QUALITY]:
    'So we can make it easier to browse products that suit your lifestyle and diet',
  [questionText.HEALTH_GOAL]: 'So we can suggest products to help you get there',
  [questionText.SHOP_FOR]: 'So we can recommend products suitable for everyone you shop for',
  [questionText.CHILDREN_AGE]: 'So we can suggest products that they would enjoy as well',
  [questionText.BABY]: 'We can suggest products to support expecting parents',
  [questionText.BOD]: 'You must be 18 years or over to proceed.',
};

export const questionRequired = {
  [questionText.CATEGORY]: true,
  [questionText.ORGANIC_PERCENTAGE]: true,
  [questionText.GROCERY_STORE]: true,
  [questionText.INDEPENDENT_LOCAL_GROCER]: false,
  [questionText.INDEPENDENT_HEALTH_STORE]: false,
  [questionText.ONLINE_STORE]: true,
  [questionText.AMAZON_PRIME]: true,
  [questionText.QUALITY]: true,
  [questionText.NO_QUALITY]: true,
  [questionText.NUT]: true,
  [questionText.HEALTH_GOAL]: true,
  [questionText.SHOP_FOR]: true,
  [questionText.CHILDREN_AGE]: false,
  [questionText.BABY]: true,
  [questionText.BABY_DUE_DATE]: false,
  [questionText.GENDER]: true,
  [questionText.BOD]: true,
};

export const questionNoneOption = {
  [questionText.GROCERY_STORE]: 'I only shop online',
  [questionText.ONLINE_STORE]: "I don't shop online",
  [questionText.NO_QUALITY]: 'I buy all of them',
  [questionText.NUT]: 'All Nuts',
};

export const questionEditType = {
  [questionText.INDEPENDENT_LOCAL_GROCER]: customQuestionTypes.MULTIPLE_TEXT,
  [questionText.INDEPENDENT_HEALTH_STORE]: customQuestionTypes.MULTIPLE_TEXT,
  [questionText.BABY_DUE_DATE]: customQuestionTypes.DUE_DATE,
};

export const questionImage = {
  [questionText.HEALTH_GOAL]: true,
};

export const questionLoadMore = {
  [questionText.GROCERY_STORE]: 'LOAD MORE STORES',
};

export const questionValidate = {
  [questionText.CHILDREN_AGE]: { notFuture: true, oldestYear: '1920-01-01' },
  [questionText.BABY_DUE_DATE]: { allowFutureYear: 1 },
  [questionText.BOD]: {
    notFuture: true,
    oldestYear: '1920-01-01',
    olderThan: {
      value: 18,
      message: 'You must be 18 years or over to proceed.',
    },
  },
};
