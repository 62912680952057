import { intersection } from 'ramda';
import { isNilOrEmpty, toFirstLowerCase } from './customRamda';
import {
  oldAnswerMap,
  questionText,
  questionTooltip,
  questionRequired,
  questionNoneOption,
  questionEditType as questionEditTypes,
  questionImage,
  questionLoadMore,
  questionValidate,
  disableWhyWeAskText,
} from './profileQuestionTypes';
import { customQuestionAnswerTypes, answerTypes, customQuestionTypes } from './customQuestionTypes';
import { CountryISO2FromPostalCode } from './formatCountry';
import { handleError } from './error';

// TODO: This function will be deleted when profile questions is defined on DB
// with profileQuestionTypes.js
const formatChoices = (choices, hasImage) => {
  if (isNilOrEmpty(choices)) return null;

  if (hasImage) {
    return choices.map((choice) => ({
      image: `/images/icons/custom-questions/${choice
        .toLowerCase()
        .replace(/&/g, '')
        .replace(/\s+/g, '-')}/image.svg`,
      activeImage: `/images/icons/custom-questions/${choice
        .toLowerCase()
        .replace(/&/g, '')
        .replace(/\s+/g, '-')}/image-active.svg`,
      label: choice,
      value: choice,
    }));
  }

  return choices;
};

// We need to map old answer text to the new ones.
const formatAnswers = (questionText, answers) => {
  if (isNilOrEmpty(answers)) return null;

  const answerMap = oldAnswerMap[questionText];
  if (answerMap) {
    const newAnswers = new Set();

    answers.forEach((answer) => {
      const newAnswer = answerMap[answer.toLowerCase()];
      if (newAnswer) {
        newAnswer.forEach((a) => {
          newAnswers.add(a);
        });
      } else {
        newAnswers.add(answer);
      }
    });

    return Array.from(newAnswers.values());
  }

  return answers;
}

// TODO: This function will be deleted when profile questions is defined on DB
// with profileQuestionTypes.js
const formatStores = (question, postCode, stores) => {
  if (!postCode) return [];
  const { choices: defaultChoices, questionText: text } = question;
  const choices = JSON.parse(defaultChoices);
  const isOnlineStore = text === questionText.ONLINE_STORE;
  const countryPrefix = CountryISO2FromPostalCode(postCode);
  const countryPrefixRegex = new RegExp(`^(${countryPrefix}_)(.+)`, 'i');
  const noneOption = questionNoneOption[text];
  let filteredStores = [];

  if (Array.isArray(choices)) {
    filteredStores = choices.filter((choice) => choice.trim().match(countryPrefixRegex));
  }

  if (isOnlineStore) {
    return [noneOption, ...filteredStores].map((choice) => ({
      label: choice.trim().replace(countryPrefixRegex, (_, p1, p2) => p2),
      value: choice,
    }));
  }

  if (Array.isArray(stores)) {
    const c = [...stores.map((store) => `${countryPrefix}_${store.name}`), ...filteredStores];
    filteredStores = Array.from(new Set(c));
  }

  // Independent stores are always 23th~ in the array
  const grocer = filteredStores.find((s) => s.match(/^.*Independent Local Grocer$/i));
  const health = filteredStores.find((s) => s.match(/^.*Independent Health Store$/i));

  if (grocer || health) {
    const c = filteredStores.filter((s) => s !== grocer && s !== health);
    c.splice(21, 0, grocer, health);
    filteredStores = c.filter((s) => s);
  }

  return [noneOption, ...filteredStores].map((choice) => ({
    label: choice.trim().replace(countryPrefixRegex, (_, p1, p2) => p2),
    value: choice,
  }));
};

export const formatCustomQuestions = (questions, postCode, type = 'preTrial', stores) => {
  if (isNilOrEmpty(questions)) return [];
  const isProfile = type === 'profile';
  let formattedQuestions = null;

  try {
    formattedQuestions = questions.map((q, index) => {
      const { answers, ...rest } = q;
      let answer = {};
      if (!isNilOrEmpty(answers)) {
        answer = {
          answerId: answers[0].id,
          numberAnswer: answers[0].number_value,
          listAnswer: formatAnswers(q.questionText, answers[0].list_value ? JSON.parse(answers[0].list_value) : []),
          stringAnswer: answers[0].string_value,
        };
      }

      const isStoreQuestion =
        q.questionText === questionText.GROCERY_STORE ||
        q.questionText === questionText.ONLINE_STORE;
      if (isProfile) {
        return {
          ...rest,
          index,
          questionEditType: questionEditTypes[q.questionText] || q.questionType,
          choices: isStoreQuestion
            ? formatStores(q, postCode, stores)
            : formatChoices(JSON.parse(q.choices), questionImage[q.questionText]),
          questionTooltip: questionTooltip[q.questionText],
          disableWhyWeAskText: disableWhyWeAskText[q.questionText] || false,
          required: questionRequired[q.questionText],
          noneOption: questionNoneOption[q.questionText],
          loadMore: questionLoadMore[q.questionText],
          validate: questionValidate[q.questionText],
          ...answer,
        };
      }
      return {
        ...rest,
        index,
        questionEditType: q.questionType,
        choices: formatChoices(JSON.parse(q.choices)),
        required: true,
        ...answer,
      };
    });
  } catch (error) {
    handleError(error);
  }

  return formattedQuestions || [];
};

export const isIncludedAnswer = (base, valueToFind) => {
  if (!base || !valueToFind) return false;
  const list1 = Array.isArray(base) ? base : base.split(',');
  const list2 = Array.isArray(valueToFind) ? valueToFind : valueToFind.split(',');

  return intersection(list1, list2).length !== 0;
};

export function hasAnswered(value) {
  if (!Array.isArray(value)) {
    return !isNilOrEmpty(value);
  }
  return value.some((v) => !isNilOrEmpty(v));
}

export function getDefaultValues(questions) {
  if (!Array.isArray(questions)) return {};
  return questions.reduce((obj, question) => {
    const { questionEditType } = question || {};

    // TODO: This condition will be deleted when profile questions is defined on DB
    // with profileQuestionTypes.js
    if (
      questionEditType === customQuestionTypes.AGERANGE ||
      questionEditType === customQuestionTypes.MULTIPLE_TEXT
    ) {
      const answers = (question[toFirstLowerCase(answerTypes.LIST)] || []).reduce(
        (o, q, i) => ({
          ...o,
          [`CustomQuestions[${question.index}].Value[${i}]`]: q || '',
        }),
        {},
      );
      if (isNilOrEmpty(answers)) {
        return {
          ...obj,
          [`CustomQuestions[${question.index}].Value[0]`]: '',
        };
      }
      return {
        ...obj,
        ...answers,
      };
    }

    const answerType = customQuestionAnswerTypes[questionEditType];
    const questionAnswer = question[toFirstLowerCase(answerType)];
    let answer;
    switch (answerType) {
      case answerTypes.STRING:
        answer = questionAnswer || '';
        break;
      case answerTypes.LIST:
        answer = questionAnswer || [];
        break;
      case answerTypes.NUMBER:
        answer = typeof questionAnswer === 'number' ? questionAnswer : null;
        break;
      default:
        answer = undefined;
    }
    return {
      ...obj,
      [`CustomQuestions[${question.index}].Value`]: answer,
    };
  }, {});
}
