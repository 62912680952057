/**
 * This file is also meant for documentation(dataLayer.push event).
 * Some variables are not actually used, but strings are hard-coded.
 *
 * NOTE: Please add strings to this file always when creating new dataLayer.push event.
 */

export const TrackingEventType = {
  // How many users submit Address confirmation page?
  // On Address confirmation page, when user successfully submit the form
  ADDRESS_CONFIRMATION_COMPLETE: 'address_confirmation_completion',

  // How many users see Congrats page?
  // On Congrats page load
  APPLICATION_COMPLETE_CONGRATS: 'application_complete_congrats',

  // How many users see Waitlist page?
  // On Waitlist page load
  APPLICATION_COMPLETE_WAITLIST: 'application_complete_waitlist',

  // How many users click on Apply to try?
  // On Apply to try btn click
  // IMPORTANT: BOOST button uses same CTA base, make sure it doesn't trigger this event
  APPLY_TO_TRY_CLICKED: 'apply_to_try_click',

  // How many users submit pre-trial survey?
  // On Pre-trial survey page, when user successfully submit the form
  PRETRIAL_SURVEY_COMPLETE: 'pre-trial_survey_completion',

  // How many users have location matching campaign criteria?
  // After User location check, if user location matches campaign criteria
  USER_LOCATION_MATCH: 'user_location_match',

  // How many users have location NOT matching campaign criteria?
  // After User location check, if user location doesn't matches campaign criteria
  USER_LOCATION_NOMATCH: 'user_location_no_match',

  // How many users need to provide locations when they apply?
  // On product page load, if user location is missing
  USER_LOCATION_REQUIRED: 'user_location_required',

  // How many users provide their locations?
  // On User Location pop up, on Check Availability btn click
  USER_LOCATION_SUBMITTED: 'user_location_submitted',
  // How many users land on "Confirm Phone Number"?
  // During Pretrial
  CONFIRM_USER_PHONE_NUMBER_VIEW: 'confirm_phone_number_view',
  CONFIRM_USER_PHONE_NUMBER_COMPLETE: 'confirm_phone_number_complete',

  PHONE_VALIDATION: {
    CATEGORY: 'PhoneValidation',
    INITIATED: 'PhoneValidationFlowInitiated',
    USER_CLICKED_SEND_CODE_BUTTON: 'UserClickedSendCodeButton',
    PHONE_NUMBER_IN_USE: 'PhoneNumberInUse',
    COMPLETED: 'ValidationCompleted',
  },

  // REPORT ISSUE FLOW EVENT TYPES
  REPORT_ISSUE_FLOW: {
    REPORT_ISSUE_FLOW_REACHED: 'report_issue_started',
    // How many users click on "Report Issue" from "Review" Page?
    // On click "Report Issue" from "Review" Page
    CONTACT_SUPPORT_FROM_REVIEW_PAGE: 'contact_support_review_page',
    // How many users click on "I need help" from "Notification"?
    // On click "I need help" from "Notification"
    CONTACT_SUPPORT_FROM_EDIT_REVIEW_PAGE: 'contact_support_notification',
    // How many users click on "Contact Support" from "My Product" Page?
    // On click "Contact Support" from "My Product" Page
    CONTACT_SUPPORT_FROM_MY_PRODUCT_PAGE: 'contact_support_my_product',
    // How many users click on "Contact Support" from Email?
    // On click "Contact Support" from Email
    CONTACT_SUPPORT_FROM_EMAIL: 'contact_support_email',
    // How many users click on "Contact Support" from "Overdue Review" Page?
    // On click "Contact Support" from "Overdue Review Pop Up" Page
    CONTACT_SUPPORT_FROM_OVERDUE_REVIEW_POPUP: 'contact_support_overdue_review_popup',
    // How many users click on "continue"  - on the first question page
    // On click "continue" on the first question page
    CONTINUED_FIRST_QUESTION: 'contact_support_continue_1st_page',
    // How many users click on "continue"  - on the second question page
    // On click "continue" on the second question page
    CONTINUED_SECOND_QUESTION: 'contact_support_continue_2nd_page',
    // How many users click on "continue"  - on the third question page
    // On click "continue" on the third question page
    CONTINUED_THIRD_QUESTION: 'contact_support_continue_3rd_page',
    // How many users see "Support Requested" page?
    // On "Support Requested" page load
    SUPPORT_REQUESTED_REACHED: 'contact_support_requested',
    // How many users click "Yes" to  "Was this information helpful?"?
    // On  click "Yes" to  "Was this information helpful?"
    THIS_INFORMATION_WAS_HELPFUL: 'Yes_to_info_helpful_question',
    THIS_INFORMATION_WAS_NO_HELPFUL: 'No_to_info_helpful_question',
    // How many users see "We have extended the deadline for your review"?
    // On "We have extended the deadline for your review" page load
    EXTENDED_REVIEW_DEADLINE_REACHED: 'extend_deadline',
    // How many users see "It's too early to report this issue!"?
    // On "It's too early to report this issue!" page load
    WAIT_FOR_PACKAGE: 'wait_for_package',
  },
  CONTACT_SUPPORT_FLOW: {
    // How many user open the offers not listed view
    OFFER_NOT_LISTED_VIEW: 'OFFER_NOT_LISTED_VIEW',
  },

  // REGISTRATION FLOW EVENT TYPES
  REGISTRATION_FLOW: {
    // Sources from where events are logged
    SOURCES: {
      FRIEND_REFERRAL: 'friend_referral_flow',
      SIGN_IN: 'sign_in_flow',
      HOW_IT_WORKS: 'how_it_works_flow',
      OUR_MISSION: 'our_mission',
    },
    // How many users open /sign-in page?
    // On /sign-in page load
    SIGN_IN_PAGEVIEW: 'sign_in_page_load',
    // How many users see email consent page?
    // On "email-consent" page load
    CONSENT_PAGEVIEW: 'consent_page_view',
    // How many users see PHONENUMBER page?
    // On "PHONENUMBER" page load
    PHONENUMBER_PAGEVIEW: 'phonenumber_pageview',
    // How many click on NEXT after adding phone-number and SMS-consent?
    // On click "NEXT"
    PHONENUMBER_COMPLETED: 'phonenumber_completed',
    // How many click on SKIP button to skip addind phone information?
    // On click "Skip for now"
    PHONENUMBER_SKIP: 'phonenumber_skip',
    // How many users see customize your experience page?
    // On "customize your experience" page load
    POSTCODE_PAGEVIEW: 'customize_your_experience',
    // How many click on continue after adding the post code?
    // On click "continue"
    POSTCODE_COMPLETED: 'continue_after_zipcode_input',
    // How many users see create account page?
    // On "create account" page load
    CREATE_ACCOUNT_PAGEVIEW: 'create_account_email',
    // How many click on get started after adding name and password?
    // On click "get started"
    CREATE_ACCOUNT_GET_STARTED: 'get_started_on_create_account_page_email',
    // How many click on the sign in button in sign-in page?
    // On click "facebook, google or email" buttons
    SIGN_IN_CONTINUE: 'sign_in_continue',
    // How many users see choose product page?
    // On "choose product" page load
    CHOOSE_PRODUCT_PAGEVIEW: 'choose_product_page_load',
    // How many click on the submit button in choose product page?
    // On click "submit" button
    CHOOSE_PRODUCT_SUBMISSION: 'choose_product_continue',
    // How many users see profile questions page?
    // On "profile questions" page load
    PROFILE_QUESTIONS_PAGEVIEW: 'profile_question_page_load',
    // How many click on the submit button in profile questions page?
    // On click "submit" button
    PROFILE_QUESTIONS_SUBMISSION: 'profile_question_submission',
    // How many users hit the "SIGN UP NOW" button on /friend-referral page?
    // On /friend-referral "SIGN UP NOW" button clicked
    FRIEND_REFERRAL_SIGN_UP_NOW: 'friend_referral_sign_up_now',
  },

  SMS_OPTIN: {
    POP_UP: {
      // How many users see PHONENUMBER/SMSCONSENT popup?
      // On "PHONENUMBER/SMSCONSENT" popup load
      SMS_OPTIN_VIEW: 'sms_optin_popup_view',
      // How many users submit PHONENUMBER/SMSCONSENT popup?
      // On "PHONENUMBER/SMSCONSENT" popup submit
      SMS_OPTIN_COMPLETED: 'sms_optin_popup_completed',
      // How many users close PHONENUMBER/SMSCONSENT popup?
      // On "PHONENUMBER/SMSCONSENT" popup closes
      SMS_OPTIN_CLOSE: 'sms_optin_popup_close',
    },
    ANNOUNCEMENT_PAGE: {
      // How many users see PHONENUMBER/SMSCONSENT announcement page?
      // On "PHONENUMBER/SMSCONSENT" announcement page load
      SMS_OPTIN_VIEW: 'sms_optin_announcement_page_view',
      // How many users submit PHONENUMBER/SMSCONSENT announcement page?
      // On "PHONENUMBER/SMSCONSENT" announcement page submit
      SMS_OPTIN_COMPLETED: 'sms_optin_announcement_page_completed',
    },
  },

  STORE_LOCATOR_FLOW: {
    // How many users click on choose preferred pickup location?
    // On Choose preferred pickup location btn click
    CHOOSE_PREFERRED_LOCATION_CLICKED: 'choose_preferred_location_click',
    // How many users click on store location popup cta clicked?
    // On store location popup cta clicked btn click
    STORE_LOCATION_POPUP_CTA_CLICKED: 'store_location_popup_cta_click',
    // How many users click on the apply to try button on product page?
    // On apply to try button on product page clicked btn click
    PRODUCT_PAGE_APPLY_TO_TRY_CLICKED: 'store_location_product_page_apply_to_try_clicked',
    SEE_SAMPLES_AVAILABLE_POPUP: 'see_samples_available_popup',
  },
  // How many users click on load more stores?
  // On load more stores btn click
  LOAD_MORE_STORES_CLICKED: 'load_more_stores_click',
  // How many users click on participant stores?
  // On participant stores btn click
  PARTICIPANT_STORES_CLICK: 'participant_stores_click',
  // How many users click on share product?
  // On share product btn click
  SHARE_PRODUCT_CLICK: 'share_product_click',

  REBATE_FLOW: {
    // How many users click on activate offer?
    // On activate offer btn click
    ACTIVATE_OFFER: 'activate_offer',
    // On reactivate offer btn click
    REACTIVATE_OFFER: 'reactivate_offer',
    // On users lands on congrats page
    OFFER_ACTIVATED: 'offer_activated',
    // How many users click on add receipt link?
    // On add receipt link btn click
    ADD_RECEIPT_LINK: 'add_receipt_link_click',
    // How many users click on how to cashback?
    // On how to cashback btn click
    HOW_TO_CASHBACK: 'how_to_cashback_click',
    // How many users click on add receipt?
    // On add receipt btn click
    ADD_RECEIPT: 'add_receipt_click',
    // How many users click on add receipt?
    // On add receipt btn click
    ADD_ADDITIONAL_RECEIPT: 'add_additional_receipt_click',
    // How many users click on validate?
    // On validate btn click
    VALIDATE_RECEIPT: 'validate_receipt_click',
    // How many users click on write review?
    // On write review btn click
    WRITE_REVIEW: 'write_review_click',
    SOURCES: {
      MY_PRODUCTS_PAGE: 'my_products_page',
      PRODUCT_PAGE: 'product_page',
      ACCOUNT_WANT: 'account_want',
    },
  },

  DISCOUNT_FLOW: {
    // How many users click on how to cashback?
    // On how to cashback btn click
    HOW_TO_CASHBACK: 'how_to_cashback_click',
    // How many users click on add receipt?
    // On add receipt btn click
    ADD_RECEIPT: 'add_receipt_click',
    // How many users click on add receipt?
    // On add receipt btn click
    ADD_ADDITIONAL_RECEIPT: 'add_additional_receipt_click',
    // How many users click on validate?
    // On validate btn click
    VALIDATE_RECEIPT: 'discount_validate_receipt_click',
    SOURCES: {
      MY_PRODUCTS_PAGE: 'my_products_page',
      PRODUCT_PAGE: 'product_page',
      ACCOUNT_WANT: 'account_want',
    },
  },

  HOW_IT_WORKS_FLOW: {
    // How many users open /how-it-works page?
    // On /how-it-works page load
    HOW_IT_WORKS_PAGEVIEW: 'how_it_works_page_load',
    // How many users click SIGN-UP-NOW button?
    // On SIGN-UP-NOW click
    SIGN_UP_NOW_CLICKED: 'sign_up_now_click',
    // How many users click MY_PRODUCTS_CLICKED button?
    // On MY_PRODUCTS_CLICKED click
    MY_PRODUCTS_CLICKED: 'my_products_click',
    // How many users click MY_PRODUCTS_CLICKED button?
    // On MY_PRODUCTS_CLICKED click
    NEW_MY_PROFILE: 'new_my_profile',
    // How many users click CREATE-YOUR-ACCOUNT button?
    // On CREATE-YOUR-ACCOUNT click
    CREATE_YOUR_ACCOUNT_CLICKED: 'create_your_account_click',
    // How many users click DISCOVER_FREE_PRODUCTS button?
    // On DISCOVER_FREE_PRODUCTS click
    DISCOVER_FREE_PRODUCTS_CLICKED: 'discover_free_products_click',
    // How many users click TRY_PRODUCTS button?
    // On TRY_PRODUCTS click
    TRY_PRODUCTS_CLICKED: 'try_products_click',
    // How many users click BROWSE_DISCOUNTS button?
    // On BROWSE_DISCOUNTS click
    BROWSE_DISCOUNTS_CLICKED: 'browse_discounts_click',
    // How many users click BOOST_PRODUCTS button?
    // On BOOST_PRODUCTS click
    BOOST_PRODUCTS_CLICKED: 'boost_products_click',
    // How many users click FREQUENT_ASKED_QUESTIONS button?
    // On FREQUENT_ASKED_QUESTIONS click
    FREQUENT_ASKED_QUESTIONS_CLICKED: 'frequent_asked_questions_click',
  },
  OUR_MISSION_FLOW: {
    // How many users open /our-mission page?
    // On /our-mission page load
    OUR_MISSION_FLOW_PAGEVIEW: 'our_mission_page_load',
    // How many users click OUR_MISSION_VIDEO_PLAYED video?
    // On OUR_MISSION_VIDEO_PLAYED click
    OUR_MISSION_VIDEO_PLAYED: 'our_mission_video_play',
    // How many users click TRY_NATURAL_PRODUCTS_CLICKED button?
    // On TRY_NATURAL_PRODUCTS_CLICKED click
    TRY_NATURAL_PRODUCTS_CLICKED: 'try_natural_products_click',
    SOURCES: {
      JOIN_OUR_COMMUNITY: 'join_our_community',
      MAKE_THE_SWITCH: 'make_the_switch',
      ADD_IMPACT: 'add_impact',
    },
    // How many users click JOIN_SOCIALNATURE_CLICKED button?
    // On JOIN_SOCIALNATURE_CLICKED click
    JOIN_SOCIALNATURE_CLICKED: 'join_socialnature_click',
  },
  HOME: {
    // How many users click GET_STARTED button?
    // On GET_STARTED_CLICKED click
    GET_STARTED_CLICKED: 'get_started_clicked',
    // How many users click VIEW_OFFERS button?
    // On VIEW_OFFERS_CLICKED click
    VIEW_OFFERS_CLICKED: 'view_offers_clicked',
    // How many users click HOW_IT_WORKS button?
    // On HOW_IT_WORKS_CLICKED click
    HOW_IT_WORKS_CLICKED: 'how_it_works_clicked',
    SOURCES: {
      BANNER: 'banner',
      CASHBACK_STEPS: 'cashback_steps',
    },
  },
  PHONE_VALIDATION_FLOW: {
    PHONE_VALIDATION: 'PhoneValidation',
    INITIATED: 'PhoneValidationFlowInitiated',
  },
};

export const REPORT_ISSUE_CONTINUE_OPTIONS = {
  1: TrackingEventType.REPORT_ISSUE_FLOW.CONTINUED_FIRST_QUESTION,
  2: TrackingEventType.REPORT_ISSUE_FLOW.CONTINUED_SECOND_QUESTION,
  3: TrackingEventType.REPORT_ISSUE_FLOW.CONTINUED_THIRD_QUESTION,
};
