import React from 'react';
import { Image } from 'grommet';
import { Box, Card, CardBody, CardContent, Text } from 'socialnature-ui';
import StarRating from '../../Atoms/StarRating';
import { getReviewerName, getAvatarUrl } from '../../utils/reviews';
import { getCookie } from '../../utils/cookie';

const ProductFeaturedReview = ({ featuredReview }) => {
  const { avatar, description, firstName, lastName, rating } = featuredReview;
  const isCookieConsent = getCookie('cookies-marketing') === 'true';

  return (
    <Box className="launch-content" margin={{ bottom: '16px' }}>
      <Card noPadding>
        <CardBody>
          <CardContent>
            <Box direction="row" gap="xsmall" margin={{ bottom: 'small' }}>
              <Box margin={{ bottom: '8px' }}>
                <StarRating rating={rating} />
              </Box>
            </Box>
            <Box overflow="hidden">
              <Text size="small">{`"${description}"`}</Text>
            </Box>
            <Box direction="row" gap="xsmall" align="center" margin={{ top: 'xsmall' }}>
              <Box width="32px" height="32px" round="50%" overflow="hidden">
                <Image
                  fit="cover"
                  src={getAvatarUrl(avatar && avatar.small, isCookieConsent)}
                  fallback="/images/default-user.png"
                />
              </Box>
              <Text size="small">{getReviewerName(firstName, lastName)}</Text>
            </Box>
          </CardContent>
        </CardBody>
      </Card>
    </Box>
  );
};

export default ProductFeaturedReview;
