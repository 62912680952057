import React, { useState } from 'react';
import styled from 'styled-components';
import { Text, UploadPicture } from 'socialnature-ui';
import { handleError } from '../../../utils/error';

const UploadAvatarButton = styled.label`
  span:hover {
    text-decoration: underline;
  }
  input[type='file'] {
    display: none;
  }
`;

const UploadAvatar = ({ setAvatar }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const updateAvatarOnDB = async (url) => {
    const storedUrl = await fetch('/profile/photo/cloudinary', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
      body: `url=${url}`,
    })
      .then((res) => res.json())
      .then((data) => data.large)
      .catch((err) => {
        handleError(err);
        setError('Failed to upload a image. Try again later.');
      });

    return storedUrl;
  };

  const saveAvatar = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    setLoading(true);
    const cloudName = process.env.CLOUDINARY_CLOUD_NAME;
    const uploadPreset = process.env.CLOUDINARY_UPLOAD_PRESET;
    const api = `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`;
    const fd = new FormData();
    fd.append('file', file);
    fd.append('upload_preset', uploadPreset);

    await fetch(api, {
      method: 'POST',
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      body: fd,
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw new Error('Failed to upload a image');
      })
      .then((data) => data.secure_url)
      .then(async (url) => {
        const storedUrl = await updateAvatarOnDB(url);
        if (storedUrl) {
          setAvatar(storedUrl);
        }
      })
      .catch((err) => {
        handleError(err);
        setError('Failed to upload a image. Try again later.');
      });

    setLoading(false);
  };

  return (
    <>
      <UploadAvatarButton htmlFor="upload-avatar">
        <Text
          as="span"
          weight="bold"
          color="blue"
          icon={
            loading ? <i className="fas fa-circle-notch spinner" /> : <UploadPicture color="blue" />
          }
        >
          Upload profile picture
        </Text>
        <input
          id="upload-avatar"
          type="file"
          name="file"
          accept="'image/*'"
          tabIndex="-1"
          onChange={saveAvatar}
        />
      </UploadAvatarButton>
      {error && (
        <Text color="red" size="small">
          {error}
        </Text>
      )}
    </>
  );
};

export default UploadAvatar;
