import React from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import Spinner from '../Atoms/Icons/Spinner';

const Loading = ({ label }) => (
  <Wrapper>
    <Box background={{ color: 'black', opacity: 'strong' }} align="center" justify="center" fill>
      <Spinner />
      {label && <Label>{label}</Label>}
    </Box>
  </Wrapper>
);

export default Loading;

const Wrapper = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Label = styled.div`
  text-align: center;
  margin: 20px 24px 0;
`;
