export const canadaTimezones = [
  'America/Cambridge_Bay',
  'America/Edmonton',
  'America/Halifax',
  'America/Iqaluit',
  'America/Moncton',
  'America/Nipigon',
  'America/Pangnirtung',
  'America/Rainy_River',
  'America/Rankin_Inlet',
  'America/Regina',
  'America/Resolute',
  'America/St_Johns',
  'America/Swift_Current',
  'America/Thunder_Bay',
  'America/Toronto',
  'America/Vancouver',
  'America/Whitehorse',
  'America/Winnipeg',
  'America/Yellowknife',
];
