import { handleError } from '../error';
import { campaignTypes } from '../campaignTypes';
import {
  ACTIVATE_AND_SHOP,
  CAPTURE_INFORMATION,
  GET_CASH_BACK_SENT,
  GET_CASH_BACK_VIA_EMAIL,
  LONG_RECEIPTS,
  PHOTO_QUALITY,
  SHARE_YOUR_PRODUCT_FEEDBACK,
  SHOP_AT_PARTICIPANT_STORE,
  SUBMIT_PURCHASE,
  SUBMIT_RECEIPT,
} from './sets/rebate';

export const views = {
  PRETRIAL: 'PRETRIAL',
  CONGRATS: 'CONGRATS',
  UPLOAD: 'UPLOAD',
  RECEIPT_UPLOADED: 'RECEIPT_UPLOADED',
  REVIEW_WRITTEN: 'REVIEW_WRITTEN',
};

export const rebateStepSets = {
  [views.PRETRIAL]: [
    ACTIVATE_AND_SHOP,
    SUBMIT_RECEIPT,
    GET_CASH_BACK_VIA_EMAIL,
  ],
  [views.CONGRATS]: [
    SHOP_AT_PARTICIPANT_STORE,
    SUBMIT_PURCHASE,
    GET_CASH_BACK_VIA_EMAIL,
    SHARE_YOUR_PRODUCT_FEEDBACK,
  ],
  [views.UPLOAD]: [CAPTURE_INFORMATION, PHOTO_QUALITY, LONG_RECEIPTS, GET_CASH_BACK_VIA_EMAIL],
  [views.RECEIPT_UPLOADED]: [GET_CASH_BACK_VIA_EMAIL, SHARE_YOUR_PRODUCT_FEEDBACK],
  [views.REVIEW_WRITTEN]: [GET_CASH_BACK_SENT],
};

export function getInstructionSteps(campaingType, view) {
  if (campaingType === campaignTypes.REBATE) {
    const steps = rebateStepSets[view];

    if (steps) {
      return steps;
    }

    handleError(`There is not a set of instructions for ${campaingType} in ${view} view`);

    return null;
  }

  handleError(`There are not a set of instructions for ${campaingType}`);

  return null;
}

export const shouldShowInstructions = (campaingType) => campaingType === campaignTypes.REBATE;
