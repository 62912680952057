import React, { forwardRef } from 'react';
import { Text, themes } from 'socialnature-ui';
import styled from 'styled-components';
import Cross from '../../Atoms/Icons/Cross';
import { StyledInput } from '../../Atoms/Inputs/StyledInput';

const StyledInputContainer = styled.div`
  position: relative;
`;

const StyledClearButton = styled(Cross)`
  right: 3.6rem;
  position: absolute;
  top: 0.5rem;
  fill: ${themes.global.colors.silver};
  &:hover {
    cursor: pointer;
  }
`;

const StyledVerticalLine = styled.div`
  border-left: 1px solid ${themes.global.colors.silver};
  position: absolute;
  right: 3.1rem;
  top: 0.5rem;
  margin-left: -3px;
  height: 1.7rem;
`;

const StyledSearchButton = styled.i`
  position: absolute;
  font-size: 1.3rem;
  top: .68rem;
  right: 1rem;
  color: ${themes.global.colors.brand}
  &:hover {
    cursor: pointer;
  }
`;

export const SearchField = forwardRef(
  (
    {
      label,
      placeholder,
      a11yTitle,
      showErrorIcon,
      errorText,
      handleOnChange,
      handleOnClear,
      handleOnSearch,
      inputName,
      autoFocus,
      className,
    },
    ref,
  ) => (
    <>
      {label && (
        <Text color={themes.global.colors.slate} size="12px" margin={{ bottom: '4px' }}>
          {label}
        </Text>
      )}
      <StyledInputContainer>
        <StyledInput
          className={className}
          placeholder={placeholder}
          a11yTitle={a11yTitle}
          name={inputName}
          autoFocus={autoFocus}
          errorText={errorText}
          error
          ref={ref}
          showErrorIcon={showErrorIcon}
          handleOnChange={handleOnChange}
          height="40px"
        />
        <StyledClearButton onClick={handleOnClear} />
        <StyledVerticalLine />
        <StyledSearchButton className="fa fa-search" onClick={handleOnSearch} />
      </StyledInputContainer>
    </>
  ),
);
