/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from 'react';
import TextLink from '../../Atoms/TextLink';
import ArrowUp from '../../Atoms/Icons/ArrowUp';
import ArrowDown from '../../Atoms/Icons/ArrowDown';

const IngredientsMaxHeight = 1000;

const Ingredients = ({ ingredients }) => {
  const container = useRef(null);

  const [collapse, setCollapse] = useState(false);
  const [height, setHeight] = useState(null);
  const [message, setMessage] = useState('');

  const handleExpand = () => {
    setCollapse(!collapse);
  };

  useEffect(() => {
    if (collapse) {
      setMessage('See more ingredients');
    } else {
      setMessage('See less');
    }
  }, [collapse]);

  useEffect(() => {
    setHeight(container.current.offsetHeight);
    if (height > IngredientsMaxHeight) {
      setCollapse(true);
    } else {
      setCollapse(false);
    }
  }, [height]);

  return (
    <div>
      <a id="ingredients" className="anchor" />

      <h2>Ingredients</h2>
      <div
        ref={container}
        style={
          collapse
            ? { maxHeight: `${IngredientsMaxHeight}px`, overflowY: 'clip' }
            : { maxHeight: '100%', overflowY: 'visible' }
        }
        dangerouslySetInnerHTML={{
          __html: ingredients,
        }}
      />
      {height && height > IngredientsMaxHeight && (
        <TextLink
          onClick={handleExpand}
          label={message}
          right
          icon={
            collapse ? (
              <ArrowDown width="30" height="30" color="launchpad" />
            ) : (
              <ArrowUp width="30" height="30" color="launchpad" />
            )
          }
        />
      )}
    </div>
  );
};

export default Ingredients;
