import React, { useMemo } from 'react';
import styled from 'styled-components';

/**
 * @param {string} name
 * @param {boolean} isXsmall
 * @param {number} left
 * @returns {JSX.Element}
 */

const ContainerIcon = styled.img`
  position: relative;
  border: 2px solid #ffffff;
  border-radius: 15px;
  flex-shrink: 0;
  width: ${({ isXsmall }) => (isXsmall ? '26px' : '32px')};
  height: ${({ isXsmall }) => (isXsmall ? '26px' : '32px')};
  left: ${({ left }) => (left ? `${left}px` : 'unset')};
`;

export function RetailerIcon({ name, isXsmall, left = 0 }) {
 

  return (
    <ContainerIcon
      alt={`${name} logo`}
      isXsmall={isXsmall}
      left={left}
      src={name}
    />
  );
}
