import React from 'react';
import { useHistory, useRouteMatch, useParams, useLocation } from 'react-router-dom';
import TextLink from '../../Atoms/Buttons/TextButton';
import Cross from '../../Atoms/Icons/Cross';

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

const FilterClearButton = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { qualitySlug } = useParams();
  const query = useQueryParams();

  const getUrl = () => {
    const baseUrl = qualitySlug ? '/search' : url;
    if (query.has('country')) {
      return `${baseUrl}?country=${query.get('country')}`; // country is always one so using get method
    }
    return baseUrl;
  };

  const handleClick = () => {
    if (qualitySlug) {
      window.location.href = getUrl();
    } else {
      history.push(getUrl());
    }
  };

  return (
    <TextLink
      icon={<Cross width="16" height="16" color="launchpad" />}
      label="Clear Filters"
      onClick={handleClick}
    />
  );
};

export default FilterClearButton;
