import React from 'react';
import styled from 'styled-components';
import basicTheme from '../../../themes';

const StyledLink = styled.a`
  color: ${({ color }) => color || basicTheme.global.colors.blue};
  font-weight: ${({ fontWeight }) => fontWeight || '400'};
  text-decoration: underline;

  &:hover {
    color: ${({ hoverColor }) => hoverColor || basicTheme.global.colors.blue};
  }
`;

export default function BasicLink({ styles, label, onClick }) {
  return (
    <StyledLink {...styles} onClick={onClick}>
      {label}
    </StyledLink>
  );
}
