import styled from 'styled-components';
import { rowWidth, rowWidthMobile } from '../themes';

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => (props.mobile ? rowWidthMobile : rowWidth)};
  margin: 0 auto;
  padding: 0 16px;
`;

export default Container;
