import React from 'react';
import { Box, Image } from 'grommet';
import Card from '../../Atoms/Card/Card';
import CardBody from '../../Atoms/Card/CardBody';
import CardContent from '../../Atoms/Card/CardContent';
import CardAction from '../../Atoms/Card/CardAction';
import StarRating from '../../Atoms/StarRating';
import P from '../../Atoms/Paragraph';
import { getTimeAgo } from '../../utils/formatDate';
import ArrowRight from '../../Atoms/Icons/ArrowRight';
import { getReviewerName, getAvatarUrl } from '../../utils/reviews';
import { getCookie } from '../../utils/cookie';

const ReviewCard = ({ review }) => {
  const { user, product, rating, createdAt, description, title } = review;
  const { avatar, firstName, lastName } = user;
  const { featuredImage, name: productName, slug: productSlug } = product;
  const isCookieConsent = getCookie('cookies-marketing') === 'true';

  return (
    <>
      <Card>
        <CardAction href={`/${productSlug}#reviews`}>
          <CardBody>
            <CardContent>
              <Box direction="row" gap="xsmall" margin={{ bottom: 'small' }}>
                <Box
                  border={{ color: 'smoke', size: 'xsmall' }}
                  width={{ min: '53px', max: '53px' }}
                  height="53px"
                  pad="4px"
                >
                  <Image fit="cover" src={featuredImage} />
                </Box>
                <Box>
                  <Box margin={{ bottom: '8px' }} data-cy="review-rating">
                    <StarRating rating={rating} />
                  </Box>
                  <Box direction="row" align="center" data-cy="review-product-name">
                    <P color="launchpad">{productName}</P>
                    <Box pad={{ left: '4px' }}>
                      <ArrowRight color="launchpad" width="12" height="12" />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box overflow="hidden">
                <P>
                  <b data-cy="review-title">{title}</b>
                </P>
                <br />
                <P dataCy="review-description">{`"${description}"`}</P>
              </Box>
              <Box direction="row" gap="xsmall" align="center" margin={{ top: 'xsmall' }}>
                <Box
                  width="32px"
                  height="32px"
                  round="50%"
                  overflow="hidden"
                  data-cy="review-avatar"
                >
                  <Image
                    fit="cover"
                    src={getAvatarUrl(avatar, isCookieConsent)}
                    fallback="/images/default-user.png"
                  />
                </Box>
                <P>{getReviewerName(firstName, lastName)}</P>
              </Box>
            </CardContent>
          </CardBody>
        </CardAction>
      </Card>
      <Box margin={{ horizontal: 'card' }}>
        <P color="iron">
          <span>Reviewed </span>
          <time dateTime={createdAt}>{getTimeAgo(createdAt)}</time>
        </P>
      </Box>
    </>
  );
};

export default ReviewCard;
