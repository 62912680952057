import styled from 'styled-components';

const ReactPlayerContainer = styled.div`
  width: 100%;
  height: 0;
  position: relative;
  padding-bottom: 56.25%; // aspect: 16:9 = 9/16 * 100%
  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
`;

export default ReactPlayerContainer;
