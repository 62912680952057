import React from 'react';
import styled from 'styled-components';
import theme from '../themes';

const StyledSections = styled.div`
  .section-bg-gray {
    &:nth-of-type(3n + 1) {
      background: ${theme.global.colors.cloud};
    }
    &:nth-of-type(3n + 2) {
      background: ${theme.global.colors.white};
    }
    &:nth-of-type(3n) {
      background: ${theme.global.colors.smoke};
    }
  }
`;

const Sections = ({ children, ...props }) => <StyledSections {...props}>{children}</StyledSections>;

export default Sections;
