import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  ArrowRight,
  Box,
  Card,
  CardContent,
  Container,
  Deliver,
  Discount,
  Heading,
  PromoCode,
  SecondaryButton,
  Text,
  TextLink,
  themes as uiTheme,
} from 'socialnature-ui';
import { Grommet, ResponsiveContext } from 'grommet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Time from '../../Atoms/Icons/Time';
import { formatDate } from '../../utils/formatDate';
import BackgroundImageLeftUrl from '../../images/AcceptedPage/promocode-bg-left.svg';
import BackgroundImageRightUrl from '../../images/AcceptedPage/promocode-bg-right.svg';
import HowToRedeemModal from '../../Organisms/Product/HowToRedeemModal';

const CardBackground = styled.div`
  position: relative;
  &::before,
  &::after {
    content: '';
    display: ${(props) => (props.isXSmall ? 'none' : 'flex')};
    height: 100%;
    background-repeat: no-repeat;
    background-position-y: 30px;
    position: absolute;
    top: 0;
    z-index: -1;
  }
  &::before {
    background-image: url(${BackgroundImageLeftUrl});
    left: -110px;
    width: 220px;
  }
  &::after {
    background-image: url(${BackgroundImageRightUrl});
    right: -116px;
    width: 216px;
  }
`;

const ShippingInfoText = styled(Text)`
  display: flex;
  flex: 1;
`;
// TODO: Remove the component when don't use site.css. It's only for overriding site.css
const PromoCodeWrapper = styled.div`
  input:not([type])[readonly] {
    margin: 0;
    background-color: #ffffff;
    box-shadow: none;
    height: 40px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 17px;
    padding-left: 16px;
    border: 1px dashed #cccccc;
    border-right: none;
  }
  button {
    padding: 8px 16px;
    flex-shrink: 0;
  }
  label {
    font-weight: normal;
    cursor: default;
  }
`;

const StyledSecondaryButton = styled(SecondaryButton)`
  &:focus {
    background: #fff;
    border-color: ${uiTheme.global.colors.pink};
    color: ${uiTheme.global.colors.pink};
  }
`;

const DtcSection = (props) => {
  const {
    product,
    promoCode,
    productUrl,
    promoExpiry,
    shippingInfo,
    buttonText,
    channelName,
    redeemCode,
  } = props;
  const { id: productId, slug: productSlug } = product || {};
  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const size = useContext(ResponsiveContext);
  const isXSmall = size === 'xsmall';

  return (
    <Grommet theme={uiTheme}>
      <>
        <Box pad={{ vertical: 'xxlarge' }}>
          <Container>
            <Box width={{ max: '414px' }} margin={{ horizontal: 'auto' }}>
              <Heading
                level="3"
                size="3"
                textAlign={isXSmall ? 'start' : 'center'}
                margin={{ bottom: '16px' }}
              >
                Redeem your free trial:
              </Heading>
              <CardBackground isXSmall={isXSmall}>
                <Card noPadding>
                  <CardContent
                    pad={{ vertical: 'medium', horizontal: isXSmall ? 'medium' : 'xlarge' }}
                  >
                    <Box margin={{ bottom: 'xsmall' }}>
                      <CopyToClipboard text={promoCode} onCopy={() => setCopied(true)}>
                        <PromoCodeWrapper>
                          <PromoCode
                            value={promoCode}
                            copied={copied}
                            data-track-samplekit-id={productId || null}
                            data-track-category="Congrats-DTC"
                            data-track-action="Copy Sampling Promo Code"
                            data-track-label={productSlug}
                          />
                        </PromoCodeWrapper>
                      </CopyToClipboard>
                    </Box>
                    {shippingInfo && (
                      <Box direction="row" align="center" gap="8px" margin={{ bottom: 'xsmall' }}>
                        <Deliver />
                        <ShippingInfoText size="small" textAlign="start">
                          {shippingInfo}
                        </ShippingInfoText>
                      </Box>
                    )}
                    <Box direction="row" align="center" gap="8px" margin={{ bottom: 'medium' }}>
                      <Discount isOutline color="black" />
                      <ShippingInfoText size="small" textAlign="start">
                        Promo code is one-time use only
                      </ShippingInfoText>
                    </Box>
                    <StyledSecondaryButton
                      label={buttonText}
                      margin={{ bottom: 'small' }}
                      data-track-samplekit-id={productId}
                      data-track-category="Congrats-DTC"
                      data-track-action="Redeem Sampling Promo Code"
                      data-track-label={productSlug}
                      onClick={() => {
                        window.open(productUrl, '_blank').opener = null;
                        redeemCode();
                      }}
                    />
                    <Box direction="row" justify="center" gap="xxsmall">
                      <Time width="20" height="20" color="slate" />
                      <Text color="slate">{`Offer expires ${formatDate(promoExpiry, true)}`}</Text>
                    </Box>
                  </CardContent>
                </Card>
              </CardBackground>
              <Box margin={{ vertical: 'small' }} alignContent="center">
                <Text weight="bold" textAlign="center">
                  {"Don't know how to redeem?"}
                </Text>
                <TextLink
                  icon={<ArrowRight width="16" height="16" color="launchpad" />}
                  iconRight
                  label="Read instructions"
                  fontWeight="bold"
                  alignSelf="center"
                  onClick={() => setShowModal(true)}
                />
              </Box>
            </Box>
          </Container>
        </Box>
        {showModal && (
          <HowToRedeemModal
            open={showModal}
            onClose={() => setShowModal(false)}
            channelName={channelName}
            promoCode={promoCode}
            productUrl={productUrl}
            buttonText={buttonText}
          />
        )}
      </>
    </Grommet>
  );
};

export default DtcSection;
