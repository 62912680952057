// Anchor on Grommet doesn't set up gap between icon and text.
// If an anchor has an icon, please use this component.
import React from 'react';
import styled from 'styled-components';
import { Anchor } from 'grommet';

const TextLink = ({ fontWeight, icon, color, label, href, right, onClick }) => {
  if (!icon) {
    return <Anchor label={label} href={href} color={color} />;
  }
  return (
    <Anchor
      label={
        <LabelWrapper>
          {!right && icon}
          <Label right={right || false} fontWeight={fontWeight || undefined}>
            {label}
          </Label>
          {right && icon}
        </LabelWrapper>
      }
      color={color}
      href={href}
      onClick={onClick}
    />
  );
};

export default TextLink;

const Label = styled.span`
  margin: ${(props) => (props.right ? '0 8px 0 0' : '0 0 0 8px')};
  font-weight: ${(props) => props.fontWeight && 'bold'};
`;

const LabelWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
`;
