// @ts-check
import React from 'react';
import { useModal } from '../useModal';
import { Coupon } from '../../Organisms/Product/Coupon';

/**
 * @typedef {{
 * popupEnabled: boolean,
 * popupDiscountCode?: string,
 * image: string,
 * popupMessage: string,
 * popupUrl: string,
 * }} CouponInfo
 * @param {CouponInfo} coupon
 * @param {number} productId
 * @param {string} productSlug
 * @param {boolean} initOpen
 * @param {string=} dataTrackCategory
 * @returns
 */
export function useCouponModal(coupon, productId, productSlug, initOpen, dataTrackCategory) {
  const { Modal, open, close } = useModal(initOpen);
  const { popupEnabled } = coupon || {};
  if (!popupEnabled) {
    return { CouponModal: () => <></>, open: null, close: null };
  }

  const CouponModal = () => (
    <Modal>
      <div className="coupon_modal__wrapper">
        <div className="coupon_modal__content">
          <Coupon
            coupon={coupon}
            productId={productId}
            productSlug={productSlug}
            dataTrackCategory={dataTrackCategory}
          />
        </div>
      </div>
    </Modal>
  );

  return { CouponModal, open, close };
}
