// @ts-check
/**
 * @type { import("./types").CTATypesObject }
 */
export const CTATypes = {
  WANT: 'want',
  BOOST: 'boost',
  BANNER: 'banner',
  WHERE_TO_BUY: 'where to buy',
  SIGN_UP: 'sign up',
  DISABLED: 'disabled',
  APPLIED: 'applied',
  ACTIVATE_OFFER: 'activate offer',
  OFFER_ACTIVATED: 'offer activated',
  REACTIVATE_OFFER: 'reactivate offer',
  ADD_A_RECEIPT: 'add a receipt',
  OFFER_REDEEMED: 'offer redeemed',
  SIGNUP_TO_ACTIVATE: 'signup to activate',
  SIGNUP_FOR_UPDATES: 'signup for updates',
};
