import React from 'react';
import theme from '../../themes/Grommet';

export default function createSvgIcon(path, displayName) {
  const Component = React.memo(
    React.forwardRef((props, ref) => (
      <SvgIcon data-icon-name={`${displayName}Icon`} ref={ref} {...props}>
        {path}
      </SvgIcon>
    )),
  );

  return Component;
}

const SvgIcon = React.forwardRef((props, ref) => {
  const {
    children,
    color,
    htmlColor,
    isOutline,
    titleAccess,
    width = '24',
    height = '24',
    viewBox = '0 0 24 24',
    ...other
  } = props;

  const iconColor = theme.global.colors[color] || htmlColor || 'inherit';

  return (
    <svg
      focusable="false"
      viewBox={viewBox}
      width={width}
      height={height}
      fill={!isOutline && iconColor}
      stroke={isOutline && iconColor}
      aria-hidden={titleAccess ? null : 'true'}
      role={titleAccess ? 'img' : 'presentation'}
      ref={ref}
      {...other}
      xmlns="http://www.w3.org/2000/svg"
    >
      {children}
      {titleAccess ? <title>{titleAccess}</title> : null}
    </svg>
  );
});
