import React from 'react';
import styled from 'styled-components';
import { useAvailableDiscounts } from '../../hooks/useAvailableDiscounts';
import LoadingSection from '../../Molecules/LoadingSection';
import DiscountsSection from '../../Organisms/DiscountsSection';

const Container = styled.div`
  padding: ${({ isXsmall }) => (isXsmall ? '32px 16px 0px 16px' : '56px 16px 16px 16px')};
`;

const BonusOffersSection = ({ wantId, isXsmall }) => {
  const { isLoading, discounts } = useAvailableDiscounts(wantId);
  if (isLoading) {
    return <LoadingSection />;
  }
  if (!discounts || !discounts.length) {
    return <></>;
  }

  return (
    <Container isXsmall={isXsmall}>
      <DiscountsSection discounts={discounts} disableBackground disableDivider />
    </Container>
  );
};

export default BonusOffersSection;
