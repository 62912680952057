import React from 'react';

const Filter = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#5D5D5D"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M4 8.77778V10.5556H12.8889V8.77778H4ZM7.55556 12.3333V14.1111H4V15.8889H7.55556V17.6667H9.33333V12.3333H7.55556ZM20 15.8889V14.1111H11.1111V15.8889H20ZM14.6667 12.3333H16.4444V10.5556H20V8.77778H16.4444V7H14.6667V12.3333Z" />
  </svg>
);

export default Filter;
