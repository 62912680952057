// @ts-check
// TODO: need to refactor. this hook is only for the product page
import { useState, useMemo, useEffect, useCallback } from 'react';
import { showBanner } from '../../utils/product/showBanner';
import { getProductCTAType, getCallToAction } from '../../utils/product/index';
import { SELECT_THIS_STORE } from '../../utils/campaignTypes';
import { useProductPage } from '../../contexts/ProductPageContext/useProductPage';
import { receiptValidationStatusEnum } from '../../utils/rebates';

export function useProductCTA(
  data,
  geoInfo,
  showStoreLocatorFlow,
  { openGeoModal, openCouponModal, openStoreLocationModal, isPrequalifyCheckPassed, useNewActivationFlow, guestSessionID },
) {
  const {
    campaignRebateData: {
      rebate: {
        status: { validationStatus },
      },
      campaignProgress: { progressValue: campaignProgressValue },
    },
  } = useProductPage();

  const offerRedeemed = validationStatus === receiptValidationStatusEnum.SUCCESS;
  const { userId, product, campaign, userWant } = data || {};
  const { id: wantId } = userWant || {};
  const { id, slug, name, coupon, whereToBuy } = product || {};
  const { campaignType, isOfferExpired } = campaign || {};

  const [CTAType, setCTAType] = useState(null);
  const [CTAOptions, setCTAOptions] = useState({
    bannerMessage: null,
    openBannerPopup: null,
    bannerUrl: null,
    whereToBuy: null,
    openGeoModal: null,
    showStoreLocatorFlow: null,
    openStoreLocationModal: null,
  });

  const productCTA = useMemo(() => {
    if (!data) return null;
    return getCallToAction(
      CTAType,
      !!userId,
      geoInfo.location,
      id,
      slug,
      name,
      CTAOptions,
      showStoreLocatorFlow,
      wantId,
      useNewActivationFlow,
      guestSessionID
    );
  }, [
    CTAOptions,
    CTAType,
    data,
    geoInfo.location,
    id,
    slug,
    userId,
    showStoreLocatorFlow,
    wantId,
    name,
  ]);

  const openGeoModalFn = useCallback(() => {
    const successUri = `/sign-in?ReturnUrl=/${slug}/want-boost&productId=${id}&snsource=apply_to_try&geo=nearby`;

    openGeoModal(geoInfo.location, successUri);
  }, [geoInfo.location, id, openGeoModal, slug]);

  useEffect(() => {
    if (data) {
      const { bannerMessage, bannerUrl } = coupon || {};
      setCTAOptions({
        bannerMessage,
        openBannerPopup: openCouponModal,
        bannerUrl,
        whereToBuy,
        openGeoModal: openGeoModalFn,
        showStoreLocatorFlow,
        openStoreLocationModal: () => openStoreLocationModal(SELECT_THIS_STORE),
      });
    }
  }, [
    coupon,
    data,
    openCouponModal,
    openGeoModal,
    openGeoModalFn,
    openStoreLocationModal,
    product,
    showStoreLocatorFlow,
    whereToBuy,
    geoInfo.location,
  ]);

  const getCTAInfo = useCallback(async () => {
    if (data) {
      const { details } = product || {};
      const { isLaunchpad, wantDisabled, hasWanted, hasBeenAccepted } = details || {};
      const { campaign: wantCampaign } = userWant || {};
      const isUserWaitlisted = !wantCampaign;

      const isValidBanner = showBanner(coupon);

      const cta = await getProductCTAType({
        isLoggedIn: !!userId,
        isLaunchpad,
        wantDisabled: wantDisabled,
        hasWanted,
        isValidBanner,
        whereToBuyUrl: whereToBuy,
        hasBeenAccepted,
        hasCampaignsOpen: !wantDisabled && product.campaigns.length > 0,
        campaignType,
        offerRedeemed,
        isUserWaitlisted,
        campaignProgress: campaignProgressValue,
        isOfferExpired,
        isPrequalifyCheckPassed,
      });

      setCTAType(cta);
    }
  }, [
    campaignType,
    coupon,
    data,
    product,
    userId,
    whereToBuy,
    userWant,
    offerRedeemed,
    geoInfo,
    campaignProgressValue,
    isOfferExpired,
    isPrequalifyCheckPassed,
  ]);

  useEffect(() => {
    getCTAInfo();
  }, [
    coupon,
    data,
    geoInfo.isNearby,
    geoInfo.location,
    product,
    userId,
    whereToBuy,
    campaignType,
    getCTAInfo,
  ]);

  const productCTAProps = useMemo(() => {
    const { details } = product || {};
    const { isLaunchpad } = details || {};
    return {
      ...productCTA,
      CTAType,
      color: isLaunchpad ? 'blue' : 'pink',
      productId: id,
      productSlug: slug,
      disabled: validationStatus === receiptValidationStatusEnum.MANUAL_CHECK || offerRedeemed,
    };
  }, [CTAType, id, product, productCTA, slug, validationStatus, offerRedeemed]);

  return {
    CTAType,
    productCTAProps,
  };
}
