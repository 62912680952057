import React, { useMemo } from 'react';
import { ResponsiveContext } from 'grommet';
import { TextLink } from 'socialnature-ui';
import { CarouselProvider } from 'pure-react-carousel';
import H from '../../Atoms/Heading';
import HeadingDescription from '../../Molecules/HeadingDescription';
import FreeTrialCard from '../../Pages/FreeTrialPage/FreeTrialCard';
import Carousel from './Carousel';
import { getProductNavigation } from '../../utils/app';

const FreeTrialList = ({
  userId,
  title: titleProp,
  description: descriptionProp,
  products,
  dataTrackCategory,
  isSNUser = true
}) => {
  const [heightCard, setHeight] = React.useState(0);
  const [widthCard, setWidth] = React.useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const title = useMemo(() => {
    if (titleProp) {
      return titleProp;
    }
    
    if (userId) {
      return isSNUser
        ? 'You pre-qualify to try these free samples'  // SN user and with userId
        : 'Try these products available near you';  // non-SN user and with userId
    }
  
    return 'Try better-for-you products';  // If no userId exists
  }, [titleProp, userId, isSNUser]);

  const description = useMemo(() => {
    if (typeof descriptionProp !== 'undefined') return descriptionProp;
    return userId ? (
      <>
        <span>Products are matched based on your </span>
        <TextLink href="/account/profile" label="profile preferences" fontWeight="bold" />
        <span> and </span>
        <TextLink href="/account/address" label="location" fontWeight="bold" />
        <span>.</span>
      </>
    ) : null;
  }, [descriptionProp, userId]);

  const size = React.useContext(ResponsiveContext);
  const slides = {
    xsmall: 2,
    small: 2,
    medium: 3,
    large: 4,
  };

  if (!products) return <></>;

  return (
    <>
      <H level={2}>{title}</H>
      {isSNUser && description && <HeadingDescription fontSize="medium">{description}</HeadingDescription>}
      <CarouselProvider
        naturalSlideWidth={Math.round(widthCard)}
        naturalSlideHeight={Math.round(heightCard) + 32}
        totalSlides={products.length}
        lockOnWindowScroll
        step={slides[size]}
        visibleSlides={slides[size]}
      >
        <Carousel
          products={products}
          size={size}
          measuredRef={measuredRef}
          renderSlide={(product, index) => (
            <FreeTrialCard
              product={product}
              navigationLink={getProductNavigation(product)}
              showCountry={!userId}
              width="100%"
              height="100%"
              dataTrackCategory={dataTrackCategory}
              index={index}
            />
          )}
        />
      </CarouselProvider>
    </>
  );
};

export default FreeTrialList;
