import React from 'react';
import { ResponsiveContext } from 'grommet';
import P from '../Atoms/Paragraph';

const HeadingDescription = ({ children, textAlign, fontSize: fontSizeProp }) => {
  const size = React.useContext(ResponsiveContext);
  const fontSize = fontSizeProp || (size === 'large' || size === 'medium' ? 'large' : 'medium');
  return (
    <P size={fontSize} textAlign={textAlign || 'start'}>
      {children}
    </P>
  );
};

export default HeadingDescription;
