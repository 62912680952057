import React from 'react';
import { Heading, ThemeContext } from 'grommet';

const H = ({
  level,
  size,
  color,
  margin,
  textAlign,
  transform,
  className,
  style = undefined,
  children,
}) => (
  <ThemeContext.Extend
    value={{
      heading: {
        extend: () => transform && `text-transform: ${transform}`,
      },
    }}
  >
    <Heading
      level={level}
      size={size}
      color={color || 'black'}
      margin={margin}
      textAlign={textAlign || 'start'}
      className={className}
      style={style}
    >
      {children}
    </Heading>
  </ThemeContext.Extend>
);

export default H;
