import React from 'react';
import styled from 'styled-components';
import { Text, themes as uiThemes } from 'socialnature-ui';

const colors = {
  success: {
    background: uiThemes.global.colors.green,
    color: uiThemes.global.colors.white,
  },
  error: {
    background: uiThemes.global.colors.red,
    color: uiThemes.global.colors.white,
  },
};

const Message = styled(Text)`
  background: ${(props) => props.background};
  color: ${(props) => props.color};
  padding: 4px 1em;
`;

const Alert = ({ severity, children }) => {
  const { background = uiThemes.global.colors.green, color = uiThemes.global.colors.white } =
    colors[severity];
  return (
    <Message background={background} color={color}>
      {children}
    </Message>
  );
};

export default Alert;
