const retailerIconsEnum = {
  default: `/images/retailers/default.png`,
  more: `/images/retailers/more.svg`,
  morepdf: `/images/retailers/more.png`,
  wholefoodsmarket: `/images/retailers/wholefoodsmarket.png`,
  wholefoods: `/images/retailers/wholefoodsmarket.png`,
  wfm: `/images/retailers/wholefoodsmarket.png`,
  walmart: `/images/retailers/walmart.png`,
  kroger: `/images/retailers/kroger.png`,
  target: `/images/retailers/target.png`,
  sprouts: `/images/retailers/sproutsfarmersmarket.png`,
  publix: `/images/retailers/publix.png`,
  safeway: `/images/retailers/safeway.png`,
  heb: `/images/retailers/heb.png`,
  ultabeauty: `/images/retailers/ultabeauty.png`,
  costco: `/images/retailers/costco.png`,
  sephora: `/images/retailers/sephora.png`,
  wegmans: `/images/retailers/wegmans.png`,
  albertsons: `/images/retailers/albertsons.png`,
  samsclub: `/images/retailers/samsclub.png`,
  '7eleven': `/images/retailers/7eleven.png`,
  '711': `/images/retailers/7eleven.png`,
  seveneleven: `/images/retailers/7eleven.png`,
  harristeeter: `/images/retailers/harristeeter.png`,
  petsmart: `/images/retailers/petsmart.png`,
  centralmarket: `/images/retailers/centralmarket.png`,
  erewhonmarket: `/images/retailers/erewhonmarket.png`,
  fredmeyer: `/images/retailers/fredmeyer.png`,
  giantfood: `/images/retailers/giantfood.png`,
  kingsoopers: `/images/retailers/kingsoopers.png`,
  meijer: `/images/retailers/meijer.png`,
  raleyssupermarkets: `/images/retailers/raleyssupermarkets.png`,
  ralphs: `/images/retailers/ralphs.png`,
  vons: `/images/retailers/vons.png`,
  thefreshmarket: `/images/retailers/thefreshmarket.png`,
  freshmarker: `/images/retailers/thefreshmarket.png`,
  riteaid: `/images/retailers/riteaid.png`,
  stopshop: `/images/retailers/stopshop.png`,
  aldi: `/images/retailers/aldi.png`,
  lowes: `/images/retailers/lowes.png`,
  thehomedepot: `/images/retailers/thehomedepot.png`,
  homedepot: `/images/retailers/thehomedepot.png`,
  gnc: `/images/retailers/gnc.png`,
  foodlion: `/images/retailers/foodlion.png`,
  pavilions: `/images/retailers/pavilions.png`,
  randalls: `/images/retailers/randalls.png`,
  hyvee: `/images/retailers/hyvee.png`,
  bigyfoods: `/images/retailers/bigyfoods.png`,
  petco: `/images/retailers/petco.png`,
  naturalgrocers: `/images/retailers/naturalgrocers.png`,
  pricechopper: `/images/retailers/pricechopper.png`,
  bristolfarms: `/images/retailers/bristolfarms.png`,
  citymarket: `/images/retailers/citymarket.png`,
  fareway: `/images/retailers/fareway.png`,
  food4less: `/images/retailers/food4less.png`,
  fruitfulyield: `/images/retailers/fruitfulyield.png`,
  frys: `/images/retailers/frys.png`,
  harmons: `/images/retailers/harmons.png`,
  keyfoodsupermarkets: `/images/retailers/keyfoodsupermarkets.png`,
  lidlmarkets: `/images/retailers/lidlmarkets.png`,
  marianos: `/images/retailers/marianos.png`,
  metromarket: `/images/retailers/metromarket.png`,
  moms: `/images/retailers/moms.png`,
  mothers: `/images/retailers/mothers.png`,
  newseasons: `/images/retailers/newseasons.png`,
  savemart: `/images/retailers/savemart.png`,
  smiths: `/images/retailers/smiths.png`,
  staterbros: `/images/retailers/staterbros.png`,
  traderjoes: `/images/retailers/traderjoes.png`,
  walgreens: `/images/retailers/walgreens.png`,
  winndixie: `/images/retailers/winndixie.png`,
  gelsons: `/images/retailers/gelsons.png`,
  heinens: `/images/retailers/heinens.png`,
  foodtown: `/images/retailers/foodtown.png`,
  giant: `/images/retailers/giant.png`,
  hannaford: `/images/retailers/hannaford.png`,
  martins: `/images/retailers/martins.png`,
  dillons: `/images/retailers/dillons.png`,
  shoprite: `/images/retailers/shoprite.png`,
  auranaturalmarket: `/images/retailers/auranaturalmarket.png`,
  haggen: `/images/retailers/haggen.png`,
  jewelosco: `/images/retailers/jewelosco.png`,
  nutterscanmore: `/images/retailers/nutterscanmore.png`,
  pommenaturalmarket: `/images/retailers/pommenaturalmarket.png`,
  shaws: `/images/retailers/shaws.png`,
  sproutsfarmersmarket: `/images/retailers/sproutsfarmersmarket.png`,
  starmarket: `/images/retailers/starmarket.png`,
  thriftyfoods: `/images/retailers/thriftyfoods.png`,
  thriftys: `/images/retailers/thriftyfoods.png`,
  tomthumb: `/images/retailers/tomthumb.png`,
};

export default retailerIconsEnum;
