import React from 'react';
import { Box } from 'grommet';
import H from '../../../Atoms/Heading';
import Hr from '../../../Atoms/Hr';
import FilterCategory from './FilterCategory';
import FilterCountry from './FilterCountry';

const FilterDesktop = ({ countryFilter, filters }) => (
  <>
    {countryFilter &&
      countryFilter.map((filter) => (
        <FilterCountry key={filter.name} name={filter.name} categories={filter.filters} />
      ))}
    <Box margin={{ top: 'large' }}>
      <H level={4} transform="uppercase">
        FILTER BY:
      </H>
      <Hr margin="10px 0 0" />
    </Box>
    {filters &&
      filters.map((filter) => (
        <FilterCategory key={filter.name} name={filter.name} categories={filter.filters} />
      ))}
  </>
);

export default FilterDesktop;
