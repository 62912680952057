// @ts-check
import React, { useContext, useRef, useState, useEffect } from 'react';
// @ts-ignore
import { Error, Heading, InputLabel, PrimaryButton, Text } from 'socialnature-ui';
import { Box, ResponsiveContext, TextInput as GrommetTextInput } from 'grommet';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { errorMessages, reverseGeocode, validPostCodePattern } from '../../utils/apis/maps';
import LoadingSection from '../../Molecules/LoadingSection';
import { setProductMatchLocation } from '../../utils/apis/products';
import { TrackingEventType } from '../../utils/tracking';
import { handleError } from '../../utils/error';

const AvailabilityButton = styled(PrimaryButton)`
  line-height: 3px;
`;

// @ts-ignore
const LocationField = styled(GrommetTextInput)`
  border: 1px solid #cccccc;
  border-radius: 4px !important;
  margin: 0 !important;
  text-transform: uppercase;
  font-size: 16px !important;
`;

const LocationSelector = (props) => {
  const {
    defaultLocation,
    onClose: closeModal,
    onGeoCheckRequested: repeatGeoCheck,
    productId,
    successUri,
    isRebate,
  } = props;
  const [isRecheckingNearby, setIsRecheckingNearby] = useState(false);
  const [isCheckingCurrentPosition, setIsCheckingCurrentPosition] = useState(false);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  const { control, errors, handleSubmit, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      Location: defaultLocation,
    },
  });

  useEffect(() => () => setIsRecheckingNearby(false));

  const setPosition = async (position) => {
    const { latitude, longitude } = position.coords;
    if (latitude && longitude) {
      try {
        const location = await reverseGeocode(latitude, longitude);
        if (location && location.success && location.location) {
          setValue('Location', location.location);
        }
        setIsCheckingCurrentPosition(false);
      } catch (ex) {
        handleError(ex);
      }
    }
  };

  const handleFormSubmit = (data) => {
    const location = data.Location;
    if (location) {
      // @ts-ignore
      if (window.dataLayer) {
        // @ts-ignore
        window.dataLayer.push({ event: TrackingEventType.USER_LOCATION_SUBMITTED });
      }
      setProductMatchLocation(location);
      setIsRecheckingNearby(true);
      closeModal();
      repeatGeoCheck()
        .then((result) => {
          if (successUri && result.success.includes(productId)) {
            window.location.href = successUri;
          }
        })
        .catch(() => {
          closeModal();
        });
    } else {
      closeModal();
    }
  };

  const handleLocateClicked = (e) => {
    e.preventDefault();
    setIsCheckingCurrentPosition(true);
    navigator.geolocation.getCurrentPosition(setPosition, () => {
      setIsCheckingCurrentPosition(false);
    });
  };

  const locationSupported = navigator.geolocation;

  const locationSelectorFormRef = useRef(null);

  return (
    <form onSubmit={handleSubmit(handleFormSubmit)} ref={locationSelectorFormRef}>
      <Box width="50rem">
        <Box width={{ max: '490px' }}>
          <Heading level={3} size="24px" textAlign="start">
            Check {isRebate ? 'sample' : 'delivery'} availability in your area
          </Heading>
        </Box>
        <Box margin={{ top: '10px' }}>
          <Text textAlign="start">
            Provide your ZIP/Postal Code to see if there are free samples available near you.
          </Text>
        </Box>
        <Box
          direction={isXsmall ? 'column' : 'row'}
          gap="small"
          align={isXsmall ? 'stretch' : 'start'}
        >
          <Box direction="row" align="start">
            <Box width="100%" margin={{ top: '29px', right: '3px' }}>
              <InputLabel>ZIP/Postal Code</InputLabel>

              <Controller
                type="text"
                name="Location"
                placeholder="Zip/Postal Code"
                defaultValue={defaultLocation || ''}
                as={<LocationField autoFocus={!isXsmall} />}
                control={control}
                error={errors && errors.Location}
                showErrorIcon={false}
                rules={{
                  required: errorMessages.REQUIRED,
                  pattern: {
                    value: validPostCodePattern,
                    message: errorMessages.POSTCODE_ERROR,
                  },
                }}
              />
              {errors && errors.Location && (
                <Text
                  icon={<Error color="red" />}
                  color="red"
                  size="xsmall"
                  margin={{ bottom: '4px' }}
                >
                  {errors.Location.message}
                </Text>
              )}
            </Box>
            {locationSupported && (
              <Box
                margin={{ top: '53px', left: '0.65rem' }}
                height={{ max: '54px' }}
                width={{ max: '50px', min: '50px' }}
              >
                {isCheckingCurrentPosition ? (
                  <LoadingSection />
                ) : (
                  <button
                    style={{ padding: '6px' }}
                    className="sample__browser-locate"
                    type="button"
                    onClick={handleLocateClicked}
                  >
                    <i style={{ verticalAlign: 'middle' }} className="material-icons">
                      gps_fixed
                    </i>
                  </button>
                )}
              </Box>
            )}
          </Box>
          <Box margin={{ top: isXsmall ? null : '53px' }}>
            <AvailabilityButton
              label="Check Availability"
              disabled={isRecheckingNearby}
              type="submit"
            />
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default LocationSelector;
