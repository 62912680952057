import React from 'react';
import FilterTag from './FilterTag';
import FilterQueryContext from '../../contexts/FilterQueryContext';
import FilterClearButton from './FilterClearButton';

function createFilterMap(filters) {
  const filterTypeMap = new Map();

  filters.forEach((filter) => {
    if (filter.filters) {
      filter.filters.forEach((f) => {
        const map = filterTypeMap.get(f.type) || filterTypeMap.set(f.type, new Map()).get(f.type);
        map.set(f.slug, f.name);
      });
    }
  });
  return filterTypeMap;
}

function createTags(filters, filterQueryMap) {
  const tags = [];
  const filtersMap = createFilterMap(filters);

  filterQueryMap.forEach((slugs, type) => {
    // Format slug to name
    if (type === 'country') return;
    const filter = filtersMap.get(type);
    if (!filter) return;
    Array.from(slugs).forEach((slug) => {
      const name = filter.get(slug);
      tags.push(<FilterTag key={slug} type={type} slug={slug} label={name} />);
    });
  });
  return tags;
}

const FilterTags = ({ filters, filterQueryMap }) => (
  <FilterQueryContext.Provider value={filterQueryMap}>
    <div>
      {createTags(filters, filterQueryMap)}
      <FilterClearButton />
    </div>
  </FilterQueryContext.Provider>
);

export default FilterTags;
