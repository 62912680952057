/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useQuery } from '@apollo/react-hooks';
import GET_REVIEWS from '../../utils/apis/graphql/review';
import StarRating from '../../Atoms/StarRating';
import { getReviewerName, getAvatarUrl } from '../../utils/reviews';
import { handleError } from '../../utils/error';
import { getCookie } from '../../utils/cookie';

dayjs.extend(relativeTime);
const numberPerPage = 5;

const ProductReviews = (props) => {
  const { totalReviews, productId } = props;
  const [isLoading, setIsLoading] = useState(true);
  const { data, loading, error, fetchMore } = useQuery(GET_REVIEWS, {
    variables: {
      productId,
      offset: 0,
      limit: numberPerPage,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'sn-public',
      },
      clientName: 'hasura',
    },
  });

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  if (error) {
    handleError(error);
  }

  const { reviews_public: reviews } = data || {};
  if (!reviews) return null;

  const handleLoadMoreReviews = (e) => {
    e.preventDefault();

    fetchMore({
      variables: {
        offset: data.reviews_public.length,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        return {
          ...prev,
          reviews_public: [...prev.reviews_public, ...fetchMoreResult.reviews_public],
        };
      },
    });
  };

  const reviewsDom = reviews.map((review) => {
    const { id, avatar, firstName, lastName, rating, description, createdAt, title, variety } =
      review;

    const isCookieConsent = getCookie('cookies-marketing') === 'true';

    return (
      <div
        className="layout-row launch-review"
        key={id}
        itemProp="review"
        itemType="https://schema.org/Review"
        itemScope
      >
        <div className="layout-column">
          <div className="launch-review--avatar">
            <img src={getAvatarUrl(avatar, isCookieConsent)} alt="" />
          </div>
          <div className="launch-review--name" itemProp="author">
            {getReviewerName(firstName, lastName)}
          </div>
        </div>

        <div className="layout-column">
          <div itemProp="reviewRating" itemType="http://schema.org/Rating" itemScope>
            <meta itemProp="ratingValue" content={rating} />
            <meta itemProp="bestRating" content="5" />
          </div>
          <StarRating rating={rating} />
          <div className="launch-review--description" itemProp="headline">
            <b>{title}</b>
          </div>
          <div className="launch-review--description" itemProp="description">
            {description}
          </div>

          <p>
            {!R.isNil(variety) && !R.isEmpty(variety) ? (
              <React.Fragment>
                <small>
                  <strong>{`Variety tried: ${variety}`}</strong>
                </small>
                <br />
              </React.Fragment>
            ) : null}
            <small>
              <span>Reviewed </span>
              <strong>{dayjs(createdAt).fromNow()}</strong>
            </small>
          </p>
        </div>
      </div>
    );
  });

  return (
    <div data-cy="reviews-container">
      <div className="nudge-lg" />

      <a id="reviews" className="anchor" />
      <h2>Reviews</h2>
      {!reviewsDom.length ? (
        <>
          <p className="launch-reviews">No reviews have been posted for this product yet.</p>
          {/*
            Product requires either review, rating or offer to be valid for Google Search Console even if data is not available
            see https://developers.google.com/search/docs/advanced/structured-data/product#structured-data-type-definitions
          */}
          <div itemProp="offers" itemScope itemType="https://schema.org/Offer" hidden>
            <meta itemProp="price" content="0" />
          </div>
        </>
      ) : (
        reviewsDom
      )}

      {reviews.length < totalReviews ? (
        <>
          <div className="nudge-md" />
          <p className="text-center">
            <a className="link-launchpad" onClick={handleLoadMoreReviews}>
              {isLoading ? 'Loading...' : 'Read More Reviews'}
            </a>
          </p>
        </>
      ) : null}
    </div>
  );
};

export default ProductReviews;
