import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box, Modal, themes as uiTheme, Heading, SecondaryButton } from 'socialnature-ui';
import { Grommet, ResponsiveContext } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { HowToRedeem } from '../HowToRedeem';

const customUiTheme = deepMerge(uiTheme, {
  layer: {
    zIndex: 110,
  },
  level: {
    3: {
      medium: {
        size: '24px',
        height: '32px',
        maxWidth: '100%',
      },
    },
    4: {
      medium: {
        size: '24px',
        height: '32px',
        maxWidth: '100%',
      },
    },
  },
});

const StyledModal = styled(Modal)`
  width: 500px;
`;

const HowToRedeemModal = (props) => {
  const { open, onClose, promoCode, productUrl, buttonText, channelName } = props;
  const size = useContext(ResponsiveContext);
  const isXSmall = size === 'xsmall';

  return (
    <Grommet theme={customUiTheme}>
      <StyledModal open={open} onClose={onClose}>
        <Box width="446px">
          <Heading
            level="3"
            margin={{ bottom: 'medium' }}
            textAlign={isXSmall ? 'start' : 'center'}
          >
            How to redeem your free product online:
          </Heading>
          <HowToRedeem
            promoCode={promoCode}
            productUrl={productUrl}
            buttonText={buttonText}
            channelName={channelName}
          />
          <SecondaryButton label={buttonText} href={productUrl} target="_blank" rel="noopener" />
        </Box>
      </StyledModal>
    </Grommet>
  );
};

export default HowToRedeemModal;
