import React, { useContext } from 'react';
import { Box, themes, Text } from 'socialnature-ui';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';

const typeColorEnum = {
  success: themes.global.colors.green,
  error: themes.global.colors.red,
};

const StyledBox = styled(Box)`
  border-radius: 4px;
  width: ${({ isXsmall }) => (isXsmall ? 'unset' : '350px')};
`;

export default function ToastMessage({ message, type }) {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <StyledBox
      width="350px"
      align="center"
      background={typeColorEnum[type]}
      alignContent="center"
      isXsmall={isXsmall}
      margin={{ bottom: '16px', horizontal: '16px' }}
      pad={{
        horizontal: '4px',
        vertical: '8px',
      }}
    >
      <Text textAlign="center" size="14px">
        {message}
      </Text>
    </StyledBox>
  );
}
