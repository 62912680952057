import { useEffect, useState } from 'react';
import { getAvailableDiscounts } from '../../utils/apis/discounts';

export function useAvailableDiscounts(wantId, companyId, { fetchBrandDiscountsOnly = false } = {}) {
  const [discounts, setDiscounts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (fetchBrandDiscountsOnly && !companyId) {
      return;
    }

    getAvailableDiscounts(wantId, companyId)
      .then((response) => {
        if (response) {
          const filteredDiscounts = response.filter((item) => item.isBudgedReached === false);
          setDiscounts(filteredDiscounts);
        }
      })
      .finally(() => setIsLoading(false));
  }, [wantId, companyId, fetchBrandDiscountsOnly]);

  return { discounts, isLoading };
}
