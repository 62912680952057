import { useQuery } from '@apollo/react-hooks';
import { GET_FREETRIALS_HOME } from '../../utils/apis/products';
import formatParam from '../../utils/apis/formatParam';

/**
 *
 * @param {string} postalCode
 * @param {string} country
 * @param {string} userId - The userId is tracking user ID instead of user table Id
 * @param {bool} skip
 * @param {bool} cacheOnly
 * @returns
 */
export function useFreeSample(postalCode, country, userId, skip = false, cacheOnly = false) {
  // TODO: improve catching service when calling the customhook from different component (reference)
  // it looks like useQuery catching only works when the call reference exactly is the same
  const { loading, error, data } = useQuery(GET_FREETRIALS_HOME, {
    variables: {
      param: formatParam({
        limit: 24,
        filter: country && `country=${country[0] === 'U' ? 'US' : 'CA'}`,
        userid: userId,
        zip: postalCode,
      }),
    },
    skip,
    fetchPolicy: cacheOnly ? 'cache-only' : 'network-only',
  });

  return { loading, error, data };
}
