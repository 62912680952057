import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import { RetailerIcon } from '../../Atoms/RetailerIcon';
import basicTheme from '../../themes';
import retailerIconsEnum from '../../Atoms/Icons/Retailers';

/**
 * @param {string} targetRetailers
 * @returns {JSX.Element}
 */

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({ isXsmall }) => (isXsmall ? '8px' : '16px')};
`;

const Span = styled.span`
  color: ${basicTheme.global.colors.black};
  font-weight: 700;
  font-size: ${({ isXsmall }) => (isXsmall ? '12px' : '14px')};
`;

function TargetRetailers({ targetRetailers }) {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  const { retailersWithIcon, retailersWithoutIcon } = useMemo(() => {
    const retailersWithIcon = [];
    const retailersWithoutIcon = [];

    targetRetailers.split(',').forEach((retailerName) => {
      const sanitizedName = retailerName
        .trim()
        .replace(/[^a-zA-Z0-9]/g, '')
        .toLowerCase();

      if (!sanitizedName) return;

      // Check for an exact match or partial match in the icons enum
      const matchedRetailer = Object.keys(retailerIconsEnum).find((key) => {
        return sanitizedName.includes(key) || key.includes(sanitizedName);
      });

      if (matchedRetailer) {
        return retailersWithIcon.push(retailerIconsEnum[matchedRetailer]);
      } else {
        return retailersWithoutIcon.push(retailerIconsEnum.default);
      }
    });

    return { retailersWithIcon, retailersWithoutIcon };
  }, [targetRetailers]);

  if (retailersWithIcon.length <= 0 && retailersWithoutIcon.length <= 0) {
    return null;
  }

  const retailersArray = [...retailersWithIcon, ...retailersWithoutIcon];

  if (retailersArray.length <= 0) {
    return null;
  }

  return (
    <Container isXsmall={isXsmall}>
      {retailersArray[0] && <RetailerIcon name={retailersArray[0]} isXsmall={isXsmall} />}
      {retailersArray[1] && <RetailerIcon name={retailersArray[1]} isXsmall={isXsmall} left={-4} />}
      {retailersArray[2] && <RetailerIcon name={retailersArray[2]} isXsmall={isXsmall} left={-8} />}
      {retailersArray.length > 3 && (
        <Span isXsmall={isXsmall}>+{retailersArray.length - 3} more</Span>
      )}
    </Container>
  );
}

export default React.memo(TargetRetailers);
