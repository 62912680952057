import React from 'react';
import * as R from 'ramda';

export default class LaunchpadItem extends React.Component {
  constructor(props) {
    super(props);

    this.totalWants = R.path(['sample', 'totalWants'], this.props) || 0;

    this.state = {
      percentage:
        this.totalWants > 0
          ? Math.round((this.totalWants * 100) / (this.totalWants + 5000)) || 1
          : 0,
    };
  }

  render() {
    const sample = R.path(['sample', 'sampleKit'], this.props) || {};
    const featuredImage =
      R.path(['sample', 'sampleKit', 'featuredImage', 'full'], this.props) || '';

    const sampleLink = '/' + sample.slug;
    const isBoosted = this.props.wanted || false;

    return (
      <div className="launch-item">
        <a className="launch-item-link" href={sampleLink}></a>
        <div className="launch-item-top">
          <img src={featuredImage} alt={sample.name} />
        </div>
        <div className="launch-item-bottom">
          <div className="layout-row">
            <div className="flex-80 launch-item--info flex-center-vert">
              <div className="launch-item--type">{sample.name}</div>
              <div className="launch-item--name">
                {sample.companyEnableStorefront ? (
                  <a
                    href={`/brand/${sample.companySlug}`}
                    className="launch-item--name__storefornt"
                  >
                    {sample.companyName}
                  </a>
                ) : (
                  sample.companyName
                )}
              </div>
            </div>

            <div className="flex-20 flex-right">
              {isBoosted ? (
                <div className="link-boosted">
                  <img src="/images/boosted.png" />
                  <br />
                  Boosted!
                </div>
              ) : (
                <div className="link-boost">
                  <img src="/images/boost.png" />
                  <br />
                  BOOST
                </div>
              )}
            </div>
          </div>

          <div
            className="launch-item--desc truncate"
            dangerouslySetInnerHTML={{
              __html: sample.description,
            }}
          ></div>

          <p className="link-more">learn more</p>

          <div className="nudge-lg" />

          <ProgressBar percentage={this.state.percentage} />

          <div className="note">{this.totalWants} Boosts</div>
        </div>
      </div>
    );
  }
}

const ProgressBar = (props) => {
  return (
    <div className="progress-bar">
      <Filler percentage={props.percentage} />
    </div>
  );
};

const Filler = (props) => {
  return <div className="filler" style={{ width: `${props.percentage}%` }} />;
};
