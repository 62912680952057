// @ts-check
import React from 'react';
import { Box, Text } from 'socialnature-ui';
import { SearchStoreCampaignLocationsForm } from '../../CampaignLocation/SearchStoreCampaignLocationsForm';
import { Tab, Tabs } from '../../../Atoms/Tabs';
import { LocationList } from '../../CampaignLocation/LocationList';
import { LocationMap } from '../../CampaignLocation/LocationMap';
import LoadingSection from '../../../Molecules/LoadingSection';
import { CENTER_NORTH_AMERICA } from '../../../utils/apis/maps';

/**
 * @typedef {{
 * campaignLocations: Array,
 * setIsRecheckingNearby: Function,
 * setCampaignLocations: Function,
 * isXsmall: boolean,
 * isRecheckingNearby: boolean,
 * errorMessage: string,
 * setPostalCodeWithNoLocations: Function,
 * postalCodeWithNoLocations: string,
 * selectedIndex: number,
 * setSelectedIndex: Function,
 * handleFormSubmitFetch: Function,
 * Header?: Function
 * }} Props
 * @param {Props} props
 * @returns
 */
export function StorePickUpLocationMobile({
  campaignLocations,
  setIsRecheckingNearby,
  setCampaignLocations,
  isXsmall,
  isRecheckingNearby,
  errorMessage,
  setPostalCodeWithNoLocations,
  postalCodeWithNoLocations,
  selectedIndex,
  setSelectedIndex,
  handleFormSubmitFetch,
  Header,
}) {
  return (
    <>
      <Box margin={{ bottom: '24px' }}>
        {Header}
        <SearchStoreCampaignLocationsForm
          setIsRecheckingNearby={setIsRecheckingNearby}
          setCampaignLocations={setCampaignLocations}
          isXsmall={isXsmall}
          setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
          postalCodeWithNoLocations={postalCodeWithNoLocations}
          handleFormSubmitFetch={handleFormSubmitFetch}
        />
      </Box>
      {!Array.isArray(campaignLocations) || !campaignLocations.length ? (
        <Box margin={{ vertical: '16px' }}>
          <Text>{errorMessage}</Text>
        </Box>
      ) : (
        <Tabs>
          <Tab title="LIST VIEW">
            <Box margin={{ top: '16px', bottom: '32px' }}>
              {isRecheckingNearby ? (
                <LoadingSection />
              ) : (
                <LocationList locations={campaignLocations} />
              )}
            </Box>
          </Tab>
          <Tab title="MAP VIEW">
            <Box margin={{ top: '16px' }}>
              <LocationMap
                locations={campaignLocations}
                centerLatitude={
                  campaignLocations.length
                    ? campaignLocations[0].latitude
                    : CENTER_NORTH_AMERICA.lat
                }
                centerLongitude={
                  campaignLocations.length
                    ? campaignLocations[0].longitude
                    : CENTER_NORTH_AMERICA.lng
                }
                zoom={(!campaignLocations.length && 2.8) || 10}
                styles={{ height: '374px' }}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            </Box>
          </Tab>
        </Tabs>
      )}
    </>
  );
}
