// Need to load `pinterestScriptsLoaded` first to use this component.
// See more detail: https://developers.pinterest.com/docs/widgets/save/
import React, { useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import Pinterest from '../../../Atoms/Icons/sns/Pinterest';
import { getDataset } from '../../../utils/socialShare';

const ShareButton = (props) => {
  const { id, name, companyName, image, shareUrl, message, variant } = props;
  const dataAttributes = getDataset(props);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <ShareButtonStyle
      isXsmall={isXsmall}
      href={`https://www.pinterest.com/pin/create/button?url=${encodeURIComponent(
        shareUrl
          .replace(/\{0\}/g, 'pinterest')
          .replace('http://localhost:5000', 'https://socialnature.com'),
      )}&media=${encodeURIComponent(image)}&description=${encodeURIComponent(message || '')}`}
      data-sample-kit-name={name}
      data-sample-kit-company-name={companyName}
      data-share-type="product"
      data-share-url={shareUrl.replace(/\{0\}/g, 'pinterest')}
      data-share-channel="pinterest"
      data-share-model-id={id}
      data-pin-do="buttonPin"
      data-pin-custom="true"
      target="_blank"
      data-track-label={(shareUrl || '').replace(/\{0\}/g, 'twitter')}
      icon={<Pinterest />}
      label="Pin it"
      {...dataAttributes}
      variant={variant}
    />
  );
};

export default ShareButton;
