import jwtDecode from 'jwt-decode';
import { getCookie } from './cookie';

const CLAIM_TYPES = {
  ROLE: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
  NAME: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name',
};

export default function getUserId() {
  const label = process.env.USER_ID_LABEL;
  const value = getCookie(label);
  return value;
}

export function getUserToken() {
  const label = process.env.USER_TOKEN_LABEL;
  const value = getCookie(label);
  return value;
}

export function getHasuraUserId() {
  const token = getUserToken();
  const undefinedUserId = -1;
  try {
    const decoded = token && jwtDecode(token);
    if (decoded) {
      const claims = decoded[process.env.CLAIM_NAMESPACE] || {};
      return claims[process.env.HASURA_USER_ID] || undefinedUserId;
    }
  } catch {
    return undefinedUserId;
  }
  return undefinedUserId;
}

// returns {userID, role}
export function getUserSession() {
  const token = getUserToken();
  let userID = -1;
  let isSNUser = '';
  let userIdentifier = '';
  try {
    const decoded = token && jwtDecode(token);
    if (decoded) {
      const claims = decoded[process.env.CLAIM_NAMESPACE];
      userID = claims?.[process.env.HASURA_USER_ID] ?? userID; // Refers to Database Record ID
      isSNUser = decoded[CLAIM_TYPES.ROLE] !== 'guest' ?? true;
      userIdentifier = decoded[CLAIM_TYPES.NAME] ?? userIdentifier;
    }
  } catch {}
  return [userID, isSNUser, userIdentifier];
}
