import gql from 'graphql-tag';

const GET_REVIEWS = gql`
  query ($param: String) {
    reviews(param: $param) @rest(type: "Reviews", path: "reviews?{args.param}") {
      id
      user
      product
      rating
      createdAt
      description
      title
    }
  }
`;

export default GET_REVIEWS;
