import { getCookie } from '../../../cookie';

let isShoperMediaSubmitted = false;

export function trackUserSignUp() {
  const shoperID = getCookie('ShopherMediaClickID');
  const shoperMediatrackerURL = `https://trk.shophermedia.net/pixel.track?CID=465840&p=img&ClickID=${shoperID}&MerchantReferenceID=`;
  if (shoperID && shoperID.length && !isShoperMediaSubmitted) {
    return fetch(shoperMediatrackerURL)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        // eslint-disable-next-line no-console
        console.info('Shopermedia Tracking submitted');
        isShoperMediaSubmitted = true;
        return response.text();
      })
      .catch((error) => {
        // Not Handling anything
        // eslint-disable-next-line no-console
        console.error(`Track Shopermedia error: ${error.message}`);
        return Promise.resolve();
      });
  }
  return Promise.resolve();
}
