import React, { useEffect, useState } from 'react';
import { CompanyName, ProductName } from '../../Molecules/ProductCard/Name';
import Card from '../../Atoms/Card/Card';
import CardAction from '../../Atoms/Card/CardAction';
import CardBody from '../../Atoms/Card/CardBody';
import CardImage from '../../Atoms/Card/CardImage';
import CardContent from '../../Atoms/Card/CardContent';
import CardFooter from '../../Atoms/Card/CardFooter';
import CardBadge from '../../Atoms/Card/CardBadge';
import CardTag from '../../Atoms/Card/CardTag';
import Count from '../../Molecules/ProductCard/Count';
import TargetRetailers from '../../Molecules/ProductCard/TargetRetailers';
import hasExpired from '../../utils/expiryDate';
import { formatDate } from '../../utils/formatDate';
import { isRebateCampaign } from '../../utils/campaignTypes';
import { SplitNames, isFeatureOn } from '../../utils/splitio';
import { discountTypes } from '../../utils/discountTypes';
import { rebateFreebieLabel } from '../../utils/product/getBadge/freebieLabel';
import { getCashBackFormattedPrice } from '../../utils/product';

const getBadge = (product, isUpdateLabelFF) => {
  const {
    wantDisabled,
    bannerMessage,
    bannerExpiry,
    hasWanted,
    campaignType,
    offerDiscountPercentage,
    bogoLabel = '',
    moneyCashBackValue = 0.0,
  } = product;

  if (!wantDisabled && !hasWanted && isRebateCampaign(campaignType)) {
    const rebateLabel = rebateFreebieLabel(offerDiscountPercentage);
    const discountType = (product?.discountType || '').toUpperCase();

    let text = isUpdateLabelFF ? rebateLabel : `${offerDiscountPercentage}% off`;
    if (discountType === discountTypes.BOGO) {
      text = bogoLabel;
    } else if (discountType === discountTypes.MONEY_CASH_BACK) {
      text = `$${getCashBackFormattedPrice(moneyCashBackValue)} off`;
    }

    return {
      text,
      color: 'pink',
      fontWeight: offerDiscountPercentage === 100 ? 800 : 700,
    };
  }
  if (!wantDisabled && !hasWanted) {
    return {
      text: isUpdateLabelFF ? 'Free product' : 'Free Sample',
      color: 'pink',
    };
  }
  // If wantDisabled is false, the product is in Campaign. Doesn't display a badge.
  if (wantDisabled && bannerMessage && !hasExpired(bannerExpiry)) {
    return {
      text: bannerMessage,
      color: 'blue',
    };
  }
  return null;
};

const getTag = ({ wantDisabled, hasWanted, shippingDate }) => {
  if (wantDisabled) return null;
  if (!hasWanted) return null;
  if (shippingDate) {
    return `shipping ${formatDate(shippingDate)}`;
  }
  return 'waitlisted';
};

const FreeTrialCard = (props) => {
  const { product, width, height, dataTrackCategory, index } = props;
  const [isUpdateLabelFF, setIsUpdateLabelFF] = useState(false);

  useEffect(() => {
    isFeatureOn(SplitNames.changeCashBackLabelToFreeProduct).then(setIsUpdateLabelFF);
  }, []);

  const {
    name,
    slug,
    companyName,
    featuredImage,
    totalWants,
    averageRating,
    totalReviews,
    wantDisabled,
    nearRetailers,
  } = product;
  const productLink = props.navigationLink || `/${slug}`;
  const badge = getBadge(product, isUpdateLabelFF);
  const tag = getTag(product);

  return (
    <Card width={width} height={height}>
      <CardAction
        href={productLink}
        data-track-category={dataTrackCategory}
        data-track-action={dataTrackCategory && `Suggested ${index + 1}`}
        data-track-label={dataTrackCategory && slug}
      >
        <CardBody>
          <CardImage src={featuredImage} />
          <CardContent>
            <CompanyName>{companyName}</CompanyName>
            <ProductName>{name}</ProductName>
          </CardContent>
          <CardFooter>
            {wantDisabled ? (
              <Count type="review" value={totalReviews} averageRating={averageRating} />
            ) : (
              <Count type="freeTrial" value={totalWants} />
            )}
            {!wantDisabled && nearRetailers && <TargetRetailers targetRetailers={nearRetailers} />}
          </CardFooter>
        </CardBody>
        {badge && <CardBadge text={badge.text} color={badge.color} fontWeight={badge.fontWeight} />}
        {tag && <CardTag text={tag} />}
      </CardAction>
    </Card>
  );
};

export default FreeTrialCard;
