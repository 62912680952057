import React, { useState } from 'react';
import styled from 'styled-components';
import { PromoCode, Text, TextLink } from 'socialnature-ui';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// TODO: Remove the component when don't use site.css. It's only for overriding site.css
const PromoCodeWrapper = styled.div`
  margin-bottom: 16px;
  input:not([type])[readonly] {
    margin: 0;
    background-color: #ffffff;
    box-shadow: none;
    height: 40px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    font-size: 17px;
    padding-left: 16px;
    border: 1px dashed #cccccc;
    border-right: none;
  }
  button {
    padding: 8px 16px;
    flex-shrink: 0;
  }
  label {
    font-weight: normal;
    cursor: default;
  }
`;

export function HowToRedeem(props) {
  const { productName = 'product', promoCode, productUrl, channelName } = props;
  const [copied, setCopied] = useState(false);

  return (
    <>
      <Text margin={{ bottom: '16px' }}>
        <Text as="span">1. Go to </Text>
        <TextLink
          label={channelName}
          href={productUrl}
          fontWeight="bold"
          target="_blank"
          rel="noopener"
        />
        <Text as="span">{` and follow the prompts to redeem your ${productName}. This will include entering in your shipping details.`}</Text>
      </Text>
      <Text margin={{ bottom: '16px' }}>2. Copy and paste your promo code.</Text>
      <CopyToClipboard text={promoCode} onCopy={() => setCopied(true)}>
        <PromoCodeWrapper>
          <PromoCode value={promoCode} copied={copied} />
        </PromoCodeWrapper>
      </CopyToClipboard>
      <Text>3. Click APPLY or SUBMIT and double-check your product total!</Text>
      <Text margin={{ bottom: '16px' }} size="small">
        *Note shipping charges or tax may apply.
      </Text>
      <Text margin={{ bottom: '16px' }}>
        4. Keep an eye on your mailbox. Your product should arrive within 7-10 business days.
      </Text>
    </>
  );
}
