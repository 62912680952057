import React from 'react';
import { Box } from 'grommet';
import Star, { HalfStar } from './Icons/Star';

const StarRating = (props) => {
  const { rating, size } = props || {};
  const sanitizedRating = Math.round(rating * 10);
  const keys = Array.from({ length: 5 }, (_, k) => k * 10 + 10);

  return (
    <Box direction="row" gap="xxsmall">
      {keys.map((v) => {
        const rate = sanitizedRating - v;
        if (rate > -4) {
          return <Star width={size} height={size} htmlColor="#FFB624" key={v} />;
        }
        if (rate < -3 && rate > -8) {
          return <HalfStar width={size} height={size} key={v} />;
        }
        return <Star width={size} height={size} key={v} />;
      })}
    </Box>
  );
};

export default StarRating;
