/* eslint-disable import/prefer-default-export */
export async function sendEmail(reviewId, email) {
  const body = {
    FriendEmail: email,
    ReviewId: reviewId,
  };

  const response = await fetch('/review/emailshare', {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(body),
  });

  if (response.ok) return {};

  const errorResponse = await response.json();
  throw new Error(errorResponse.message);
}
