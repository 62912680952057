import React, {
  useEffect,
  useRef,
  createElement,
  Fragment,
  Children,
  isValidElement,
  cloneElement,
  useState,
} from 'react';
import ErrorPage from '../../Pages/ErrorPage';
import { useDeepCompareEffectForMaps } from './useDeepCompareEffectForMaps';

export function Map({ children, options = {}, styles = {} }) {
  const { height = '300px' } = styles;
  const { center, zoom, ...others } = options;
  const ref = useRef(null);
  const [map, setMap] = useState();

  if (!window.google || !window.google.maps) {
    window.google = {
      maps: () => {},
    };
  }

  useEffect(() => {
    if (ref.current && !map) {
      if (!center || !center.lat || !center.lng) return;

      setMap(
        new window.google.maps.Map(ref.current, {
          center,
          zoom,
          ...others,
        }),
      );
    }
  }, [ref, map, options, center, zoom, others]);

  // because React does not do deep comparisons, a custom hook is used
  // see discussion in https://github.com/googlemaps/js-samples/issues/946
  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options);
    }
  }, [map, options]);

  if (!center || !center.lat || !center.lng) return <ErrorPage />;

  return createElement(
    Fragment,
    null,
    createElement('div', { ref, style: { ...styles, height }, className: 'LocationMap' }),
    Children.map(children, (child) => {
      if (isValidElement(child)) {
        // set the map prop on the child component
        return cloneElement(child, { map });
      }
      return null;
    }),
  );
}
