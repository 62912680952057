import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ResponsiveContext } from 'grommet';
import { Box, Container, Text, Heading, TextLink } from 'socialnature-ui';
import { useForm, FormProvider } from 'react-hook-form';
import { isEmpty } from 'ramda';
import TwoColumns, { Side, Main } from '../../Templates/TwoColumns';
import ProgressBar from './ProgressBar';
import PersonalInformation from './PersonalInformation';
import { errorMessages, messages } from '../../utils/customQuestionTypes';
import ProfileQuestionForm from '../../Organisms/ProfileQuestionForm';
import { getDefaultValues } from '../../utils/formatCustomQuestion';
import Alert from '../../Molecules/Alert';

const ProfilePage = (props) => {
  const { userInformation, questions } = props;
  const size = useContext(ResponsiveContext);
  const isLarge = size === 'large';
  const initQuestions = useMemo(() => {
    const values = getDefaultValues(questions);
    const ids = questions.reduce(
      (prev, q) => ({
        ...prev,
        [`CustomQuestions[${q.index}].QuestionId`]: q.questionId.toString(),
      }),
      {},
    );
    return { ...values, ...ids };
  }, [questions]);
  const profileQuestionMethods = useForm({ defaultValues: initQuestions });
  const userInfoMethods = useForm();
  const [success, setSuccess] = useState(false);
  const hasError = !isEmpty(profileQuestionMethods.errors) || !isEmpty(userInfoMethods.errors);

  useEffect(() => {
    if (hasError) {
      setSuccess(false);
    }
  }, [hasError]);

  useEffect(() => {
    const timer = success ? setTimeout(() => setSuccess(false), 2000) : undefined;
    return () => clearTimeout(timer);
  }, [success]);

  const handleSubmit = () => {
    setSuccess(true);
    window.scrollTo({ top: 0 });
  };

  return (
    <Box pad={isLarge ? { vertical: 'xlarge' } : { top: 'medium', bottom: 'xlarge' }}>
      <Container>
        <Box margin={{ bottom: 'medium' }} align="center">
          {!hasError && success && <Alert severity="success">{messages.FORM_SUCCESS}</Alert>}
          {hasError && <Alert severity="error">{errorMessages.FORM_ERROR}</Alert>}
        </Box>
        <TwoColumns reverse>
          <Main>
            <Box width={{ max: '592px' }} margin={{ horizontal: 'auto' }}>
              <Box margin={{ bottom: 'medium' }}>
                <FormProvider {...profileQuestionMethods}>
                  <ProgressBar questions={questions} />
                </FormProvider>
              </Box>
              <Heading level={2} margin={{ bottom: '8px' }}>
                My Preferences
              </Heading>
              <Text>
                <Text as="span">
                  {
                    'We use this info to match you to the right products and customize your experience based on the info you provide. Read our '
                  }
                </Text>
                <TextLink
                  label="Privacy Policy"
                  href="/privacy-policy"
                  target="_blank"
                />
                <Text as="span">{' '} to learn more.</Text>
              </Text>
              <FormProvider {...profileQuestionMethods}>
                <ProfileQuestionForm
                  questions={questions}
                  submitCallback={handleSubmit}
                  modelType="profile"
                  submitButtonValue="save answers"
                  // No facebookMessengerCheckBox
                />
              </FormProvider>
            </Box>
          </Main>
          <Side reverse>
            <Box
              width={isLarge ? '384px' : { max: 'calc(592px + 32px)' }}
              margin={{ horizontal: 'auto' }}
            >
              <FormProvider {...userInfoMethods}>
                <PersonalInformation data={userInformation} submitCallback={handleSubmit} />
              </FormProvider>
            </Box>
          </Side>
        </TwoColumns>
      </Container>
    </Box>
  );
};
export default ProfilePage;
