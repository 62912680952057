import React, { useContext } from 'react';
import { Grommet, ResponsiveContext } from 'grommet';
import { Box, Text, themes } from 'socialnature-ui';
import theme from '../../themes';

const bellStyles = {
  fontSize: 10,
  marginRight: 8,
};

const HighlightedBox = ({ children }) => {
  const size = useContext(ResponsiveContext);
  const isXSmall = size === 'xsmall';
  const yAxisSize = isXSmall ? 'small' : 'xsmall';

  return (
    <Grommet theme={themes}>
      <Box
        width={{ max: '798px' }}
        background={theme.global.colors.smoke}
        round="8px"
        margin={{ horizontal: 'auto' }}
        pad={{
          top: yAxisSize,
          right: '16px',
          bottom: yAxisSize,
          left: '16px',
        }}
      >
        <Text size="small" textAlign="center" margin="10px 0px 10px 0px">
          <i className="fa fa-bell fa-1x" style={bellStyles} />
          <span>Get notified about new FREE natural product samples</span>
        </Text>
        {children}
      </Box>
    </Grommet>
  );
};

export default HighlightedBox;
