import React from 'react';
import { useLocation } from 'react-router-dom';
import { Box, ResponsiveContext } from 'grommet';
import { useQuery } from '@apollo/react-hooks';
import ProductTitle from '../../Organisms/Products/ProductTitle';
import FreeTrialCard from '../FreeTrialPage/FreeTrialCard';
import LaunchpadCard from '../LaunchpadPage/LaunchpadCard';
import Hr from '../../Atoms/Hr';
import { GET_QUALITIES_PRODUCTS, GET_FREETRIALS, GET_LAUNCHPADS } from '../../utils/apis/products';
import getUserId from '../../utils/userId';
import client from '../../utils/apis';
import formatParam from '../../utils/apis/formatParam';
import ErrorPage from '../ErrorPage';
import Loading from '../../Molecules/Loading';
import TwoColumns, { Side, Main } from '../../Templates/TwoColumns';
import Container from '../../Atoms/Container';
import Filters from '../../Organisms/Filters/Filters';
import ProductList from '../../Templates/ProductList';
import FilterTags from '../../Organisms/Filters/FilterTags';
import LoadMoreButton from '../../Molecules/Buttons/LoadMoreButton';
import Section from '../../Templates/Section';
import Breadcrumb from '../../Atoms/Breadcrumb';

function getFreetrialTitle() {
  return 'Discover better-for-you products';
}

function getLaunchpadTitle(isLogin) {
  return isLogin ? 'Boost these products into stores near you' : 'Boost these innovative products';
}

function getBreadcrumb() {
  return [{ name: 'All Products', link: '/' }];
}

function createQueryMap(query) {
  const queryMap = new Map();

  query.forEach((value, key) => {
    queryMap.set(key, new Set(value.split(',')));
  });
  return queryMap;
}

function useQueryParams() {
  return new URLSearchParams(useLocation().search);
}

// TODO: need to refactor
function showTags(filterQueryMap) {
  if (!filterQueryMap) return false;
  if (filterQueryMap.size === 0) return false;
  if (filterQueryMap.size > 1) return true;
  return !filterQueryMap.has('country');
}

export default function SearchPage() {
  const [launchpads, setLaunchpads] = React.useState(null);
  const [freetrials, setFreetrials] = React.useState(null);
  const [lpLoading, setLpLoading] = React.useState(false);
  const [ftLoading, setFtLoading] = React.useState(false);

  // TODO: Create class about filter query like using on FilterCheckBox
  const query = useQueryParams();
  const filterQueryMap = createQueryMap(query);
  const userId = getUserId();
  const size = React.useContext(ResponsiveContext);
  const isDesktop = size === 'large';
  const width = {
    xsmall: '50%',
    small: '50%',
    medium: '33.333%',
    large: '33.333%',
  };

  const { loading, error, data } = useQuery(GET_QUALITIES_PRODUCTS, {
    variables: {
      param: formatParam({
        limit: 6,
        filter: query.toString(),
        userid: userId,
      }),
    },
  });

  function loadMoreLaunchpad(offset) {
    setLpLoading(true);
    client
      .query({
        query: GET_LAUNCHPADS,
        variables: {
          param: formatParam({
            limit: 6,
            offset,
            filter: query.toString(),
            userid: userId,
          }),
        },
      })
      .then((response) => {
        const {
          data: {
            launchpads: { products: newProducts },
          },
        } = response;
        if (newProducts) {
          setLaunchpads([...launchpads, ...newProducts]);
        }
        setLpLoading(false);
      });
  }

  function loadMoreFreetrial(offset) {
    setFtLoading(true);
    client
      .query({
        query: GET_FREETRIALS,
        variables: {
          param: formatParam({
            limit: 6,
            offset,
            filter: query.toString(),
            userid: userId,
          }),
        },
      })
      .then((response) => {
        const {
          data: {
            freetrials: { products: newProducts },
          },
        } = response;
        if (newProducts) {
          setFreetrials([...freetrials, ...newProducts]);
        }
        setFtLoading(false);
      });
  }

  React.useEffect(() => {
    const { launchpadproducts: launchpad } = (data && data.products) || {};
    const { freetrialproducts: freetrial } = (data && data.products) || {};
    if (launchpad && launchpad.products) {
      setLaunchpads(launchpad.products);
    }
    if (freetrial && freetrial.products) {
      setFreetrials(freetrial.products);
    }
    return () => {
      setLaunchpads(null);
      setFreetrials(null);
    };
  }, [data]);

  let loadingPage;
  if (loading) {
    loadingPage = <Loading />;
  } else {
    loadingPage = null;
  }
  if (error) return <ErrorPage />;

  const {
    totalCount,
    filtergroups: filterGroups,
    freetrialproducts,
    launchpadproducts,
  } = (data && data.products) || {};
  const { totalCount: totalLaunchpads } = launchpadproducts || {};
  const { totalCount: totalFreetrials } = freetrialproducts || {};
  const isLeadMoreFreetrial = freetrials && freetrials.length < totalFreetrials;
  const isLeadMoreLaunchpad = launchpads && launchpads.length < totalLaunchpads;

  return (
    <>
      <Container>
        <Box margin={{ top: 'breadcrumb', bottom: 'huge' }}>
          <TwoColumns>
            <Side>
              <Box margin={{ bottom: 'breadcrumb' }}>
                <Breadcrumb items={getBreadcrumb()} />
              </Box>
              <Filters
                filters={filterGroups}
                filterQueryMap={filterQueryMap}
                totalCount={totalCount}
              />
            </Side>
            <Main>
              {isDesktop && showTags(filterQueryMap) && (
                <FilterTags filters={filterGroups || []} filterQueryMap={filterQueryMap} />
              )}
              <div>
                <>
                  {freetrials && (
                    <>
                      <ProductTitle title={getFreetrialTitle()} number={totalCount} />
                      <ProductList>
                        {freetrials.map((product) => (
                          <FreeTrialCard key={product.slug} product={product} width={width[size]} />
                        ))}
                      </ProductList>
                      {isLeadMoreFreetrial && (
                        <Section size="large">
                          <LoadMoreButton
                            onClick={() => {
                              loadMoreFreetrial(freetrials.length);
                            }}
                            loading={ftLoading}
                          />
                        </Section>
                      )}
                    </>
                  )}
                  {freetrials && launchpads && (
                    <Box margin={{ top: 'large', bottom: 'medium' }}>
                      <Hr color="smoke" />
                    </Box>
                  )}
                  {launchpads && (
                    <>
                      <ProductTitle
                        title={getLaunchpadTitle(!!userId)}
                        number={!freetrials && totalCount}
                      />
                      <ProductList>
                        {launchpads.map((product) => (
                          <LaunchpadCard key={product.slug} product={product} width={width[size]} />
                        ))}
                      </ProductList>
                      {isLeadMoreLaunchpad && (
                        <Box margin={{ top: 'large' }} align="center">
                          <LoadMoreButton
                            onClick={() => {
                              loadMoreLaunchpad(launchpads.length);
                            }}
                            loading={lpLoading}
                          />
                        </Box>
                      )}
                    </>
                  )}
                </>
              </div>
            </Main>
          </TwoColumns>
        </Box>
      </Container>
      {loadingPage}
    </>
  );
}
