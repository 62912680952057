import React from 'react';
import styled from 'styled-components';

const ChipContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  column-gap: 2px;
  padding: 2px 6px;
  align-items: center;
  width: fit-content;

  height: 22px;
  padding: 3px 6px;
  border-radius: 20px;
  background: #edf7f7;
  background: ${({ background }) => background || '#edf7f7'};
  color: ${({ color }) => color || '#0f9494'};
  text-align: right;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Karla;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
`;

function Chip({ label, icon, color, background }) {
  return (
    <ChipContainer color={color} background={background}>
      {icon || <></>}
      <span>{label}</span>
    </ChipContainer>
  );
}

export default Chip;
