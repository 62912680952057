import React from 'react';
import { Text, Box } from 'socialnature-ui';
import BasicLabel from '../../Atoms/Labels/BasicLabel';

export function LocationListItem({
  index,
  name,
  street1,
  street2,
  city,
  state,
  isPreferredLocation,
}) {
  return (
    <>
      <Box margin={{ bottom: '8px' }}>
        <Text weight="bold">{`${index}. ${name}`}</Text>
        {isPreferredLocation && (
          <BasicLabel label="Your Preferred Store" style={{ marginTop: '8px' }} />
        )}
      </Box>
      <Text>{`${street1} ${street2}`}</Text>
      <Text>{`${city}, ${state}`}</Text>
    </>
  );
}
