import { useQuery } from '@apollo/react-hooks';
import { getCampaignTypeFromWant } from '../../utils/apis/campaigns';

export function useCampaignFromWant(wantId) {
  const { data, error, loading } = useQuery(getCampaignTypeFromWant(wantId), {
    context: {
      clientName: 'hasura',
    },
  });

  const want = data && data.wants ? data.wants[0] : {};
  const { campaign } = want || {};

  return [campaign, error, loading];
}
