import React from 'react';
import { Box } from 'grommet';
import { useQuery } from '@apollo/react-hooks';
import { GET_LAUNCHPADS_HOME } from '../../../utils/apis/products';
import formatParam from '../../../utils/apis/formatParam';
import LaunchpadList from '../../../Organisms/Products/LaunchpadList';
import LoadingSection from '../../../Molecules/LoadingSection';
import Container from '../../../Atoms/Container';
import PageAnchor from '../../../Templates/PageAnchor';

const LaunchpadSection = (props) => {
  const { userId, userCountry } = props;
  const { loading, error, data } = useQuery(GET_LAUNCHPADS_HOME, {
    variables: {
      param: formatParam({
        limit: 20,
        userid: userId,
        filter: userCountry && `country=${userCountry}`,
      }),
    },
  });
  if (loading) return <LoadingSection />;
  if (error) return <></>;
  const { launchpads } = data || {};

  return (
    <>
      {launchpads && launchpads.products && (
        <Box flex={false} pad={{ vertical: 'section' }} className="section-bg-gray">
          <PageAnchor id="launchpads" />
          <Container>
            <LaunchpadList
              products={launchpads.products}
              linkViewAll={userCountry ? `/launchpad?country=${userCountry}` : '/launchpad'}
            />
          </Container>
        </Box>
      )}
    </>
  );
};

export default LaunchpadSection;
