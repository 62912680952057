import React from 'react';
import styled from 'styled-components';
import basicTheme from '../../../themes';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => height && height};
`;

const StyledImg = styled.img`
  width: 195px;
`;

const StyledH3 = styled.h3`
  font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: ${basicTheme.global.colors.black};
  text-align: center;
  margin: 16px 0 8px;
`;

const StyledP = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: ${basicTheme.global.colors.black};
  text-align: center;
  margin-bottom: 0;
`;

const CheckYourText = ({ children, height }) => (
  <Container height={height}>
    <StyledImg
      src="/images/smsConsentAnnouncement/sms-consent-announcement.png"
      alt="congrats-sms-consent-announcement"
    />
    <StyledH3 data-cy="check-your-text">Check your text!</StyledH3>
    <StyledP>Reply “YES” to start receiving offers by text</StyledP>
    {children}
  </Container>
);

export default CheckYourText;
