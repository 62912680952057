import React from 'react';
import * as R from 'ramda';
import { useQuery } from '@apollo/react-hooks';
import { Box } from 'grommet';
import { TextLink } from 'socialnature-ui';
import GET_USER from '../../utils/apis/users';
import Container from '../../Atoms/Container';
import ProductHeader from '../../Organisms/Product/ProductHeader';
import RelatedProducts from '../Sections/RelatedProducts';
import getUserId, { getHasuraUserId } from '../../utils/userId';
import Loading from '../../Molecules/Loading';
import hasExpired from '../../utils/expiryDate';
import ProductPerks from '../ProductPage/ProductPerks';
import InviteFriendPopup from '../../Organisms/InviteFriend/InviteFriendPopup';
import { TrackingEventType } from '../../utils/tracking';
import { openHotjarSurveyForWaitlisted } from '../../utils/hotjar';

// NOTE: if a product is launchpad, check ProductWanted component.
export default function InvitePendingPage(props) {
  window.dataLayer.push({ event: TrackingEventType.APPLICATION_COMPLETE_WAITLIST });
  window.dataLayer.push({ invitation_flag: 'waitlisted' });
  const disabledInviteFriendPopup = true; // ENG-1070

  const { product, campaignType } = props;
  const { loading, data } = useQuery(GET_USER, {
    variables: {
      id: getUserId(),
    },
  });

  const userId = getHasuraUserId();

  if (loading) return <Loading />;

  const { user } = data || {};
  const { country } = user || {};
  const { bannerExpiry, bannerMessage, slug } = product || {};

  const showBanner =
    !R.isNil(bannerMessage) && !R.isEmpty(bannerMessage) && !hasExpired(bannerExpiry);

  openHotjarSurveyForWaitlisted(campaignType, userId);

  return (
    <>
      <Box background="smoke">
        <Container>
          <ProductHeader
            userId={userId}
            product={product}
            title={`Thanks for applying to try ${product.name}`}
            description={
              <>
                <span>Sorry, you&apos;re not a match for this sample.</span>
                <br />
                <span>
                  {"We've added this product to your "}
                  <TextLink href="/account/products" label="wishlist" fontWeight="bold" />
                  {" and you'll be notified when it's available for you."}
                </span>
              </>
            }
            isEmailForm
            showSNSButtons
            shrinkButton
            dataTrackCategory="Waitlist"
          />
        </Container>
      </Box>
      {showBanner && (
        <Box margin={{ vertical: 'large' }}>
          <Container>
            <ProductPerks
              details={{ ...product, hasWanted: true }}
              coupon={{ ...product, showBanner }}
              isLoggedIn={!!userId}
              userId={userId}
              dataTrackCategory="Waitlist"
            />
          </Container>
        </Box>
      )}
      <RelatedProducts userCountry={country} dataTrackCategory={`${slug} Waitlist`} />
      {!disabledInviteFriendPopup && (
        <InviteFriendPopup
          userId={userId}
          product={product}
          dataTrackCategory="Waitlist Share Popup"
        />
      )}
    </>
  );
}
