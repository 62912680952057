import gql from 'graphql-tag';
import { getCookie } from '../cookie';
import { handleError } from '../error';

const SN_V2_HOST = process.env.API_V2_URL;
const BEARER_TOKEN = getCookie('User_Token');

export function getAvailableDiscounts(wantID = null, brandID = null) {
  const url = new URL(`${SN_V2_HOST}discount/available-discounts`);

  if (wantID) {
    url.searchParams.append('wantID', wantID);
  }

  if (brandID) {
    url.searchParams.append('brandID', brandID);
  }

  return fetch(url.href, {
    headers: {
      Authorization: `Bearer ${BEARER_TOKEN}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);
      }
      return response.json();
    })
    .catch(handleError);
}

export const getDiscountInformation = async (discountId) => {
  const response = await fetch(`${SN_V2_HOST}discount/${discountId}`, {
    headers: {
      Authorization: `Bearer ${BEARER_TOKEN}`,
    },
  });
  if (!response.ok) {
    throw new Error('Discount not found.');
  }
  return response.json();
};

export const createUserDiscount = async (discountId, wantId = null) => {
  const queryParams = wantId ? `?wantID=${wantId}` : '';
  const response = await fetch(`${SN_V2_HOST}discount/${discountId}/user${queryParams}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${BEARER_TOKEN}`,
    },
  });
  if (!response.ok) {
    throw new Error('This action could not be processed. Please try again.');
  }
  return response.json();
};

export const getRedeemedDiscounts = async () => {
  const response = await fetch(`${SN_V2_HOST}discount/user/redeemed`, {
    headers: {
      Authorization: `Bearer ${BEARER_TOKEN}`,
    },
  });
  if (!response.ok) {
    throw new Error(response);
  }
  return response.json();
};

export const getWantByDiscountId = (discountId) => gql`
	query getWantByDiscountId {
		wants(where: {campaignData: {discounts: {id: {_eq: ${discountId}}}}}, limit: 1) {
			id
		}
	}
`;

export const getCompanyIdFromUserDiscount = (userDiscountId) => gql`
  query GetCampaignFromDiscounts {
    user_discounts(where:{ id: {_eq: ${userDiscountId} } }) {
      discount {
        campaign {
          samplekit {
            company
          }
        }
      }
    }
  }
`;
