import React, { useState } from 'react';
import { CampaignContext } from '.';

export default function CampaignContextProvider({ children, campaignInfo }) {
  const [campaign, setCampaign] = useState(campaignInfo || {});
  const [featureFlags, setFeatureFlags] = useState({});

  const value = { campaign, setCampaign, featureFlags, setFeatureFlags };

  return <CampaignContext.Provider value={value}>{children}</CampaignContext.Provider>;
}
