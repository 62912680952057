import React from 'react';
import { RadioButtonGroup } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { isNil } from 'ramda';
import { errorMessages } from '../../../utils/customQuestionTypes';

const CustomRadioGroup = (props) => {
  const { name, error, question } = props;
  const { required = true, choices } = question;
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      as={RadioButtonGroup}
      control={control}
      options={choices}
      error={!isNil(error)}
      rules={{ required: required && errorMessages.REQUIRED }}
    />
  );
};

export default CustomRadioGroup;
