import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { handleError } from '../error';
import getUserId from '../userId';
import { errorMessages } from '../customQuestionTypes'; // TODO: create error message file
import { getCookie } from '../cookie';

const V2_HOST = process.env.API_V2_URL;

// Used by @apollo
// id on path is Tracking user ID
const GET_USER = gql`
  query ($id: String) {
    user(id: $id) @rest(type: "User", path: "user/{args.id}") {
      id
      firstName
      country
    }
  }
`;

// Used by apollo-client
export const getUserFromTrackingId = (userTrackId) => gql`
  query getUser {
    users: users_public(where: { user_tracking_id: { _eq: "${userTrackId}" } }) {
      id
      address(path: "postCode")
      phone: address(path: "phone")
      sms_optin
      sms_optin_date
      properties
    }
  }
`;

export const useQueryUser = () => {
  const { loading, data } = useQuery(GET_USER, {
    variables: {
      id: getUserId(),
    },
  });

  if (data) {
    return { loading, data: data.user };
  }

  return { loading, data };
};

const handleFetch = (promise) =>
  promise
    .then((response) => {
      if (!response.ok) {
        handleError(response);
      }
      const contentType = response.headers.get('Content-Type') || '';
      if (contentType.includes('application/json')) {
        return response.json();
      } else if (contentType.includes('text/')) {
        return response.text();
      } else {
        return response;
      }
    })
    .then((data) => data)
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });

export const createPendingAccountIfNotExist = (email, postCode) =>
  handleFetch(
    fetch('/public/users/pending', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        postCode,
      }),
    }),
  );

export const login = (email, password) =>
  handleFetch(
    fetch('/account/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
      }),
    }),
  );

export const updateEmailConsent = (id, emailConsent) =>
  handleFetch(
    fetch(`${process.env.BASE_URL.replace(/\/$/, '')}/public/users/${id}/emailconsent`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie('User_Token')}`,
      },
      body: JSON.stringify({
        emailConsent,
      }),
    }),
  );

export const updateAddressWithPostCode = (id, postCode) =>
  handleFetch(
    fetch(`/public/users/${id}/postcode`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        postalCode: postCode,
      }),
    }),
  );

export const updatePhoneAndConsent = (id, phoneNumber, smsConsent, trackEventLabelType) =>
  handleFetch(
    fetch(`/public/users/${id}/phonenumberandconsent?trackEventLabelType=${trackEventLabelType}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phoneNumber,
        smsConsent,
      }),
    }),
  );

export const cleanUserNotifications = () =>
  handleFetch(
    fetch(`/account/notifications/cleancache`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }),
  );

export const register = (userData) =>
  handleFetch(
    fetch('/account/register', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify(userData),
    }),
  );

export const acceptTermsAndConditions = (userId) =>
  handleFetch(
    fetch(`${V2_HOST}user/${userId}/terms-and-conditions`, {
      headers: {
        Authorization: `Bearer ${getCookie('User_Token')}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({
        accepted: true,
      }),
    }),
  );

export const getFirstNameAndLastName = (name) => {
  if (!name) {
    return {
      firstName: '',
      lastName: '',
    };
  }
  const parts = name.split(' ');

  return {
    firstName: parts[0],
    lastName: parts.slice(1).join(' '),
  };
};

export default GET_USER;

export const wantProduct = (url) => url && url.includes('want-boost');

export async function postAddress(postAPI, data) {
  const response = await fetch(postAPI, {
    method: 'post',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(data),
  });

  if (response.ok) return response.json();

  throw response;
}

export function useShippingInfo() {
  const [data, setData] = useState({});

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('/account/shippinginfo', {
        method: 'get',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
      });
      if (response.ok) {
        const info = await response.json();
        setData(info);
      }
    }
    fetchData();
  }, []);

  async function updateShippingInfo(formData) {
    try {
      const response = await fetch('/account/shippinginfo', {
        method: 'post',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json;charset=UTF-8',
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        return null;
      }
      if (response.status === 400) {
        const errors = await response.json().then((e) => e);
        return {
          errors,
        };
      }
      handleError(response);
      return { error: errorMessages.SERVER_ERROR };
    } catch (error) {
      handleError(error);
      return { error: errorMessages.SERVER_ERROR };
    }
  }

  return { data, updateShippingInfo };
}

export function usePendingUserInfo(userTracking) {
  const [data, setData] = useState({ inProgress: true });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(`${V2_HOST}user/${userTracking}/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
          },
        });
        if (response.ok) {
          const profile = await response.json();
          setData({ ...profile, inProgress: false });
        } else {
          throw new Error('Failed to fetch user profile');
        }
      } catch (error) {
        setData({ inProgress: false });
      }
    }

    if (userTracking && userTracking.length > 0) {
      fetchData();
    } else {
      setData({ inProgress: false });
    }
  }, [userTracking]);

  return { data };
}

export const handleZendeskLogin = async () => {
  try {
    const response = await fetch(`${V2_HOST}user/zendesk-token`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${getCookie('User_Token')}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Failed to generate JWT token');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    return handleError(error);
  }
};

export const updateUserEmail = async (email) =>
  handleFetch(
    fetch(`${V2_HOST}user/email`, {
      headers: {
        Authorization: `Bearer ${getCookie('User_Token')}`,
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({
        email: email,
      }),
    }),
  );
