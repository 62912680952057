import React from 'react';
import { Text } from 'grommet';

export const CompanyName = ({ children }) => (
  <div>
    <Text color="slate" size="small">
      {children}
    </Text>
  </div>
);

export const ProductName = ({ children }) => (
  <div>
    <Text weight="bold" color="slate" size="medium">
      {children}
    </Text>
  </div>
);
