import React from 'react';
import styled from 'styled-components';
import { Box, Button, ResponsiveContext } from 'grommet';
import Container from '../../../Atoms/Container';
import P from '../../../Atoms/Paragraph';
import Span from '../../../Atoms/Span';
import ArrowRight from '../../../Atoms/Icons/ArrowRight';
import Cross from '../../../Atoms/Icons/Cross';

const TextLink = styled.a`
  color: #fefefe;
  font-weight: bold;
  &:hover span {
    text-decoration: underline;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  right: 8px;
  top: ${(props) => (props.isSmall ? '8px' : '17px')};
`;

const BannerBar = ({ handleClose }) => {
  const size = React.useContext(ResponsiveContext);
  const isSmall = size === 'small' || size === 'xsmall';

  const onClick = () => {
    handleClose(false);
  };
  return (
    <Box background="launchpad">
      <Container>
        <Box
          direction="row"
          gap="small"
          justify="center"
          align={isSmall ? 'start' : 'center'}
          pad={{ top: 'small', bottom: 'small' }}
        >
          <Box direction="row-responsive" gap="xsmall" align="center" pad={{ right: '24px' }}>
            <P color="white">Update your profile so we can match you with FREE samples! </P>
            <TextLink href="/account/profile">
              <Box direction="row" gap="3px" align="center">
                <Span color="white" weight="bold">
                  Complete Profile
                </Span>
                <ArrowRight size={16} color="white" />
              </Box>
            </TextLink>
          </Box>
        </Box>
      </Container>
      <CloseButton isSmall={isSmall}>
        <Button
          plain
          icon={<Cross size={24} fill="#FEFEFE" />}
          hoverIndicator="launchpad"
          onClick={onClick}
        />
      </CloseButton>
    </Box>
  );
};

export default BannerBar;
