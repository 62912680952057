import React from 'react';
import { Text, ResponsiveContext } from 'grommet';
import styled from 'styled-components';

const CardBadge = ({ text }) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <Tag isXsmall={isXsmall}>
      <Text textAlign="center" color="text" weight="bold" size="small">
        {text}
      </Text>
    </Tag>
  );
};

export default CardBadge;

const Tag = styled.div`
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  z-index: 2;
  background: #cccccc;
  border-radius: 8px 8px 0 0;
  text-align: center;
  text-transform: uppercase;
  padding: 4px 0;
`;
