// @ts-check
import React from 'react';
import PrimaryButton from '../../Atoms/Buttons/PrimaryButton';

/**
 * @typedef {{
 * href: string,
 * onClick: Function,
 * productId: number,
 * productSlug: string,
 * label: string,
 * icon?: React.ComponentElement,
 * disabled?: boolean,
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function ApplyCampaignButton(props) {
  const { href, onClick, productId, productSlug, label, icon, disabled } = props;

  return (
    <PrimaryButton
      icon={icon}
      label={label}
      href={href}
      onClick={onClick}
      color="pink"
      size="small"
      gap="xsmall"
      data-tagmanager-event={label}
      data-cy={label}
      data-track-category="Product-Button"
      data-track-action={label}
      data-track-samplekit-id={productId}
      data-track-label={productSlug}
      disabled={disabled}
    />
  );
}
