import React from 'react';

const FBMessenger = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.94679 19.9802V17.1342C5.14843 15.7777 4 13.7119 4 11.3982C4 7.31228 7.58171 4 12 4C16.4186 4 20 7.31228 20 11.3982C20 15.4839 16.4186 18.7965 12 18.7965C11.1757 18.7965 10.3801 18.6811 9.63171 18.4673L6.94679 19.9802ZM6.83131 13.8994L11.1457 9.37183L13.2164 11.4697L17.0992 9.37183L12.8092 13.8994L10.7577 11.7642L6.83131 13.8994Z"
      fill="white"
    />
  </svg>
);

export default FBMessenger;
