import React from 'react';

export default function Marker({ width = 40, height = 40, className = '', viewBox = '0 0 40 40' }) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5506 2.91038C11.9646 2.91038 7.40869 7.41825 7.40869 13.0523C7.40869 17.1365 9.23896 21.1271 11.4929 24.4136C13.6991 27.6523 16.1878 30.1409 17.5484 31.3625C18.8633 30.1411 21.3978 27.6543 23.6039 24.4136C25.8578 21.0794 27.6881 17.1364 27.6881 13.0523C27.6441 7.41844 23.1381 2.91038 17.5499 2.91038H17.5506ZM4.50019 13.0044C4.50019 5.82076 10.3209 0 17.5046 0C24.6883 0 30.5091 5.82076 30.5091 13.0044C30.5091 17.9341 28.3487 22.4422 25.9077 26.0089C23.4667 29.5778 20.7433 32.2536 19.3348 33.5208C18.2546 34.4597 16.7049 34.4597 15.6266 33.5208C14.2181 32.2535 11.4489 29.5301 9.05369 26.0089C6.70624 22.4877 4.5 17.9341 4.5 13.0044H4.50019ZM17.5506 10.1416C15.9551 10.1416 14.6402 11.4566 14.6402 13.0521C14.6402 14.6476 15.9551 15.9626 17.5506 15.9626C19.1462 15.9626 20.4611 14.6476 20.4611 13.0521C20.4153 11.4089 19.148 10.1416 17.5506 10.1416ZM11.7299 13.0044C11.7299 9.81153 14.3121 7.22923 17.5051 7.22923C20.6981 7.22923 23.2803 9.81144 23.2803 13.0044C23.2803 16.1975 20.6981 18.7797 17.5051 18.7797C14.3599 18.7797 11.7299 16.1975 11.7299 13.0044Z"
        fill="#37C0C0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 18.0044C9.5 10.8208 15.3208 5 22.5044 5C29.6881 5 35.5089 10.8208 35.5089 18.0044C35.5089 22.9341 33.3485 27.4422 30.9075 31.0089C28.4665 34.5778 25.7431 37.2536 24.3346 38.5208C23.2544 39.4597 21.7047 39.4597 20.6264 38.5208C19.2179 37.2535 16.4487 34.5301 14.0535 31.0089C11.706 27.4877 9.5 22.9341 9.5 18.0044Z"
        fill="#37C0C0"
        fillOpacity="0.2"
      />
    </svg>
  );
}
