import styled from 'styled-components';
import basicTheme from '../themes';

const Hr = styled.hr`
  height: ${(props) => (props.height ? props.height : '0px')};
  border-color: ${(props) =>
    props.color ? basicTheme.global.colors[props.color] : basicTheme.global.colors.silver};
  border-width: ${(props) => (props.borderWidth ? props.borderWidth : '1px 0 0')};
  border-style: ${(props) => (props.borderStyle ? props.borderStyle : 'solid')};
  background: ${(props) => (props.background ? props.background : '')};
  margin: ${(props) => (props.margin ? props.margin : '1rem 0')};
`;

export default Hr;
