import { PrimaryButton as PrimaryButtonUI, themes } from 'socialnature-ui';
import styled from 'styled-components';

// Hover and focus styles are to overlap site.css
const PrimaryButton = styled(PrimaryButtonUI)`
  &[disabled]:focus,
  &[disabled]:hover {
    background: ${themes.global.colors.silver};
    color: ${themes.global.colors.iron};
  }
`;

export default PrimaryButton;
