import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { CarouselProvider } from 'pure-react-carousel';
import H from '../../Atoms/Heading';
import HeadingDescription from '../../Molecules/HeadingDescription';
import LaunchpadCard from '../../Pages/LaunchpadPage/LaunchpadCard';
import ArrowRight from '../../Atoms/Icons/ArrowRight';
import TextLink from '../../Atoms/TextLink';
import LaunchpadLearnMore from '../../Molecules/Buttons/LaunchpadLearnMore';
import Carousel from './Carousel';

const LaunchpadList = ({
  title = 'Boost innovative brands into stores',
  description,
  products,
  linkViewAll,
  dataTrackCategory,
}) => {
  const [height, setHeight] = React.useState(0);
  const [width, setWidth] = React.useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const size = React.useContext(ResponsiveContext);
  const slides = {
    xsmall: 2,
    small: 2,
    medium: 3,
    large: 4,
  };
  const isXsmall = size === 'xsmall';

  if (!products) return <></>;

  return (
    <>
      <Box direction="row-responsive" gap={!isXsmall ? 'small' : 'none'} align="baseline">
        <H level={2}>{title}</H>
        <ButtonBlock>
          <LaunchpadLearnMore />
        </ButtonBlock>
      </Box>
      {description && <HeadingDescription>{description}</HeadingDescription>}
      <CarouselProvider
        naturalSlideWidth={Math.round(width)}
        naturalSlideHeight={Math.round(height) + 32}
        totalSlides={products.length}
        lockOnWindowScroll
        step={slides[size]}
        visibleSlides={slides[size]}
      >
        <Carousel
          products={products}
          size={size}
          measuredRef={measuredRef}
          renderSlide={(product, index) => (
            <LaunchpadCard
              product={product}
              width="100%"
              height="100%"
              dataTrackCategory={dataTrackCategory}
              index={index}
            />
          )}
        />
      </CarouselProvider>
      {linkViewAll && (
        <Box align="end" margin={{ top: 'small' }}>
          <TextLink
            label="VIEW ALL"
            href={linkViewAll}
            fontWeight="bold"
            icon={<ArrowRight color="launchpad" />}
            right
          />
        </Box>
      )}
    </>
  );
};

export default LaunchpadList;

const ButtonBlock = styled.div`
  margin-bottom: 8px;
`;
