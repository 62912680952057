import React from 'react';
import { Box } from 'grommet';
import Spinner from '../Atoms/Icons/Spinner';

const LoadingSection = () => (
  <Box pad="medium" align="center">
    <Spinner htmlColor="#37C0C0" />
  </Box>
);

export default LoadingSection;
