import React from 'react';
import { Box } from 'grommet';
import Span from '../Span';
import Check from '../Icons/Check';

const InlineMessage = ({ msg }) => (
  <Box direction="row" align="center" gap="4px">
    <Check color="green" width="16px" height="16px" />
    <Span color="green" size="small">
      {msg}
    </Span>
  </Box>
);

export default InlineMessage;
