// @ts-check
import React, { useContext } from 'react';
import { ResponsiveContext } from 'grommet';
// @ts-ignore
import { Box, TextLink, ArrowRight, ArrowLeft } from 'socialnature-ui';
import styled from 'styled-components';
import { TrackingEventType } from '../../utils/tracking';
import { trackEvent as trackGTMEvent } from '../../utils/gtm';

export const StyledTextLink = styled(TextLink)`
  &:hover {
    text-decoration: ${(props) => props.isXsmall && 'none'};
  }
`;

export function LocationListFooterButtons({ prevPage, nextPage, hasPrevPage, hasNextPage }) {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const handleMoreStoresClick = () => {
    trackGTMEvent(TrackingEventType.LOAD_MORE_STORES_CLICKED);
    nextPage();
  };
  return (
    <Box
      style={{
        display: 'grid',
        gridTemplateColumns: '[prev] 1fr [next] 2fr',
      }}
    >
      {hasPrevPage && (
        <StyledTextLink
          data-testid="BackButton"
          label="BACK"
          fontWeight="bold"
          icon={<ArrowLeft color="launchpad" />}
          style={{ gridArea: 'prev' }}
          onClick={prevPage}
          isXsmall={isXsmall}
        />
      )}
      {hasNextPage && (
        <StyledTextLink
          data-testid="MoreStores"
          label="MORE STORES"
          fontWeight="bold"
          icon={<ArrowRight color="launchpad" />}
          iconRight
          style={{ gridArea: 'next', textAlign: 'right' }}
          onClick={handleMoreStoresClick}
          isXsmall={isXsmall}
        />
      )}
    </Box>
  );
}
