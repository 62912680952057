import React from 'react';
import styled from 'styled-components';
import H from '../../../Atoms/Heading';
import ArrowUp from '../../../Atoms/Icons/ArrowUp';
import ArrowDown from '../../../Atoms/Icons/ArrowDown';
import TextButton from '../../../Atoms/Buttons/TextButton';
import FilterCheckBox from '../FilterCheckBox';

const FilterCategory = ({ name, categories }) => {
  const [open, setOpen] = React.useState(false);

  if (!categories) return <></>;

  const defaultLength = 4;
  let firstCategories = [];
  let secondCategories = [];
  if (categories.length <= defaultLength) {
    firstCategories = [...categories];
  } else {
    firstCategories = categories.slice(0, 4);
    secondCategories = categories.slice(4, categories.length);
  }

  return (
    <Wrapper>
      <H level={4} transform="uppercase">
        {name}
      </H>
      {firstCategories.map((category) => (
        <CheckBoxWrapper key={category.slug}>
          <FilterCheckBox
            key={category.slug}
            type={category.type}
            slug={category.slug}
            name={category.name}
            qty={category.qty}
          />
        </CheckBoxWrapper>
      ))}
      {open &&
        secondCategories.map((category) => (
          <CheckBoxWrapper key={category.slug}>
            <FilterCheckBox
              key={category.slug}
              type={category.type}
              slug={category.slug}
              name={category.name}
              qty={category.qty}
            />
          </CheckBoxWrapper>
        ))}
      {secondCategories.length > 0 && (
        <TextButton
          onClick={() => {
            setOpen(!open);
          }}
          icon={open ? <ArrowUp color="launchpad" /> : <ArrowDown color="launchpad" />}
          label={open ? 'SEE LESS' : 'SEE ALL'}
          weight="bold"
        />
      )}
    </Wrapper>
  );
};

export default FilterCategory;

const Wrapper = styled.div`
  margin-top: 32px;
`;

const CheckBoxWrapper = styled.div`
  margin-bottom: 8px;
`;
