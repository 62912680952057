import React from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
/*
type Variant = 'round' | undefined;
*/
const Button = styled.a`
  background: linear-gradient(344.15deg, #37aec0 -0.39%, #08adcd 99.69%);
  border: 1px solid #08adcd;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  width: ${(props) => (props.isXsmall ? 'calc(50% - 8px)' : '135px')};
  margin: ${(props) => (props.isXsmall ? '8px 0' : '8px 16px 8px 0')};
  ${(props) => props.lastChild && 'margin: 8px 0'};
`;

const RoundButton = styled.a`
  background: linear-gradient(314.95deg, #37aec0 -0.39%, #08adcd 99.69%);
  border: 1px solid #08adcd;
  box-sizing: border-box;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4px;
`;

const ButtonLabel = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  padding-left: 14px;
  white-space: nowrap;
`;

const ShareButton = (props) => {
  const { icon, label: labelProp, variant, ...other } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const ButtonStyle = variant === 'round' ? RoundButton : Button;
  const label = variant !== 'round' ? labelProp : null;

  return (
    <ButtonStyle {...other} isXsmall={isXsmall}>
      {icon}
      {label && <ButtonLabel>{label}</ButtonLabel>}
    </ButtonStyle>
  );
};

export default ShareButton;
