import React from 'react';
import { Text } from 'grommet';

const P = ({ margin, color, textAlign, children, size, weight, style = undefined, dataCy }) => (
  <Text
    as="p"
    color={color || 'black'}
    textAlign={textAlign}
    margin={margin || { bottom: 'none' }}
    weight={weight}
    size={size || 'medium'}
    style={style}
    data-cy={dataCy}
  >
    {children}
  </Text>
);

export default P;
