import React from 'react';
import { Box } from 'grommet';

const CardContent = ({ children }) => (
  <Box
    pad={{
      top: 'small',
      right: 'small',
      bottom: 'xsmall',
      left: 'small',
    }}
    flex="grow"
  >
    {children}
  </Box>
);

export default CardContent;
