import { useEffect, useState } from 'react';

const useLocationAndSessionFromPath = () => {
  const [locationData, setLocationData] = useState({ postalCode: null, userRedirectionSessionID: null });

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const postalCode = query.get("postalcode");
    const userRedirectionSessionID = query.get("user-session");

    if (postalCode || userRedirectionSessionID) {
      // Store postal code in localStorage
      if (postalCode) {
        window.localStorage.setItem("cn_location", postalCode);
        query.delete("postalcode");
      }

      // Update the URL without the removed parameters
      window.history.replaceState(
        {},
        "",
        window.location.pathname + "?" + query.toString() // New URL without postalcode and session
      );

      setLocationData({ postalCode, userRedirectionSessionID });
    }
  }, []);

  return locationData;
}

export default useLocationAndSessionFromPath;