import React from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import ShareIcon from '../../../Atoms/Icons/sns/Share';
import SocialShareModal from '../../../Organisms/Product/SocialShareModal';

const ShareButton = styled(ShareButtonStyle)`
  width: 120px;
`;

const ShareButtonPopup = (props) => {
  const { userId, product, isAccepted, dataTrackCategory } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [open, setOpen] = React.useState(null);
  const dataAttributes = Object.fromEntries(
    Object.entries(props).filter(([key]) => key.match(/^data-.+/) !== null),
  );

  return (
    <>
      <ShareButton
        isXsmall={isXsmall}
        onClick={() => setOpen(true)}
        icon={<ShareIcon />}
        label="Share"
        {...dataAttributes}
      />
      {open && (
        <SocialShareModal
          open={open}
          userId={userId}
          product={product}
          isAccepted={isAccepted}
          onClose={() => setOpen(false)}
          dataTrackCategory={dataTrackCategory}
        />
      )}
    </>
  );
};

export default ShareButtonPopup;
