import React, { useContext } from 'react';
import { Box, Card, CardContent, Container } from 'socialnature-ui';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import ToastMessage from '../../../Molecules/SignInPage/ToastMessage';
import { useSignIn } from '../../../contexts/SignInContext/useSignIn';
import Navbar from '../../../Atoms/SignInPage/Navbar';

const MainContainer = styled.div`
  ${({ isXsmall, stickyToBottom }) =>
    isXsmall &&
    `
    ${stickyToBottom ? 'background: url(/images/signInPage/bg-mobile.jpg);' : ''}
    background-size: cover;
    flex-direction: column;
    display: flex;
  `}
`;

const StyledContainer = styled(Container)`
  padding: 0;
  ${({ isXsmall, stickyToBottom }) =>
    isXsmall &&
    /** NOTE: min-height added to avoid scroll.
         84 is the max value to avoid scroll and keep the card container in the farthest position from the top. */
    `
    min-height: 84vh;
    flex-direction: column;
    display: flex;
    justify-content: ${stickyToBottom ? 'flex-end' : 'center'};
  `}
`;

const StyledBox = styled(Box)`
  ${({ isXsmall, stickyToBottom }) =>
    isXsmall &&
    stickyToBottom &&
    // margin-bottom to avoid showing the bg of the page between the component and the end of the .container
    `
    & > div > div {
      border-radius: 8px 8px 0 0;
      margin-bottom: -1px;
      box-shadow: none;
    }
  `}
`;

export default function Layout({
  children,
  fullChildren,
  cardWidth,
  stickyToBottom = true,
  showToast = true,
}) {
  const { vars } = useSignIn();
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  const { message, toastType } = vars || {};

  return (
    <MainContainer isXsmall={isXsmall} stickyToBottom={stickyToBottom}>
      <Navbar isXsmall={isXsmall} />
      <StyledContainer isXsmall={isXsmall} stickyToBottom={stickyToBottom}>
        <StyledBox
          align={isXsmall && stickyToBottom ? 'end' : 'center'}
          isXsmall={isXsmall}
          stickyToBottom={stickyToBottom}
        >
          {showToast && !!message && <ToastMessage message={message} type={toastType} />}
          {fullChildren || (
            <Card width={isXsmall && stickyToBottom ? '100%' : cardWidth || '382px'} noPadding>
              <CardContent pad={{ horizontal: isXsmall ? '16px' : '24px', top: '24px' }}>
                {children}
              </CardContent>
            </Card>
          )}
        </StyledBox>
      </StyledContainer>
    </MainContainer>
  );
}
