// script used to call the SMS_CONSENT popup
import React from 'react';
import ReactDOM from 'react-dom';
import { Grommet } from 'grommet';
import { ApolloProvider } from '@apollo/react-hooks';
import clientGraphql from './utils/apis/graphql';
import basicTheme from './themes';
import { useSmsOptInModal } from './hooks/useSmsOptInModal/index';
import { getCookie } from './utils/cookie';
import getUserId from './utils/userId';
import { isFeatureOn, SplitNames } from './utils/splitio';

// If notificationCallback is provided popup is being opened from a notification directly
window.renderSmsOptInPopUp = async function renderSmsOptInPopUp(notificationCallback) {
  const userTrackingId = getUserId();
  isFeatureOn(SplitNames.SMS_CONSENT).then((smsConsentFeatureFlag) => {
    if (smsConsentFeatureFlag) {
      if (notificationCallback) {
        // First remove DOM element to avoid weird behaviors
        // since element is being mounted from two different places
        ReactDOM.unmountComponentAtNode(document.querySelector('#sms-optin-popup'));
      }
      ReactDOM.render(
        <Grommet theme={basicTheme}>
          <ApolloProvider client={clientGraphql}>
            <SmsOptInPopUp
              userTrackingId={userTrackingId}
              notificationCallback={notificationCallback}
            />
          </ApolloProvider>
        </Grommet>,
        document.querySelector('#sms-optin-popup'),
      );
    }
  });
};

function SmsOptInPopUp({ userTrackingId, notificationCallback }) {
  const showSmsOptinPopup = getCookie('showSmsOptinPopup2') === 'true';
  if (!notificationCallback && showSmsOptinPopup) {
    // if popup is opened normally then reviewreminderpopup should no be shown until next session
    window.sessionStorage.setItem(['showReviewReminderUntilNextSession'], [true]);
  }
  const loggedIn = !!userTrackingId;
  const shouldShowPopup = (showSmsOptinPopup || !!notificationCallback) && loggedIn;
  const { SmsOptinModal } = useSmsOptInModal(shouldShowPopup, userTrackingId, notificationCallback);

  if (!shouldShowPopup && !notificationCallback) return null;

  return (
    <SmsOptinModal userTrackingId={userTrackingId} notificationCallback={notificationCallback} />
  );
}

window.renderSmsOptInPopUp();
