import { useCallback, useEffect, useMemo, useState } from 'react';

const PAGE_SIZE = 3;

// Controls the Locationlist pagination
export function useLocationList(locations) {
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (Array.isArray(locations)) {
      setCurrentPage(1);
    }
  }, [locations]);

  const maxPage = useMemo(() => {
    if (Array.isArray(locations)) {
      return Math.ceil(locations.length / PAGE_SIZE);
    }
    return 0;
  }, [locations]);

  const hasPrevPage = useMemo(() => currentPage > 1, [currentPage]);
  const hasNextPage = useMemo(() => currentPage < maxPage, [currentPage, maxPage]);
  const current = useMemo(() => {
    if (maxPage > 1) {
      return locations.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE);
    }
    return locations;
  }, [currentPage, maxPage, locations]);

  const prevPage = useCallback(() => {
    if (hasPrevPage) {
      setCurrentPage((page) => page - 1);
    }
  }, [hasPrevPage]);

  const nextPage = useCallback(() => {
    if (hasNextPage) {
      setCurrentPage((page) => page + 1);
    }
  }, [hasNextPage]);

  return { current, prevPage, nextPage, hasPrevPage, hasNextPage };
}
