import { themes } from 'socialnature-ui';
import styled from 'styled-components';
import basicTheme from '../../../themes';

const BasicInput = styled.input`
  &[type='text'] {
    font-size: 16px;
    border-radius: 4px;
    box-shadow: unset;
    height: 48px;
    margin: 0;
    ${({ error }) => error && `border-color: ${themes.global.colors.red};`}
  }

  &#${({ id }) => id}[type='text']:focus {
    border-color: ${basicTheme.global.colors.launchpad};
    background: ${basicTheme.global.colors.anotherWhite};
    border-color: ${({ error }) => `${themes.global.colors[error ? 'red' : 'blue']}`};
  }
`;

export default BasicInput;
