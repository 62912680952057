import React from 'react';
import createSvgIcon from './SvgIcon';

export default createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M6.5472 15.7266C4.27392 16.2232 4.7424 18.2507 4.33536 19.6613C5.74336 19.2542 7.77344 19.7227 8.27008 17.4494C7.56096 18.0818 6.95424 18.5528 5.79968 18.197C5.44384 17.0424 5.91488 16.4382 6.5472 15.7266ZM16.3238 7.67535C16.9766 8.32815 16.9766 9.38287 16.3238 10.0357C15.671 10.6885 14.6163 10.6885 13.9635 10.0357C13.3107 9.38287 13.3107 8.32815 13.9635 7.67535C14.6163 7.02255 15.6736 7.02255 16.3238 7.67535ZM10.4077 19.9992C10.6816 18.627 10.6893 17.5237 10.5715 16.5432C10.1491 16.7378 9.26336 17.2011 8.76672 16.7045L7.29728 15.235C6.79808 14.7358 7.264 13.8501 7.456 13.4302C6.47552 13.3099 5.37216 13.3176 4 13.5915C4.5888 11.9326 5.58208 10.4914 6.2272 10.0818C7.3792 9.34959 8.49536 9.23695 9.4144 9.30351C11.7184 6.08559 15.3792 3.31567 19.8515 4.15023C20.6886 8.62255 17.9162 12.2808 14.6982 14.5874C14.7648 15.5064 14.6522 16.6226 13.92 17.772C13.5078 18.4171 12.0691 19.4104 10.4077 19.9992Z"
  />,
  'Boost',
);
