// Kept this to support existing code
export const getLocalStorage = (name) => {
  const value = window.localStorage.getItem(name);

  return value && JSON.parse(value);
};

export const getLocalStorageV2 = (name) => {
  try {
    const value = window.localStorage.getItem(name);
    return value ? (typeof value === 'string' ? value : JSON.parse(value)) : null;
  } catch {
    return null;
  }
};

export const setLocalStorage = (name, value) => {
  window.localStorage.setItem(name, JSON.stringify(value));
};

export const getSessionStorage = (name) => {
  try {
    const value = window.sessionStorage.getItem(name);
    // eslint-disable-next-line no-nested-ternary
    return value ? (typeof value === 'string' ? value : JSON.parse(value)) : null;
  } catch {
    return null;
  }
};

export const setSessionStorage = (name, value) => {
  window.sessionStorage.setItem(name, typeof value !== 'string' ? JSON.stringify(value) : value);
};

export const removeSessionStorage = (key) => {
  window.sessionStorage.removeItem(key);
};

export const STORAGE_KEYS = {
  nextNavigationPage: 'nextNavigationPage',
  userTempPhoneNumber: 'userTempPhoneNumber',
};
