import React, { useMemo } from 'react';
import { isNil } from 'ramda';
import { Box, InputLabel, Text, Error } from 'socialnature-ui';
import { useFormContext } from 'react-hook-form';
import {
  customQuestionTypes,
  errorMessages,
  customQuestionAnswerTypes,
} from '../../utils/customQuestionTypes';
import { isIncludedAnswer } from '../../utils/formatCustomQuestion';
import CustomQuestionWrapper from './CustomQuestionWrapper';
import {
  CustomTextInput,
  CustomDateInput,
  CustomSliderInput,
  CustomRadioGroup,
  CustomCheckBoxGroup,
  AgeRangeInput,
  DueDateInput,
  CheckBoxGroupWithLoadMore,
  MultipleTextInput,
} from './Fields';
import CustomCheckBox from './Fields/CustomCheckbox';

const CustomQuestion = (props) => {
  const { name, question, error, questionIdMap } = props;
  const {
    questionId,
    index,
    questionText,
    questionType,
    questionEditType,
    questionTooltip,
    loadMore,
    required = true,
    answerId,
    disableWhyWeAskText,
  } = question;
  const { register, unregister, watch, setValue, getValues } = useFormContext();

  const FormField = useMemo(() => {
    const valueName = `${name}.Value`;
    // TODO: after designed DB, we will use questionType instead of questionEditType
    switch (questionEditType) {
      case customQuestionTypes.TEXT:
        return (
          <CustomQuestionWrapper error={!isNil(error)}>
            <CustomTextInput name={valueName} error={error} question={question} />
          </CustomQuestionWrapper>
        );
      case customQuestionTypes.DATE:
        return (
          <CustomQuestionWrapper error={!isNil(error)}>
            <CustomDateInput name={valueName} error={error} question={question} />
          </CustomQuestionWrapper>
        );
      case customQuestionTypes.LIKERT:
        return <CustomSliderInput name={valueName} error={error} question={question} />;
      case customQuestionTypes.SLIDER:
        return <CustomSliderInput name={valueName} error={error} question={question} unit="%" />;
      case customQuestionTypes.LIST:
        return <CustomRadioGroup name={valueName} error={error} question={question} />;
      case customQuestionTypes.MULTIPLE:
        if (loadMore) {
          return <CheckBoxGroupWithLoadMore name={valueName} error={error} question={question} />;
        }
        return (
          <CustomQuestionWrapper error={!isNil(error)}>
            <CustomCheckBoxGroup name={valueName} error={error} question={question} />
          </CustomQuestionWrapper>
        );
      case customQuestionTypes.AGERANGE:
        return <AgeRangeInput name={name} error={error} question={question} />;
      case customQuestionTypes.MULTIPLE_TEXT:
        return <MultipleTextInput name={name} error={error} question={question} />;
      case customQuestionTypes.DUE_DATE:
        return (
          <CustomQuestionWrapper error={!isNil(error)}>
            <DueDateInput name={valueName} error={error} question={question} />
          </CustomQuestionWrapper>
        );
      case customQuestionTypes.CHECKBOX:
        return <CustomCheckBox name={valueName} error={error} question={question} />;
      default:
    }
    return null;
  }, [error, loadMore, name, question, questionEditType]);

  const isShow = useMemo(() => {
    const { dependsOnQuestion: dependsOnQuestionId, dependsOnAnswer } = question;
    if (!dependsOnQuestionId || !questionIdMap) return true;

    const dValue = watch(`CustomQuestions[${questionIdMap.get(dependsOnQuestionId)}].Value`);

    if (!dValue) return false;

    if (isIncludedAnswer(dValue, dependsOnAnswer)) {
      // HACK: To update the watching data with one change
      // Will be rendered 3 times
      if (!getValues(`CustomQuestions[${index}].Index`)) {
        setValue(`CustomQuestions[${index}].Index`, index);
      }
      return true;
    }
    return false;
  }, [getValues, index, question, questionIdMap, setValue, watch]);

  if (!isShow) {
    unregister(`CustomQuestions[${index}].Index`);
    unregister(`CustomQuestions[${index}].QuestionId`);
    unregister(`CustomQuestions[${index}].QuestionType`);
    unregister(`CustomQuestions[${index}].AnswerId`);
    unregister(`CustomQuestions[${index}].AnswerType`);
    return null;
  }

  return (
    <Box margin={{ top: 'medium', bottom: 'xlarge' }}>
      <Box margin={{ bottom: '16px' }}>
        <InputLabel
          required={required}
          option={!required ? '(optional)' : null}
          tooltipId={`tooltip${questionId}`}
          tooltip={
            questionTooltip && (
              <>
                {!disableWhyWeAskText && (
                  <Text as="span" weight="bold">
                    Why we ask:{' '}
                  </Text>
                )}
                <Text as="span">{questionTooltip}</Text>
              </>
            )
          }
        >
          {questionText}
        </InputLabel>
      </Box>
      {!isNil(error) && (
        <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
          {(error.Value && error.Value.message) || errorMessages.DEFAULT_ERROR}
        </Text>
      )}
      <input type="hidden" name={`${name}.Index`} value={index} ref={register} />
      <input type="hidden" name={`${name}.QuestionId`} value={questionId} ref={register} />
      <input type="hidden" name={`${name}.QuestionType`} value={questionType} ref={register} />
      <input type="hidden" name={`${name}.AnswerId`} value={answerId || ''} ref={register} />
      <input
        type="hidden"
        name={`${name}.AnswerType`}
        value={customQuestionAnswerTypes[questionType]}
        ref={register}
      />
      {FormField}
    </Box>
  );
};

export default CustomQuestion;
