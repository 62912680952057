import React from 'react';
import createSvgIcon from './SvgIcon';

export default createSvgIcon(
  <>
    <circle cx="12" cy="12" r="12" fill="white" />
    <path
      d="M13.848 11.0887C15.608 11.5207 16.536 12.7207 16.536 14.2727C16.536 16.1927 15 18.1127 11.416 18.1127C9.88 18.1127 8.2 17.7767 7 17.1047L8.28 14.3207C9.24 14.8807 10.344 15.1367 11.288 15.1367C12.136 15.1367 12.744 14.8487 12.744 14.2727C12.744 13.7927 12.376 13.5047 11.416 13.5047H9.928V11.1847L11.576 9.50474H7.576V6.65674H15.928V8.97674L13.848 11.0887Z"
      fill="#08ADCD"
    />
  </>,
  'StepThree',
);
