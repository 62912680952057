import React from 'react';
import { Box } from 'grommet';
import H from '../Atoms/Heading';
import CTAButton from '../Atoms/Buttons/CTAButton';

const MessageBox = (props) => {
  const { imageUrl, title, buttonLabel, href, children } = props;

  return (
    <Box align="center">
      {imageUrl && <img src={imageUrl} alt="" role="presentation" />}
      <Box margin={{ top: 'xsmall', bottom: 'small' }}>
        <H level={2} textAlign="center">
          {title}
        </H>
      </Box>
      <Box margin={{ bottom: 'large' }}>{children}</Box>
      {buttonLabel && href && <CTAButton label={buttonLabel} href={href} />}
    </Box>
  );
};

export default MessageBox;
