import React from 'react';
import { Tabs as GrommetTabs, Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { themes } from 'socialnature-ui';

const customTheme = deepMerge(themes, {
  tabs: {
    header: {
      extend: {
        justifyContent: 'stretch',
        '[role="tab"]': {
          flex: 1,
        },
        '[aria-selected="true"] > *': {
          color: themes.global.colors.blue,
          borderSize: '2px',
          borderBottom: `2px solid ${themes.global.colors.blue}`,
        },
        button: {
          // Override existing style
          background: 'transparent',
        },
      },
    },
  },
  tab: {
    pad: 'none',
    margin: 'none',
    extend: {
      color: themes.global.colors.iron,
      borderBottom: `1px solid ${themes.global.colors.iron}`,
      '&:hover': {
        color: themes.global.colors.blue,
      },
      textAlign: 'center',
      span: {
        textTransform: 'uppercase !important',
        lineHeight: '24px',
      },
    },
  },
});

export default function Tabs({ children }) {
  return (
    <Grommet theme={customTheme}>
      <GrommetTabs>{children}</GrommetTabs>
    </Grommet>
  );
}
