/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Grommet } from 'grommet';
import { themes as uiTheme } from 'socialnature-ui';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import basicTheme, { headerMargin } from './themes';
import ProductPage from './Pages/ProductPage';
import LaunchpadPage from './Pages/LaunchpadPage';
import Storefront from './Storefront';
import FreeTrialPage from './Pages/FreeTrialPage';
import SearchPage from './Pages/SearchPage';
import QualityProductsPage from './Pages/QualityProductsPage';
import HomePage from './Pages/HomePage';
import InvitePendingPage from './Pages/InvitePendingPage';
import { InviteAcceptedPage, InviteAcceptedPageContainer } from './Pages/InviteAcceptedPage';
import ShareReviewPage from './Pages/ShareReviewPage';
import { AcceptInvitePage, AcceptInvitePageContainer } from './Pages/AcceptInvitePage';
import ProfilePage from './Pages/ProfilePage';
import InviteFriendsPage from './Pages/InviteFriendsPage';
import client, { multiClient } from './utils/apis';
import clientGraphql from './utils/apis/graphql';
import './styles/main.scss';
import LocateMyProduct from './Pages/LocateMyProduct';
import './smsOptinPopup';
import { PendingActivationV2Page } from './Pages/PendingActivationV2';

(function (window) {
  window.renderLaunchpadPage = function () {
    ReactDOM.render(
      <Grommet theme={basicTheme}>
        <Router basename="/launchpad">
          <ApolloProvider client={client}>
            <Wrapper>
              <LaunchpadPage />
            </Wrapper>
          </ApolloProvider>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderFreeTrialPage = function () {
    ReactDOM.render(
      <Grommet theme={basicTheme}>
        <Router basename="/products">
          <ApolloProvider client={client}>
            <Wrapper>
              <FreeTrialPage />
            </Wrapper>
          </ApolloProvider>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderSearchPage = function () {
    ReactDOM.render(
      <Grommet theme={basicTheme}>
        <Router basename="/search">
          <ApolloProvider client={client}>
            <Wrapper>
              <SearchPage />
            </Wrapper>
          </ApolloProvider>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderQualityProductsPage = function () {
    ReactDOM.render(
      <Grommet theme={basicTheme}>
        <Router basename="/quality">
          <Switch>
            <Route path="/:qualitySlug">
              <ApolloProvider client={client}>
                <Wrapper>
                  <QualityProductsPage />
                </Wrapper>
              </ApolloProvider>
            </Route>
          </Switch>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderStorefront = function (
    container,
    company,
    products,
    ratings,
    launchpadProducts,
    userWantedSamples,
  ) {
    ReactDOM.render(
      <Storefront
        company={company}
        products={products}
        ratings={ratings}
        launchpadProducts={launchpadProducts}
        userWantedSamples={userWantedSamples}
      />,
      container,
    );
  };

  window.renderProductPage = function () {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <Router basename="/">
          <Route path="/:productSlug">
            <ApolloProvider client={multiClient}>
              <ProductPage />
            </ApolloProvider>
          </Route>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderHomePage = function () {
    ReactDOM.render(
      <Grommet theme={basicTheme}>
        <ApolloProvider client={client}>
          <Wrapper>
            <HomePage />
          </Wrapper>
        </ApolloProvider>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderInvitePendingPage = function (product, campaignType) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <ApolloProvider client={client}>
          <Wrapper>
            <InvitePendingPage product={product} campaignType={campaignType} />
          </Wrapper>
        </ApolloProvider>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderInviteAcceptedPage = function (product, shippingTime, campaignId, campaignType) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <Router basename="/invitation">
          <Switch>
            <Route path="/:wantId">
              <ApolloProvider client={clientGraphql}>
                <Wrapper>
                  <InviteAcceptedPage
                    product={product}
                    shippingTime={shippingTime}
                    campaignId={campaignId}
                    campaignType={campaignType}
                  />
                </Wrapper>
              </ApolloProvider>
            </Route>
          </Switch>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderInviteAcceptedPageWithV2 = function (wantId, samplekitID) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <Router basename="/invitation">
          <Switch>
            <Route path="/:wantId">
              <ApolloProvider client={clientGraphql}>
                <Wrapper>
                  <InviteAcceptedPageContainer wantId={wantId} samplekitID={samplekitID} />
                </Wrapper>
              </ApolloProvider>
            </Route>
          </Switch>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderShareReviewPage = function (review, product, discountCode) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <Router basename="/review">
          <Switch>
            <Route path="/share/:productSlug">
              <ApolloProvider client={client}>
                <Wrapper>
                  <ShareReviewPage review={review} product={product} discountCode={discountCode} />
                </Wrapper>
              </ApolloProvider>
            </Route>
          </Switch>
        </Router>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderAcceptInvitePage = function (data) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <Wrapper>
          <AcceptInvitePage data={data} />
        </Wrapper>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderAcceptInvitePageWithV2 = function (wantId) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <Wrapper>
          <AcceptInvitePageContainer wantId={wantId} />
        </Wrapper>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderProfilePage = function (data) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <ApolloProvider client={clientGraphql}>
          <Wrapper>
            <ProfilePage data={data} />
          </Wrapper>
        </ApolloProvider>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderInviteFriendsPage = function (data) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <ApolloProvider client={clientGraphql}>
          <Wrapper>
            <InviteFriendsPage data={data} />
          </Wrapper>
        </ApolloProvider>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.renderLocateMyProduct = function (sampleKitSlug, wantId) {
    ReactDOM.render(
      <Grommet theme={uiTheme}>
        <ApolloProvider client={clientGraphql}>
          <Wrapper>
            <LocateMyProduct sampleKitSlug={sampleKitSlug} wantId={wantId} />
          </Wrapper>
        </ApolloProvider>
      </Grommet>,
      document.getElementById('product-app-container'),
    );
  };

  window.ScrollToAnchor = function () {
    const { hash } = window.location;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        const elementPosition = element.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth',
        });
      }
    }
  };
})(window);

window.renderPendingActivationV2 = function (slug) {
  ReactDOM.render(
    <Grommet theme={uiTheme}>
      <Router basename="/pending-activation">
        <Switch>
          <Route path="/:slug">
            <ApolloProvider client={clientGraphql}>
              <Wrapper>
                <PendingActivationV2Page slug={slug} />
              </Wrapper>
            </ApolloProvider>
          </Route>
        </Switch>
      </Router>
    </Grommet>,
    document.getElementById('product-app-container'),
  );
};

// This style is to delete existing one
const Wrapper = styled.div`
  margin-top: ${headerMargin};
  margin-bottom: ${headerMargin};
`;
