import React, { useMemo } from 'react';
import { Box, Image } from 'grommet';
import styled from 'styled-components';
import WhiteLogo from '../../Icons/WhiteLogo';

const Container = styled.nav`
  position: ${({ isXsmall, isWhiteBgNavbar }) =>
    isXsmall || isWhiteBgNavbar ? 'relative' : 'absolute'};
  color: white;
  top: ${({ isWhiteBgNavbar }) => (!isWhiteBgNavbar ? '0' : 'auto')};
  padding-top: ${({ isXsmall }) => (isXsmall ? '20px' : '16px')};
  padding-left: ${({ isXsmall }) => (isXsmall ? '16px' : '114px')};
  padding-bottom: ${({ isXsmall }) => (isXsmall ? '20px' : '16px')};
  z-index: 9;
  box-shadow: ${({ isWhiteBgNavbar }) =>
    !isWhiteBgNavbar ? 'none' : '0 0 40px rgb(65 75 90 / 10%)'};
  background: ${({ isWhiteBgNavbar }) => (isWhiteBgNavbar ? 'white' : '')};
`;

export default function Navbar({ isWhiteBgNavbar = false, isXsmall }) {
  const whiteLogo = useMemo(
    () => <WhiteLogo width={isXsmall ? '126px' : '172px'} height={isXsmall ? '24px' : '32px'} />,
    [isXsmall],
  );

  const defaultLogo = useMemo(
    () => (
      <Box width={isXsmall ? '126px' : '172px'} height={isXsmall ? '24px' : '32px'}>
        <Image fit="cover" src="/images/logo.png" />
      </Box>
    ),
    [isXsmall],
  );

  const logo = isWhiteBgNavbar ? defaultLogo : whiteLogo;

  return (
    <Container isXsmall={isXsmall} isWhiteBgNavbar={isWhiteBgNavbar}>
      <a href="/">{logo}</a>
    </Container>
  );
}
