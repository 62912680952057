import React from 'react';
import styled from 'styled-components';
import '../../styles/_spinner.scss';

const Spinner = ({ htmlColor }) => (
  <div className="sk-chase">
    {[1, 2, 3, 4, 5, 6].map((value) => (
      <Circle key={value} color={htmlColor} className="sk-chase-dot" />
    ))}
  </div>
);

export default Spinner;

const Circle = styled.div`
  &::before {
    background-color: ${(props) => props.color || '#fff'};
  }
`;
