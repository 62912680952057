import React from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import LinkIcon from '../../../Atoms/Icons/sns/Link';
import Position from '../../../Atoms/Position';
import InlineMessage from '../../../Atoms/Form/InlineMessage';

const CopyLinkButton = (props) => {
  const { shareUrl, variant, onClick } = props;
  const dataAttributes = Object.fromEntries(
    Object.entries(props).filter(([key]) => key.match(/^data-.+/) !== null),
  );
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [copied, setCopied] = React.useState(false);

  return (
    <CopyButtonWrapper isXsmall={isXsmall} variant={variant} onClick={onClick}>
      <CopyToClipboard text={shareUrl.replace(/\{0\}/g, 'link')} onCopy={() => setCopied(true)}>
        <CopyButton
          isXsmall={isXsmall}
          data-track-label={shareUrl.replace(/\{0\}/g, 'link')}
          icon={<LinkIcon />}
          label="Copy link"
          {...dataAttributes}
          variant={variant}
        />
      </CopyToClipboard>
      {copied && (
        <Position left="0" bottom="-16px">
          <InlineMessage msg="Copied!" />
        </Position>
      )}
    </CopyButtonWrapper>
  );
};

export default CopyLinkButton;

const CopyButtonWrapper = styled.div`
  position: relative;
  width: ${(props) => {
    if (props.variant === 'round') return '32px';
    return props.isXsmall ? 'calc(50% - 8px)' : '135px';
  }};
`;

const CopyButton = styled(ShareButtonStyle)`
  width: 100%;
`;
