// @ts-check
import React, { useContext } from 'react';
import styled from 'styled-components';
import { Box, themes } from 'socialnature-ui';
import { ResponsiveContext } from 'grommet';
import { SecondaryButton } from 'socialnature-ui/build/components/Button';
import { trackEvent as trackGTMEvent } from '../../../utils/gtm';
import { TrackingEventType } from '../../../utils/tracking';
import { useParticipantStoreModal } from '../../../hooks/useParticipantStoreModal';
import Marker from '../../../Atoms/Icons/Marker';
import { concatStoreList } from '../../../utils/product';
import { useCampaignNearRetailers } from '../../../hooks/useCampaignNearStores';
import ProductHeaderFooter from '../../../Organisms/Product/ProductHeaderFooter';

const Container = styled.div`
  padding: ${({ isXsmall, discounts }) =>
    isXsmall ? '32px 0' : (discounts && !discounts.length && '32px 0 56px') || '56px 0'};
`;

const BoldSecondaryButton = styled(SecondaryButton)`
  font-weight: bold;
  &[disabled]:focus,
  &[disabled]:hover {
    background: ${themes.global.colors.silver};
    color: ${themes.global.colors.iron};
  }
  ${({ isXsmall }) => (isXsmall ? `width: 100%;` : 'padding: 16px 32px;')}
`;

const ButtonsWrapper = styled(Box)`
  align-items: center;
`;

const WhereToFind = styled(Box)`
  text-align: center;
  margin-bottom: 24px;

  .wfind__header-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
    ${({ isXsmall }) => (isXsmall ? '' : 'margin-bottom: 8px;')}

    .wfind__header-title {
      font-family: Montserrat;
      font-weight: 700;
      margin: 8px 0;
      ${({ isXsmall }) => (isXsmall ? `font-size: 16px;` : `font-size: 32px;`)};
      ${({ isXsmall }) => (isXsmall ? `text-transform: uppercase;` : ``)};
    }

    .wfind__header-icon {
      ${({ isXsmall }) => (isXsmall ? 'margin: 0 auto' : 'margin-right: 4px')};
    }
  }
`;

const WhereToFindSectionContainer = styled.div`
  padding-bottom: ${({ isXsmall, discounts }) =>
    !isXsmall && discounts && !discounts.length && '28px'};
`;

const FooterContainer = styled(Box)`
  & > div {
    align-items: center;
  }
  hr {
    margin: ${({ isXsmall }) => (isXsmall ? `32px 0;` : `24px 0`)};
  }
`;

const WhereToFindSection = ({ discounts, userId, product, campaign, dataStore, showUserFlow }) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const { samplekit, userLocation, preferredLocation, handleFormSubmitFetch } = dataStore;
  const { type: campaignType, id: campaignId } = campaign || {};
  const retailers = useCampaignNearRetailers(campaignId);
  const productName = product.name;

  const { LocationStoreModal, open: openStoreLocationModal } = useParticipantStoreModal(
    userLocation || '',
    false,
    samplekit,
    handleFormSubmitFetch,
    preferredLocation,
    campaignType,
  );

  const handleParticipantStores = () => {
    trackGTMEvent(TrackingEventType.PARTICIPANT_STORES_CLICK, {
      samplekit: productName,
    });

    openStoreLocationModal();
  };

  return (
    <Container isXsmall={isXsmall} discounts={discounts}>
      <WhereToFindSectionContainer isXsmall={isXsmall} discounts={discounts}>
        {retailers && retailers.length > 0 && (
          <WhereToFind isXsmall={isXsmall}>
            <div className="wfind__header-wrapper">
              {isXsmall ? (
                <Marker className="wfind__header-icon" />
              ) : (
                <Marker width={57} height={56} className="wfind__header-icon" />
              )}
              <b className="wfind__header-title">Where to find</b>
            </div>
            <div>Eligible {concatStoreList(retailers)}</div>
          </WhereToFind>
        )}

        <ButtonsWrapper isXsmall={isXsmall}>
          <BoldSecondaryButton
            label="See all eligible stores"
            href=""
            onClick={handleParticipantStores}
            color="white"
            gap="xsmall"
            isXsmall={isXsmall}
          />
        </ButtonsWrapper>
      </WhereToFindSectionContainer>

      {showUserFlow && (
        <FooterContainer isXsmall={isXsmall}>
          <ProductHeaderFooter
            isXsmall={isXsmall}
            userId={userId}
            product={product}
            isAccepted
            dataTrackCategory="Congrats"
            marginBottom="12px"
          />
        </FooterContainer>
      )}

      {LocationStoreModal()}
    </Container>
  );
};

export default WhereToFindSection;
