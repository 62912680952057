import React, { useEffect, useState } from 'react';
import { Collapsible } from 'grommet';
import { ArrowDown, ArrowUp } from 'socialnature-ui/build/icons';
import TextLink from '../Atoms/TextLink';

const Variants = ({ variants }) => {
  const showAccordion = variants.length > 5;
  const [collapMessage, setCollapMessage] = useState('');
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (!open) {
      setCollapMessage(
        variants.length - 5 > 1
          ? `See ${variants.length - 5} more varieties`
          : `See ${variants.length - 5} more variety`,
      );
    } else {
      setCollapMessage('See less');
    }
  }, [variants, open]);

  const handleOpen = () => setOpen(!open);

  return (
    <div className="flavors-toggle">
      <span>Choose from participating varieties</span>:
      {showAccordion ? (
        <div>
          <ul style={{ margin: '0.5em 1em 0 1.5em' }}>
            {variants.slice(0, 5).map((v) => (
              <li key={v}>{v}</li>
            ))}
          </ul>
          <Collapsible open={open}>
            <ul style={{ margin: '0 1em 0.5em 1.5em' }}>
              {variants.slice(5, variants.length).map((v) => (
                <li key={v}>{v}</li>
              ))}
            </ul>
          </Collapsible>
          <TextLink
            onClick={handleOpen}
            label={collapMessage}
            right
            fontWeight="bold"
            icon={
              open ? (
                <ArrowUp width="30" height="30" color="launchpad" />
              ) : (
                <ArrowDown width="30" height="30" color="launchpad" />
              )
            }
          />
        </div>
      ) : (
        <ul>
          {variants.map((v) => (
            <li key={v}>{v}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Variants;
