import React from 'react';
import styled from 'styled-components';
import { Button, ThemeContext } from 'grommet';
import basicTheme from '../../themes';
import Filter from '../../Atoms/Icons/Filter';

const FilterButton = ({ count, onClick }) => (
  <ThemeContext.Extend
    value={{
      button: {
        border: {
          color: 'slate',
        },
        color: { light: 'slate' },
        extend: (grommet) => `
						${basicTheme.button.extend}
						background: #FFFFFF;
						&:hover {
							background-color: inherit;
							color: inherit;
							border-color: ${grommet.theme.global.colors.launchpad};
						}
					`,
      },
    }}
  >
    <Button
      icon={<Filter />}
      label={
        <>
          <span>Filter</span>
          {count && <StackFilter text={count} />}
        </>
      }
      fill="horizontal"
      onClick={onClick}
    />
  </ThemeContext.Extend>
);

export default FilterButton;

const StackFilter = ({ text }) => <Stack>{text}</Stack>;

const Stack = styled.div`
  display: inline-block;
  background: ${basicTheme.global.colors.freetrial};
  line-height: 2em;
  padding: 0 0.83em;
  color: #ffffff;
  border-radius: 2em;
  font-size: 10px;
  font-weight: bold;
  margin-left: 16px;
`;
