// @ts-check
import React from 'react';
import List from '../../Atoms/List/List';
import { LocationListFooterButtons } from './LocationListFooterButtons';
import { LocationListItem } from './LocationListItem';
import { useLocationList } from './useLocationList';

/**
 * @typedef {{
 * locations: Array,
 * }} StoreListProps
 * @param {StoreListProps} StoreListProps
 * @returns
 */
export function LocationList({ locations }) {
  const {
    current: currentLocations,
    prevPage,
    nextPage,
    hasPrevPage,
    hasNextPage,
  } = useLocationList(locations);

  if (!Array.isArray(locations) || !locations.length) return null;

  return (
    <>
      <List
        data={currentLocations}
        renderItem={({ index, name, street1, street2, city, state, isPreferredLocation }) => (
          <LocationListItem
            index={index}
            name={name}
            street1={street1}
            street2={street2}
            city={city}
            state={state}
            isPreferredLocation={isPreferredLocation}
          />
        )}
      />
      <LocationListFooterButtons
        prevPage={prevPage}
        nextPage={nextPage}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
      />
    </>
  );
}
