import React from 'react';
import { useHistory } from 'react-router-dom';
import FilterQueryContext from '../../contexts/FilterQueryContext';
import { createQuery } from '../../utils/productFilter';
import SimpleSelect from '../../Atoms/SimpleSelect';

function createOptions(filters) {
  return filters.map((filter) => ({
    ...filter,
    label: `${filter.name} (${filter.qty})`,
  }));
}

const FilterSelect = ({ filter }) => {
  const filterQueryMap = React.useContext(FilterQueryContext);
  const history = useHistory();

  if (!filter || !filter.filters) return <></>;

  const allValue = 'all';
  const { type } = filter.filters[0] || {};
  const options = createOptions(filter.filters);

  const updateFilter = (curType, curSlug) => {
    if (curSlug !== allValue) {
      filterQueryMap.set(curType, new Set([curSlug])); // value is always only one
      history.push(`?${createQuery(filterQueryMap)}`);
    } else {
      filterQueryMap.delete(curType);
      history.push(`?${createQuery(filterQueryMap)}`);
    }
  };

  const getValue = (curType) => {
    if (!filterQueryMap.has(curType)) {
      return options.filter((f) => f.slug === allValue);
    }
    const value = [...filterQueryMap.get(curType)];

    // value is always only one so return only first value: value[0]
    return options.filter((f) => f.slug === value[0]);
  };

  return (
    <SimpleSelect
      id={type}
      name={type}
      value={getValue(type)}
      options={options}
      labelKey="label"
      valueKey="slug"
      placeholder={`Select ${filter.name}`}
      onChange={({ value }) => updateFilter(type, value.slug)}
    />
  );
};

export default FilterSelect;
