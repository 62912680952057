import React from 'react';
import { Box, Text, Anchor } from 'grommet';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { themes } from 'socialnature-ui';

/**
 * @param {bool} showLastSlash
 * @param {item[]} items example [{ name: home, link:'/' isRouterLink: true }, {name: Vegan, link:'/vegan', fontWeight: 'normal'}]
 * @var {
 *	name: string;
 *	link: string;
 *	isRouterLink: bool;
 *	fontWeight: string;
 *  width: string;
 *  enableElipsis: bool;
 * } item
 */
const Breadcrumb = ({ items, showLastSlash = true }) => {
  if (!items) return <></>;

  const lastItem = items.pop();

  return (
    <Box direction="row" style={{ lineHeight: '20px' }}>
      {items.map((item) => (
        <Box direction="row" margin="none" key={item.name} width={item.width || ''}>
          <Text>
            {item.isRouterLink ? (
              <StyledLink to={item.link} color={item.color}>
                {item.name}
              </StyledLink>
            ) : (
              <Anchor href={item.link} color="iron">
                {item.name}
              </Anchor>
            )}
          </Text>
          <Text color="iron" margin={{ horizontal: '8px' }}>
            /
          </Text>
        </Box>
      ))}
      <Box direction="row" margin="none" width={lastItem.width || ''}>
        <LastItem enableElipsis={lastItem.enableElipsis || false}>
          <Text weight={lastItem.fontWeight || 'bold'}>{lastItem.name}</Text>
        </LastItem>
        {showLastSlash && (
          <Text color="iron" margin={{ horizontal: '8px' }}>
            /
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default Breadcrumb;

const LastItem = styled.div`
  border-bottom: 1px solid ${themes.global.colors.launchpad};
  padding-bottom: 3px;
  ${({ enableElipsis }) =>
    enableElipsis && 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;'}
`;

const StyledLink = styled(Link)`
	color: ${({ color }) => color || themes.global.colors.iron}

	&:hover {
		color: ${themes.global.colors.launchpad};
	}
`;
