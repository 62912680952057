import React from 'react';
import { Text, Error, CheckBox, themes } from 'socialnature-ui';
import styled from 'styled-components';
import { Controller, useFormContext } from 'react-hook-form';
import { errorMessages } from '../../../utils/customQuestionTypes';

const StyledText = styled.span`
  color: ${themes.global.colors.black};

  font-weight: 700;
  font-size: 17px;
  margin-left: 10px;
  padding-top: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`;

const ConsentInput = (props) => {
  const { name, label, error, onChange, checked } = props;

  return (
    <>
      {error && (
        <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
          {error.message}
        </Text>
      )}
      <Wrapper>
        <CheckBox onChange={onChange} error={!!error} name={name} checked={checked} />
        <StyledText>{label}</StyledText>
      </Wrapper>
    </>
  );
};

const Consent = ({ name, error, required = true, label, errorMessage }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ onChange, value }) => (
        <ConsentInput
          onChange={(e) => onChange(e.target.checked)}
          checked={value || false}
          name={name}
          error={error}
          label={label}
        />
      )}
      control={control}
      rules={{
        required: required && (errorMessage || errorMessages.CONSENT_REQUIRED),
        validate: required && ((value) => !!value),
      }}
    />
  );
};

export default Consent;
