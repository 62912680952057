import React from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import basicTheme from '../../../themes';

const InviteFriendsButtonContainer = styled.div`
  display: flex;

  &:hover {
    cursor: pointer;
  }
`;

const ButtonLabel = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${basicTheme.global.colors.pink};
  padding-left: 8px;
  white-space: nowrap;
`;

const InviteFriendsButton = (props) => {
  const { icon, label: labelProp, variant, ...other } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <InviteFriendsButtonContainer {...other} isXsmall={isXsmall}>
      {icon}
      <ButtonLabel>{labelProp}</ButtonLabel>
    </InviteFriendsButtonContainer>
  );
};

export default InviteFriendsButton;
