import React from 'react';
import { Box } from 'grommet';

const ProductList = ({ children }) => (
  <Box
    direction="row"
    justify="start"
    wrap
    margin={{
      vertical: 'none',
      horizontal: 'cardWrapper',
    }}
    width={{
      max: 'none',
    }}
  >
    {children}
  </Box>
);

export default ProductList;
