import React from 'react';
import PropTypes from 'prop-types';

let hashFragment = '';
let observer = null;
let asyncTimerId = null;
let scrollFunction = null;

function reset() {
  hashFragment = '';
  if (observer !== null) observer.disconnect();
  if (asyncTimerId !== null) {
    window.clearTimeout(asyncTimerId);
    asyncTimerId = null;
  }
}

function isInteractiveElement(element) {
  const formTags = ['BUTTON', 'INPUT', 'SELECT', 'TEXTAREA'];
  const linkTags = ['A', 'AREA'];
  return (
    (formTags.includes(element.tagName) && !element.hasAttribute('disabled')) ||
    (linkTags.includes(element.tagName) && element.hasAttribute('href'))
  );
}

function getElAndScroll() {
  let element = null;
  if (hashFragment === '#') {
    element = document.body;
  } else {
    const id = hashFragment.replace('#', '');
    element = document.getElementById(id);
    if (element === null && hashFragment === '#top') {
      element = document.body;
    }
  }

  if (element !== null) {
    scrollFunction(element);

    let originalTabIndex = element.getAttribute('tabindex');
    if (originalTabIndex === null && !isInteractiveElement(element)) {
      element.setAttribute('tabindex', -1);
    }
    element.focus({ preventScroll: true });
    if (originalTabIndex === null && !isInteractiveElement(element)) {
      element.blur();
      element.removeAttribute('tabindex');
    }

    reset();
    return true;
  }
  return false;
}

function hashLinkScroll(timeout) {
  window.setTimeout(() => {
    if (getElAndScroll() === false) {
      if (observer === null) {
        observer = new MutationObserver(getElAndScroll);
      }
      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true,
      });
      asyncTimerId = window.setTimeout(() => {
        reset();
      }, timeout || 10000);
    }
  }, 0);
}

export function GenericHashLink({
  as: As = 'a',
  to,
  elementId,
  scroll,
  smooth,
  timeout,
  children,
  onClick,
  ...props
}) {
  let linkHash = '';
  if (typeof to === 'string' && to.includes('#')) {
    linkHash = `#${to.split('#').slice(1).join('#')}`;
  } else if (typeof to === 'object' && typeof to.hash === 'string') {
    linkHash = to.hash;
  }

  function handleClick(e) {
    reset();
    hashFragment = elementId ? `#${elementId}` : linkHash;
    if (onClick) onClick(e);
    if (
      hashFragment !== '' &&
      !e.defaultPrevented &&
      e.button === 0 &&
      (!props.target || props.target === '_self') &&
      !(e.metaKey || e.altKey || e.ctrlKey || e.shiftKey)
    ) {
      e.preventDefault();
      scrollFunction =
        scroll ||
        ((el) => (smooth ? el.scrollIntoView({ behavior: 'smooth' }) : el.scrollIntoView()));
      hashLinkScroll(timeout);
    }
  }

  return (
    <As {...props} href={to} onClick={handleClick}>
      {children}
    </As>
  );
}

GenericHashLink.propTypes = {
  as: PropTypes.elementType,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  elementId: PropTypes.string,
  scroll: PropTypes.func,
  smooth: PropTypes.bool,
  timeout: PropTypes.number,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export const HashLink = (props) => <GenericHashLink as="a" {...props} />;
export const NavHashLink = (props) => <GenericHashLink as="a" {...props} />;

// if (process.env.NODE_ENV !== 'production') {
//   HashLink.displayName = 'HashLink';
//   NavHashLink.displayName = 'NavHashLink';

//   const propTypes = {
//     onClick: PropTypes.func,
//     children: PropTypes.node,
//     scroll: PropTypes.func,
//     timeout: PropTypes.number,
//     elementId: PropTypes.string,
//     to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
//   };

//   HashLink.propTypes = propTypes;
//   NavHashLink.propTypes = propTypes;
// }
