import React, { useContext, useState, useEffect } from 'react';
import styled from 'styled-components';
import { themes } from 'socialnature-ui';
import { ResponsiveContext, Box } from 'grommet';
import { SecondaryButton } from 'socialnature-ui/build/components/Button';
import CashBackCard from '../../Molecules/Home/CashBackCard';
import Container from '../../Atoms/Container';
import H from '../../Atoms/Heading';
import { TrackingEventType } from '../../utils/tracking';
import { trackEvent as trackGTMEvent } from '../../utils/gtm';
import { isFeatureOn, SplitNames } from '../../utils/splitio';

const StyledH = styled(H)`
  margin: 0;
  max-width: 100%;
  ${({ isXsmall }) =>
    isXsmall
      ? `
		    font-size: 24px;
    		line-height: 32px;
			`
      : `
				text-align: center;
			`}
`;

const CashBackSteps = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${({ isXsmall }) => (isXsmall ? `start` : 'center')};
  flex-wrap: ${({ isXsmall }) => (isXsmall ? `wrap` : 'nowrap')};
  row-gap: 32px;
  column-gap: 80px;
  margin-top: 32px;
  margin-bottom: ${({ isXsmall }) => (isXsmall ? `32px` : '48px')};

  @media only screen and (max-width: 1024px) {
    column-gap: 18px;
  }
`;

const BoldSecondaryButton = styled(SecondaryButton)`
  font-weight: bold;
  padding: 12px 32px;

  &[disabled]:focus,
  &[disabled]:hover {
    background: ${themes.global.colors.silver};
    color: ${themes.global.colors.iron};
  }
  ${({ isXsmall }) => (isXsmall ? `width: 100%;` : ``)}
`;

const CashBackSection = ({ loggedIn = false }) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const boxPad = isXsmall ? '24px' : '40px';
  const [isEtaOffersPageOn, setIsEtaOffersPageOn] = useState(false);

  useEffect(() => {
    isFeatureOn(SplitNames.etaOffersPage).then(setIsEtaOffersPageOn);
  }, []);

  return (
    <Box flex={false} pad={{ top: boxPad, bottom: boxPad }} background="#FFFFFF">
      <Container style={{ textAlign: 'center' }}>
        <StyledH level={3} isXsmall={isXsmall}>
          Cash back with every healthy choice
        </StyledH>

        <CashBackSteps isXsmall={isXsmall}>
          <CashBackCard
            image="images/home/shop-in-store.png"
            title="Shop in-store"
            caption="Browse our offers at stores near you"
          />
          <CashBackCard
            image="images/home/upload-receipt.png"
            title="Upload your receipt"
            caption="After shopping in-store upload your receipt"
          />
          <CashBackCard
            image="images/home/get-paid.png"
            title="Get paid within 48hrs!"
            caption="Venmo(US Only), PayPal, or gift cards within 48hrs 🎉️🤑"
          />
        </CashBackSteps>

        <BoldSecondaryButton
          label="VIEW OFFERS"
          href={isEtaOffersPageOn ? '/offers?snsource=home' : '/products'}
          color="white"
          gap="xsmall"
          isXsmall={isXsmall}
          onClick={() =>
            trackGTMEvent(TrackingEventType.HOME.VIEW_OFFERS_CLICKED, {
              loggedIn,
              source: TrackingEventType.HOME.SOURCES.CASHBACK_STEPS,
            })
          }
        />
      </Container>
    </Box>
  );
};

export default CashBackSection;
