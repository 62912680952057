import React from 'react';
import styled from 'styled-components';
import { Heading, themes } from 'socialnature-ui';
import BackgroundLeftImage from '../../images/ReviewSharePage/bg-left-title.svg';
import BackgroundRightImage from '../../images/ReviewSharePage/bg-right-title.svg';

const StyledCatchCopy = styled.div`
  display: inline-block;
  border-bottom: 3px solid ${themes.global.colors.brand};
  position: relative;
  margin-bottom: 12px; /* To display all background images */
  &::before,
  &::after {
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    content: url(${BackgroundLeftImage});
    right: calc(100% + 16px);
  }
  &::after {
    content: url(${BackgroundRightImage});
    left: calc(100% + 16px);
  }
`;

const CatchCopy = ({ copy }) => {
  if (!copy) return null;

  return (
    <StyledCatchCopy>
      <Heading level={5} color="brand" transform="uppercase" textAlign="center">
        {copy}
      </Heading>
    </StyledCatchCopy>
  );
};

export default CatchCopy;
