// @ts-check
import React from 'react';
// @ts-ignore
import { Heart as HeartIcon } from 'socialnature-ui';
import { CTATypes } from '../../utils/product/index';
import AppliedCTABtn from './Applied';
import ApplyCampaignButton from './ApplyCampaign';
import BannerButton from './Banner';
import BoostButton from './Boost';
import SignUp from './SignUp';
import WhereToBuyButton from './WhereToBuy';

/**
 * @typedef {{
 * CTAType: import('../../utils/product/index').CTATypes,
 * color?: 'blue' | 'pink',
 * label?: string,
 * href: string,
 * onClick?: Function,
 * productId: number,
 * productSlug: string,
 * disabled: boolean,
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function ProductCTAButton(props) {
  const { CTAType, color, label, href, onClick, productId, productSlug, disabled } = props;

  switch (CTAType) {
    case CTATypes.WANT:
      return (
        <ApplyCampaignButton
          href={href}
          onClick={onClick}
          productId={productId}
          productSlug={productSlug}
          label={label}
          icon={<HeartIcon isOutline color="white" />}
        />
      );
    case CTATypes.BOOST:
      return <BoostButton href={href} productId={productId} productSlug={productSlug} />;
    case CTATypes.BANNER:
      return (
        <BannerButton
          label={label}
          href={href}
          onClick={onClick}
          color={color}
          productId={productId}
          productSlug={productSlug}
        />
      );
    case CTATypes.WHERE_TO_BUY:
      return (
        <WhereToBuyButton
          label={label}
          href={href}
          color={color}
          productId={productId}
          productSlug={productSlug}
        />
      );
    case CTATypes.SIGN_UP:
      return <SignUp href={href} productId={productId} productSlug={productSlug} />;
    case CTATypes.SIGNUP_TO_ACTIVATE:
      return <SignUp href={href} productId={productId} productSlug={productSlug} label={label} />;
    case CTATypes.SIGNUP_FOR_UPDATES:
      return <SignUp href={href} productId={productId} productSlug={productSlug} label={label} />;
    case CTATypes.APPLIED:
      return <AppliedCTABtn />;
    case CTATypes.ACTIVATE_OFFER:
    case CTATypes.REACTIVATE_OFFER:
      return (
        <ApplyCampaignButton
          href={!disabled ? href : undefined}
          onClick={onClick}
          productId={productId}
          productSlug={productSlug}
          label={label}
          disabled={disabled}
        />
      );
    case CTATypes.OFFER_ACTIVATED:
      return (
        <ApplyCampaignButton
          href={null}
          onClick={onClick}
          productId={productId}
          productSlug={productSlug}
          label={label}
          disabled
        />
      );
    case CTATypes.ADD_A_RECEIPT:
      return (
        <ApplyCampaignButton
          href={!disabled ? href : undefined}
          onClick={onClick}
          productId={productId}
          productSlug={productSlug}
          label={label}
          disabled={disabled}
        />
      );
    case CTATypes.OFFER_REDEEMED:
      return (
        <ApplyCampaignButton
          href={!disabled ? href : undefined}
          onClick={onClick}
          productId={productId}
          productSlug={productSlug}
          label={label}
          disabled={disabled}
        />
      );
    default:
      return <></>;
  }
}
