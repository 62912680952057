import gql from 'graphql-tag';
import { handleError } from '../error';
import { getCookie } from '../cookie';
import clientGraphql from './graphql';

const host = process.env.API_V2_URL;
const rebateEndpoint = `${host}rebate`;
const token = getCookie('User_Token');

export const uploadReceipt = (file, wantID) => {
  const data = new FormData();

  data.append('receipt', file);
  data.append('wantID', wantID);

  return fetch(`${rebateEndpoint}/receipt`, {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.json();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });
};

export const uploadReceiptV2 = (file, elementID, isDiscount) => {
  const data = new FormData();

  data.append('receipt', file);
  data.append('elementID', isDiscount ? `userdiscount-${elementID}` : elementID);

  return fetch(`${rebateEndpoint}/v2/receipt`, {
    method: 'POST',
    body: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.json();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });
};

export const deleteReceipt = (filePath, wantID, allImages) =>
  fetch(`${rebateEndpoint}/receipt/${wantID}${!allImages ? `?filePath=${filePath}` : ''}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.text();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });

export const deleteAllReceipts = (wantID) => deleteReceipt(null, wantID, true);

export const deleteReceiptV2 = (filePath, elementID, allImages, isDiscount) =>
  fetch(
    `${rebateEndpoint}/v2/receipt/${elementID}?isDiscount=${isDiscount || false}${
      !allImages ? `&filePath=${filePath}` : ''
    }`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  )
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.text();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });

export const deleteAllReceiptsV2 = (elementID, isDiscount) =>
  deleteReceiptV2(null, elementID, true, isDiscount);

export const getReceipts = (wantID) =>
  fetch(`${rebateEndpoint}/receipts/${wantID}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.json();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });

export const validateReceipts = (wantID, fingerPrint = undefined) => {
  const queryParams = new URLSearchParams();
  if (fingerPrint) {
    queryParams.append('visitorId', fingerPrint);
  }
  return fetch(`${rebateEndpoint}/validate/${wantID}?${queryParams.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.text();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });
};

export const validateDiscountReceipts = (userDiscountId, fingerPrint = undefined) => {
  const queryParams = new URLSearchParams();
  if (fingerPrint) {
    queryParams.append('visitorId', fingerPrint);
  }
  return fetch(`${rebateEndpoint}/discount/${userDiscountId}/validate?${queryParams.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.text();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });
};

export const rebateReceiptStatusQuery = (wantID) => gql`
  query GetReceiptValidationStatus {
    rebates(where: {want_id: {_eq: ${wantID}}}) {
    validationStatus: receipt_validation_status
    validationError: receipt_validation_error_reason
    }
  }
`;

export const getRebateReceiptStatus = (wantID) =>
  clientGraphql
    .query({
      query: rebateReceiptStatusQuery(wantID),
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      const { rebates } = data || {};
      if (rebates && rebates.length > 0) {
        const rebate = rebates[0];
        return rebate;
      }
      return {};
    });

export const rebateReceiptDiscountStatusQuery = (elementID) => gql`
  query getRebateReceiptDiscountStatus {
    rebates(where: {user_discount_id: {_eq: ${elementID}}}) {
      validationStatus: receipt_validation_status
      validationError: receipt_validation_error_reason
    }
  }
`;

export const getRebateReceiptDiscountStatus = (elementID) =>
  clientGraphql
    .query({
      query: rebateReceiptDiscountStatusQuery(elementID),
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      const { rebates } = data || {};
      if (rebates && rebates.length > 0) {
        const rebate = rebates[0];
        return rebate;
      }
      return {};
    });

export const rebateRewardStatusQuery = (wantID) => gql`
    query GetRebateRewardStatus {
      transactions: rebate_transactions(where: {want_id: {_eq: ${wantID}}}) {
        amount: amount,
        rewardStatus: reward_status,
      }
    }
`;

export const getRebateRewardStatus = (wantID) =>
  clientGraphql
    .query({
      query: rebateRewardStatusQuery(wantID),
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      const { transactions } = data || {};
      if (transactions && transactions.length > 0) {
        const reward = transactions[0];
        return reward;
      }
      return {};
    });

export const rebateRewardDiscountStatusQuery = (elementID) => gql`
    query getRebateRewardDiscountStatus {
      transactions: rebate_transactions(where: {user_discount_id: {_eq: ${elementID}}}) {
        amount: amount,
        rewardStatus: reward_status,
      }
    }
`;

export const getSuccessWantTransactionStatus = (wantId) => gql`
  query CalculateTransactionWantQuery {
    rebates(where: {receipt_validation_status: {_eq: "SUCCESS"}, want_id: {_eq: ${wantId}}}) {
      want {
        campaignData {
          offerDiscountPercentage: properties(path: "offerDiscountPercentage")
          samplekit {
            priceValue: price_value
          }
        }
      }
    }
  }
`;

export const getSuccessDiscountTransactionStatus = (userDiscountId) => gql`
  query CalculateTransactionDiscountQuery {
    rebates(where: {receipt_validation_status: {_eq: "SUCCESS"}, user_discount_id: {_eq: ${userDiscountId}}}) {
      userDiscount: user_discount {
        discount {
          discount_type
          discountValue: discount_value
          discountSamplekits: discount_samplekits {
            samplekit {
              priceValue: price_value
            }
          }
        }
      }
    }
  }
`;

export const getMyProductsPageDiscountTransactionStatusQuery = (discountId, userId) => gql`
  query getMyProductsPageDiscountTransactionStatusQuery {
    userDiscount: user_discounts(where: {
      discount_id: {_eq: ${discountId}}, user_id: {_eq: ${userId}}, rebates: {receipt_validation_status: {_eq: "SUCCESS"}}
    }) {
      rebateTransactions: rebate_transactions {
        id
        rewardStatus: reward_status
      }
    }
  }
`;
export const getMyProductsPageDiscountTransactionStatus = (discountId, userId) =>
  clientGraphql
    .query({
      query: getMyProductsPageDiscountTransactionStatusQuery(discountId, userId),
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      const { userDiscount } = data || {};
      if (userDiscount && userDiscount.length > 0) {
        const { rebateTransactions } = userDiscount[0];
        const discountHaveRebateTransaction = rebateTransactions.length > 0;
        return {
          hasRebateTransaction: discountHaveRebateTransaction,
          rewardStatus: rebateTransactions[0]?.rewardStatus,
        };
      }
      return { hasRebateTransaction: false, rewardStatus: null };
    });

export const getRebateRewardDiscountStatus = (elementID) =>
  clientGraphql
    .query({
      query: rebateRewardDiscountStatusQuery(elementID),
      fetchPolicy: 'network-only',
    })
    .then(({ data }) => {
      const { transactions } = data || {};
      if (transactions && transactions.length > 0) {
        const reward = transactions[0];
        return reward;
      }
      return {};
    });

export const reactivateOffer = (wantID) =>
  fetch(`${rebateEndpoint}/activate/${wantID}`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.text();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });

export const handleReactivation = (wantId) => {
  reactivateOffer(wantId).then((res) => {
    if (res.error) {
      handleError(res.error);
    } else {
      window.location = `/invitation/${wantId}`;
    }
  });
};

export const getCampaignProgress = (campaignId) =>
  fetch(`${rebateEndpoint}/campaign-progress/${campaignId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        handleError(response);

        return {
          error: true,
        };
      }

      return response.json();
    })
    .catch((error) => {
      handleError(error);

      return {
        error: true,
      };
    });
