import React, { useMemo, memo } from 'react';
import { handleError } from '../../../utils/error';
import { CampaignProgressBar as Bar } from '../../../Organisms/Product/CampaignProgressBar';
import { getValue } from './utils';
import { useProductPage } from '../../../contexts/ProductPageContext/useProductPage';

function RebateProgressBar({ campaignId }) {
  const {
    campaignRebateData: {
      campaignProgress: { loading, error, progressValue },
    },
  } = useProductPage();

  const value = useMemo(
    () => getValue({ campaigns: [{ acceptedPercentage: progressValue }] }),
    [progressValue],
  );

  if (loading) {
    return <></>;
  }
  if (error) {
    handleError(error);
    return <></>;
  }

  if (value < 0) {
    handleError(
      `ValueError: Value is under 0 at CampaignProgressBar.(campaign: ${campaignId}, value: ${value})`,
    );
    return <></>;
  }

  return <Bar value={value} />;
}

export default memo(RebateProgressBar);
