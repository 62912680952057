import React from 'react';
import BannerBar from './Sections/BannerBar';
import getUserId from '../../utils/userId';
import LoggedInHomePage from './LoggedInHomePage';
import LoggedOutHomePage from './LoggedOutHomePage';

export default function HomePage() {
  const [openBanner, setOpenBanner] = React.useState(false);
  const userId = getUserId();
  const isLogin = !!userId;

  return (
    <>
      {openBanner && <BannerBar handleClose={setOpenBanner} />}
      {isLogin ? <LoggedInHomePage userId={userId} /> : <LoggedOutHomePage />}
    </>
  );
}
