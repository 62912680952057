import React from 'react';
import createSvgIcon from './SvgIcon';

export default createSvgIcon(
  <>
    <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8774 4.50939C13.8277 4.06001 13.4467 3.71045 12.984 3.71045H11.847C12.1263 3.37059 12.2991 2.94714 12.2991 2.44387C12.2991 2.08017 12.1948 1.69377 11.9363 1.39676C11.6756 1.09719 11.268 0.9 10.6859 0.9C9.91628 0.9 9.21966 1.30907 8.64965 1.81639C8.13029 2.27864 7.7061 2.83124 7.4151 3.25962C7.12207 2.83076 6.6891 2.27817 6.16112 1.81602C5.58186 1.30897 4.87605 0.9002 4.10686 0.9002C3.52474 0.9002 3.11712 1.09739 2.85644 1.39696C2.59798 1.69397 2.49368 2.08037 2.49368 2.44407C2.49368 2.94752 2.67114 3.37104 2.95598 3.71065H1.79893C1.30249 3.71065 0.9 4.11315 0.9 4.60959V7.001V7.101H1H1.70593V12.2011C1.70593 12.6975 2.10841 13.1 2.60486 13.1H12.1881C12.6845 13.1 13.087 12.6975 13.087 12.2011V7.1008H13.783H13.883V7.0008V4.60939V4.50939H13.8774ZM10.6859 1.89913C10.9215 1.89913 11.0705 1.94905 11.1608 2.03146C11.2492 2.11205 11.3001 2.24103 11.3001 2.44409C11.3001 2.84473 11.0784 3.15793 10.7349 3.37547C10.3898 3.59413 9.92865 3.71025 9.4781 3.71025H8.31606C8.58481 3.3292 8.92946 2.90545 9.31272 2.56312C9.75189 2.17085 10.2265 1.89913 10.6859 1.89913ZM4.10685 1.89915C4.56677 1.89915 5.05108 2.17134 5.49984 2.5638C5.89127 2.90613 6.24396 3.32971 6.51418 3.71007H5.35217C4.90199 3.71007 4.43116 3.591 4.07609 3.3688C3.72223 3.14734 3.49258 2.83075 3.49258 2.4315C3.49258 2.22857 3.54348 2.10353 3.63077 2.02624C3.72082 1.94651 3.86988 1.89915 4.10685 1.89915ZM12.884 6.10186H7.89093V4.70939H12.884V6.10186ZM1.89892 4.70939H6.892V6.10186H1.89892V4.70939ZM2.70485 7.1008H6.892V12.1009H2.70485V7.1008ZM12.0883 12.1009H7.89093V7.1008H12.0883V12.1009Z"
        fill="#0F9494"
        stroke="#0F9494"
        strokeWidth="0.2"
      />
    </svg>
  </>,
  'Gift2',
);
