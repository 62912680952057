import React from 'react';
import styled from 'styled-components';

const Position = ({ top, right, bottom, left, children }) => (
  <Wrapper top={top} right={right} bottom={bottom} left={left}>
    {children}
  </Wrapper>
);

export default Position;

const Wrapper = styled.div`
  position: absolute;
  ${(props) => props.top && `top: ${props.top}`};
  ${(props) => props.right && `right: ${props.right}`};
  ${(props) => props.bottom && `bottom: ${props.bottom}`};
  ${(props) => props.left && `left: ${props.left}`};
`;
