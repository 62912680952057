import { SplitFactory } from '@splitsoftware/splitio-browserjs';

// eslint-disable-next-line prefer-destructuring
const SPLIT_IO_API_KEY = process.env.SPLIT_IO_API_KEY;

const splitClients = {}; // Cache for clients
const initializingPromises = {}; // Cache for initializing instances (This is required because split takes time to initialise)

// eslint-disable-next-line consistent-return
const getSplitClient = async (userId) => {
  const cacheKey = userId;
  if (splitClients[cacheKey]) {
    return splitClients[cacheKey]; // Return cached client if already exists
  }

  if (initializingPromises[cacheKey]) {
    return initializingPromises[cacheKey]; // Return existing initialization promise if in progress
  }

  // Create a new promise for initializing the client
  initializingPromises[cacheKey] = new Promise((resolve) => {
    const factory = SplitFactory({
      core: {
        authorizationKey: SPLIT_IO_API_KEY,
        key: `${userId}`,
      },
    });
    const client = factory.client();

    client.on(client.Event.SDK_READY, () => {
      splitClients[cacheKey] = client; // Cache the client
      resolve(client);
    });
  });

  try {
    const client = await initializingPromises[cacheKey];
    delete initializingPromises[cacheKey]; // Cleanup
    return client;
  } catch (_) {
    // Ignore
  }
};

const SplitNames = {
  hotjarCampaignSurvey: 'hotjar_campaign_survey',
  reactivateRebateOffer: 'reactivate_rebate_offer',
  disableCustomQuestionsSubmitButton: 'disable_custom_questions_submit_button',
  rebateOfferDeadline: 'rebate_offer_deadline',
  rebateRedemptionsFull: 'rebate_redemptions_full',
  SMS_CONSENT: 'ENG-2310_SMS_CONSENT',
  customCampaignRAFGiveaways: 'custom_campaign_RAF_giveaways',
  showTermsAndConditionsConsent: 'ENG-2390_show_terms_and_conditions_consent',
  useTargetActivations: 'ENG-2460_use_rebate_target_activations',
  discountsV1: 'ENG-2868_DISCOUNTS_V1',
  rebateBannerHome: 'ENG-3041_REBATE_BANNER_HOME',
  hideFacebookLogin: 'hide_facebook_login',
  removeIsFullFromProductPageGraphql: 'ENG-3211_remove_is_full_from_product_page_graphql',
  rebateTwoZero: 'REBATE_2_0_FRONT_END',
  etaOffersPage: 'ETA_OFFERS_PAGE',
  captchaValidation: 'CAPTCHA_VALIDATION',
  phoneNumberConfirmation: 'ENG-3238_PHONE_NUMBER_CONFIRMATION',
  hideOldRedemptionPages: 'ENG-3081_HIDE_OLD_REDEMPTION_PAGES',
  IOS_CHROME_CAMERA_PERMISSIONS: 'ENG-3240_IOS_CHROME_CAMERA_PERMISSIONS',
  samsClubMembershipQuestion: 'ENG-3248_SAMS_CLUB_MEMBERSHIP_QUESTION',
  costcoMembershipQuestion: 'ENG-3248_COSTCO_MEMBERSHIP_QUESTION',
  updateReportIssueFlowFlag: 'ENG-3335_UPDATE_REPORT_ISSUE_FLOW_FLAG',
  loginCaptchaChallenge: 'LOGIN_CAPTCHA_CHALLENGE',
  launchpadSectionHome: 'ENG-3164_LAUNCHPAD_SECTION_HOME',
  changeCashBackLabelToFreeProduct: 'ENG-3389_CHANGE_CASH_BACK_LABEL_TO_FREE_PRODUCT',
  v2ActivationFeature: 'ENG-3373_V2_API_FOR_PRODUCT_PAGE',
  CashbackDelay_24FF: 'ENG-3043_CashbackDelay_24',
  fingerprintEnabled: 'ENG-3426_FINGERPRINT',
  fingerprintCacheEnabled: 'FINGERPRINT_CACHE',
  retargetEnabledFlag: 'ENG-3372_COLLECTIONS_WITH_RETARGET',
  discountsSectionHome: 'ENG-3475_DISCOUNTS_SECTION_HOME',
  collectionsFilteredByPostalCodeLoggedOut:
    'ENG-3708_COLLECTIONS_FILTERED_BY_POSTAL_CODE_LOGGED_OUT',
  updateUserCashbackEmail: 'ENG-3814_UPDATE_USER_CASHBACK_EMAIL',
  ETA_Logged_Out: 'ETA_Logged_Out',
  updateRejectionReasonResponseFF: 'ENG-3444_update_rejection_reason_response_to_frontend',
  tprRetailerDiscountLabel: 'ENG-3732_SHOW_TPR_RETAILER_DISCOUNT_LABEL',
  newProfileQuestionFlow : 'ENG-3402_ETA_NEW_PROFILE_QUESTIONS_FLOW'
};

const usingFeatureFlag = (splitName, userId, featureOnFn, featureOffFn) => {
  const factory = SplitFactory({
    core: {
      authorizationKey: SPLIT_IO_API_KEY,
      key: `${userId}`,
    },
  });
  const client = factory.client();
  client.on(client.Event.SDK_READY, () => {
    const treatment = client.getTreatment(splitName);
    if (treatment === 'on') {
      return featureOnFn && featureOnFn();
    }
    if (treatment === 'off') {
      return featureOffFn && featureOffFn();
    }

    return null;
  });
};

/**
 * @param {string} splitName
 * @param {string=} userId
 * @returns {Promise<boolean>}
 */
const isFeatureOn = async (splitName, userId = 'user') => {
  const client = await getSplitClient(userId);
  const value = client.getTreatment(splitName);
  return value === 'on';
};

/**
 * @param {string} splitName
 * @param {string=} userId
 * @returns {Promise<boolean>}
 */
const getFeatureTreatment = async (splitName, userId = 'user', attributes = undefined) => {
  const client = await getSplitClient(userId);
  const value = client.getTreatment(splitName, attributes);
  return value;
};

/**
 * @param {Record<string, string>} treatments
 * @returns {Record<string, bool>}
 */
const processTreatments = (treatments) => {
  if (!treatments) {
    return {};
  }

  const treatmentValues = {
    on: true,
    off: false,
  };

  return Object.fromEntries(
    Object.entries(treatments).map(([key, value]) => [
      key,
      treatmentValues[value] === undefined ? value : treatmentValues[value],
    ]),
  );
};

/**
 * @param {string[]} splitNames
 * @param {string=} userId
 * @param {object{}} attributes : ex: {campaign: 1234}, will return if a FF is enabled for a campaign. On Split UI - create attribute based targeting
 * @returns {Promise<Record<string, bool>>}
 */
const getTreatments = async (splitNames, userId = 'user', attributes = undefined) => {
  const client = await getSplitClient(userId);
  const treatments = client.getTreatments(splitNames, attributes);
  return processTreatments(treatments);
};

export { usingFeatureFlag, isFeatureOn, SplitNames, getTreatments, getFeatureTreatment };
