import React, { useContext } from 'react';
import { Text } from 'socialnature-ui';
import { ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { Error as Question } from 'socialnature-ui/build/icons';
import { Tooltip } from 'socialnature-ui/build/components/Tooltip';
import EditIcon from '../../Atoms/Icons/Edit';
import { campaignTypes, storeLocationCtaLabels } from '../../utils/campaignTypes';
import StoreLocatorLink from '../StoreLocatorLink';

export const QuestionIconWrapper = styled.span`
  display: inline-block;
  line-height: 0;
  vertical-align: top;
  transform: rotate(180deg);
`;

export const StyledLocationText = styled(Text)`
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap;
  color: #08adcd;
`;

export const ReadonlyStyledLocationText = styled(Text)`
  text-transform: uppercase;
  white-space: nowrap;
  font-weight: bold;
  color: #000000;
`;

export const StyledEditIcon = styled(EditIcon)`
  height: 1rem;
  vertical-align: middle;
  color: #08adcd;
`;

const ProvidedLocationText = (props) => {
  const { allowEditLocation, onClick, providedLocation, campaignType, storesListValue } = props;
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const tooltip =
    'We use the ZIP/Postal Code you provided when signing up. You can change it on the My Address page.';

  const LocationLabelEnum = {
    [campaignTypes.IN_STORE]: 'Eligible at',
    [campaignTypes.REBATE]: 'Eligible at',
    DEFAULT: 'Delivery available to',
  };
  if (allowEditLocation) {
    return (
      <>
        <span>{LocationLabelEnum[campaignType] || LocationLabelEnum.DEFAULT}&nbsp;</span>
        <strong>
          <span style={{ textTransform: 'capitalize' }}>{storesListValue}</span>
        </strong>{' '}
        near&nbsp;
        <StyledLocationText
          as="span"
          cursor="pointer"
          weight="bold"
          size="small"
          color="launchpad"
          onClick={onClick}
        >
          <>
            {providedLocation}
            <StyledEditIcon htmlColor="#08ADCD" />
          </>
        </StyledLocationText>
      </>
    );
  }

  const LocationAndTooltip = () => (
    <>
      <ReadonlyStyledLocationText as="span" weight="bold" size="small">
        {providedLocation}
      </ReadonlyStyledLocationText>
      <Tooltip content={tooltip} maxWidth={isXsmall ? 'calc(100vw - 32px)' : '280px'}>
        <QuestionIconWrapper>
          <Question width="18" height="18" color="launchpad" />
        </QuestionIconWrapper>
      </Tooltip>
    </>
  );

  return (
    <>
      {campaignType === campaignTypes.IN_STORE || campaignType === campaignTypes.REBATE ? (
        <>
          <span>{LocationLabelEnum[campaignType]}&nbsp;</span>
          <strong>
            <span style={{ textTransform: 'capitalize' }}>{storesListValue}</span>
          </strong>{' '}
          near&nbsp;
          <LocationAndTooltip />
        </>
      ) : (
        <>
          <span>{LocationLabelEnum[campaignType] || LocationLabelEnum.DEFAULT}&nbsp;</span>
          <LocationAndTooltip />
        </>
      )}
    </>
  );
};

const LocationSelectorButton = ({
  allowEditLocation,
  openGeoModal,
  providedLocation,
  campaignType,
  hasBeenAccepted,
  slug,
  openStoreLocationModal,
  campaignId,
  userId,
  reactivateOfferIsOn,
  hasWanted,
  storesInfo,
}) => {
  const openModal = (e) => {
    e.preventDefault();
    const storeLocatorCTALabel = storeLocationCtaLabels[campaignType];

    if (storeLocatorCTALabel) {
      openStoreLocationModal(storeLocatorCTALabel);
    } else {
      openGeoModal(providedLocation);
    }
  };

  const CtaWrapper = styled.span`
    cursor: pointer;
  `;

  return (
    <div className="launch-campaign-info">
      <Text margin={{ down: '20px' }} size="16px">
        {providedLocation ? (
          <>
            <ProvidedLocationText
              onClick={openModal}
              allowEditLocation={allowEditLocation}
              providedLocation={providedLocation}
              campaignType={campaignType}
              hasBeenAccepted={hasBeenAccepted}
              storesListValue={storesInfo.listValue}
            />
            {(campaignType === campaignTypes.IN_STORE || campaignType === campaignTypes.REBATE) && (
              <StoreLocatorLink
                hasBeenAccepted={hasBeenAccepted}
                openStoreLocatorPopup={openStoreLocationModal}
                slug={slug}
                campaignType={campaignType}
                campaignId={campaignId}
                userId={userId}
                reactivateOfferIsOn={reactivateOfferIsOn}
                hasWanted={hasWanted}
              />
            )}
          </>
        ) : (
          <>
            {campaignType === campaignTypes.IN_STORE || campaignType === campaignTypes.REBATE ? (
              <StoreLocatorLink
                hasBeenAccepted={hasBeenAccepted}
                openStoreLocatorPopup={openStoreLocationModal}
                slug={slug}
                campaignType={campaignType}
                campaignId={campaignId}
                userId={userId}
                reactivateOfferIsOn={reactivateOfferIsOn}
                hasWanted={hasWanted}
              />
            ) : (
              <>
                <CtaWrapper>
                  <Text
                    as="span"
                    cursor="pointer"
                    weight="bold"
                    size="16px"
                    color="launchpad"
                    onClick={openModal}
                  >
                    Enter location
                  </Text>
                </CtaWrapper>{' '}
                to check delivery availability
              </>
            )}
          </>
        )}
      </Text>
    </div>
  );
};

export default LocationSelectorButton;
