/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
// @ts-check
import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { HashLink as Link } from '../../Atoms/HashLink';
// @ts-ignore
import classNames from 'classnames';
// @ts-ignore
import { Heart as HeartIcon, Boost as BoostIcon, Text, Box } from 'socialnature-ui';
import { ImageSlider } from '../../Organisms/Product/ImageSlider';
import ProductSocialShare from './ProductSocialShare';
import Badge from '../../Atoms/Badge';
import NoSamplesInArea from '../../Organisms/Products/NoSamplesInArea';
import { CampaignInfo, BannerInfo, GeoInfo, CampaignInfoText } from '../../Molecules/PerkInfo';
import HighlightedFreeSamplesBellBox from '../../Organisms/Products/HighlightedFreeSamplesBellBox';
import { useFreeSample } from '../../hooks/useFreeSample';
import { CTATypes, getBadge } from '../../utils/product/index';
import CampaignProgressBar from './CampaignProgressBar';
import RebateProgressBar from './CampaignProgressBar/rebate';
import { campaignTypes, RedeemInfo } from '../../utils/campaignTypes';
import getUserId from '../../utils/userId';
import LoadingSection from '../../Molecules/LoadingSection';
import { isFeatureOn, SplitNames } from '../../utils/splitio';

function SingleProductInfo({ campaign, countries, showBanner, wantDisabled, ctaType }) {
  const hasOfferActivated =
    ctaType === CTATypes.OFFER_ACTIVATED ||
    ctaType === CTATypes.ADD_A_RECEIPT ||
    ctaType === CTATypes.REACTIVATE_OFFER ||
    ctaType === CTATypes.OFFER_REDEEMED;
  return (
    <>
      {hasOfferActivated || !wantDisabled ? (
        <CampaignInfo campaign={campaign} size="16px" />
      ) : (
        showBanner && !R.isEmpty(countries) && <BannerInfo countries={countries} />
      )}
    </>
  );
}

export default function ProductAssetsGallery(props) {
  const {
    ctaType,
    ProductCTAButton,
    campaignStatus,
    assets,
    video,
    details,
    coupon,
    geoInfo,
    socialShare,
    openGeoModal,
    campaign,
    isLoggedIn,
    openStoreLocationModal,
    userId,
    reactivateOfferIsOn,
    userWant,
  } = props;
  const {
    id: productId,
    name: productName,
    companySlug,
    companyName,
    companyEnableStorefront,
    totalWants,
    isLaunchpad,
    averageRating,
    totalReviews,
    wantDisabled,
    hasWanted,
    hasBeenAccepted,
    qualities,
    certifications,
  } = details;
  const compoundValues = [...qualities, ...certifications];
  const { showBanner, bannerMessage, countries } = coupon;
  const { location, country, isNearby } = geoInfo;
  const { campaign: wantCampaign } = userWant || {};
  const isUserWaitlisted = !wantCampaign;

  const userTrackingId = getUserId();
  const { loading, error, data } = useFreeSample(location, country, userTrackingId, isNearby, true);
  const [alternateGeoProducts, setAlternateGeoProducts] = useState([]);
  const [rebateRedemptionsFull, setRebateRedemptionsFull] = useState(false);

  useEffect(() => {
    isFeatureOn(SplitNames.rebateRedemptionsFull, userId).then(setRebateRedemptionsFull);
  }, [userId]);

  useEffect(() => {
    if (!loading && !error && data && data.freetrials) {
      setAlternateGeoProducts(data.freetrials.products || []);
    }
  }, [data, loading, error, location]);

  const formedAvgRating = Math.round(averageRating * 10);

  // @ts-ignore
  const keys = Array.from({ length: 5 }, (v, k) => k * 10 + 10);

  const values = compoundValues.map((value) => (
    <span className="launch-qualities--list" key={value.id}>
      {value.name}
    </span>
  ));

  const { properties } = campaign || {};
  const { offerDiscountPercentage } = properties || {};

  const badge = getBadge(ctaType, bannerMessage, offerDiscountPercentage);

  const userIsNearLocations = () =>
    geoInfo.success && geoInfo.success.includes(productId) && geoInfo.location;
  const showCampaignInfo = () => {
    // user is logged out and there is not location
    if (!isLoggedIn && !geoInfo.location) return true;

    // user is logged out and is near location
    if (!isLoggedIn && userIsNearLocations()) return true;

    // user is logged-in, near locations, he is never applied, or he have been already accepted
    if (
      isLoggedIn &&
      userIsNearLocations() &&
      ((!hasWanted && !hasBeenAccepted) ||
        (hasWanted && hasBeenAccepted) ||
        (hasWanted &&
          !hasBeenAccepted &&
          campaign.campaignType === campaignTypes.REBATE &&
          !isUserWaitlisted &&
          reactivateOfferIsOn))
    ) {
      return true;
    }

    return false;
  };

  const { icon: RedeemIcon } = campaign
    ? RedeemInfo[campaign.campaignType]
    : RedeemInfo[campaignTypes.IN_STORE];

  return (
    <div className="launch-content fade-in">
      <a id="top" className="anchor" />
      <div className="layout-row-sm">
        <div className="flex-65">
          <ImageSlider video={video} images={assets} productName={productName} />
        </div>

        <div className="module-info-hero layout-column flex-35">
          {badge && (
            <div className="module-info-hero--badge">
              <Badge text={badge.text} color={badge.color} />
            </div>
          )}

          <h1 itemProp="name">{productName}</h1>
          <h2>
            <span>by </span>
            {companyEnableStorefront ? (
              <a
                href={`/brand/${companySlug}`}
                itemProp="brand"
                itemScope
                itemType="https://schema.org/Brand"
              >
                <span itemProp="name">{companyName}</span>
              </a>
            ) : (
              <span itemProp="brand" itemScope itemType="https://schema.org/Brand">
                <span itemProp="name">{companyName}</span>
              </span>
            )}
          </h2>

          {compoundValues.length < 4 ? (
            <h4>{values}</h4>
          ) : (
            <h4>
              {values.slice(0, 3)}
              <Link to="#qualities" smooth className="link-site">
                {` +${compoundValues.length - 3} more`}
              </Link>
            </h4>
          )}

          <div className="launch-numbers">
            {formedAvgRating > 0 && totalReviews > 0 && (
              <div
                className="launch-rating"
                itemProp="aggregateRating"
                itemScope
                itemType="https://schema.org/AggregateRating"
              >
                <meta itemProp="reviewCount" content={totalReviews} />
                <meta itemProp="ratingValue" content={averageRating} />
                <meta itemProp="bestRating" content="5" />
                <div className="launch-rating--icons">
                  {keys.map((v) => (
                    <i key={v} className="material-icons">
                      {classNames({
                        star: formedAvgRating - v > -4,
                        star_half: formedAvgRating - v < -3 && formedAvgRating - v > -8,
                        star_border: formedAvgRating - v < -7,
                      })}
                    </i>
                  ))}
                </div>
                <Link to="#reviews" smooth className="link-site">
                  {`${totalReviews.toLocaleString()} reviews`}
                </Link>
              </div>
            )}
            {totalWants > 0 && (
              <div className="launch-totalwants">
                {isLaunchpad ? <BoostIcon color="launchpad" /> : <HeartIcon color="freetrial" />}
                <Text as="span" size="small">
                  {totalWants.toLocaleString()}
                </Text>
              </div>
            )}
          </div>
          <div className="nudge-sm" />

          {(ctaType === CTATypes.ACTIVATE_OFFER ||
            ctaType === CTATypes.OFFER_ACTIVATED ||
            ctaType === CTATypes.ADD_A_RECEIPT ||
            ctaType === CTATypes.REACTIVATE_OFFER ||
            ctaType === CTATypes.OFFER_REDEEMED) && (
            <>
              <div className="nudge-sm" />
              {rebateRedemptionsFull ? (
                <CampaignProgressBar campaignId={campaign.id} />
              ) : (
                <RebateProgressBar campaignId={campaign.id} />
              )}
              <div className="nudge-md" />
            </>
          )}

          {!geoInfo.loaded ? (
            <Box margin={{ top: '16px' }}>
              <LoadingSection />
            </Box>
          ) : (
            <>
              {showCampaignInfo() && (
                <SingleProductInfo
                  countries={countries}
                  campaign={campaign}
                  // @ts-ignore
                  geoInfo={geoInfo}
                  openGeoModal={openGeoModal}
                  productId={productId}
                  showBanner={showBanner}
                  wantDisabled={wantDisabled}
                  ctaType={ctaType}
                />
              )}
              {(!wantDisabled ||
                (hasBeenAccepted &&
                  (ctaType === CTATypes.ACTIVATE_OFFER ||
                    ctaType === CTATypes.OFFER_ACTIVATED ||
                    ctaType === CTATypes.REACTIVATE_OFFER ||
                    ctaType === CTATypes.ADD_A_RECEIPT ||
                    ctaType === CTATypes.OFFER_REDEEMED))) && (
                <>
                  {showCampaignInfo() ? (
                    <>
                      <div data-cy="how-to-get-it">
                        <CampaignInfoText size="16px" icon={<RedeemIcon color="black" />}>
                          <strong>How to get it</strong> <br />
                        </CampaignInfoText>
                        <GeoInfo
                          geoInfo={geoInfo}
                          openGeoModal={openGeoModal}
                          productId={productId}
                          campaignType={campaign.campaignType}
                          campaignId={campaign.id}
                          userId={userId}
                          hasBeenAccepted={hasBeenAccepted}
                          slug={details.slug}
                          openStoreLocationModal={openStoreLocationModal}
                          reactivateOfferIsOn={reactivateOfferIsOn}
                          hasWanted={hasWanted}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="nudge-sm" />
                      <NoSamplesInArea
                        campaignType={campaign.campaignType}
                        alternateGeoProducts={alternateGeoProducts}
                        location={geoInfo.location}
                        openGeoModal={openGeoModal}
                        openStoreLocationModal={openStoreLocationModal}
                      />
                      <div className="nudge-sm" />
                    </>
                  )}
                </>
              )}
              <>
                {ctaType === CTATypes.SIGN_UP ? (
                  <HighlightedFreeSamplesBellBox>{ProductCTAButton}</HighlightedFreeSamplesBellBox>
                ) : (
                  <div className="desktop">{ProductCTAButton}</div>
                )}
                <div className="desktop">
                  {campaignStatus && (
                    <Text textAlign="center" size="small" margin="xsmall">
                      <span>SAMPLE STATUS:</span>
                      <Text as="span" weight="bold" size="small" margin={{ left: '0.5em' }}>
                        {campaignStatus}
                      </Text>
                    </Text>
                  )}
                  <ProductSocialShare viewModel={socialShare} />
                </div>
              </>
            </>
          )}
        </div>
      </div>

      {assets.length < 2 && <div className="nudge-lg" />}
    </div>
  );
}
