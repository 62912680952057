import { useEffect } from 'react';
import { handleError } from '../error';
import { getCookie } from '../cookie';

async function doesUserPrequalifyForProduct(userId, productId) {
  const baseUrl = process.env.API_V2_URL;
  const tokenResult = getCookie('User_Token');
  const result = await fetch(`${baseUrl}/prequalify/${userId}/product/${productId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${tokenResult}`,
    },
  })
    .then((r) => {
      if (r.status === 200) return r.json();

      return Promise.reject(r);
    })
    .then((data) => data);
  return result;
}

export function CheckQualificationEngine(userId, productId, setQualificationResult) {
  useEffect(() => {
    if (userId && productId) {
      doesUserPrequalifyForProduct(userId, productId)
        .then((result) => {
          if (result.results.length > 0) {
            const campaigns = result.results.filter((r) => r.isQualified);
            setQualificationResult({ loaded: true, qualifiedCampaigns: campaigns });
          }
        })
        .catch((e) => {
          handleError(e);
          setQualificationResult({ loaded: false, qualifiedCampaigns: [], error: e });
        });
    }
  }, [userId, productId, setQualificationResult]);
}

export async function doesUserPrequalifyForCampaign(userId, campaignId) {
  const baseUrl = process.env.API_V2_URL;
  const tokenResult = getCookie('User_Token');
  try {
    const response = await fetch(
      `${baseUrl}prequalify/${userId}/campaign/${campaignId}?useSegment=false`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokenResult}`,
        },
      },
    );

    if (response.status === 200) {
      const data = await response.json();
      return data;
    }
    return null;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error in doesUserPrequalifyForCampaign:', error);
    return null; // Return null in case of an error
  }
}
