import React from 'react';
import { getShareUrl, getEmailText } from '../../utils/socialShare';
import FacebookShareButton from '../../Molecules/SocialShare/Facebook/ShareButton';
import TwitterShareButton from '../../Molecules/SocialShare/Twitter/ShareButton';
import EmailShareButton from '../../Molecules/SocialShare/Email/ShareButton';
import EmailShareButtonPopup from '../../Molecules/SocialShare/Email/ShareButtonPopup';
import CopyLinkButton from '../../Molecules/SocialShare/CopyLink/CopyLinkButton';

const SocialShareButtons = (props) => {
  const { userId, product, twitterMessage, isEmailForm, isAccepted, dataTrackCategory, variant } =
    props;
  const { name, company, id, slug } = product || {};
  const { name: companyName } = company || {};
  const shareUrl = getShareUrl(product, userId);
  const emailText = getEmailText(product, userId);

  return (
    <>
      <FacebookShareButton
        data-track-action="Facebook"
        data-track-category={dataTrackCategory || 'Product Share'}
        data-track-label={slug}
        data-track-samplekit-id={id}
        name={name}
        companyName={companyName}
        shareUrl={shareUrl}
        variant={variant}
      />
      <TwitterShareButton
        data-track-action="Twitter"
        data-track-category={dataTrackCategory || 'Product Share'}
        data-track-label={slug}
        data-track-samplekit-id={id}
        message={twitterMessage}
        shareUrl={shareUrl}
        variant={variant}
      />
      {!isEmailForm ? (
        <EmailShareButton
          data-track-action="Email"
          data-track-category={dataTrackCategory || 'Product Share'}
          data-track-label={slug}
          data-track-samplekit-id={id}
          subject={emailText.subject}
          body={emailText.body}
          variant={variant}
        />
      ) : (
        <EmailShareButtonPopup
          data-track-action="Email"
          data-track-category={dataTrackCategory || 'Product Share'}
          data-track-label={slug}
          data-track-samplekit-id={id}
          shareUrl={shareUrl}
          userId={userId}
          product={product}
          isAccepted={isAccepted}
          dataTrackCategory={dataTrackCategory}
          variant={variant}
        />
      )}
      <CopyLinkButton
        data-track-action="Copy Link"
        data-track-category={dataTrackCategory || 'Product Share'}
        data-track-label={slug}
        data-track-samplekit-id={id}
        shareUrl={shareUrl}
        variant={variant}
      />
    </>
  );
};

export default SocialShareButtons;
