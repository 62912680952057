import React, { useCallback, useState, useMemo, useRef, memo } from 'react';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';

export const ImageSlider = memo((props) => {
  const { video, images: initImages, productName } = props;
  const images = Array.isArray(initImages) ? initImages : [];
  const [videoPlaying, setVideoPlaying] = useState(false);
  const slider = useRef(null);
  const videoPlayer = useRef(null);

  const handleSelectMedia = useCallback(
    (index) => (e) => {
      e.preventDefault();
      if (slider) {
        // @ts-ignore
        slider.current.slickGoTo(index);
      }
    },
    [],
  );

  const handleOnPause = useCallback(() => {
    setVideoPlaying(false);
  }, []);

  const handleOnPlay = useCallback(() => {
    setVideoPlaying(true);
  }, []);

  const settings = useMemo(
    () => ({
      dots: false,
      infinite: false,
      speed: 100,
      slidesToShow: 1,
      slidesToScroll: 1,
      beforeChange: handleOnPause,
      responsive: [
        {
          breakpoint: 840,
          settings: {
            dots: true,
          },
        },
      ],
    }),
    [handleOnPause],
  );

  let assetIndex = 0;

  if (!video && !images.length) {
    return <></>;
  }

  return (
    <>
      <div className="module-hero">
        <Slider ref={slider} {...settings} data-testid="slider">
          {video && (
            // @ts-ignore
            <ReactPlayer
              ref={videoPlayer}
              playing={videoPlaying}
              onPlay={handleOnPlay}
              width="100%"
              height="100%"
              url={`${video}?dnt=1`}
              controls={true}
              className="reactPlayer"
              data-testid="react-player"
              config={{
                vimeo: {
                    playerOptions: {
                        dnt: true, // Do Not Track option
                    },
                },
            }}
            />
          )}
          {images.map((asset) => (
            <img
              key={asset.medium.split('/').pop()}
              src={asset.medium}
              itemProp="image"
              alt={productName}
              data-testid="slider-image"
            />
          ))}
        </Slider>
      </div>
      <div className="layout-column">
        <div className="flex-center">
          {video && images.length > 0 && (
            <div className="module-selector">
              <button
                type="button"
                className="module-selector-thumb"
                itemProp="video"
                onClick={handleSelectMedia(assetIndex++)}
                data-testid="video-button"
              >
                <i className="fas fa-play-circle" />
              </button>

              <div className="nudge-lg" />
            </div>
          )}
          {((video && images.length === 1) || images.length > 1) && (
            <div className="module-selector">
              {images.map((asset) => (
                <button
                  type="button"
                  key={asset.medium.split('/').pop()}
                  onClick={handleSelectMedia(assetIndex++)}
                  className="module-selector-thumb"
                  data-testid="image-button"
                >
                  <img
                    key={asset.medium.split('/').pop()}
                    src={asset.thumbnail}
                    alt=""
                    role="presentation"
                  />
                </button>
              ))}
              <div className="nudge-lg" />
            </div>
          )}
        </div>
      </div>
    </>
  );
});
