import React, { useContext } from 'react';
import { Grommet, ResponsiveContext } from 'grommet';
import { Box, Text, TextLink, themes } from 'socialnature-ui';
import theme from '../../themes';
import GiftIcon from '../../Atoms/Icons/Gift';

const NoFreeTrial = ({ width }) => {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <Grommet theme={themes}>
      <Box
        width={width || { max: '798px' }}
        background={theme.global.colors.lightPink}
        round="8px"
        margin={{ horizontal: 'auto' }}
        pad="medium"
      >
        <Text
          weight="bold"
          size={isXsmall ? 'medium' : 'large'}
          color="pink"
          icon={
            <GiftIcon
              htmlColor={themes.global.colors.pink}
              width={isXsmall ? '16px' : '24px'}
              height={isXsmall ? '16px' : '24px'}
            />
          }
          textAlign={isXsmall ? 'start' : 'center'}
        >
          Free samples coming soon near you!
        </Text>
        <Text
          size={isXsmall ? 'small' : 'medium'}
          textAlign={isXsmall ? 'start' : 'center'}
          margin={{ top: 'xsmall', bottom: '0' }}
        >
          <>
            <span>Every week, we add new samples for you to try for free. </span>
            <span>Products are matched based on your </span>
            <TextLink href="/account/profile" label="profile preferences" fontWeight="bold" />
            <span> and </span>
            <TextLink href="/account/address" label="location" fontWeight="bold" />
            <span>. </span>
            <span>You can explore hundreds of reviewed products and enjoy perks.</span>
          </>
        </Text>
      </Box>
    </Grommet>
  );
};

export default NoFreeTrial;
