// NOTE: Using light mode only
import React from 'react';
import themeFontFamily from './Fonts';

const Check = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#FFFFFF"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
  >
    <path d="M8.59 15.58L4.42 11.41L3 12.82L8.59 18.41L20.59 6.41L19.18 5L8.59 15.58Z" />
  </svg>
);

const basicTheme = {
  global: {
    colors: {
      brand: '#37C0C0',
      blue: '#08ADCD',
      pink: '#DB2573',
      lightPink: '#F9DCE9',
      launchpad: '#08ADCD',
      freetrial: '#E42748',
      green: '#22B46E',
      anotherGreen: '#DEF3F3',
      black: '#333333',
      slate: '#5D5D5D',
      anotherSlate: '#4D4D4D',
      iron: '#AAAAAA',
      silver: '#CCCCCC',
      smoke: '#F1F1F1',
      cloud: '#F7F7F7',
      white: '#FBFBFB',
      anotherWhite: '#FFFFFF',
      active: 'launchpad',
      focus: 'launchpad',
      control: 'launchpad',
      placeholder: 'iron',
      selected: 'launchpad',
      text: {
        light: 'black',
      },
      background: 'transparent',
      body: 'white',
      'status-critical': '#E42748',
      'cta-primary': 'linear-gradient(348.5deg, #D1239D 0%, #E42748 100%)',
      'cta-primary-hover': 'linear-gradient(346.52deg, #D01334 0%, #BD0F89 100%);',
      'cta-secondary': 'linear-gradient(348.48deg, #37AEC0 -0.39%, #08ADCD 99.69%);',
    },
    borderSize: {
      xsmall: '1px',
      small: '2px',
      medium: '4px',
      large: '12px',
      xlarge: '24px',
    },
    breakpoints: {
      xsmall: {
        value: 640,
      },
      small: {
        value: 768,
        borderSize: {
          xsmall: '1px',
          small: '2px',
          medium: '4px',
          large: '6px',
          xlarge: '12px',
        },
        edgeSize: {
          none: '0px',
          hair: '1px',
          xxsmall: '2px',
          xsmall: '3px',
          small: '8px',
          medium: '16px',
          large: '24px',
          xlarge: '40px',
          xxlarge: '48px',
          huge: '56px',
          card: '8px',
          cardWrapper: '-16px',
          section: '32px',
          negativeSection: '-32px',
          breadcrumb: '16px',
        },
        size: {
          xxsmall: '24px',
          xsmall: '48px',
          small: '96px',
          medium: '192px',
          large: '384px',
          xlarge: '768px',
          full: '100%',
          quality: '60px',
        },
      },
      medium: {
        value: 1024,
      },
      large: {},
    },
    deviceBreakpoints: {
      phone: 'small',
      tablet: 'medium',
      computer: 'large',
    },
    edgeSize: {
      none: '0px',
      hair: '1px',
      xxsmall: '3px',
      xsmall: '8px',
      small: '16px',
      medium: '24px',
      large: '32px',
      xlarge: '48px',
      xxlarge: '56px',
      huge: '64px',
      radius: '4px',
      card: '16px',
      cardWrapper: '-16px',
      section: '56px',
      negativeSection: '-56px',
      breadcrumb: '56px',
      responsiveBreakpoint: 'small',
    },
    elevation: {
      light: {
        none: 'none',
        image: '0px 4px 0px #FBFBFB',
        card: '0px 8px 16px rgba(0, 0, 0, 0.1);',
        smallImage: '0px 8px 24px rgba(204, 204, 204, 0.6)',
      },
    },
    focus: {
      border: {
        color: 'focus',
      },
    },
    font: {
      size: '16px',
      height: '24px',
      maxWidth: '100%',
      family: `${themeFontFamily.default}`,
    },
    selected: {
      background: 'selected',
      color: 'white',
    },
    size: {
      xxsmall: '48px',
      xsmall: '96px',
      small: '192px',
      medium: '384px',
      large: '768px',
      xlarge: '1152px',
      xxlarge: '1536px',
      full: '100%',
      quality: '103px',
    },
    opacity: {
      strong: 0.7,
    },
    control: {
      border: {
        radius: '4px',
      },
    },
  },
  icon: {
    size: {
      small: '12px',
      medium: '24px',
      large: '48px',
      xlarge: '96px',
    },
  },
  anchor: {
    textDecoration: 'none',
    fontWeight: 400,
    color: {
      light: 'launchpad',
    },
    hover: {
      textDecoration: 'underline',
      extend: 'color: #08ADCD',
    },
  },
  box: {
    responsiveBreakpoint: 'small',
    extend: 'position: relative',
  },
  button: {
    border: {
      width: '1px',
      radius: '4px',
    },
    primary: {},
    disabled: {
      opacity: 0.3,
    },
    minWidth: '96px',
    maxWidth: '384px',
    padding: {
      vertical: '8px',
      horizontal: '0',
    },
    extend: `
		&:hover {
			box-shadow: none;
		}
		&:focus {
			background-color: inherit;
			color: inherit;
		}
	`,
  },
  checkBox: {
    border: {
      color: {
        light: 'silver',
      },
      width: '1px',
    },
    check: {
      radius: '4px',
      thickness: '4px',
      extend: ({ checked }) => `
			${checked && 'background-color: #08ADCD; svg {fill: #FFFFFF}'}
			`,
    },
    icon: {},
    icons: {
      checked: Check,
    },
    hover: {
      border: {
        color: {
          light: 'launchpad',
        },
      },
    },
    size: '16px',
  },
  radioButton: {
    border: {
      color: {
        light: 'silver',
      },
      width: '1px',
    },
    hover: {
      border: {
        color: {
          light: 'launchpad',
        },
      },
    },
    size: '16px',
  },
  formField: {
    extend: 'width: 100%; &>div {border-radius: 4px;}',
    label: {
      color: 'black',
      size: '16px',
      margin: { vertical: '0', bottom: 'xsmall', horizontal: '0' },
      weight: 'bold',
    },
    border: {
      color: 'silver',
      error: {
        color: 'status-critical',
      },
      side: 'all',
    },
    error: {
      margin: {
        vertical: 'xxsmall',
        horizontal: 'none',
      },
      size: 'xsmall',
    },
    margin: 0,
  },
  select: {
    control: {
      extend: 'padding: 2px 0;',
      open: {
        border: '1px solid #08ADCD',
      },
    },
    options: {
      container: {
        pad: 'medium',
      },
    },
    container: {
      extend: `background: #FBFBFB;
			border: 1px solid #CCCCCC;
			box-sizing: border-box;
			box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
			border-radius: 4px;
			`,
    },
    icons: {
      color: 'slate',
    },
  },
  heading: {
    font: {
      family: `${themeFontFamily.heading}`,
    },
    level: {
      1: {
        medium: {
          size: '40px',
          height: '48px',
        },
        hero: {
          size: '40px',
          height: '48px',
          maxWidth: '100%',
        },
      },
      2: {
        medium: {
          size: '32px',
          height: '40px',
          maxWidth: '100%',
        },
        hero: {
          size: '24px',
          height: '32px',
          maxWidth: '100%',
        },
      },
      3: {
        medium: {
          size: '24px',
          height: '32px',
          maxWidth: '100%',
        },
      },
      4: {
        medium: {
          size: '16px',
          height: '24px',
          maxWidth: '432px',
        },
      },
    },
    responsiveBreakpoint: 'small',
    weight: 700,
  },
  // Keep the value below 10000 so that the loading image is always on top.
  layer: {
    background: 'background',
    container: {
      zIndex: '110',
    },
    border: {
      radius: '0',
    },
    overlay: {
      background: 'rgba(51, 51, 51, 0.7)',
    },
    responsiveBreakpoint: 'small',
    zIndex: '110',
  },
  meter: {
    color: 'launchpad',
  },
  paragraph: {
    xsmall: {
      size: '12px',
      height: '20px',
      maxWidth: '100%',
    },
    small: {
      size: '14px',
      height: '20px',
      maxWidth: '100%',
    },
    medium: {
      size: '16px',
      height: '20px',
      maxWidth: '100%',
    },
    large: {
      size: '24px',
      height: '28px',
      maxWidth: '100%',
    },
  },
  text: {
    xsmall: {
      size: '12px',
      height: '18px',
      maxWidth: '100%',
    },
    small: {
      size: '14px',
      height: '20px',
      maxWidth: '100%',
    },
    medium: {
      size: '16px',
      height: '20px',
      maxWidth: '100%',
    },
    large: {
      size: '24px',
      height: '28px',
      maxWidth: '100%',
    },
  },
  textInput: {
    extend: 'line-height: 24px',
  },
};

export default basicTheme;
