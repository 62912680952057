import React, { useLayoutEffect, useState } from 'react';
import { getTreatments, SplitNames } from '../../utils/splitio';
import Sections from '../../Templates/Sections';
import HomeBanner from '../../Organisms/Home/HomeBanner';
import Loading from '../../Molecules/Loading';
import HeroSection from './Sections/HeroSection';
import CashBackSection from '../../Organisms/Home/CashBackSection';
import FreeTrialSection from './Sections/FreeTrialSection';
import LaunchpadSection from './Sections/LaunchpadSection';
import QualitySection from './Sections/QualitySection';
import AboutTryNatural from './Sections/AboutTryNatural';
import ReviewSection from './Sections/ReviewSection';
import TopRatedSection from './Sections/TopRatedSection';
import useLocationAndSessionFromPath from '../../hooks/usePostalCodeFromQuery';


const LoggedOutHomePage = () => {
  const _ = useLocationAndSessionFromPath();
  const [featureFlags, setFeatureFlags] = useState({
    [SplitNames.rebateBannerHome]: undefined,
    [SplitNames.launchpadSectionHome]: undefined,
    [SplitNames.discountsSectionHome]: undefined,
    [SplitNames.collectionsFilteredByPostalCodeLoggedOut]: undefined,
    isLoading: true,
  });

  useLayoutEffect(() => {
    getTreatments([
      SplitNames.rebateBannerHome,
      SplitNames.launchpadSectionHome,
      SplitNames.discountsSectionHome,
      SplitNames.collectionsFilteredByPostalCodeLoggedOut
    ]).then((treatments) => {
      setFeatureFlags((prev) => ({ ...prev, ...treatments, isLoading: false }));
    });
  }, [setFeatureFlags]);

  const isRebateHomeOn = featureFlags[SplitNames.rebateBannerHome];
  const isLaunchpadHomeOn = featureFlags[SplitNames.launchpadSectionHome];
  const collectionsFilteredByPostalCodeLoggedOutFF = featureFlags[SplitNames.collectionsFilteredByPostalCodeLoggedOut];

  const getStoredPostalCode = () => window.localStorage.getItem('cn_location') || '';
  // Get postal code from local storage if the feature flag is on
  const postalCode = collectionsFilteredByPostalCodeLoggedOutFF
    ? getStoredPostalCode()
    : '';

  if (isRebateHomeOn === undefined || featureFlags.isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isRebateHomeOn ? <HomeBanner /> : <HeroSection />}
      <Sections>
        {isRebateHomeOn && <CashBackSection />}
        <FreeTrialSection postalCode={postalCode} />
        {isLaunchpadHomeOn && <LaunchpadSection />}
        <TopRatedSection />
        <ReviewSection />
        <QualitySection />
        {!isRebateHomeOn && <AboutTryNatural />}
      </Sections>
    </>
  );
};

export default LoggedOutHomePage;
