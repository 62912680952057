import { canadaTimezones } from './timezone/canadaTimezones';
import { usTimezones } from './timezone/usTimezones';

export const ISO2_USA = 'US';
export const ISO2_CAN = 'CA';
export const COUNTRY_NAME_LIST = ['Canada', 'United States'];

export function CountryToIso2(country) {
  const usaSpellings = ['United States', 'USA', 'US', 'Estados Unidos', 'America'];
  const canadaSpellings = ['Canada', 'Can', 'CA'];
  if (usaSpellings.includes(country)) {
    return ISO2_USA;
  }
  if (canadaSpellings.includes(country)) {
    return ISO2_CAN;
  }
  return null;
}

export function CountryNameFromCountryISO2(countryISO2) {
  if (countryISO2 === ISO2_USA) return 'United States';
  if (countryISO2 === ISO2_CAN) return 'Canada';
  return null;
}

// MEMO: same logic as CountryFromPostalCode on CountryUtil.cs
export function CountryNameFromPostalCode(postCode) {
  if (!postCode) return null;
  const regexUS = /^[0-9].+$/;
  if (postCode.match(regexUS)) {
    return 'United States';
  }
  return 'Canada';
}

export function CountryNameFromTimezone() {
  if (!Intl) return null;

  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (canadaTimezones.includes(timezone)) {
    return ISO2_CAN;
  }

  if (usTimezones.includes(timezone)) {
    return ISO2_USA;
  }

  return null;
}

export function CountryISO2FromPostalCode(postCode) {
  if (!postCode) return null;
  const regexUS = /^[0-9].+$/;
  if (postCode.match(regexUS)) {
    return CountryToIso2('United States');
  }
  return CountryToIso2('Canada');
}

export function getCompanyName(slug) {
  if (!slug || !Array.isArray(slug)) return null;

  const name = slug.reduce((str, s) => {
    switch (s) {
      case 'US':
      case 'United States':
        return `${str}United States & `;
      case 'CA':
      case 'Canada':
        return `${str}Canada & `;
      default:
        return '';
    }
  }, '');
  if (name.length > 3) {
    // Delete ' & '
    return name.slice(0, -3);
  }
  return null;
}


export function getUserRegionFromNavigatorLanguage() {
  const supportedRegions = ['US', 'CA'];
  
  const language = navigator.language || '';
  const region = language.split('-')[1]?.toUpperCase() || null;

  if (region && supportedRegions.includes(region)) {
    return region;
  }

  return 'US';
}