import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Box } from 'grommet';
import { useQuery } from '@apollo/react-hooks';
import Container from '../../Atoms/Container';
import getUserId, { getUserSession } from '../../utils/userId';
import LaunchpadList from '../../Organisms/Products/LaunchpadList';
import FreeTrialList from '../../Organisms/Products/FreeTrialList';
import TopRatedList from '../../Organisms/Products/TopRatedList';
import {
  GET_FREETRIALS_UPSELL,
  GET_LAUNCHPADS_UPSELL,
  GET_TOPRATED_UPSELL,
} from '../../utils/apis/products';
import { SplitNames, isFeatureOn } from '../../utils/splitio';
import { campaignTypes } from '../../utils/campaignTypes';
import LoadingSection from '../../Molecules/LoadingSection';
import formatParam from '../../utils/apis/formatParam';
import Sections from '../../Templates/Sections';
import NoFreeTrial from '../../Organisms/Products/NoFreeTrial';
import ExploreSN from '../../Organisms/ExploreSNModal';
import { isMultiChannelDomain } from '../../utils/app';

const StyledSections = styled(Sections)`
  &&& {
    .section-bg-gray {
      ${({ displayDivider }) => (displayDivider ? 'background: #FBFBFB;' : '')}
    }
  }
`;

const CarouselBox = styled(Box)`
  ${({ displayDivider }) =>
    displayDivider
      ? `
		padding-bottom: calc(56px - 32px);
		@media only screen and (max-width: 768px) {
			padding-bottom: calc(32px - 24px);
		}
	`
      : ''}
`;

const LineDivider = styled.div`
  display: flex;
  justify-content: center;

  hr {
    margin: 0;
    padding: 0;
    height: 0px;
    width: 100%;
    max-width: 1200px;
    border-color: #cccccc;
    border-width: 1px 0 0;
    border-style: solid;
  }
`;

const LaunchpadSection = ({ userId, userCountry, dataTrackCategory }) => {
  const { loading, error, data } = useQuery(GET_LAUNCHPADS_UPSELL, {
    variables: {
      param: formatParam({
        limit: 20,
        userid: userId,
        filter: userCountry && `country=${userCountry}`,
      }),
    },
  });
  if (loading) return <LoadingSection />;
  if (error) return <></>;
  const { launchpads } = data || {};

  return (
    <>
      {launchpads && launchpads.products && (
        <Box flex={false} pad={{ vertical: 'section' }} className="section-bg-gray">
          <Container>
            <LaunchpadList
              products={launchpads.products}
              linkViewAll={userCountry ? `/launchpad?country=${userCountry}` : '/launchpad'}
              dataTrackCategory={dataTrackCategory}
            />
          </Container>
        </Box>
      )}
    </>
  );
};

const TopRatedSection = ({ userId, userCountry, dataTrackCategory }) => {
  const { loading, error, data } = useQuery(GET_TOPRATED_UPSELL, {
    variables: {
      param: formatParam({
        limit: 20,
        userid: userId,
        filter: userCountry && `country=${userCountry}`,
      }),
    },
  });
  if (loading) return <LoadingSection />;
  if (error) return <></>;
  const { toprated } = data || {};

  return (
    <>
      {toprated && toprated.products && (
        <Box flex={false} pad={{ vertical: 'section' }} className="section-bg-gray">
          <Container>
            <TopRatedList products={toprated.products} dataTrackCategory={dataTrackCategory} />
          </Container>
        </Box>
      )}
    </>
  );
};

const NoProductsAvailable = ({isSNUser, openExploreModal, isGuestDomain, userTrackingID}) => {
  if (!isGuestDomain) {
    return (
      <Box flex={false} pad={{ top: 'section' }} background="white">
        <Container>
          <NoFreeTrial />
        </Container>
      </Box>
    )
  } else {
    return (
      <ExploreSN isOpenModal={openExploreModal} userTrackingID={userTrackingID}/>
    )
  }
}

const RelatedProducts = ({ userCountry, dataTrackCategory, campaignType }) => {
  const [userID, isSNUser] = getUserSession();
  const userTrackingID = getUserId();
  const [isDiscountFeatureEnable, setIsDiscountFeatureEnable] = useState(false);

  const { loading, data } = useQuery(GET_FREETRIALS_UPSELL, {
    variables: {
      param: formatParam({
        limit: 20,
        userid: userTrackingID,
        filter: userCountry && `country=${userCountry}`,
      }),
    },
  });

  useEffect(() => {
    isFeatureOn(SplitNames.discountsV1).then(setIsDiscountFeatureEnable);
  }, []);

  if (loading) return <LoadingSection />;

  const { freetrials } = data || {};
  const displayDivider = isDiscountFeatureEnable && campaignType === campaignTypes.REBATE;
  const hasFreeTrialProducts = freetrials && freetrials.products && freetrials.products.length > 0;

  return (
    <StyledSections displayDivider={displayDivider}>
      {
        hasFreeTrialProducts ? (
          <CarouselBox
            displayDivider={displayDivider}
            flex={false}
            pad={{ vertical: 'section' }}
            className="section-bg-gray"
          >
            <Container>
              <FreeTrialList
                userId={userID}
                products={freetrials.products}
                dataTrackCategory={dataTrackCategory && `${dataTrackCategory} Free Sample`}
                isSNUser={isSNUser}
              />
            </Container>
          </CarouselBox>
        ) : <NoProductsAvailable
          isSNUser={isSNUser}
          openExploreModal={!hasFreeTrialProducts}
          isGuestDomain={isMultiChannelDomain()}
          userTrackingID={userTrackingID}
        />
      }
      {displayDivider && (
        <LineDivider>
          <Container>
            <hr />
          </Container>
        </LineDivider>
      )}

      {!hasFreeTrialProducts && isSNUser && (
        <>
          <LaunchpadSection
            userId={userTrackingID}
            userCountry={userCountry}
            dataTrackCategory={dataTrackCategory && `${dataTrackCategory} Launchpad`}
          />
          <TopRatedSection
            userId={userTrackingID}
            userCountry={userCountry}
            dataTrackCategory={dataTrackCategory && `${dataTrackCategory} Top-Rated`}
          />
        </>
      )}
    </StyledSections>
  );
};

export default RelatedProducts;
