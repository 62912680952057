import React from 'react';
import { RangeInput } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { isNil } from 'ramda';
import { errorMessages } from '../../../utils/customQuestionTypes';

const CustomSliderInput = (props) => {
  const { name, error, question, unit } = props;
  const { required = true, choices = [1, 2, 3, 4, 5] } = question;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      as={RangeInput}
      control={control}
      options={choices}
      unit={unit}
      error={!isNil(error)}
      rules={{ required: required && errorMessages.REQUIRED }}
    />
  );
};

export default CustomSliderInput;
