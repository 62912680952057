import React from 'react';
import { Meter } from 'grommet';

const ProgressBar = ({ value, color }) => (
  <Meter
    type="bar"
    background={{
      color: '#F1F1F1',
    }}
    round
    thickness="xsmall"
    values={[{ value, color: color || '#08ADCD' }]}
  />
);

export default ProgressBar;
