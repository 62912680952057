// @ts-check
import gql from 'graphql-tag';
import { handleError } from '../error';
import clientGraphql from './graphql';
import { getCookie, setCookie } from '../cookie';
import { STORAGE_KEYS } from '../localStorage';

export function locateStoresNearLocationByCampaignIdPostalCode(campaignId, postalCode) {
  return fetch(
    `api/campaigns/locateNearLocation/campaignLocations/${campaignId}/search?postalCode=${postalCode}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then((response) => {
      if (!response.ok) {
        handleError(response);
      }

      return response.json();
    })
    .then((data) => {
      if (data.error) {
        handleError(data.errorCause);
      }

      return data;
    })
    .catch(handleError);
}

export function locateStoresNearLocationByCampaignId(campaignId, distance = 50000) {
  return fetch(
    `api/campaigns/locateNearLocation/campaignLocations/${campaignId}?distance=${distance}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then((response) => {
      if (!response.ok) {
        handleError(response);
      }

      return response.json();
    })
    .then((data) => {
      if (data.error) {
        handleError(data.errorCause);
      }

      return data;
    })
    .catch(handleError);
}

/**
 * @param {number} locationId
 * @returns
 */
export const getCampaignLocation = (locationId) => gql`
  query GetCampaignLocation {
    campaign_locations(where: {id: {_eq: ${locationId}}}) {
      id,
      name,
      street1,
      city,
      state
    }
  }
`;

export const getCampaignTypeAndUserRebateWantsQuery = (samplekitId, userId) => gql`
  query GetCampaignTypeAndUserRebateWants {
    campaigns(where: { sample_kit:{_eq: ${samplekitId}},  status: {_in: ["opened", "shipped"]} }) {
      campaign_type
      properties
    }
    wants(where: {
      user: {_eq: ${userId}},
      campaign: {_is_null: false},
      campaignData: {
        campaign_type: {_eq: 200}
      }
    }) {
      id
    }
  }
`;

export const getCampaignTypeAndUserRebateWants = (samplekitId, userId) =>
  clientGraphql
    .query({
      query: getCampaignTypeAndUserRebateWantsQuery(samplekitId, userId),
    })
    .then(({ data }) => {
      const { campaigns, wants: rebateWantsOfCurrentUser } = data;

      return { campaigns, rebateWantsOfCurrentUser };
    });

export const getUserRebateRedemptionsQuery = (userId) => gql`
  query GetUserRebateRedemptions {
    transactions: rebate_transactions(where: {
      want: {
        user: {_eq: ${userId}}
      }
    }) {
      id
      status: reward_status
      want {
        campaignData {
          properties
        }
      }
    }
  }
`;

export const getUserRebateRedemptions = (userId) =>
  clientGraphql
    .query({
      query: getUserRebateRedemptionsQuery(userId),
    })
    .then(({ data }) => {
      const { transactions } = data;

      if (transactions && transactions.length > 0) {
        return transactions.map((transaction) => {
          const { want } = transaction;
          const { campaignData } = want;
          const { properties } = campaignData;
          const { offerDiscountPercentage } = properties || {};

          return { ...transaction, offerDiscountPercentage };
        });
      }

      return [];
    });

export const getWantFromCampaignUser = (campaignId, userId) => gql`
  query GetWantFromCampaignUser {
    wants(where: {user: {_eq: ${userId}}, campaign: {_eq: ${campaignId}}}) {
      id
    }
  }
`;

/**
 * @param {number} campaignId
 */
export const getCampaign = (campaignId) => gql`
  query GetCampaign {
    campaigns(where: {id: {_eq: ${campaignId}}}) {
      id,
      properties,
      type: campaign_type
    }
  }
`;

export const getCampaignTypeFromWant = (wantId) => gql`
  query GetCampaignFromWant {
    wants(where:{ id: {_eq: ${wantId} } }) {
      campaign: campaignData {
        id,
        type: campaign_type,
	    discountType: discount_type,
        completeByDate: complete_by_date,
        startDate: start_date,
        discountType: discount_type,
        samplekit {
          priceValue: price_value
          id,
          company
        }
        properties
      }
    }
  }
`;

/**
 * @param {number} productId
 * @param {string} postalCode
 * @returns
 */
export function LocateStoresNearLocationByProductId(productId, postalCode) {
  return fetch(
    `api/campaigns/locateNearLocation/campaignLocationsByProductId/${productId}?postalCode=${postalCode}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
    .then((response) => {
      if (!response.ok) {
        handleError(response);
      }

      return response.json();
    })
    .then((data) => {
      if (data.error) {
        handleError(data.errorCause);
      }

      return data;
    })
    .catch(handleError);
}

const SN_V2_HOST = process.env.API_V2_URL;

/**
 * @param {number} campaignId
 * @returns
 */
export function getCampaignRetailers(campaignId) {
  if (!campaignId) {
    return Promise.resolve('campaignId is required');
  }

  return fetch(`${SN_V2_HOST}campaign/${campaignId}/retailers`)
    .then((response) => {
      if (!response.ok) {
        handleError(response);
      }

      return response.json();
    })
    .catch(handleError);
}

/**
 * @param {number} campaignId
 * @returns
 */
export function activateCampaignApiV2(campaignId, product, locationID) {
  if (!campaignId) {
    return Promise.resolve('campaignId is required');
  }
  return fetch(`${SN_V2_HOST}campaign/${campaignId}/activate?locationID=${locationID}`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCookie('User_Token')}`,
    },
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Failed to activate Campaign');
    })
    .then((payload) => {
      if (payload && payload.action === 'redirect') {
        if (payload.nextNavigation) {
          setCookie(STORAGE_KEYS.nextNavigationPage, payload.nextNavigation, 30 / (24 * 60));
        }
        window.location.href = `/${payload.url}`;
      } else {
        // Fallback
        window.location.href = `/${product.slug}/want-boost`;
      }
    })
    .catch((e) => {
      handleError(e);
      throw e;
    });
}

/**
 * @param {number} wantId
 * @returns
 */
export function getCongratsPageData(wantId) {
  if (!wantId) {
    return Promise.resolve('wantID is required');
  }
  return fetch(`${SN_V2_HOST}campaign/congrats/${wantId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getCookie('User_Token')}`,
    },
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Failed to Accept Invite V2');
      }
      return response.json();
    })
    .then((payload) => {
      if (payload && payload.action === 'redirect' && payload.path) {
        if (payload.nextNavigation) {
          setCookie(STORAGE_KEYS.nextNavigationPage, payload.nextNavigation, 30 / (24 * 60));
        }
        window.location.href = `/${payload.path}`;
        return null;
      }
      return payload.data;
    });
}
