import React, { useState, useCallback } from 'react';
import { Modal } from 'socialnature-ui';

export function useModal(initState) {
  const [open, setOpen] = useState(initState || false);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const ModalComponent = ({ children, ...others }) => (
    <Modal open={open} onClose={handleClose} ariaHideApp={false} {...others}>
      {children}
    </Modal>
  );

  return { Modal: ModalComponent, open: handleOpen, close: handleClose, isOpen: open };
}
