/* eslint-disable no-nested-ternary */
import React from 'react';
import { InstructionStep } from '../../../Organisms/shared/InstructionStep';
import Intro from '../../../images/Instructions/Rebate/IntroDiscount.png';
import Activate from '../../../images/Instructions/Rebate/Activate.jpg';
import Email from '../../../images/Instructions/Rebate/Email.jpg';
import ShareReview from '../../../images/Instructions/Rebate/ShareReview.jpg';
import ShopAt from '../../../images/Instructions/Rebate/ShopAt.jpg';
import Submit from '../../../images/Instructions/Rebate/Submit.jpg';
import PhotoQuality from '../../../images/Instructions/Rebate/PhotoQuality.jpg';
import Capture from '../../../images/Instructions/Rebate/Capture.jpg';
import LongReceipts from '../../../images/Instructions/Rebate/LongReceipts.jpg';

// ETA - New Instructions
export const ACTIVATE_AND_SHOP = (
  <InstructionStep
    title="Activate & shop in-store"
    description="Answer a quick survey to activate your offer, then buy the product at an eligible store."
    image={ShopAt}
  />
);

export const SUBMIT_RECEIPT = (
  <InstructionStep
    title="Submit your receipt"
    description="Take a clear photo of your full receipt and  submit it before the offer expires."
    image={Submit}
  />
);

// END ETA - new Instructions

export const HOW_TO_GET_CASH_BACK = (
  <InstructionStep
    title="How to get cash back"
    description="Read on to find out how you can try natural products on Social Nature!"
    image={Intro}
  />
);

export const ACTIVATE_PRODUCT_OFFER = (
  <InstructionStep
    title="Activate product offer"
    description="Start by answering a quick survey that will help brands improve their product!"
    image={Activate}
  />
);

export const SHOP_AT_PARTICIPANT_STORE = (
  <InstructionStep
    title="Shop at an eligible store"
    description={
      <>
        Once you activate your offer, shop at{' '}
        <b>an eligible store and upload your in-store receipt</b> before it expires.
      </>
    }
    image={ShopAt}
  />
);

export const SUBMIT_PURCHASE = (
  <InstructionStep
    title="Submit receipt before offer expires"
    description="Capture the entire receipt, and make sure the receipt details are clearly visible. You can take multiple photos for long receipts."
    image={Submit}
  />
);

export const GET_CASH_BACK_VIA_EMAIL = (
  <InstructionStep
    title="Get cash back via email"
    description="Once your receipt is approved, you’ll get an email with instructions to cash out. Don’t forget to check spam and promotions too!"
    image={Email}
  />
);

export const GET_CASH_BACK_SENT = (
  <InstructionStep
    title="Get cash back via email"
    description="An email with a link to withdraw your funds was sent to your inbox. Don't forget to check spam and promotion folders too!"
    image={Email}
  />
);

export const SHARE_YOUR_PRODUCT_FEEDBACK = (
  <InstructionStep
    title="Share your product feedback"
    description="Inspire others to #trynatural by sharing your experience on Social Nature and remain eligible to try more products!"
    image={ShareReview}
  />
);

export const CAPTURE_INFORMATION = (
  <InstructionStep
    title="Capture all important information"
    description="Make sure to include store details, product details, date, time, and total."
    image={Capture}
  />
);

export const PHOTO_QUALITY = (
  <InstructionStep
    title="Photos must be bright, sharp, and clear"
    description="We won't be able to verify poor-quality photos."
    image={PhotoQuality}
  />
);

export const LONG_RECEIPTS = (
  <InstructionStep
    title="Take multiple photos for long receipts"
    description="Make sure you capture long receipts from top to bottom."
    image={LongReceipts}
  />
);
