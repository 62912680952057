import React from 'react';
import { TextArea } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { isNil } from 'ramda';
import { errorMessages } from '../../../utils/customQuestionTypes';

const CustomTextInput = (props) => {
  const { name, error, question } = props;
  const { required = true } = question;
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      placeholder="Please type here..."
      rows="4"
      as={TextArea}
      control={control}
      error={!isNil(error)}
      rules={{
        required: required && errorMessages.REQUIRED,
        maxLength: {
          value: 256,
          message: errorMessages.MAX_LENGTH,
        },
      }}
    />
  );
};

export default CustomTextInput;
