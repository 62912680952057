// Need to `facebookScriptsLoaded` first to use this component.
// See more detail: https://developers.facebook.com/docs/sharing/reference/share-dialog
import React, { useContext } from 'react';
import { ResponsiveContext } from 'grommet';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import Facebook from '../../../Atoms/Icons/sns/Facebook';
import { getDataset } from '../../../utils/socialShare';

const { FACEBOOK_APP_ID } = process.env;

const ShareButton = (props) => {
  const { name, companyName, shareUrl, variant } = props;
  const dataAttributes = getDataset(props);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <ShareButtonStyle
      isXsmall={isXsmall}
      data-sample-kit-name={name || ''}
      data-sample-kit-company-name={companyName || ''}
      icon={<Facebook />}
      label="Share"
      href={`https://www.facebook.com/sharer/sharer.php?app_id=${FACEBOOK_APP_ID}&u=${encodeURIComponent(
        shareUrl.replace(/\{0\}/g, 'facebook'),
      )}redirect_uri=${encodeURIComponent(window.location.href)}`}
      target="_blank"
      {...dataAttributes}
      variant={variant}
    />
  );
};

export default ShareButton;
