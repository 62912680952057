import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Box, Image, ResponsiveContext } from 'grommet';
import { GET_FEATURED_QUALITIES } from '../../../utils/apis/qualities';
import H from '../../../Atoms/Heading';
import Card from '../../../Atoms/Card/Card';
import CardAction from '../../../Atoms/Card/CardAction';
import CardBody from '../../../Atoms/Card/CardBody';
import CardContent from '../../../Atoms/Card/CardContent';
import Cards from '../../../Atoms/Card/Cards';
import LoadingSection from '../../../Molecules/LoadingSection';
import Container from '../../../Atoms/Container';
import PageAnchor from '../../../Templates/PageAnchor';

const QualitySection = ({ userCountry }) => {
  const size = React.useContext(ResponsiveContext);
  const width = {
    xsmall: '50%',
    small: '50%',
    medium: '25%',
    large: '25%',
  };

  const { loading, error, data } = useQuery(GET_FEATURED_QUALITIES);
  if (loading) return <LoadingSection />;
  if (error) return <></>;
  const { qualities } = data || {};

  if (!qualities) return <></>;

  return (
    <Box flex={false} pad={{ vertical: 'section' }} className="section-bg-gray">
      <PageAnchor id="qualities" />
      <Container>
        <H level={2}>Discover products by diet</H>
        <Cards direction="row" wrap>
          {qualities.map((quality) => {
            const { slug, name, image } = quality;
            let qualityLink = `/quality/${slug}`;
            if (userCountry) {
              qualityLink = `/quality/${slug}?country=${userCountry}`;
            }

            return (
              <Card key={slug} width={width[size]}>
                <CardAction href={qualityLink}>
                  <CardBody>
                    <CardContent>
                      <Box height="quality" width="quality" alignSelf="center" margin="xsmall">
                        <Image fit="cover" src={image} />
                      </Box>
                      <H level={3} textAlign="center" margin="none">
                        {name}
                      </H>
                    </CardContent>
                  </CardBody>
                </CardAction>
              </Card>
            );
          })}
        </Cards>
      </Container>
    </Box>
  );
};

export default QualitySection;
