// @ts-check
import { CTATypes } from '../CTATypes';
import { getWantCallToAction } from './getWantCallToAction';
import { getBannerCallToAction } from './getBannerCallToAction';
import { TrackingEventType } from '../../tracking';

export const LABELS = {
  WANT: 'Apply To Try',
  BOOST: 'Boost It',
  WHERE_TO_BUY: 'Where To Buy',
  EXPLORE_MORE_OFFERS: 'Explore more offers',
  SIGN_UP: 'Sign Up',
  SIGN_UP_TO_ACTIVATE: 'Sign Up to Activate',
  SIGN_UP_FOR_UPDATES: 'Sign Up For Updates',
  APPLIED: 'Applied',
  ACTIVATE_OFFER: 'Activate Offer',
  OFFER_ACTIVATED: 'Offer Activated',
  REACTIVATE_OFFER: 'Reactivate Offer',
  ADD_A_RECEIPT: 'add a receipt',
  SUBMIT_RECEIPT: 'submit receipt',
  OFFER_REDEEMED: 'offer redeemed',
};

/**
 * @param {import("../types").CTAType} ctaType
 * @param {boolean} isLoggedIn
 * @param {?string} postalCode
 * @param {number} productId
 * @param {string} productSlug
 * @typedef {{
 * bannerMessage: string | null,
 * openBannerPopup: () => void | null,
 * bannerUrl: string | null,
 * whereToBuy: string,
 * openGeoModal: (postalCode: string) => void,
 * showStoreLocatorFlow: boolean,
 * openStoreLocationModal: (postalCode: string) => void,
 * userWant?: number | null,
 * }} CallToActionOptions
 * @param {?CallToActionOptions} options
 * @param {?boolean} showStoreLocatorFlow
 * @typedef {{
 * href: string | null,
 * onClick?:Function,
 * label: string,
 * event?: string,
 * parameter?: Object,
 * }} CallToAction
 * @returns {CallToAction}
 * 
 */

export function getCallToAction(
  ctaType,
  isLoggedIn,
  postalCode,
  productId,
  productSlug,
  productName,
  options,
  showStoreLocatorFlow,
  wantId,
  useNewActivationFlow = false,
  guestSessionID = false
) {

  function getLabel(ctype) {
    if (ctype === CTATypes.SIGN_UP)   
      return LABELS.SIGN_UP;
    if (ctype === CTATypes.SIGNUP_TO_ACTIVATE)
      return LABELS.SIGN_UP_TO_ACTIVATE;
    if (ctype === CTATypes.SIGNUP_FOR_UPDATES)
      return LABELS.SIGN_UP_FOR_UPDATES;
    if(ctaType === CTATypes.ADD_A_RECEIPT)
      return LABELS.SUBMIT_RECEIPT;
    if(ctaType === CTATypes.WHERE_TO_BUY)
      return LABELS.EXPLORE_MORE_OFFERS;
    return ctype;
  }

  if (ctaType === CTATypes.SIGN_UP || ctaType === CTATypes.SIGNUP_TO_ACTIVATE || ctaType === CTATypes.SIGNUP_FOR_UPDATES) {
    let signupUrl = `/sign-in/signup?snsource=apply_to_try`;

    if (ctaType === CTATypes.SIGNUP_TO_ACTIVATE) {
      signupUrl = `/sign-in/signup?snsource=activate_offer`;
    }
    if (ctaType === CTATypes.SIGNUP_FOR_UPDATES) {
      signupUrl = `/sign-in/signup?snsource=for_updates`;
    }

    if (postalCode) {
      return {
        label: getLabel(ctaType),
        href: `${signupUrl}&isFarAndLoggedOut=true`,
      };
    }

    return {
      label: getLabel(ctaType),
      href: signupUrl,
    };
  }
  if (ctaType === CTATypes.WANT) {
    return {
      label: LABELS.WANT,
      event: showStoreLocatorFlow
        ? TrackingEventType.STORE_LOCATOR_FLOW.PRODUCT_PAGE_APPLY_TO_TRY_CLICKED
        : TrackingEventType.APPLY_TO_TRY_CLICKED,
      ...getWantCallToAction(isLoggedIn, postalCode, productId, productSlug, options, useNewActivationFlow, guestSessionID),
    };
  }
  if (ctaType === CTATypes.BOOST) {
    const boostUrl = isLoggedIn
      ? `/${productSlug}/want-boost`
      : `/sign-in?ReturnUrl=/${productSlug}/want-boost`;
    return {
      label: LABELS.BOOST,
      href: boostUrl,
    };
  }
  if (ctaType === CTATypes.BANNER) {
    if (options) {
      if (options.bannerMessage && (options.bannerUrl || options.openBannerPopup)) {
        return {
          label: options.bannerMessage,
          ...getBannerCallToAction(options.openBannerPopup, options.bannerUrl),
        };
      }
      return {
        label: LABELS.EXPLORE_MORE_OFFERS,
        href: '/products/',
      };
    }
  }
  if (ctaType === CTATypes.WHERE_TO_BUY) {
      return {
        label: LABELS.EXPLORE_MORE_OFFERS,
        href: '/offers/',
      };
  }

  if (ctaType === CTATypes.ACTIVATE_OFFER) {
    return {
      label: LABELS.ACTIVATE_OFFER,
      event: TrackingEventType.REBATE_FLOW.ACTIVATE_OFFER,
      ...getWantCallToAction(isLoggedIn, postalCode, productId, productSlug, options),
    };
  }

  if (ctaType === CTATypes.OFFER_ACTIVATED) {
    return {
      label: LABELS.OFFER_ACTIVATED,
      href: null,
    };
  }

  if (ctaType === CTATypes.ADD_A_RECEIPT) {
    return {
      label: LABELS.SUBMIT_RECEIPT,
      event: TrackingEventType.REBATE_FLOW.ADD_RECEIPT_LINK,
      parameter: {
        samplekit: productName,
        source: TrackingEventType.REBATE_FLOW.SOURCES.PRODUCT_PAGE,
      },
      href: `/uploadreceipt/${wantId}`,
    };
  }

  if (ctaType === CTATypes.OFFER_REDEEMED) {
    return {
      label: LABELS.OFFER_REDEEMED,
      href: '',
    };
  }

  if (ctaType === CTATypes.REACTIVATE_OFFER) {
    return {
      label: LABELS.REACTIVATE_OFFER,
      event: TrackingEventType.REBATE_FLOW.REACTIVATE_OFFER,
      ...getWantCallToAction(isLoggedIn, postalCode, productId, productSlug, {
        reactivateOffer: true,
        wantId,
      }),
    };
  }

  if (ctaType === CTATypes.APPLIED) {
    return {
      label: LABELS.APPLIED,
      href: '',
    };
  }

  return {
    label: null,
    href: null,
  };
}
