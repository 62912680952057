import React from 'react';
import { RadioButton } from 'grommet';
import styled from 'styled-components';
import List from '../../Atoms/List/List';
import { LocationListFooterButtons } from './LocationListFooterButtons';
import { LocationListItem } from './LocationListItem';
import { useLocationList } from './useLocationList';
import { Text } from 'socialnature-ui';

const Wrapper = styled.div`
  input[type='radio'] {
    margin: 0;
  }
  & > label > div:first-child {
    padding-top: 2px;
  }
  label {
    align-items: flex-start;
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }
`;
const WrapperLabel = styled.div`
  display: flex;
  flex-direction: column;
`;

export function LocationListSelectable({ locations, selectedIndex, setSelectedIndex, tprRetailersList }) {
  const {
    current: currentLocations,
    prevPage,
    nextPage,
    hasPrevPage,
    hasNextPage,
  } = useLocationList(locations);

  const handleContinue = (e) => {
    const storeLocationPopupCTAButton = document.querySelector('#storeLocationPopupCTAButton');
    if (storeLocationPopupCTAButton)
      storeLocationPopupCTAButton.scrollIntoView({ behavior: 'smooth' });
    return setSelectedIndex(e.target.value);
  };

  if (!Array.isArray(locations) || !locations.length) return null;

  return (
    <>
      <List
        data={currentLocations}
        renderItem={({ index, name, street1, street2, city, state }) => (
          <Wrapper data-cy="store-location">
            <RadioButton
              data-testid="LocationListRadioButton"
              label={
                <WrapperLabel>
                  <LocationListItem
                    index={index}
                    name={name}
                    street1={street1}
                    street2={street2}
                    city={city}
                    state={state}
                  />
                </WrapperLabel>
              }
              name="radio"
              value={index}
              checked={+selectedIndex === index}
              onChange={(event) => handleContinue(event)}
            />
            {(tprRetailersList || []).includes(name) && <Text
              style={{
                padding: '4px 15px',
                backgroundColor: '#F1F1F1',
                borderRadius: '4px',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                width: 'fit-content'
              }}
              color="#333"
              margin={{ bottom: 'unset !important', top: '5px' }}
              size="12px"
            >
              Active In-store discount
            </Text>}
          </Wrapper>
        )}
        styles={{ marginBottom: '8px' }}
      />
      <LocationListFooterButtons
        prevPage={prevPage}
        nextPage={nextPage}
        hasPrevPage={hasPrevPage}
        hasNextPage={hasNextPage}
      />
    </>
  );
}
