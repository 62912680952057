import React from 'react';

const WhiteLogo = ({ width, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 172 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M141.209 24.5545L138.766 26.2226L151.415 24.1186C153.23 24.8532 155.174 25.2287 157.136 25.2236C166.391 25.2175 171.076 20.9732 171.076 12.6027C171.07 5.64897 164.823 0 157.121 0C148.26 0.00605463 143.183 4.60454 143.183 12.6209C143.191 14.9991 143.928 17.3199 145.297 19.281C144.151 21.6695 143.119 23.2407 141.209 24.5545ZM160.186 7.44418C160.312 5.37047 159 3.60555 159 3.60555C159 3.60555 157.487 5.21608 157.358 7.28676C157.229 9.35744 158.544 11.1193 158.544 11.1193C158.544 11.1193 160.06 9.51789 160.186 7.44418ZM152.047 19.3006C155.025 20.8147 158.591 20.7437 161.511 19.0963C158.817 17.6273 155.602 17.4858 152.81 18.6651C156.082 15.9056 159.632 12.42 162.139 8.60972L162.709 7.67428L163.39 6.08797L161.603 8.43413C159.804 10.8731 155.268 16.6022 149.866 20.2538C150.866 18.6199 151.025 16.604 150.269 14.8248C149.041 16.4708 148.748 18.6113 149.477 20.5119C147.96 21.5016 146.378 22.3197 144.774 22.85L144.771 22.8465L144.767 22.8522L144.764 22.8532L144.765 22.8548L144.272 23.5458L143.557 24.3904L144.675 24.2028L145.629 24.0437L145.633 24.049L145.645 24.041L145.655 24.0393L145.652 24.0358C147.053 23.1056 149.423 21.4547 152.047 19.3006ZM154.807 15.6088L154.801 15.6011C155.994 12.4081 155.395 8.83393 153.224 6.1848C152.03 9.37819 152.629 12.9523 154.801 15.6011L154.798 15.6088H154.807ZM164.561 12.8631C164.561 12.8631 163.516 14.9549 161.462 15.8692C159.407 16.7834 157.121 16.1719 157.121 16.1719C157.121 16.1719 158.162 14.08 160.217 13.1658C162.272 12.2515 164.561 12.8631 164.561 12.8631Z"
      fill="white"
    />
    <path
      d="M1.61432 30.2458H1.56503C1.56503 30.2458 1.6174 31.4355 1.6174 31.5082C1.6174 31.6754 1.47947 31.8109 1.30933 31.8109H0.348135C0.259746 31.8223 0.170668 31.7955 0.103908 31.7375C0.037149 31.6794 -0.000745796 31.5958 1.11264e-05 31.5082L0.0246571 27.8754C0.0246571 27.7082 0.162587 27.5727 0.332732 27.5727H1.42024C1.59038 27.5727 1.72831 27.7082 1.72831 27.8754V28.3598C1.72831 28.3598 2.44612 30.3033 5.23728 30.3003C6.99331 30.3003 7.90521 29.6676 7.90521 28.5505C7.90521 26.0257 0.443639 27.391 0.440558 23.1679C0.440558 21.0064 2.24279 19.7168 4.59032 19.7168C6.41721 19.7168 7.48006 20.3465 8.09929 21.1215H8.14859C8.14859 21.1215 8.07157 20.2496 8.07157 20.2133C8.07081 20.1257 8.1087 20.0421 8.17546 19.984C8.24222 19.9259 8.3313 19.8992 8.41969 19.9105H9.30695C9.39129 19.9064 9.47366 19.9364 9.53482 19.9937C9.59597 20.0509 9.63053 20.1303 9.63042 20.2133V23.5857C9.63107 23.6727 9.59358 23.7558 9.52754 23.8137C9.4615 23.8717 9.37328 23.8989 9.28538 23.8884H8.22252C8.13808 23.8885 8.05729 23.8546 7.99905 23.7945C7.94081 23.7344 7.91023 23.6534 7.91445 23.5706V23.2315C7.91445 22.6018 7.09805 21.3879 4.84911 21.3879C3.61681 21.3879 2.47693 21.9479 2.48001 23.1134C2.48001 25.8078 10.0648 24.397 10.0679 28.5626C10.0679 30.3336 8.80787 31.9865 5.52379 31.9895C2.95137 31.9986 1.83922 30.6636 1.61432 30.2458Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9906 19.7078C13.5679 19.7078 10.8291 21.9692 10.8291 25.8048C10.8322 29.6162 13.4755 31.9956 17.006 31.9926C20.5365 31.9896 23.1521 29.5829 23.1521 25.7957C23.1521 22.0085 20.4133 19.7078 16.9906 19.7078ZM16.9783 21.4061C19.1286 21.4031 21.0603 23.056 21.0603 25.8229C21.0603 28.5657 19.2149 30.2882 16.9844 30.2913C14.754 30.2943 12.9055 28.5929 12.9055 25.826C12.9055 23.059 14.8279 21.4091 16.9783 21.4061Z"
      fill="white"
    />
    <path
      d="M35.5641 28.9956C35.7366 29.1409 35.7612 29.2983 35.6626 29.4315C35.3546 29.9644 33.6386 31.9805 30.3761 31.9836C27.0427 31.9836 24.3224 29.6798 24.3193 25.7957C24.3193 21.8602 27.1813 19.7018 30.1728 19.6987C32.4186 19.6987 33.5831 20.7643 34.1007 21.4939H34.1254V20.1589C34.1127 20.0754 34.1411 19.991 34.2018 19.9313C34.2625 19.8716 34.3485 19.8437 34.4334 19.8562H35.3731C35.5702 19.8562 35.6811 19.9772 35.6811 20.1589V24.2881C35.6983 24.3725 35.6714 24.4597 35.6095 24.5205C35.5476 24.5813 35.4589 24.6077 35.3731 24.5909H34.2609C34.0637 24.5909 33.9528 24.494 33.9528 24.2881V23.8885C33.9528 22.8441 32.6189 21.4122 30.4439 21.4122C28.5954 21.4122 26.3927 22.9258 26.3958 25.8351C26.3988 28.7443 28.4968 30.3004 30.524 30.3004C32.6497 30.3004 34.1069 28.9623 34.4519 28.4023C34.4924 28.3248 34.5678 28.2708 34.6552 28.2565C34.7425 28.2422 34.8317 28.2693 34.8955 28.3296L35.5641 28.9956Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.7445 17.6281C39.3635 17.6319 38.9965 17.4868 38.7244 17.2245C38.4506 16.9606 38.2957 16.6001 38.2941 16.2233C38.2892 15.8401 38.4398 15.4707 38.7127 15.1969C38.9855 14.9231 39.3582 14.7674 39.7482 14.7642C40.5582 14.7757 41.2085 15.4243 41.2085 16.2203C41.1967 17.0055 40.5425 17.6336 39.7445 17.6281ZM37.1519 31.7375C37.2138 31.7984 37.3025 31.8248 37.3883 31.8079L42.0865 31.7958C42.1709 31.8057 42.2552 31.777 42.3153 31.7179C42.3754 31.6589 42.4046 31.576 42.3946 31.4931V30.4487C42.4117 30.3643 42.3848 30.2771 42.3229 30.2163C42.261 30.1555 42.1723 30.1291 42.0865 30.1459H40.7772V20.1195C40.7772 19.9136 40.6663 19.8167 40.4691 19.8167H37.3883C37.3025 19.7999 37.2138 19.8263 37.1519 19.8871C37.09 19.9479 37.0631 20.0351 37.0803 20.1195V21.1639C37.0631 21.2482 37.09 21.3354 37.1519 21.3962C37.2138 21.4571 37.3025 21.4835 37.3883 21.4666H38.7716V30.158H37.3883C37.3025 30.1412 37.2138 30.1676 37.1519 30.2284C37.09 30.2893 37.0631 30.3764 37.0803 30.4608V31.5052C37.0631 31.5895 37.09 31.6767 37.1519 31.7375Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M52.8834 30.785C52.8834 31.5359 53.2285 31.8023 53.9465 31.8023L56.088 31.8145C56.1739 31.8313 56.2626 31.8049 56.3245 31.7441C56.3864 31.6832 56.4133 31.596 56.3961 31.5117V30.467C56.4133 30.3827 56.3864 30.2955 56.3245 30.2346C56.2626 30.1738 56.1739 30.1474 56.088 30.1642H54.7137V24.5566C54.7137 21.023 52.7109 19.7119 49.5217 19.7119C46.1876 19.7119 44.8288 21.1199 44.7055 21.2652C44.6358 21.3019 44.5886 21.3694 44.5788 21.4465C44.569 21.5235 44.5978 21.6005 44.6562 21.6528L45.3464 22.5036C45.4389 22.6093 45.5928 22.6393 45.7193 22.5763C45.7353 22.5763 45.7754 22.5456 45.8425 22.494C46.1887 22.2284 47.2544 21.4106 49.4508 21.4106C51.352 21.4106 52.6615 22.1887 52.6615 24.496V24.7988H50.5847C46.6806 24.7988 43.9629 25.8676 43.9629 28.6352C43.9629 30.7486 45.7193 32.0113 48.0149 32.0113C49.9417 32.0542 51.758 31.1299 52.8341 29.5587H52.8834V30.785ZM50.6834 26.4126H52.6616V28.0628C52.07 28.841 50.6834 30.249 48.585 30.249C47.0535 30.249 46.0891 29.4496 46.0891 28.3566C46.0891 26.7518 48.1659 26.4126 50.6834 26.4126Z"
      fill="white"
    />
    <path
      d="M57.9771 31.7959C57.8927 31.8058 57.8083 31.7771 57.7482 31.718C57.6882 31.659 57.6589 31.5761 57.669 31.4932V30.4488C57.669 30.255 57.7922 30.146 57.9771 30.146H59.3603V15.9661H57.9678C57.7706 15.9661 57.6597 15.8692 57.6597 15.6634V14.6189C57.6509 14.5363 57.6805 14.4541 57.7403 14.3954C57.8001 14.3366 57.8837 14.3075 57.9678 14.3162H60.7097C61.2057 14.3162 61.3782 14.51 61.3782 14.9217V30.1218H62.6875C62.8847 30.1218 62.9956 30.2187 62.9956 30.4245V31.469C63.0044 31.5516 62.9748 31.6338 62.915 31.6925C62.8552 31.7513 62.7716 31.7804 62.6875 31.7717L57.9771 31.7959Z"
      fill="white"
    />
    <path
      d="M75.6174 31.7867C74.878 31.7867 74.5329 31.5203 74.5299 30.7665V24.3819C74.5299 22.5655 73.6642 21.3728 71.8342 21.3728C70.3992 21.345 69.0944 22.1869 68.5501 23.4919V30.1671H69.8594C69.9453 30.1503 70.034 30.1767 70.0959 30.2375C70.1578 30.2983 70.1847 30.3855 70.1675 30.4699V31.5143C70.1847 31.5986 70.1578 31.6858 70.0959 31.7466C70.034 31.8075 69.9453 31.8339 69.8594 31.817H65.1151C65.0301 31.8295 64.9442 31.8016 64.8835 31.7419C64.8227 31.6822 64.7944 31.5978 64.807 31.5143V30.4578C64.7915 30.3737 64.8189 30.2875 64.8803 30.2271C64.9418 30.1667 65.0295 30.1397 65.1151 30.155H66.4983V21.4636H65.1151C65.0301 21.476 64.9442 21.4482 64.8835 21.3885C64.8227 21.3288 64.7944 21.2444 64.807 21.1609V20.1165C64.7915 20.0324 64.8189 19.9462 64.8803 19.8858C64.9418 19.8254 65.0295 19.7984 65.1151 19.8137H68.0295C68.1153 19.7969 68.204 19.8233 68.2659 19.8841C68.3278 19.9449 68.3547 20.0321 68.3375 20.1165L68.316 21.7663H68.3899C68.698 21.282 69.6962 19.6472 72.3641 19.6472C75.032 19.6472 76.5909 21.3213 76.594 23.8703V30.1338H77.9772C78.1744 30.1338 78.2853 30.2307 78.2853 30.4366V31.481C78.2941 31.5636 78.2645 31.6458 78.2047 31.7045C78.1449 31.7633 78.0613 31.7924 77.9772 31.7837L75.6174 31.7867Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M88.3785 30.785C88.3785 31.5359 88.7236 31.8024 89.4416 31.8024L91.5831 31.8145C91.669 31.8313 91.7577 31.8049 91.8196 31.7441C91.8815 31.6832 91.9084 31.596 91.8913 31.5117V30.467C91.9084 30.3827 91.8815 30.2955 91.8196 30.2347C91.7577 30.1738 91.669 30.1474 91.5831 30.1643H90.2089V24.5566C90.2089 21.023 88.206 19.7119 85.0168 19.7119C81.6827 19.7119 80.3239 21.1199 80.2006 21.2652C80.1309 21.3019 80.0837 21.3694 80.0739 21.4465C80.0641 21.5235 80.093 21.6005 80.1513 21.6528L80.8415 22.5036C80.934 22.6093 81.0879 22.6393 81.2144 22.5763C81.2304 22.5763 81.2705 22.5456 81.3376 22.494C81.6838 22.2284 82.7496 21.4106 84.9459 21.4106C86.8471 21.4106 88.1567 22.1887 88.1567 24.496V24.7988H86.0798C82.1758 24.7988 79.458 25.8677 79.458 28.6352C79.458 30.7486 81.2144 32.0113 83.51 32.0113C85.4368 32.0543 87.2532 31.1299 88.3292 29.5587H88.3785V30.785ZM86.1785 26.4126H88.1567V28.0628C87.5651 28.841 86.1785 30.249 84.0801 30.249C82.5486 30.249 81.5842 29.4496 81.5842 28.3566C81.5842 26.7518 83.661 26.4126 86.1785 26.4126Z"
      fill="white"
    />
    <path
      d="M99.955 30.5485C100.103 30.6938 100.054 30.8512 99.9057 31.0117C99.2296 31.6323 98.3354 31.9718 97.4103 31.9592C94.9457 31.9592 93.4577 30.5788 93.4546 27.836V21.4666H92.0467C91.9561 21.4775 91.8652 21.4483 91.7985 21.3869C91.7319 21.3256 91.6964 21.2383 91.7017 21.1487V20.1557C91.7009 20.0681 91.7388 19.9845 91.8056 19.9265C91.8723 19.8684 91.9614 19.8416 92.0498 19.853H93.4577V17.4009C93.4471 17.3145 93.4748 17.2278 93.5337 17.1629C93.5927 17.098 93.6772 17.0612 93.7658 17.0618H95.1521C95.2447 17.0601 95.3339 17.0957 95.3991 17.1604C95.4643 17.225 95.4997 17.313 95.4972 17.4039V19.856H98.6118C98.6997 19.8456 98.7879 19.8728 98.854 19.9308C98.92 19.9887 98.9575 20.0718 98.9568 20.1588V21.1548C98.9575 21.2418 98.92 21.3248 98.854 21.3828C98.7879 21.4407 98.6997 21.4679 98.6118 21.4575H95.5002V27.7694C95.5002 29.3951 96.2673 30.2458 97.6013 30.2458C98.0846 30.2508 98.5521 30.0768 98.9106 29.7584C98.9668 29.6925 99.0513 29.6565 99.1386 29.6612C99.226 29.666 99.3059 29.711 99.3543 29.7826L99.955 30.5485Z"
      fill="white"
    />
    <path
      d="M111.591 31.7596C110.876 31.7596 110.531 31.4932 110.528 30.7424V29.8342H110.454C110.146 30.3913 109.271 31.9231 106.603 31.9231C103.936 31.9231 102.29 30.252 102.29 27.703V21.4667H100.882C100.797 21.4836 100.708 21.4572 100.646 21.3964C100.584 21.3355 100.557 21.2484 100.574 21.164V20.1196C100.563 20.0364 100.592 19.9528 100.652 19.8934C100.713 19.834 100.798 19.8057 100.882 19.8169H103.255C103.969 19.8169 104.314 20.0833 104.318 20.8371V27.2186C104.318 29.035 105.208 30.2278 107.035 30.2278C108.471 30.256 109.776 29.4142 110.322 28.1087V21.4486H108.988C108.902 21.4654 108.813 21.439 108.751 21.3782C108.69 21.3174 108.663 21.2302 108.68 21.1458V20.1014C108.667 20.0179 108.696 19.9335 108.756 19.8738C108.817 19.8141 108.903 19.7863 108.988 19.7987H112.05C112.136 19.7818 112.225 19.8083 112.287 19.8691C112.349 19.9299 112.375 20.0171 112.358 20.1014V30.1279H113.742C113.826 30.118 113.91 30.1467 113.97 30.2058C114.03 30.2648 114.06 30.3477 114.05 30.4306V31.472C114.058 31.5547 114.029 31.6368 113.969 31.6956C113.909 31.7543 113.826 31.7834 113.742 31.7747L111.591 31.7596Z"
      fill="white"
    />
    <path
      d="M115.494 31.7594C115.409 31.7719 115.323 31.744 115.263 31.6843C115.202 31.6246 115.174 31.5402 115.186 31.4567V30.4274C115.169 30.3431 115.196 30.2559 115.258 30.1951C115.32 30.1343 115.408 30.1079 115.494 30.1247H116.871V21.4454H115.485C115.4 21.4578 115.314 21.43 115.253 21.3703C115.193 21.3106 115.164 21.2261 115.177 21.1427V20.0982C115.16 20.0139 115.187 19.9267 115.249 19.8659C115.31 19.8051 115.399 19.7787 115.485 19.7955H118.412C118.498 19.7787 118.586 19.8051 118.648 19.8659C118.71 19.9267 118.737 20.0139 118.72 20.0982V23.1256H118.794C118.794 23.1256 119.635 19.7501 124.105 19.7471C124.275 19.7471 124.413 19.8826 124.413 20.0498V21.3364C124.417 21.422 124.385 21.5054 124.323 21.566C124.262 21.6266 124.177 21.6587 124.09 21.6543H123.572C122.361 21.6117 121.185 22.0523 120.31 22.8758C119.436 23.6993 118.938 24.8355 118.929 26.0257V30.1065H120.239C120.324 30.0897 120.413 30.1161 120.475 30.1769C120.537 30.2377 120.564 30.3249 120.547 30.4093V31.4567C120.562 31.5408 120.535 31.627 120.473 31.6874C120.412 31.7478 120.324 31.7747 120.239 31.7594H115.494Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M131.468 30.2125C129.515 30.2458 127.491 29.0742 127.287 26.3103L136.212 26.1378C136.297 26.1379 136.379 26.1035 136.437 26.0428C136.495 25.982 136.525 25.9002 136.52 25.8169C136.431 21.8602 134.518 19.5655 130.886 19.6351C127.947 19.6926 125.125 21.9056 125.202 25.8411C125.279 29.7251 128.042 31.9774 131.379 31.9139C134.638 31.8503 136.28 29.8038 136.588 29.265C136.685 29.1215 136.648 28.9282 136.505 28.829L135.8 28.1872C135.739 28.1283 135.653 28.1025 135.569 28.1183C135.485 28.134 135.415 28.1892 135.381 28.266C135.048 28.8321 133.624 30.1701 131.468 30.2125ZM130.994 21.3334C133.169 21.291 134.358 22.7018 134.493 24.5454L127.297 24.6816C127.583 22.4929 129.167 21.3697 130.994 21.3334Z"
      fill="white"
    />
  </svg>
);

export default WhiteLogo;
