import React from 'react';
import { Box } from 'grommet';

const CardFooter = ({ children }) => (
  <Box
    pad={{
      top: 'none',
      bottom: 'small',
      left: 'small',
      right: 'small',
    }}
  >
    {children}
  </Box>
);

export default CardFooter;
