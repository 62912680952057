import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Avatar,
  Box,
  CheckBox,
  Error,
  PrimaryButton,
  InputLabel,
  Text,
  TextInput,
  TextLink,
  themes as uiThemes,
} from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import { errorMessages } from '../../../utils/customQuestionTypes';
import UploadAvatar from './UploadAvatar';
import ErrorPage from '../../ErrorPage';
import { CountryISO2FromPostalCode } from '../../../utils/formatCountry';
import { handleError } from '../../../utils/error';
import { getAvatarUrl } from '../../../utils/reviews';
import { getCookie } from '../../../utils/cookie';

const MAX_LENGTH = 256;
// TODO: Remove the component when don't use site.css. It's only for overriding site.css
const InputWrapper = styled(Box)`
  input[type='text'],
  input[type='email'],
  input[type='password'] {
    ${(props) => (props.error ? `border-color: ${uiThemes.global.colors.red};` : '')}
    border-radius: 4px;
    height: auto;
    margin: 0;
    &:focus {
      border-color: ${uiThemes.global.colors.blue};
      background: inherit;
    }
  }
  input[type='checkbox'] {
    margin: 0;
  }
`;

const PersonalInformationForm = (props) => {
  const {
    avatar: avatarProp,
    email,
    firstName,
    lastName,
    postalCode,
    userId,
    newPassword,
    confirmPassword,
    emailConsent,
    setUserInfo,
    setEdit,
    submitCallback,
  } = props;
  const { handleSubmit, control, errors, register, watch, reset, setError } = useFormContext();
  const [editPassword, setEditPassWord] = useState(false);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [avatar, setAvatar] = useState(avatarProp);
  const isCanada = CountryISO2FromPostalCode(postalCode) === 'CA';
  const isCookieConsent = getCookie('cookies-marketing') === 'true';


  const onChangeAvatar = (imageUrl) => {
    setAvatar(imageUrl);
    setUserInfo((state) => ({
      ...state,
      avatar: imageUrl,
    }));
  };

  const onSubmit = (data) => {
    const { ConfirmPassword, NewPassword } = data;
    const formData = { ...data };
    if (!ConfirmPassword && !NewPassword) {
      formData.ConfirmPassword = undefined;
      formData.NewPassword = undefined;
    }

    fetch('/account/profile', {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then(() => {
        reset();
        if (setUserInfo) {
          setUserInfo((state) => ({
            ...state,
            email: data.Email,
            firstName: data.FirstName,
            lastName: data.LastName,
            postalCode: data.PostalCode,
          }));
        }
        if (setEdit) {
          setEdit(false);
        }
        if (submitCallback) {
          submitCallback();
        }
      })
      .catch((error) => {
        if (error.status === 400) {
          error.json().then((err) => {
            Object.entries(err).forEach(([, v]) => {
              const { key, errors: serverError } = v;
              setError(key, {
                message: serverError[0] ? serverError[0].errorMessage : errorMessages.DEFAULT_ERROR,
              });
            });
          });
          return;
        }
        handleError(error);
        setShowErrorPage(true);
      });
  };

  if (showErrorPage) return <ErrorPage />;

  return (
    <>
      <Box direction="row" gap="medium">
        <Avatar size="64px" alt={firstName} src={getAvatarUrl(avatar, isCookieConsent)} fallback="/images/default-user.png" />
        <Box justify="center">
          <UploadAvatar setAvatar={onChangeAvatar} />
        </Box>
      </Box>
      <Box margin={{ top: 'medium' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="UserId" value={userId} ref={register} />
          <input type="hidden" name="PostalCode" value={postalCode} ref={register} />
          <InputLabel required>First Name</InputLabel>
          <InputWrapper margin={{ bottom: '16px' }} error={errors && errors.FirstName}>
            <Controller
              type="text"
              name="FirstName"
              defaultValue={firstName || ''}
              as={TextInput}
              control={control}
              error={errors && errors.FirstName}
              errorText={errors && errors.FirstName && errors.FirstName.message}
              maxLength={MAX_LENGTH}
              rules={{
                required: errorMessages.REQUIRED,
                maxLength: {
                  value: MAX_LENGTH,
                  message: errorMessages.MAX_LENGTH,
                },
              }}
            />
          </InputWrapper>
          <InputLabel required>Last Name</InputLabel>
          <InputWrapper margin={{ bottom: '16px' }} error={errors && errors.LastName}>
            <Controller
              type="text"
              name="LastName"
              defaultValue={lastName || ''}
              as={TextInput}
              control={control}
              error={errors && errors.LastName}
              errorText={errors && errors.LastName && errors.LastName.message}
              maxLength={MAX_LENGTH}
              rules={{
                required: errorMessages.REQUIRED,
                maxLength: {
                  value: MAX_LENGTH,
                  message: errorMessages.MAX_LENGTH,
                },
              }}
            />
          </InputWrapper>
          <InputLabel required>Email</InputLabel>
          <InputWrapper margin={{ bottom: '16px' }} error={errors && errors.Email}>
            <Controller
              type="email"
              name="Email"
              defaultValue={email || ''}
              as={TextInput}
              control={control}
              error={errors && errors.Email}
              errorText={errors && errors.Email && errors.Email.message}
              maxLength={MAX_LENGTH}
              rules={{
                required: errorMessages.REQUIRED,
                maxLength: {
                  value: MAX_LENGTH,
                  message: errorMessages.MAX_LENGTH,
                },
                validate: (value) => (isEmail(value) ? true : 'Invalid Email'),
              }}
            />
          </InputWrapper>
          {editPassword ? (
            <>
              <InputLabel>Password</InputLabel>
              <InputWrapper margin={{ bottom: '16px' }} error={errors && errors.NewPassword}>
                <Controller
                  type="password"
                  name="NewPassword"
                  placeholder="New Password"
                  defaultValue={newPassword || ''}
                  as={TextInput}
                  control={control}
                  error={errors && errors.NewPassword}
                  errorText={errors && errors.NewPassword && errors.NewPassword.message}
                  rules={{
                    maxLength: {
                      value: 30,
                      message: errorMessages.MAX_LENGTH,
                    },
                    minLength: {
                      value: 6,
                      message: 'Your password must be at least 6 characters long.',
                    },
                  }}
                />
              </InputWrapper>
              <InputLabel>Confirm Password</InputLabel>
              <InputWrapper margin={{ bottom: '16px' }} error={errors && errors.ConfirmPassword}>
                <Controller
                  type="password"
                  name="ConfirmPassword"
                  placeholder="Confirm New Password"
                  defaultValue={confirmPassword || ''}
                  as={TextInput}
                  control={control}
                  error={errors && errors.ConfirmPassword}
                  errorText="Please confirm your password."
                  rules={{
                    validate: (value) => value === watch('NewPassword'),
                  }}
                />
              </InputWrapper>
            </>
          ) : (
            <Box margin={{ bottom: '16px' }}>
              <InputLabel>Password</InputLabel>
              <TextLink
                label="Change Password"
                onClick={() => setEditPassWord(true)}
                fontWeight="bold"
              />
            </Box>
          )}
          {isCanada && !emailConsent && (
            <InputWrapper>
              {errors && errors.EmailConsent && (
                <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
                  {errors.EmailConsent.message}
                </Text>
              )}
              <Controller
                name="EmailConsent"
                render={({ onChange, onBlur, value, name }) => (
                  <CheckBox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value || false}
                    name={name}
                    label="Opt-in to get invites to try free natural products from SocialNature.com. We promise to send you good stuff not junk. You can unsubscribe at any time."
                    error={errors && errors.EmailConsent}
                  />
                )}
                control={control}
              />
            </InputWrapper>
          )}
          <Box margin={{ top: 'large' }}>
            <PrimaryButton type="submit" label="save personal info" fill="horizontal" />
          </Box>
        </form>
      </Box>
    </>
  );
};

export default PersonalInformationForm;
