import React from 'react';
import styled from 'styled-components';
import { CheckBox } from 'grommet';

const SimpleCheckBox = (props) => {
  const { label, number } = props || {};
  return (
    <Wrapper>
      <CheckBox
        {...props}
        label={
          <>
            <Label>{label}</Label>
            {`(${number})`}
          </>
        }
      />
    </Wrapper>
  );
};

export default SimpleCheckBox;

// TODO: Wrapper style is to overlap site.css
const Wrapper = styled.div`
  input[type='checkbox'] {
    margin: 0;
  }
  label {
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }
`;

const Label = styled.span`
  margin-right: 0.5em;
`;
