import React, { useMemo, useEffect, useContext, useState } from 'react';
import styled from 'styled-components';
import {
  Box,
  InputLabel,
  Text,
  TextInput,
  themes as uiThemes,
  PrimaryButton,
  Select,
  CheckBox,
  Error,
} from 'socialnature-ui';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { ResponsiveContext } from 'grommet';
import { errorMessages } from '../../utils/customQuestionTypes';
import {
  CountryToIso2,
  CountryISO2FromPostalCode,
  CountryNameFromCountryISO2,
  COUNTRY_NAME_LIST,
} from '../../utils/formatCountry';
import { formatPhoneNumber } from '../../utils/number';
import { isFeatureOn, SplitNames } from '../../utils/splitio';
import SMSDoubleOptinTsCs from '../../Molecules/SmsConsent/SMSDoubleOptinTsCs';
import basicTheme from '../../themes';
import { copiesSMSOptIn } from '../../Pages/SignInPage/PhoneConsent/copiesSmsOptIn';

const MAX_LENGTH = 256;
// TODO: Remove the component when don't use site.css. It's only for overriding site.css
const InputWrapper = styled(Box)`
  input[type='text'],
  input[type='number'],
  input[type='tel'],
  input:not([type]) {
    ${(props) => (props.error ? `border-color: ${uiThemes.global.colors.red};` : '')}
    border-radius: 4px;
    font-size: 17px;
    height: auto;
    margin: 0;
    padding-left: 16px;
    padding-right: 16px;
    box-shadow: none;
    &:focus {
      border-color: ${uiThemes.global.colors.blue};
      background: inherit;
    }
  }
  input[type='text'][readonly] {
    border: none;
    background: inherit;
    box-shadow: none;
  }
  input[type='checkbox'] {
    margin: 0;
  }
`;

const SmsCheckboxWrapper = styled.div`
  label {
    display: flex;
    align-items: flex-start;
  }
  label > div:first-child {
    margin-top: 3px;
  }
`;

const postCodeTypes = {
  US: {
    label: 'ZIP Code',
    placeholder: 'e.g. 90202',
    pattern: /^[0-9]{5}(?:-?[0-9]{4})?$/,
    errorMessage: 'Please enter a valid ZIP Code. e.g. 90202',
  },
  CA: {
    label: 'Postal Code',
    placeholder: 'e.g. A1A 1A1',
    pattern: /^[abceghjklmnprstvxyABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Za-z]{1}\s*\d{1}[A-Za-z]{1}\d{1}$/,
    errorMessage: 'Please enter a valid Postal Code. e.g. A1A 1A1',
  },
};

export default function AddressForm(props) {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const {
    data,
    onSubmit,
    submitButtonLabel = 'submit',
    isAcceptReview = false,
    isSmsConsent = false,
    tracking,
    serverErrors,
  } = props;
  const {
    firstName,
    lastName,
    street1,
    street2,
    country: countryProp,
    state,
    states: stateList,
    provinces: provinceList,
    city,
    postCode,
    phone,
    smsOptin,
  } = data || {};
  const {
    handleSubmit,
    control,
    errors,
    setError,
    watch,
    formState: { isSubmitting, isSubmitSuccessful },
  } = useForm();
  const formattedPhone = useMemo(() => formatPhoneNumber(phone), [phone]);

  const [smsConsentFeatureFlag, setSmsConsentFeatureFlag] = useState(false);
  useEffect(() => {
    isFeatureOn(SplitNames.SMS_CONSENT, 'user').then(setSmsConsentFeatureFlag);
  }, []);

  useEffect(() => {
    // serverError is an array of objects with the following structure:
    // {firstName: Array [ "First name is required" ], lastName: Array [ "Last name is required" ]}
    if (serverErrors && typeof serverErrors === 'object') {
      Object.entries(serverErrors).forEach(([key, value]) => {
        if (Array.isArray(value) && value.length > 0) {
          setError(key, {
            message: value[0] ? value[0] : errorMessages.DEFAULT_ERROR,
          });
        }
      });
    }
  }, [serverErrors, setError]);

  if (!onSubmit || typeof onSubmit !== 'function') return null;
  if (!countryProp && !postCode) return null;

  const country = CountryToIso2(countryProp) || CountryISO2FromPostalCode(postCode);
  const states = country === 'US' ? stateList : provinceList;

  return (
    <Box fill="horizontal">
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputWrapper margin={{ top: 'medium' }} error={errors && errors.firstName}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel required>First Name</InputLabel>
          </Box>
          {errors && errors.firstName && (
            <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
              {errors.firstName.message}
            </Text>
          )}
          <Controller
            type="text"
            name="firstName"
            placeholder="First Name"
            defaultValue={firstName || ''}
            as={TextInput}
            control={control}
            error={errors && errors.firstName}
            showErrorIcon={false}
            maxLength={MAX_LENGTH}
            rules={{
              required: errorMessages.REQUIRED,
              maxLength: {
                value: MAX_LENGTH,
                message: errorMessages.MAX_LENGTH,
              },
            }}
          />
        </InputWrapper>
        <InputWrapper margin={{ top: 'medium' }} error={errors && errors.lastName}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel required>Last Name</InputLabel>
          </Box>
          {errors && errors.lastName && (
            <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
              {errors.lastName.message}
            </Text>
          )}
          <Controller
            type="text"
            name="lastName"
            placeholder="Last Name"
            defaultValue={lastName || ''}
            as={TextInput}
            control={control}
            error={errors && errors.lastName}
            showErrorIcon={false}
            maxLength={MAX_LENGTH}
            rules={{
              required: errorMessages.REQUIRED,
              maxLength: {
                value: MAX_LENGTH,
                message: errorMessages.MAX_LENGTH,
              },
            }}
          />
        </InputWrapper>
        <InputWrapper margin={{ top: 'medium' }} error={errors && errors.street1}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel required>Address</InputLabel>
          </Box>
          {errors && errors.street1 && (
            <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
              {errors.street1.message}
            </Text>
          )}
          <Controller
            type="text"
            name="street1"
            placeholder="e.g 333 Main Street"
            defaultValue={street1 || ''}
            as={TextInput}
            control={control}
            error={errors && errors.street1}
            showErrorIcon={false}
            maxLength={MAX_LENGTH}
            rules={{
              required: errorMessages.REQUIRED,
              maxLength: {
                value: MAX_LENGTH,
                message: errorMessages.MAX_LENGTH,
              },
            }}
          />
        </InputWrapper>
        <InputWrapper margin={{ top: 'medium' }} error={errors && errors.street2}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel option="(optional)">Unit/Apt Number</InputLabel>
          </Box>
          {errors && errors.street2 && (
            <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
              {errors.street2.message}
            </Text>
          )}
          <Box width={{ max: '160px' }}>
            <Controller
              type="text"
              name="street2"
              placeholder="e.g. Unit 123"
              defaultValue={street2 || ''}
              required={false}
              as={TextInput}
              control={control}
              error={errors && errors.street2}
              showErrorIcon={false}
              maxLength={MAX_LENGTH}
              rules={{
                maxLength: {
                  value: MAX_LENGTH,
                  message: errorMessages.MAX_LENGTH,
                },
              }}
            />
          </Box>
        </InputWrapper>
        <InputWrapper margin={{ top: 'medium' }}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel required>Country</InputLabel>
          </Box>
          <Controller
            type="text"
            name="country"
            options={COUNTRY_NAME_LIST}
            defaultValue={CountryNameFromCountryISO2(country)}
            as={Select}
            control={control}
            disabled
          />
        </InputWrapper>
        <InputWrapper margin={{ top: 'medium' }} error={errors && errors.state}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel required>State / Province</InputLabel>
          </Box>
          {errors && errors.state && (
            <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
              {errors.state.message}
            </Text>
          )}
          <Controller
            name="state"
            defaultValue={state || ''}
            control={control}
            rules={{
              required: errorMessages.REQUIRED,
            }}
            render={({ onChange, onBlur, value, name }) => (
              <Select
                placeholder="Select"
                onChange={(obj) => {
                  onChange(obj.value);
                }}
                onBlur={onBlur}
                value={value}
                name={name}
                options={states}
                error={errors && errors.state}
              />
            )}
          />
        </InputWrapper>
        <InputWrapper margin={{ top: 'medium' }} error={errors && errors.city}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel required>City</InputLabel>
          </Box>
          {errors && errors.city && (
            <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
              {errors.city.message}
            </Text>
          )}
          <Controller
            type="text"
            name="city"
            placeholder="e.g Los Angeles"
            defaultValue={city || ''}
            as={TextInput}
            control={control}
            error={errors && errors.city}
            showErrorIcon={false}
            maxLength={MAX_LENGTH}
            rules={{
              required: errorMessages.REQUIRED,
              maxLength: {
                value: MAX_LENGTH,
                message: errorMessages.MAX_LENGTH,
              },
            }}
          />
        </InputWrapper>
        <InputWrapper margin={{ top: 'medium' }} error={errors && errors.postCode}>
          <Box margin={{ bottom: '8px' }}>
            <InputLabel required>{postCodeTypes[country].label}</InputLabel>
          </Box>
          {errors && errors.postCode && (
            <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
              {errors.postCode.message}
            </Text>
          )}
          <Box width={{ max: '160px' }}>
            <Controller
              type="text"
              name="postCode"
              placeholder={postCodeTypes[country].placeholder}
              defaultValue={postCode || ''}
              as={TextInput}
              control={control}
              error={errors && errors.postCode}
              showErrorIcon={false}
              rules={{
                required: errorMessages.REQUIRED,
                pattern: {
                  value: postCodeTypes[country].pattern,
                  message: postCodeTypes[country].errorMessage,
                },
              }}
            />
          </Box>
        </InputWrapper>
        {isSmsConsent && (
          <InputWrapper margin={{ top: 'medium' }} error={errors && errors.phone}>
            <Box margin={{ bottom: '8px' }}>
              <InputLabel
                required
                tooltipId="tooltip-phoneNumber"
                tooltip="Why we ask this: Phone number is required for delivery notifications"
              >
                Phone Number
              </InputLabel>
            </Box>
            {errors && errors.phone && (
              <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
                {errors.phone.message}
              </Text>
            )}
            <Box width={{ max: '160px' }}>
              <Controller
                name="phone"
                defaultValue={formattedPhone || ''}
                render={({ onChange, onBlur, value, name }) => (
                  <InputMask
                    type="tel"
                    mask="+1 (999) 999-9999"
                    placeholder="+1 (555) 555-5555"
                    name={name}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    error={errors && errors.phone}
                  >
                    {(inputProps) => (
                      <TextInput {...inputProps} disableUnderline showErrorIcon={false} />
                    )}
                  </InputMask>
                )}
                control={control}
                rules={{
                  // only when SMS Consent is true phone-number is required
                  required: watch('smsOptin') && errorMessages.REQUIRED,
                  pattern: {
                    value: /^\+1 [(][0-9]{3}[)][\s][0-9]{3}[-][0-9]{4}$/,
                    message: 'Please enter a valid phone number. e.g. +1 (555) 555-5555',
                  },
                }}
              />
            </Box>
          </InputWrapper>
        )}

        {smsConsentFeatureFlag && isSmsConsent && (
          <InputWrapper margin={{ top: 'medium' }}>
            {errors && errors.smsOptin && (
              <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
                {errors.smsOptin.message}
              </Text>
            )}
            <SmsCheckboxWrapper>
              <Controller
                name="smsOptin"
                defaultValue={smsOptin}
                render={({ onChange, onBlur, value, name }) => (
                  <CheckBox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value || false}
                    name={name}
                    label={
                      <SMSDoubleOptinTsCs
                        optional=" (Optional)"
                        fontColor={basicTheme.global.colors.anotherSlate}
                        fontWeight={700}
                        action={copiesSMSOptIn.ACTION_TS_CS}
                      />
                    }
                    error={errors && errors.smsOptin}
                  />
                )}
                control={control}
              />
            </SmsCheckboxWrapper>
          </InputWrapper>
        )}

        {isAcceptReview && (
          <InputWrapper margin={{ top: 'medium' }}>
            {errors && errors.acceptReview && (
              <Text icon={<Error color="red" />} color="red" margin={{ bottom: '4px' }}>
                {errors.acceptReview.message}
              </Text>
            )}
            <SmsCheckboxWrapper>
              <Controller
                name="acceptReview"
                render={({ onChange, onBlur, value, name }) => (
                  <CheckBox
                    onBlur={onBlur}
                    onChange={(e) => onChange(e.target.checked)}
                    checked={value || false}
                    name={name}
                    label="I agree to review this product on Social Nature within 2 weeks of receipt."
                    error={errors && errors.acceptReview}
                  />
                )}
                control={control}
                rules={{
                  required: 'Please check this box if you want to proceed',
                }}
              />
            </SmsCheckboxWrapper>
          </InputWrapper>
        )}

        <Box width={{ max: isXsmall ? '100%' : '234px' }} margin={{ top: 'large' }}>
          <PrimaryButton
            disabled={isSubmitting || isSubmitSuccessful}
            type="submit"
            label={submitButtonLabel}
            data-tagmanager-event={tracking && tracking.tagmanager}
            data-track-category={tracking && tracking.category}
            data-track-action={tracking && tracking.action}
            data-track-label={tracking && tracking.label}
          />
        </Box>
        {!!Object.keys(errors).length && (
          <Box width={{ max: isXsmall ? '100%' : '360px' }}>
            <Text color="red" size="small" margin={{ top: '16px' }}>
              {errorMessages.SUBMIT_ERROR}
            </Text>
          </Box>
        )}
      </form>
    </Box>
  );
}
