import { useCallback, useEffect, useState, useMemo, memo } from 'react';

export function MarkerComponent(props) {
  const { map, options, selected, onClick } = props;
  const [marker, setMarker] = useState();

  if (!window.google || !window.google.maps) {
    window.google = {
      maps: () => {},
    };
  }

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker, options.position]);

  const svgMarker = useMemo(
    () => ({
      path: 'M13.1911 34.0001L12.4344 34.6539L13.1911 35.5297L13.9478 34.6539L13.1911 34.0001ZM13.1911 34.0001C13.9478 34.6539 13.9479 34.6537 13.9481 34.6535L13.9487 34.6528L13.9506 34.6506L13.9573 34.6428L13.982 34.614C14.0035 34.5889 14.0348 34.5521 14.0752 34.5042C14.1562 34.4084 14.2739 34.2679 14.4231 34.0869C14.7215 33.7248 15.1461 33.2 15.6549 32.5446C16.6717 31.2348 18.0288 29.3981 19.3878 27.2924C20.745 25.1893 22.1155 22.8005 23.1494 20.3878C24.178 17.9873 24.9041 15.4937 24.9041 13.2001C24.9041 6.49819 19.7076 1.00012 13.1911 1.00012C6.67458 1.00012 1.47803 6.4982 1.47803 13.2001C1.47803 15.4937 2.20414 17.9873 3.23278 20.3878C4.26668 22.8005 5.6371 25.1893 6.99433 27.2924C8.3533 29.3981 9.71045 31.2348 10.7272 32.5446C11.236 33.2 11.6607 33.7248 11.959 34.0869C12.1082 34.2679 12.2259 34.4084 12.3069 34.5042C12.3474 34.5521 12.3787 34.5889 12.4001 34.614L12.4249 34.6428L12.4316 34.6506L12.4334 34.6528L12.434 34.6535C12.4342 34.6537 12.4344 34.6539 13.1911 34.0001Z',
      fillColor: selected ? '#DB2573' : '#FFFFFF',
      strokeColor: '#DB2573',
      fillOpacity: 1,
      strokeWeight: 2,
      rotation: 0,
      scale: 1,
      anchor: new window.google.maps.Point(15, 30),
      labelOrigin: new window.google.maps.Point(13, 13),
    }),
    [selected],
  );

  useEffect(() => {
    if (marker) {
      marker.addListener('click', () => {
        if (typeof onClick === 'function') {
          onClick();
        }
      });
    }
  }, [marker, onClick]);

  useEffect(() => {
    if (marker) {
      marker.setOptions({
        ...options,
        map,
        icon: svgMarker,
        label: {
          text: options.label,
          fontFamily: 'Montserrat","Helvetica Neue",Helvetica,Arial,sans-serif',
          color: selected ? '#FFFFFF' : '#DB2573',
          fontWeight: 'bold',
          fontSize: '12px',
        },
      });
    }
  }, [map, marker, options, selected, svgMarker]);

  useEffect(() => {
    if (marker && !marker.infoWindow) {
      marker.infoWindow = new window.google.maps.InfoWindow({
        content: options.information,
        disableAutoPan: true,
      });
      marker.infoWindow.addListener('closeclick', () => {
        if (typeof onClick === 'function') {
          onClick('close');
        }
      });
    }
  }, [map, marker, onClick, options, svgMarker]);

  const openInfo = useCallback(() => {
    if (marker) {
      marker.infoWindow.open(map, marker);
    }
  }, [map, marker]);

  const closeInfo = useCallback(() => {
    if (marker) {
      marker.infoWindow.close();
    }
  }, [marker]);

  useEffect(() => {
    if (selected) {
      openInfo();
    } else {
      closeInfo();
    }
  }, [closeInfo, openInfo, selected]);

  return null;
}

export const Marker = memo(MarkerComponent);
