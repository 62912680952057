import React from 'react';
import { CompanyName, ProductName } from '../../Molecules/ProductCard/Name';
import Card from '../../Atoms/Card/Card';
import CardAction from '../../Atoms/Card/CardAction';
import CardBody from '../../Atoms/Card/CardBody';
import CardImage from '../../Atoms/Card/CardImage';
import CardContent from '../../Atoms/Card/CardContent';
import CardFooter from '../../Atoms/Card/CardFooter';
import Count from '../../Molecules/ProductCard/Count';

const TopRatedCard = (props) => {
  const { product, width, height, dataTrackCategory, index } = props;

  const { name, slug, companyName, featuredImage, averageRating, totalReviews } = product;
  const productLink = `/${slug}`;

  return (
    <Card width={width} height={height}>
      <CardAction
        href={productLink}
        data-track-category={dataTrackCategory}
        data-track-action={dataTrackCategory && `Suggested ${index + 1}`}
        data-track-label={dataTrackCategory && slug}
      >
        <CardBody>
          <CardImage src={featuredImage} />
          <CardContent>
            <CompanyName>{companyName}</CompanyName>
            <ProductName>{name}</ProductName>
          </CardContent>
          <CardFooter>
            <Count type="review" value={totalReviews} averageRating={averageRating} />
          </CardFooter>
        </CardBody>
      </CardAction>
    </Card>
  );
};

export default TopRatedCard;
