import React from 'react';
import { CheckBoxGroup } from 'socialnature-ui';
import { Controller, useFormContext } from 'react-hook-form';
import { isNil } from 'ramda';
import { errorMessages } from '../../../utils/customQuestionTypes';

const CustomCheckBoxGroup = (props) => {
  const { name, error, question } = props;
  const { required = true, choices, other, noneOption } = question;
  const { control } = useFormContext();

  if (isNil(choices) || !Array.isArray(choices)) return null;

  return (
    <Controller
      name={name}
      as={CheckBoxGroup}
      control={control}
      options={choices}
      hasOther={other}
      noneOption={noneOption}
      error={!isNil(error)}
      rules={{
        required: errorMessages.REQUIRED,
        validate:
          required && ((value) => (value && value.length !== 0 ? true : errorMessages.REQUIRED)),
      }}
    />
  );
};

export default CustomCheckBoxGroup;
