import React from 'react';
import * as R from 'ramda';
import classNames from 'classnames';
import StarRating from '../Atoms/StarRating';

export default class StorefrontItem extends React.Component {
  getCountryClassName(countryName) {
    return countryName.toLowerCase().replace(' ', '-');
  }

  render() {
    const { name, country, slug, averageRating, reviewCount, featuredImage, imageUrl } =
      this.props.sample;
    const hasRating = !R.isNil(averageRating);
    const image = imageUrl || (featuredImage || {}).full || '';
    const link = `/${slug}`;
    const wanted = this.props.wanted || false;

    return (
      <React.Fragment>
        <a href={link}>
          <div
            className={classNames('storefront-image', { 'storefront-image__review': hasRating })}
          >
            <img src={image} />
          </div>
        </a>
        <div className="layout-row">
          <div className={classNames({ 'flex-75': !hasRating })}>
            <a href={link}>
              <h2 className="storefront-name">{name}</h2>
            </a>
          </div>
          {!hasRating && (
            <div className="flex-25 flex-right">
              <span className={`phoca-flagbox right ${this.getCountryClassName(country)}`}></span>
            </div>
          )}
        </div>

        {!hasRating ? (
          !wanted ? (
            <div className="layout-row">
              <a href={link} className="storefront-link">
                APPLY TO TRY
              </a>
            </div>
          ) : (
            <div className="layout-row">
              <a href={link} className="storefront-link">
                APPLIED
              </a>
            </div>
          )
        ) : (
          <a href={link}>
            <div className="storefront-rating__info">
              <div className="storefront-rating__stars">
                <StarRating rating={averageRating} />
              </div>
              <div className="storefront-rating__count">
                ({reviewCount.toLocaleString()} reviews)
              </div>
            </div>
          </a>
        )}
      </React.Fragment>
    );
  }
}
