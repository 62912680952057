import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ResponsiveContext } from 'grommet';
import SimpleModal from '../../../Molecules/SimpleModal';
import { getShareUrl } from '../../../utils/socialShare';
import { TrackingEventType } from '../../../utils/tracking';
import { trackEvent as trackGTMEvent } from '../../../utils/gtm';
import InviteFriendContent from '../../InviteFriend/InviteFriendContent';
import Loading from '../../../Molecules/Loading';
import { SplitNames, getFeatureTreatment } from '../../../utils/splitio';

const SocialShareModal = ({ open, userId, product, onClose, dataTrackCategory }) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const { sharingMessages, name: sampleKitName, company, id, slug: productSlug } = product || {};
  const { name: companyName } = company || {};
  const { twitter } = (sharingMessages && sharingMessages.pretrial) || {};
  const shareUrl = getShareUrl(product, userId);

  const trackShare = useCallback(
    (provider) =>
      trackGTMEvent(TrackingEventType.SHARE_PRODUCT_CLICK, {
        provider,
        samplekit: product && product.name,
      }),
    [product],
  );

  const [customCampaignRAFGiveaways, setCustomCampaignRAFGiveaways] = useState('off');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getFeatureTreatment(SplitNames.customCampaignRAFGiveaways).then((value) => {
      setCustomCampaignRAFGiveaways(value);
      setLoading(false);
    });
  }, []);

  if (loading) return <Loading />;

  return (
    <SimpleModal
      open={open}
      onClose={onClose}
      full
      position="top"
      margin={isXsmall ? {} : { top: '5%' }}
    >
      <BackgroundImage isXsmall={isXsmall}>
        <InviteFriendContent
          userId={userId}
          samplekitId={id}
          dataTrackCategory={dataTrackCategory}
          trackShare={trackShare}
          shareUrl={shareUrl}
          companyName={companyName}
          twitter={twitter}
          sampleKitName={sampleKitName}
          productSlug={productSlug}
          isPopUp
          customCampaignRAFGiveaways={customCampaignRAFGiveaways}
        />
      </BackgroundImage>
    </SimpleModal>
  );
};

export default SocialShareModal;

const BackgroundImage = styled.div`
  ${(props) =>
    !props.isXsmall &&
    `
			background: url('/images/bg-left-decoration.svg'), url('/images/bg-right-decoration.svg');
			background-repeat: no-repeat, no-repeat;
			background-position: top left, top right;
      background-size: 80px, 80px;
      background-position-y: 50%, 50%;
		`}
`;
