import { handleError } from './error';
import { isMultiChannelDomain } from './app';

const isMultiChannel = isMultiChannelDomain();

// Mappings for Site Redirections & Reusing react scripts on other domains
const GUEST_COOKIE_MAPPINGS = {
  "User_Token" : "User_Guest_Token",
  "User_TrackingID": "User_Guest_TrackingID"
}

export const getCookie = (name) => {
  var cookieName = name;
  const cookieList = document.cookie.split('; ').map((cookie) => cookie.split('='));
  if (cookieList) {
    cookieName = (isMultiChannel && GUEST_COOKIE_MAPPINGS[cookieName]) ? GUEST_COOKIE_MAPPINGS[cookieName] : cookieName;
    const cookie = cookieList.find(([candidateName]) => candidateName === cookieName);
    const value = cookie && cookie[1];
    return value && decodeURIComponent(value);
  } else {
    return null;
  }
};

export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + (exdays || 0) * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${exdays ? expires : ''};path=/`;
};

export const deleteCookie = (cname) => {
  document.cookie = `${cname}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const getSelectedCampaignForSamplekit = (productSlug) => {
  const cookieName = `${productSlug}`;
  let campaignInfo;

  try {
    campaignInfo = JSON.parse(getCookie(cookieName));
  } catch (e) {
    handleError(e);

    campaignInfo = {};
  }

  return campaignInfo;
};
