import React from 'react';
import { Text } from 'socialnature-ui';
import styled from 'styled-components';
import theme from '../../../themes';

const Divider = styled(Text)`
  position: relative;
  line-height: 48px;
  text-align: center;
  font-size: 14px;
  color: ${theme.global.colors.iron};

  &::before,
  &::after {
    position: absolute;
    width: 46%;
    height: 1px;
    top: 24px;
    background-color: ${theme.global.colors.smoke};
    content: '';
  }

  &::before {
    left: 0;
  }

  &::after {
    right: 0;
  }
`;

export default function OrDivider({ color, label }) {
  return (
    <Divider color={color || theme.global.colors.slate} size="12px">
      {label || 'or'}
    </Divider>
  );
}
