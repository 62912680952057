import React from 'react';
import { ResponsiveContext } from 'grommet';
import styled from 'styled-components';

const CardBadge = ({ text, color, fontWeight, smallTop = 8 }) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <Badges isXsmall={isXsmall} smallTop={smallTop}>
      <Badge isXsmall={isXsmall} colorProp={color || 'pink'}>
        <BadgeText isXsmall={isXsmall} fontWeight={fontWeight}>
          {text}
        </BadgeText>
      </Badge>
    </Badges>
  );
};

export default CardBadge;

const Badges = styled.div`
  position: absolute;
  top: ${(props) => (props.isXsmall ? `${props.smallTop}px` : '16px')};
  left: -2px;
  z-index: 2;
`;

const Badge = styled.div`
  position: relative;
  display: flex;
  margin-bottom: 8px;
  height: ${(props) => (props.isXsmall ? '24px' : '34px')};
  align-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 72px;
  text-align: center;
  padding: ${(props) => (props.isXsmall ? '0 8px' : '0 16px')};
  background: ${(props) => `url('/images/badge-${props.colorProp}.svg')`} center right no-repeat;
  background-size: cover;
`;

const BadgeText = styled.span`
  position: relative;
  color: #ffffff;
  font-size: ${(props) => (props.isXsmall ? '12px' : '14px')};
  z-index: 3;
  font-weight: ${({ fontWeight }) => fontWeight || 'bold'};
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-transform: uppercase;
`;
