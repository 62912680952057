// @ts-check
import React, { useContext, useState } from 'react';
import { Grommet, ResponsiveContext } from 'grommet';
import { deepMerge } from 'grommet/utils';
import { useModal } from '../useModal';
import basicTheme from '../../themes';
import { Instructions } from '../../Molecules/Instructions';

const customTheme = deepMerge(basicTheme, {
  layer: {
    overlay: {
      background: 'rgba(0, 0, 0, 0.8)',
    },
  },
});

/**
 * @param {Array} initialSteps
 * @param {boolean} initOpen
 * @param {Labels=} labels
 * @typedef {{
 * back: string,
 * next: string,
 * done: string,
 * }} Labels
 */

export function useInstructionsModal(initialSteps = [], initOpen, labels) {
  const { Modal, open, close } = useModal(initOpen);
  const [steps, setSteps] = useState(initialSteps);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  const InstructionsModal = () => (
    <Grommet theme={customTheme}>
      <Modal
        position={isXsmall ? 'bottom' : 'center'}
        crossSize="20"
        ariaHideApp={false}
        animation="slide"
        inert={true}
        layerStyle={
          !isXsmall
            ? {
                top: '35%',
              }
            : {}
        }
        style={
          isXsmall
            ? {
                width: '100vw',
                maxWidth: '100vw',
                margin: 0,
                padding: '44px 16px 0',
                borderRadius: '8px 8px 0px 0px',
                overflowY: 'scroll',
              }
            : {
                width: '380px',
                height: '510px',
                padding: '44px 16px 0',
                display: 'flex',
                top: '80px',
                flexDirection: 'column',
                placeContent: 'space-between',
              }
        }
      >
        <Instructions steps={steps} labels={labels} close={close} />
      </Modal>
    </Grommet>
  );

  return { InstructionsModal, open, close, setSteps };
}
