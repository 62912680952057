// @ts-check
import React, { useContext, useMemo, useState } from 'react';
import { ResponsiveContext } from 'grommet';
import { StorePickUpLocationMobile } from './StorePickUpLocationMobile';
import { StorePickUpLocationDesktop } from './StorePickUpLocationDesktop';
import { useHandleCampaignLocations } from '../../../hooks/useHandleCampaignLocations';

/**
 * @typedef {{
 * sampleKitName: string,
 * postalCode: string,
 * handleFormSubmitFetch: Function,
 * preferredLocation?: number,
 * Header?: Function
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function StorePickUpLocationLayout({
  sampleKitName,
  postalCode,
  handleFormSubmitFetch,
  preferredLocation,
  Header,
}) {
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [isRecheckingNearby, setIsRecheckingNearby] = useState(false);
  const [postalCodeWithNoLocations, setPostalCodeWithNoLocations] = useState(postalCode);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const { campaignLocations, handleCampaignLocations } =
    useHandleCampaignLocations(preferredLocation);

  const errorMessage = useMemo(
    () =>
      `Sorry, we weren't able to find any ${sampleKitName} near ${postalCodeWithNoLocations}. Try entering a different zip/postal code.`,
    [sampleKitName, postalCodeWithNoLocations],
  );

  if (isXsmall) {
    return (
      <StorePickUpLocationMobile
        campaignLocations={campaignLocations}
        setIsRecheckingNearby={setIsRecheckingNearby}
        setCampaignLocations={handleCampaignLocations}
        isXsmall={isXsmall}
        isRecheckingNearby={isRecheckingNearby}
        errorMessage={errorMessage}
        setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
        postalCodeWithNoLocations={postalCodeWithNoLocations}
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
        handleFormSubmitFetch={handleFormSubmitFetch}
        Header={Header}
      />
    );
  }
  return (
    <StorePickUpLocationDesktop
      campaignLocations={campaignLocations}
      setIsRecheckingNearby={setIsRecheckingNearby}
      setCampaignLocations={handleCampaignLocations}
      isXsmall={isXsmall}
      isRecheckingNearby={isRecheckingNearby}
      errorMessage={errorMessage}
      setPostalCodeWithNoLocations={setPostalCodeWithNoLocations}
      postalCodeWithNoLocations={postalCodeWithNoLocations}
      selectedIndex={selectedIndex}
      setSelectedIndex={setSelectedIndex}
      handleFormSubmitFetch={handleFormSubmitFetch}
      Header={Header}
    />
  );
}
