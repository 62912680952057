// @ts-check
import React, { useCallback, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

/**
 * @typedef {{
 * popupDiscountCode?: string,
 * image: string,
 * popupMessage: string,
 * popupUrl: string,
 * }} CouponInfo
 * @typedef {{
 * coupon: CouponInfo,
 * productId: number,
 * productSlug: string,
 * dataTrackCategory?: string | null,
 * }} Props
 * @param {Props} props
 * @returns
 */
export function Coupon(props) {
  const [copied, setCopied] = useState(false);

  const handleCopy = useCallback(() => {
    setCopied(true);
  }, []);

  const { coupon, productId, productSlug, dataTrackCategory } = props;
  const { popupDiscountCode, image, popupMessage, popupUrl } = coupon;

  return (
    <div className="launch-coupon fade-in">
      <div className="layout-row">
        <div className="launch-coupon-avatar flex-center">
          <img src={image} alt="" />
        </div>
      </div>

      <div className="nudge-md" />

      <div className="layout-row launch-coupon-banner flex-center flex-80">
        <div className="coupon-35 flex-center-vert">
          <img src="images/coupon.png" alt="" />
        </div>
        <div
          className="coupon-65"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: popupMessage,
          }}
        />
      </div>

      <div className="nudge-md" />

      {!!popupDiscountCode && (
        <>
          <div className="layout-row launch-coupon-input">
            <div className="flex-100 flex-center">
              <input value={popupDiscountCode} readOnly />
              <CopyToClipboard text={popupDiscountCode} onCopy={handleCopy}>
                <button
                  type="button"
                  data-track-category={dataTrackCategory}
                  data-track-action="Copy Discount"
                  data-track-samplekit-id={productId}
                  data-track-label={productSlug}
                  style={{ whiteSpace: 'nowrap' }}
                >
                  {copied === false ? (
                    'Copy'
                  ) : (
                    <span>
                      <i className="material-icons">done</i>
                      {' Copied!'}
                    </span>
                  )}
                </button>
              </CopyToClipboard>
            </div>
          </div>
          <div className="nudge-md" />
        </>
      )}

      {!!popupUrl && (
        <div className="layout-row launch-coupon flex-center">
          <p>
            Copy code and go to&nbsp;
            <a
              href={popupUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="link-brand"
              data-track-category={dataTrackCategory || 'Click'}
              data-track-action="Retailer Link"
              data-track-samplekit-id={productId}
              data-track-label={productSlug}
            >
              click here
            </a>
          </p>
        </div>
      )}
    </div>
  );
}
