import React, { useContext } from 'react';
import { Box, Container, Heading, Text, themes } from 'socialnature-ui';
import { Image, ResponsiveContext } from 'grommet';
import { errorMessages } from '../../utils/customQuestionTypes';
import Alert from '../../Molecules/Alert';

const ProductHeader = (props) => {
  const { productImageSrc, title, description, info } = props;
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  return (
    <Box direction="row" gap="medium">
      <Box
        width={isXsmall ? '80px' : '120px'}
        height={isXsmall ? '80px' : '120px'}
        round="8px"
        elevation="smallImage"
        pad="small"
        background="#FFFFFF"
      >
        <Image fit="cover" src={productImageSrc} alt={title} />
      </Box>
      <Box>
        <Heading level={3}>{title}</Heading>
        <Text margin={{ top: '4px', ...(info ? { bottom: '0px' } : {}) }} color="black">
          {description}
        </Text>
        {info && (
          <Text margin={{ top: '4px' }} color={themes.global.colors.slate}>
            {info}
          </Text>
        )}
      </Box>
    </Box>
  );
};

const SurveyProductHeader = (props) => {
  const { productImageSrc, productName, companyName, hasError, maxWidthSize, info } = props;

  return (
    <Box background="smoke" data-cy="product-header">
      <Container maxWidth={maxWidthSize ? `calc(${maxWidthSize} + 32px)` : 'calc(592px + 32px)'}>
        <Box pad={{ vertical: 'medium' }}>
          {hasError && (
            <Box margin={{ bottom: 'medium' }} align="center">
              <Alert severity="error">{errorMessages.FORM_ERROR}</Alert>
            </Box>
          )}
          <ProductHeader
            productImageSrc={productImageSrc}
            title={productName}
            description={companyName}
            info={info}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default SurveyProductHeader;
