import React from 'react';
import { Box } from 'grommet';
import { useQuery } from '@apollo/react-hooks';
import { GET_FREETRIALS_HOME } from '../../../utils/apis/products';
import formatParam from '../../../utils/apis/formatParam';
import FreeTrialList from '../../../Organisms/Products/FreeTrialList';
import LoadingSection from '../../../Molecules/LoadingSection';
import Container from '../../../Atoms/Container';
import PageAnchor from '../../../Templates/PageAnchor';
import NoFreeTrial from '../../../Organisms/Products/NoFreeTrial';

const FreeTrialSection = ({ userId, userCountry, postalCode = '' }) => {
  const { loading, error, data } = useQuery(GET_FREETRIALS_HOME, {
    variables: {
      param: formatParam({
        limit: 20,
        userid: userId,
        filter: userCountry && `country=${userCountry}`,
        zip: postalCode,
      }),
    },
  });
  if (loading) return <LoadingSection />;
  if (error) return <></>;
  const { freetrials } = data || {};

  return (
    <>
      {freetrials && freetrials.products ? (
        <Box flex={false} pad={{ vertical: 'section' }} className="section-bg-gray">
          <PageAnchor id="products" />
          <Container>
            <FreeTrialList
              userId={userId}
              products={freetrials.products}
              linkViewAll={userCountry ? `/products?country=${userCountry}` : '/products'}
            />
          </Container>
        </Box>
      ) : (
        <Box flex={false} pad={{ vertical: 'section' }} background="white">
          <Container>
            <NoFreeTrial />
          </Container>
        </Box>
      )}
    </>
  );
};

export default FreeTrialSection;
