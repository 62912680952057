/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { isNilOrEmpty } from '../../utils/customRamda';
import Ingredients from './Ingredients';

const ProductDetails = ({ content }) => {
  const slugify = (text) =>
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-')
      .replace(/[^\w-]+/g, '')
      .replace(/--+/g, '-')
      .replace(/^-+/, '')
      .replace(/-+$/, '');

  const { certifications, qualities, description, ingredients, recipes } = content;

  return (
    <div>
      <div className="nudge-md" />
      <a id="story" className="anchor" />

      <h2>Why it’s better</h2>
      <div
        itemProp="description"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />

      {(!isNilOrEmpty(qualities) || !isNilOrEmpty(certifications)) && (
        <div>
          <a id="qualities" className="anchor" />

          <h2>Shared Values</h2>
          <ul className="launch-qualities">
            {!isNilOrEmpty(certifications) &&
              certifications.map((certification) => (
                <a
                  key={certification.name}
                  href={certification.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={certification.image.small} alt={certification.name} />
                </a>
              ))}
            {!isNilOrEmpty(qualities) &&
              qualities.map((quality) => (
                <a key={quality.name} href={`/quality/${slugify(quality.name)}`}>
                  <img src={quality.image.small} alt={quality.name} />
                </a>
              ))}
          </ul>
        </div>
      )}

      {ingredients && <Ingredients ingredients={ingredients} />}

      {recipes && (
        <div>
          <a id="recipes" className="anchor" />

          <h2>Recipes</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: recipes,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
