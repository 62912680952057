// @ts-check
import { CapitalizeFirstLetter } from '../../string';
import { SplitNames, isFeatureOn } from '../../splitio';
import { CTATypes } from '../CTATypes';
import { rebateFreebieLabel } from './freebieLabel';
import { discountTypes } from '../../discountTypes';
import { getCashBackFormattedPrice } from '../../product';

const getRebateBadgedLabel = async (campaignProperties) => {
  const {
    offerDiscountPercentage,
    campaignBogo,
    moneyCashBackValue = 0,
  } = campaignProperties || {};
  const isUpdateLabelFF = await isFeatureOn(SplitNames.changeCashBackLabelToFreeProduct, 'user');
  const discountType = (campaignProperties?.discountType || '').toUpperCase();

  if (discountType === discountTypes.BOGO) {
    return CapitalizeFirstLetter(campaignBogo?.label.toLowerCase() || '');
  }
  if (discountType === discountTypes.MONEY_CASH_BACK) {
    return `$${getCashBackFormattedPrice(moneyCashBackValue)} Off`;
  }
  if (isUpdateLabelFF) {
    return rebateFreebieLabel(offerDiscountPercentage);
  }

  return `${offerDiscountPercentage}% Off`;
};

/**
 * @param {import("../types").CTAType} ctaType
 * @param {string} bannerMessage
 * @param {CampaignProperties} campaignProperties
 * @returns {Promise<{text: string, color: 'pink'|'blue'} | null>}
 */
export async function getBadge(ctaType, bannerMessage, campaignProperties) {
  const isUpdateLabelFF = await isFeatureOn(SplitNames.changeCashBackLabelToFreeProduct, 'user');
  if (ctaType === CTATypes.WANT) {
    return {
      text: isUpdateLabelFF ? 'Free product' : 'Free Sample',
      color: 'pink',
    };
  }
  if (ctaType === CTATypes.BOOST || ctaType === CTATypes.BANNER) {
    if (bannerMessage) {
      return {
        text: bannerMessage,
        color: 'blue',
      };
    }
  }
  if (
    ctaType === CTATypes.ACTIVATE_OFFER ||
    ctaType === CTATypes.OFFER_ACTIVATED ||
    ctaType === CTATypes.ADD_A_RECEIPT ||
    ctaType === CTATypes.REACTIVATE_OFFER ||
    ctaType === CTATypes.OFFER_REDEEMED
  ) {
    return {
      text: await getRebateBadgedLabel(campaignProperties),
      color: 'pink',
    };
  }

  return null;
}
