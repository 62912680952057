import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const MILLISECONDS_IN_A_DAY = 1000 * 60 * 60 * 24;

export default function hasExpired(expiryDate) {
  if (!expiryDate) return false;
  const today = new Date();
  const date = new Date(expiryDate);
  const diffDate = (today.getTime() - date.getTime()) / MILLISECONDS_IN_A_DAY;
  return diffDate > 0;
}

/**
 * Check to see if today is the duration.
 *
 * Example:
 * Today is 2022-03-09.
 * isDurationToday('2022-03-10', 1, 'week') return true
 * isDurationToday('2022-04-10', 1, 'week') return false
 *
 * @param {string | Date} endDate
 * @param {number} number
 * @param {string} unit - e.g. "day", "week", "month"
 * https://day.js.org/docs/en/manipulate/add#list-of-all-available-units
 * @returns {boolean}
 */
export function isDurationToday(endDate, number, unit) {
  if (!endDate || !dayjs(endDate).isValid()) return '';

  const startDate = dayjs(endDate).subtract(number, unit);
  return dayjs().isBetween(startDate, endDate);
}

// Function to check if a date has passed 365 days. 'n' represents no.of days
export function hasPassedNDays(date, n) {
  const now = dayjs();
  const inputDate = dayjs(date);
  const differenceInDays = now.diff(inputDate, 'day'); // input date: YYYY-MM-DD
  return differenceInDays > n;
}
