import TiktokPixel from 'tiktok-pixel';
import { getCookie } from '../../../cookie';
import { hashData } from '../../../product';

const isTikTokCampaign = () => getCookie('TiktokClickID');
const TIKTOK_PIXEL_ID = 'COG1N73C77UDCCMU07OG';

let isTiktokApiReady = false;
const initialiseTiktok = async (event) => {
  try {
    if (!isTiktokApiReady) {
      await TiktokPixel.init(TIKTOK_PIXEL_ID, event, { debug: true });
      isTiktokApiReady = true;
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to initialise Tiktok');
    isTiktokApiReady = false;
  }
};

export const trackTiktokCampaign = async (payload) => {
  try {
    const tiktokUTM = isTikTokCampaign();
    if (!(tiktokUTM && tiktokUTM.length)) return Promise.resolve();

    const hashedEmail = await hashData(payload.email);
    const hashedID = await hashData(payload.id);

    return initialiseTiktok({ email: hashedEmail, ...(hashedID && { id: hashedID }) }).then(() => {
      if (!window.tiktokEngagementTracked) {
        window.tiktokEngagementTracked = true;
        TiktokPixel.track('CompleteRegistration', {
          content_type: 'social-nature',
          quantity: 1,
          content_name: 'Tiktok-Campaign',
        });
      }
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.warn('Failed to send Tiktok Conversion');
    return Promise.resolve();
  }
};
