import React from 'react';
import styled from 'styled-components';
import { Select } from 'grommet';

const SimpleSelect = (props) => (
  <Wrapper>
    <Select {...props} />
  </Wrapper>
);

export default SimpleSelect;

// TODO: Wrapper style is to overlap site.css
const Wrapper = styled.div`
  input[type='text'] {
    margin: 0;
    background-color: #ffffff;
    border: none;
    box-shadow: none;
    color: #333333;
    font-weight: 400;
    font-size: 16px;
  }
  & > button {
    width: 100%;
    background: #ffffff;
  }
`;
