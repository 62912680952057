import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Box, ResponsiveContext } from 'grommet';
import { Modal, Heading } from 'socialnature-ui';
import ShareButtonStyle from '../../../Atoms/Buttons/ShareButton';
import Email from '../../../Atoms/Icons/sns/Email';
import ShareEmailForm from './ShareEmailForm';
import { getDataset } from '../../../utils/socialShare';

const ShareButtonWithOneEmail = React.memo((props) => {
  const {
    title,
    buttonLabel,
    emailLabel,
    sendEmail,
    dataTrackCategory,
    productSlug,
    productId,
    variant,
  } = props;
  const [open, setOpen] = useState(false);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const dataAttributes = useMemo(() => getDataset(props), [props]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  if (!sendEmail || typeof sendEmail !== 'function') {
    return null;
  }

  return (
    <>
      <ShareButtonStyle
        isXsmall={isXsmall}
        onClick={handleOpen}
        icon={<Email />}
        label="Email"
        {...dataAttributes}
        variant={variant}
      />
      {open && (
        <Modal open={open} onClose={handleClose}>
          <Box width="526px">
            <Box width={{ max: '490px' }} margin={{ horizontal: 'auto' }}>
              <Heading level={3} size="24px" textAlign="center">
                {title}
              </Heading>
            </Box>
            <Box margin={{ top: '32px' }}>
              <ShareEmailForm
                dataTrackCategory={dataTrackCategory}
                dataTrackAction="Click Email Share"
                productId={productId}
                productSlug={productSlug}
                buttonLabel={buttonLabel}
                emailLabel={emailLabel}
                sendEmail={sendEmail}
              />
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
});

export default ShareButtonWithOneEmail;
