/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
const { FACEBOOK_APP_ID } = process.env;

// If you can not load Twitter/Pinterest function from CDN, the buttons works as link
const ProductSocialShare = (props) => {
  const { viewModel } = props;
  const { sampleKit } = viewModel;
  return (
    <div className="social layout-row-sm">
      <a
        href={`mailto:?subject=${encodeURIComponent(
          "I'd like to invite you to #trynatural",
        )}&body=${encodeURIComponent(
          `I just discovered these new ${sampleKit.name || 'product'} from ${
            sampleKit.companyName || 'good brand'
          } on @socialnature and thought you’d like it. Social Nature is a website that helps you discover natural and sustainable products for your next shopping trip, and sometimes they offer free product samples. Thought you would like to check them out! ${viewModel.shareUrl.replace(
            /\{0\}/g,
            'email',
          )}`,
        )}`}
        className="link-social"
        data-track-category="Product-Button"
        data-track-action="Email"
        data-track-label={sampleKit.slug}
      >
        <i className="fas fa-envelope" /> <span>Email</span>
      </a>
      <a
        className="link-social"
        data-sample-kit-name={sampleKit.name}
        data-sample-kit-company-name={sampleKit.CompanyName}
        data-track-category="Product-Button"
        data-track-action="Facebook"
        data-track-label={sampleKit.slug}
        href={`https://www.facebook.com/sharer/sharer.php?app_id=${FACEBOOK_APP_ID}&u=${encodeURIComponent(
          viewModel.shareUrl.replace(/\{0\}/g, 'facebook'),
        )}redirect_uri=${encodeURIComponent(window.location.href)}`}
        target="_blank"
      >
        <i className="fab fa-facebook-square" /> <span>Facebook</span>
      </a>
      <a
        href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
          viewModel.shareUrl.replace(/\{0\}/g, 'twitter'),
        )}&text=${encodeURIComponent(viewModel.pretrialTwitterShareMessage)}`}
        className="link-social"
        target="_blank"
        rel="noopener noreferrer"
        data-track-category="Product-Button"
        data-track-action="Twitter"
        data-track-label={sampleKit.slug}
      >
        <i className="fab fa-twitter" /> <span>Twitter</span>
      </a>
      <a
        href={`https://www.pinterest.com/pin/create/button?url=${encodeURIComponent(
          viewModel.shareUrl.replace(/\{0\}/g, 'pinterest'),
        )}&media=${encodeURIComponent(
          viewModel.sampleKitFeaturedImage,
        )}&description=${encodeURIComponent(viewModel.pretrialPinterestShareMessage)}`}
        className="link-social"
        target="_blank"
        rel="noopener noreferrer"
        data-sample-kit-name={sampleKit.name}
        data-sample-kit-company-name={sampleKit.companyName}
        data-share-type="product"
        data-share-url={viewModel.shareUrl.replace(/\{0\}/g, 'pinterest')}
        data-share-channel="pinterest"
        data-share-model-id={sampleKit.id}
        data-pin-do="buttonPin"
        data-pin-custom="true"
        data-track-category="Product-Button"
        data-track-action="Pinterest"
        data-track-label={sampleKit.slug}
      >
        <i className="fab fa-pinterest" /> <span>Pinterest</span>
      </a>
    </div>
  );
};

export default ProductSocialShare;
