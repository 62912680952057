import gql from 'graphql-tag';
import { getCookie, setCookie } from '../cookie';
import { handleError } from '../error';
import clientGraphql from './graphql';

export const sampleKitFieldsFragment = `fragment sampleKitFieldsFragment on samplekits {
    id
    name
    companyData {
      name
    }
    featured_image(path: "medium")
  }
`;

export const getSampleKitInformationFromSlug = (slug) => gql`
  ${sampleKitFieldsFragment}
  query GetSampleKitInformationFromSlug {
    samplekits(where: {slug: {_eq: "${slug}"}}) {
      ...sampleKitFieldsFragment
    }
  }
`;
export const GET_LAUNCHPADS_FILTERS = gql`
  query ($param: String) {
    data(param: $param) @rest(type: "Products", path: "products/launchpad?{args.param}") {
      totalCount
      filtergroups
      products
    }
  }
`;

export const GET_FREETRIALS_FILTERS = gql`
  query ($param: String) {
    data(param: $param) @rest(type: "Products", path: "products/freetrial?{args.param}") {
      totalCount
      filtergroups
      products
    }
  }
`;

export const GET_LAUNCHPADS = gql`
  query ($param: String) {
    launchpads(param: $param)
      @rest(type: "Products", path: "products/launchpad/loadmore?{args.param}") {
      totalCount
      products
    }
  }
`;

export const GET_FREETRIALS = gql`
  query ($param: String) {
    freetrials(param: $param)
      @rest(type: "Products", path: "products/freetrial/loadmore?{args.param}") {
      totalCount
      products
    }
  }
`;

export const GET_LAUNCHPADS_HOME = gql`
  query ($param: String) {
    launchpads(param: $param) @rest(type: "Products", path: "user/home/launchpad?{args.param}") {
      products
    }
  }
`;

export const GET_FREETRIALS_HOME = gql`
  query ($param: String) {
    freetrials(param: $param) @rest(type: "Products", path: "user/home/freetrial?{args.param}") {
      products
    }
  }
`;

export const GET_TOPRATED_HOME = gql`
  query ($param: String) {
    toprated(param: $param) @rest(type: "Products", path: "user/home/toprated?{args.param}") {
      products
    }
  }
`;

export const GET_LAUNCHPADS_UPSELL = gql`
  query ($param: String) {
    launchpads(param: $param) @rest(type: "Products", path: "user/launchpad?{args.param}") {
      products
    }
  }
`;

export const GET_FREETRIALS_UPSELL = gql`
  query ($param: String) {
    freetrials(param: $param) @rest(type: "Products", path: "user/freetrial?{args.param}") {
      products
    }
  }
`;

export const GET_DISCOUNTS = gql`
  query ($param: String) {
    discounts(param: $param) @rest(type: "Products", path: "products/discount?{args.param}") {
      totalCount
      products
    }
  }
`;

export const GET_TOPRATED_UPSELL = gql`
  query ($param: String) {
    toprated(param: $param) @rest(type: "Products", path: "user/toprated?{args.param}") {
      products
    }
  }
`;

export const GET_QUALITIES_PRODUCTS = gql`
  query ($param: String) {
    products(param: $param) @rest(type: "Products", path: "products?{args.param}") {
      totalCount
      filtergroups
      freetrialproducts
      launchpadproducts
    }
  }
`;

export const productFromWantQuery = (wantId) => gql`
  query GetProductFromWant {
    samplekits(where: {wants: {id: {_eq: ${wantId}}}}) {
      id
      name
      companyData {
        name
      }
      image: featured_image(path: "medium")
      shortSummary: short_summary
      priceValue: price_value
    }
    wants(where:{ id: {_eq: ${wantId}}}) {
      campaignData {
        id
        startDate: start_date
        discount_type
        properties
        campaignType: campaign_type
      }
      accepted
    }
  }
`;
export const productFromUserDiscountQuery = (userDiscountId) => gql`
  query GetProductFromUserDiscount {
    user_discounts(where: {id: {_eq: ${userDiscountId}}}) {
      id
      wantID: want_id
      discount {
        discountSamplekits: discount_samplekits {
          samplekit {
            id
            name
            companyData {
              name
            }
            image: featured_image(path: "medium")
            shortSummary: short_summary
            priceValue: price_value
          }
        }
        campaign {
          id
          startDate: start_date
          properties
          campaignType: campaign_type
        }
        description
        discount_type
        discount_value
        properties
        status
        times
        title
      }
    }
  }
`;

export const getProductFromWant = (wantId) =>
  clientGraphql
    .query({
      query: productFromWantQuery(wantId),
    })
    .then(({ data }) => {
      const { samplekits, wants } = data;

      if (samplekits.length && wants.length) {
        const sample = samplekits[0];
        const want = wants[0];

        return { sample, want };
      }

      return {};
    });

export const getProductFromUserDiscount = (userDiscountId) =>
  clientGraphql
    .query({
      query: productFromUserDiscountQuery(userDiscountId),
    })
    .then(({ data }) => {
      const {
        user_discounts: [
          {
            discount: { discountSamplekits, campaign, ...rest },
            wantID,
          },
        ],
      } = data;

      if (Object.keys(campaign).length && discountSamplekits.length) {
        const productData = discountSamplekits[0].samplekit;
        const want = campaign;
        const discountData = rest;
        const wantId = wantID.toString();
        return { productData, want, discountData, wantId };
      }

      return {};
    });

const getSampleKitsFromKey = (key) =>
  (getCookie(key) || '')
    .split(',')
    .filter((n) => n && !Number.isNaN(n))
    .map((n) => parseInt(n, 10));

const appendSampleKitsToKey = (key, vals) => {
  let list = getSampleKitsFromKey(key);

  list.push(...vals);
  list = Array.from(new Set(list));
  setCookie(key, list.join(','), 0.02); // 0.02 represent 30 minutes fragment of 1 day
  return list;
};

export function getProductMatchLocation() {
  const result = localStorage.getItem('cn_location') || '';
  return result.length ? result : null;
}

export function resetProductMatchCache(keepLocation) {
  if (!keepLocation) {
    localStorage.removeItem('cn_location');
  }
  localStorage.removeItem('cn_country');
  localStorage.removeItem('cn_anonymous');
}

export function setProductMatchLocation(location) {
  const oldLocation = getProductMatchLocation();
  if (location !== oldLocation) {
    resetProductMatchCache();
    localStorage.setItem('cn_location', location);
  }
}

/**
 * Check the sample kit has opened campaign near the user
 *
 * @param {number} userId
 * @param {number} sampleKitId
 * @returns {Object} nearByProduct
 * @returns {boolean} nearByProduct.isNearby - TRUE if opened campaign near the user
 * @returns {string} nearByProduct.country - "CA"
 * @returns {string} nearByProduct.location - "B4V 1N3"
 * @returns {boolean} nearByProduct.anonymous
 * @returns {number[]} nearByProduct.success - TODO: let's remove when we get a chance to refactor!
 * @returns {number[]} nearByProduct.failed - TODO: let's remove when we get a chance to refactor!
 */
export async function checkIfProductNearby(userId, sampleKitId) {
  let location = getProductMatchLocation();
  let anonymous = localStorage.getItem('cn_anonymous') === 'true';
  // Refresh the cache if user login status has changed
  if (anonymous !== !userId) {
    resetProductMatchCache(true);
  }
  let country = localStorage.getItem('cn_country');
  country = ['CA', 'US'].includes(country) ? country : null;
  let success = getSampleKitsFromKey('cn_success');
  let failed = getSampleKitsFromKey('cn_failed');

  const url = '/account/products/checknearby';

  // TODO: we need to check which location is the data from???
  const sampleKitIdToCheck = !success.includes(sampleKitId) && !failed.includes(sampleKitId);

  if (sampleKitIdToCheck) {
    const response = await fetch(url, {
      method: 'post',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        SampleKitIds: Array.isArray(sampleKitId) ? sampleKitId : [sampleKitId],
        Location: location,
      }),
    });

    try {
      const results = await response.json();
      if (results.location !== location) {
        location = results.location;
        localStorage.setItem('cn_location', location);
      }

      if (results.country !== country) {
        country = results.country;
        localStorage.setItem('cn_country', country);
      }
      if (results.anonymous !== anonymous) {
        anonymous = results.anonymous;
        localStorage.setItem('cn_anonymous', anonymous);
      }
      if (results.success && results.success.length) {
        success = appendSampleKitsToKey('cn_success', results.success);
      }
      if (results.failed && results.failed.length) {
        failed = appendSampleKitsToKey('cn_failed', results.failed);
      }
    } catch (ex) {
      handleError(ex);
    }
  }

  const results = {
    success: success.filter((sId) => sampleKitId === sId),
    failed: failed.filter((sId) => sampleKitId === sId),
    isNearby: success.includes(sampleKitId),
    location,
    country,
    anonymous,
  };
  return results;
}

const SN_V2_HOST = process.env.API_V2_URL;

export async function checkIfNearbyV2(userId, sampleKitId) {
  let location = getProductMatchLocation();
  let anonymous = localStorage.getItem('cn_anonymous') === 'true';
  // Refresh the cache if user login status has changed
  if (anonymous !== !userId) {
    resetProductMatchCache(true);
  }
  let country = localStorage.getItem('cn_country');
  country = ['CA', 'US'].includes(country) ? country : null;
  let success = getSampleKitsFromKey('cn_success');
  let failed = getSampleKitsFromKey('cn_failed');

  const sampleKitIdToCheck = !success.includes(sampleKitId) && !failed.includes(sampleKitId);

  if (sampleKitIdToCheck) {
    const response = await fetch(`${SN_V2_HOST}product/checknearby`, {
      method: 'post',
      headers: {
        Authorization: `Bearer ${getCookie('User_Token')}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        SampleKitIds: Array.isArray(sampleKitId) ? sampleKitId : [sampleKitId],
        Location: location,
      }),
    });

    try {
      const results = await response.json();
      if (results.location !== location) {
        location = results.location;
        localStorage.setItem('cn_location', location);
      }

      if (results.country !== country) {
        country = results.country;
        localStorage.setItem('cn_country', country);
      }
      if (results.anonymous !== anonymous) {
        anonymous = results.anonymous;
        localStorage.setItem('cn_anonymous', anonymous);
      }
      if (results.success && results.success.length) {
        success = appendSampleKitsToKey('cn_success', results.success);
      }
      if (results.failed && results.failed.length) {
        failed = appendSampleKitsToKey('cn_failed', results.failed);
      }
    } catch (ex) {
      handleError(ex);
    }
  }

  const results = {
    success: success.filter((sId) => sampleKitId === sId),
    failed: failed.filter((sId) => sampleKitId === sId),
    isNearby: success.includes(sampleKitId),
    location,
    country,
    anonymous,
  };
  return results;
}
