import React from 'react';
import { Button, Box, Text, ThemeContext } from 'grommet';

const TextButton = ({ icon, label, onClick, transform, weight, color }) => (
  <ThemeContext.Extend
    value={{
      text: {
        extend: () => `
							${transform && `text-transform: ${transform}`}
							&:hover {
								text-decoration: underline;
							}
						`,
      },
    }}
  >
    <Button onClick={onClick} hoverIndicator="inherit">
      <Box direction="row" align="center" gap="xsmall">
        <Text weight={weight} color={color || 'launchpad'}>
          {label}
        </Text>
        {icon}
      </Box>
    </Button>
  </ThemeContext.Extend>
);

export default TextButton;
