// @ts-check
import React from 'react';
import { Text } from 'socialnature-ui';
import { handleError } from '../../../utils/error';
import ProgressBar from '../../../Atoms/ProgressBar';

/**
 * @typedef {{value: number}} CampaignProgressBarProps
 * @param {CampaignProgressBarProps} props
 * @returns
 */
export function CampaignProgressBar({ value: valueProp }) {
  if (typeof valueProp !== 'number' || valueProp < 0) {
    handleError(
      `TypeError: Value is not valid number at CampaignProgressBar (value: ${valueProp}).`,
    );
    return <></>;
  }
  const value = Math.round(valueProp);
  return (
    <>
      <Text
        data-cy="campaign-progress-bar"
        color="pink"
        size="small"
        textAlign="end"
        weight="bold"
        style={{
          maxWidth: `${value}%`,
          minWidth: 'max-content',
        }}
      >{`${value}% Claimed`}</Text>
      <ProgressBar value={value} color="pink" />
    </>
  );
}
