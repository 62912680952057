import styled from 'styled-components';
import { themes as uiThemes } from 'socialnature-ui';

// TODO: Remove the component when don't use site.css. It's only for overriding site.css
// HOT FIX: add pointer-events due to iOS focus problem
const CustomQuestionWrapper = styled.div`
  input[type='text'],
  input[type='number'] {
    ${(props) => (props.error ? `border-color: ${uiThemes.global.colors.red};` : '')}
    border-radius: 4px;
    font-size: 17px;
    height: auto;
    margin: 0;
    pointer-events: auto;
    &:focus {
      border-color: ${uiThemes.global.colors.blue};
      background: #ffffff;
    }
  }
`;

export default CustomQuestionWrapper;
