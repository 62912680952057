import { TextInput, themes } from 'socialnature-ui';
import styled from 'styled-components';

export const StyledInput = styled(TextInput)`
  &.${(props) => props.className} {
    font-size: 16px;
    border-radius: 4px;
    box-shadow: unset;
    height: ${(props) => props.height || '48px'};
    margin: 0;
    ${({ errorText }) => errorText && `border-color: ${themes.global.colors.red};`}
    &:focus {
      border-color: ${({ errorText }) => `${themes.global.colors[errorText ? 'red' : 'blue']}`};
      background: inherit;
    }
    &::placeholder {
      color: ${themes.global.colors.iron};
    }
  }

  &.sn-hidden {
    display: none;
  }
`;
