import React from 'react';
import { ResponsiveContext } from 'grommet';
import { CarouselProvider } from 'pure-react-carousel';
import H from '../../Atoms/Heading';
import HeadingDescription from '../../Molecules/HeadingDescription';
import TopRatedCard from '../../Pages/FreeTrialPage/TopRatedCard';
import Carousel from './Carousel';

const TopRatedList = ({
  title = 'Discover top-rated products',
  description,
  products,
  dataTrackCategory,
}) => {
  const [heightCard, setHeight] = React.useState(0);
  const [widthCard, setWidth] = React.useState(0);
  const measuredRef = React.useCallback((node) => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  const size = React.useContext(ResponsiveContext);
  const slides = {
    xsmall: 2,
    small: 2,
    medium: 3,
    large: 4,
  };

  if (!products) return <></>;

  return (
    <>
      <H level={2}>{title}</H>
      {description && <HeadingDescription>{description}</HeadingDescription>}
      <CarouselProvider
        naturalSlideWidth={Math.round(widthCard)}
        naturalSlideHeight={Math.round(heightCard) + 32}
        totalSlides={products.length}
        lockOnWindowScroll
        step={slides[size]}
        visibleSlides={slides[size]}
      >
        <Carousel
          products={products}
          size={size}
          measuredRef={measuredRef}
          renderSlide={(product, index) => (
            <TopRatedCard
              product={product}
              width="100%"
              height="100%"
              dataTrackCategory={dataTrackCategory}
              index={index}
            />
          )}
        />
      </CarouselProvider>
    </>
  );
};

export default TopRatedList;
