import React, { useEffect, useState } from 'react';
import { Box, ResponsiveContext, Image } from 'grommet';
import H from '../../Atoms/Heading';
import P from '../../Atoms/Paragraph';
import ProductHeaderFooter from './ProductHeaderFooter';
import CardBadge from '../../Atoms/Card/CardBadge';
import { campaignTypes } from '../../utils/campaignTypes';
import { SplitNames, isFeatureOn } from '../../utils/splitio';
import { rebateFreebieLabel } from '../../utils/product/getBadge/freebieLabel';
import { discountTypes } from '../../utils/discountTypes';
import { getCashBackFormattedPrice } from '../../utils/product';

function getBoxMarginBasedOnCampaignType(type, isXsmall) {
  switch (type) {
    case campaignTypes.REBATE:
      return isXsmall ? '164px' : '178px';
    default:
      return isXsmall ? '128px' : '178px';
  }
}

const badgeLabel = (campaign, isUpdateLabelFF) => {
  const {
    properties: { offerDiscountPercentage, bogo, moneyCashBackValue },
  } = campaign;
  const discountType = (campaign?.discountType || '').toUpperCase();

  if (discountType === discountTypes.BOGO) {
    return bogo?.label;
  }
  if (discountType === discountTypes.MONEY_CASH_BACK) {
    return `$${getCashBackFormattedPrice(moneyCashBackValue)} OFF`;
  }
  if (isUpdateLabelFF) {
    return rebateFreebieLabel(offerDiscountPercentage).toUpperCase();
  }

  return `${offerDiscountPercentage}% OFF`;
};

const ProductHeader = (props) => {
  const {
    userId,
    product,
    title,
    description,
    isAccepted,
    showSNSButtons = true,
    dataTrackCategory,
    isDescriptionAsContainer = false,
    campaign,
  } = props;
  const { slug, name, featuredImage } = product || {};
  const productUrl = `/${slug}`;
  const size = React.useContext(ResponsiveContext);
  const [isUpdateLabelFF, setIsUpdateLabelFF] = useState(false);

  useEffect(() => {
    isFeatureOn(SplitNames.changeCashBackLabelToFreeProduct).then(setIsUpdateLabelFF);
  }, []);

  const isXsmall = size === 'xsmall';
  const { type, properties } = campaign || {};
  const isRebateCampaign = type === campaignTypes.REBATE;
  const boxMargin = getBoxMarginBasedOnCampaignType(type, isXsmall);

  return (
    <Box
      direction={isXsmall ? 'column' : 'row'}
      align={isXsmall ? 'center' : 'start'}
      gap="medium"
      pad={isXsmall ? { vertical: '32px' } : { vertical: '56px' }}
    >
      {featuredImage && (
        <a href={productUrl}>
          <Box
            width={boxMargin}
            height={boxMargin}
            round="8px"
            elevation="smallImage"
            pad="small"
            background="#FFFFFF"
            style={{ position: 'relative' }}
          >
            <Image
              fit="cover"
              src={featuredImage.medium}
              alt={name}
              style={{ marginTop: isRebateCampaign ? '24px' : 'unset' }}
            />

            {type === campaignTypes.REBATE && properties && properties.offerDiscountPercentage && (
              <CardBadge text={badgeLabel(campaign, isUpdateLabelFF)} color="pink" smallTop={12} />
            )}
          </Box>
        </a>
      )}
      <Box>
        <H level={2} textAlign={isXsmall ? 'center' : 'start'}>
          {title}
        </H>
        {description &&
          (isDescriptionAsContainer ? (
            <Box textAlign={isXsmall ? 'center' : 'start'}>{description}</Box>
          ) : (
            <P textAlign={isXsmall ? 'center' : 'start'}>{description}</P>
          ))}
        {showSNSButtons && (
          <ProductHeaderFooter
            isXsmall={isXsmall}
            isDescriptionAsContainer={isDescriptionAsContainer}
            userId={userId}
            product={product}
            isAccepted={isAccepted}
            dataTrackCategory={dataTrackCategory}
          />
        )}
      </Box>
    </Box>
  );
};

export default ProductHeader;
