const socialShareDefaultMessage = 'Check out this awesome product from Social Nature!';

export function getShareUrl(product, userId) {
  const baseUrl = process.env.BASE_URL;
  const { slug, id } = product;
  let url = `${baseUrl}${slug}?social={0}`;
  if (userId) {
    url += `&user_referrer=${userId}&user_referral_channel={0}`;
  }
  url += `&product=${id}`;
  return url;
}

export function getReferralLink(userId, channel) {
  let tracking = document.cookie.match('(^|;)\\s*User_TrackingID\\s*=\\s*([^;]+)');
  tracking = tracking ? tracking.pop().split('-')[0] : '';

  return `friend-${userId}-${tracking}-${channel}`;
}

export function getInviteUrl(userId, channel) {
  const baseUrl = process.env.BASE_URL;
  const url = `${baseUrl}friend-referral/?referrer=${getReferralLink(userId, channel)}`;
  return url;
}

export function getEmailText(product, userId) {
  const getBody = () => {
    const { name, company } = product;
    const { name: companyName } = company || {};
    const shareUrl = getShareUrl(product, userId);

    return `I just discovered these new ${name || 'product'} from ${
      companyName || 'good brand'
    } on @socialnature and thought you’d like it. Social Nature is a website that helps you discover natural and sustainable products for your next shopping trip, and sometimes they offer free product samples. Thought you would like to check them out! ${shareUrl.replace(
      /\{0\}/g,
      'email',
    )}`;
  };

  const subject = "I'd like to invite you to #trynatural";
  const body = getBody();

  return {
    subject,
    body,
  };
}

export function getSocialShareData(messages, id, slug, name, companyName, image, userId) {
  const { twitter = [socialShareDefaultMessage], pinterest = [socialShareDefaultMessage] } =
    messages || {};

  return {
    sampleKit: {
      id,
      name,
      companyName,
      slug,
    },
    shareUrl: getShareUrl({ slug, id }, userId),
    sampleKitFeaturedImage: image,
    pretrialTwitterShareMessage: twitter[0],
    pretrialPinterestShareMessage: pinterest[0],
  };
}

export function getDataset(obj) {
  if (typeof obj !== 'object') return {};

  return Object.fromEntries(Object.entries(obj).filter(([key]) => key.match(/^data-.+/) !== null));
}
