// @ts-check
import React from 'react';
import styled from 'styled-components';
import basicTheme from '../../themes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 8px;
  gap: 10px;

  width: 149px;
  height: 22px;
  background: ${basicTheme.global.colors.lightPink};
  border-radius: 4px;
`;

const StyledLabel = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-family: ${basicTheme.global.font.family};
  line-height: 14px;

  text-transform: capitalize;

  color: ${basicTheme.global.colors.pink};
`;

/**
 * @typedef {{
 *  label: string,
 * style: import('styled-components').CSSObject
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function BasicLabel({ label, style }) {
  return (
    <Wrapper style={style}>
      <StyledLabel>{label}</StyledLabel>
    </Wrapper>
  );
}
