import React from 'react';
import styled from 'styled-components';
import { Box, ResponsiveContext } from 'grommet';
import basicTheme from '../../themes';

const Card = ({ width, children, height, pad, margin, checked }) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <Box pad={pad || 'card'} margin={margin || '0px'} width={width} height={height}>
      <CardStyle isXsmall={isXsmall} checked={checked}>
        {children}
      </CardStyle>
    </Box>
  );
};

export default Card;

const CardStyle = styled.div`
  display: flex;
  box-sizing: border-box;
  outline: none;
  max-width: 100%;
  margin: 0px;
  background: #ffffff;
  border: solid 2px transparent;
  flex-direction: column;
  height: 100%;
  padding: 0px;
  border-radius: 8px;
  border-color: ${(props) => (props.checked ? basicTheme.global.colors.launchpad : '')};
  box-shadow: ${(props) =>
    props.isXsmall ? '0px 4px 16px rgba(0, 0, 0, 0.05)' : '0px 8px 16px rgba(0,0,0,0.1)'};
  position: relative;
  &:hover {
    border-color: ${(props) =>
      props.checked ? basicTheme.global.colors.launchpad : basicTheme.global.colors.silver};
    box-shadow: ${(props) => (props.isXsmall ? '' : '0px 0px 8px rgba(0,0,0,0.1)')};
  }
`;
