import React from 'react';
import { ResponsiveContext } from 'grommet';
import InviteFriendsButton from '../../../Atoms/WithoutGroomet/Buttons/InviteFriendsButton';
import SocialShareModal from '../../../Organisms/Product/SocialShareModal';

const InviteFriendsPopUp = (props) => {
  const { userId, product, isAccepted, dataTrackCategory } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <InviteFriendsButton
        isXsmall={isXsmall}
        onClick={() => setOpen(true)}
        icon={<img alt="gift-icon" src="/images/congratsPage/gift.svg" />}
        label="Invite Friends & Win"
      />
      {open && (
        <SocialShareModal
          open={open}
          userId={userId}
          product={product}
          isAccepted={isAccepted}
          onClose={() => setOpen(false)}
          dataTrackCategory={dataTrackCategory}
        />
      )}
    </>
  );
};

export default InviteFriendsPopUp;
