// @ts-check
import React from 'react';
import styled from 'styled-components';
import { themes } from 'socialnature-ui';
import { handleError } from '../../utils/error';

/**
 * @typedef {{
 * marginBottom: Array,
 * }} StylesObjectProps
 * @typedef {{
 * styles: StylesObjectProps,
 * }} WrapperProps
 * @returns
 */
const Wrapper =
  /** @type {import('styled-components').ThemedStyledFunction<'div', WrapperProps>} */
  (styled.ul)`
    margin-left: 0px;
    li:last-child {
      margin-bottom: ${(props) =>
        props.styles && props.styles.marginBottom
          ? props.styles.marginBottom
          : themes.global.edgeSize.small};
    }
  `;

const StyledLi = styled.li`
  list-style: none;
  border-bottom: 1px solid ${themes.global.colors.silver};
  padding-bottom: ${themes.global.edgeSize.small};
  margin-bottom: ${themes.global.edgeSize.small};
`;

/**
 * @typedef {{
 * data: Array,
 * renderItem: Function,
 * styles?: StylesObjectProps,
 * }} StoreListProps
 * @param {StoreListProps} StoreListProps
 * @returns
 */
export default function List({ data, renderItem, styles }) {
  if (!Array.isArray(data)) return null;
  if (!renderItem || typeof renderItem !== 'function') return null;
  const isValidData = data.every(
    (item) => item && typeof item === 'object' && Object.prototype.hasOwnProperty.call(item, 'id'),
  );
  if (!isValidData) {
    handleError('List: Invalid data. Please have an id property for each item.');
    return null;
  }

  const items = data.map((d, index) => {
    const key = `list-item-${d.id}`;
    return <StyledLi key={key}>{renderItem(d, index + 1)}</StyledLi>;
  });
  return (
    <Wrapper aria-label="stores" styles={styles}>
      {items}
    </Wrapper>
  );
}
