// @ts-check

/**
 * @param {number=} offerDiscountPercentage
 * @returns {string}
 */
const ONE_HUNDRED_PERCENTAGE = 100;

export function rebateFreebieLabel(
  offerDiscountPercentage,
  anotherLabel = `${offerDiscountPercentage}% off`,
  discountType = 'percentage',
) {
  return offerDiscountPercentage === ONE_HUNDRED_PERCENTAGE && discountType !== 'bogo'
    ? 'Free product'
    : anotherLabel;
}
