import React from 'react';
import ErrorPage from '../ErrorPage';
import Loading from '../../Molecules/Loading';
import useProfileQuestions from '../../hooks/useProfileQuestions';
import StyledProfilePage from './StyledProfilePage';
import { handleError } from '../../utils/error';

const ProfilePage = ({ data: userInformation }) => {
  const { loading, error, questions } = useProfileQuestions();

  if (loading) return <Loading />;
  if (error) {
    handleError(error);
    return <ErrorPage />;
  }

  return <StyledProfilePage userInformation={userInformation} questions={questions} />;
};
export default ProfilePage;
