import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { getUserToken } from '../../userId';

const httpLink = createHttpLink({
  uri: process.env.GRAPHQL_URL,
});

/**
 * Define the User role.
 * src/SocialNature.Web/Business/Managers/UserManager.cs
 */
const authLink = setContext((_, { headers }) => {
  if (headers) {
    return { headers };
  }
  const token = getUserToken();
  if (token) {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'X-Hasura-Role': 'sn-user',
      },
    };
  }
  return {
    headers: {
      'X-Hasura-Role': 'sn-public',
    },
  };
});

export const hasuraLink = authLink.concat(httpLink);

const client = new ApolloClient({
  link: hasuraLink,
  cache: new InMemoryCache(),
});

export default client;
