import React from 'react';

const Share = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.6667 15.2043C16.0756 15.2043 15.5467 15.4376 15.1422 15.8032L9.59667 12.5754C9.63556 12.3965 9.66667 12.2176 9.66667 12.031C9.66667 11.8443 9.63556 11.6654 9.59667 11.4865L15.08 8.28984C15.5 8.67873 16.0522 8.91984 16.6667 8.91984C17.9578 8.91984 19 7.87762 19 6.58651C19 5.2954 17.9578 4.25317 16.6667 4.25317C15.3756 4.25317 14.3333 5.2954 14.3333 6.58651C14.3333 6.77317 14.3644 6.95206 14.4033 7.13095L8.92 10.3276C8.5 9.93873 7.94778 9.69762 7.33333 9.69762C6.04222 9.69762 5 10.7398 5 12.031C5 13.3221 6.04222 14.3643 7.33333 14.3643C7.94778 14.3643 8.5 14.1232 8.92 13.7343L14.4578 16.9698C14.4189 17.1332 14.3956 17.3043 14.3956 17.4754C14.3956 18.7276 15.4144 19.7465 16.6667 19.7465C17.9189 19.7465 18.9378 18.7276 18.9378 17.4754C18.9378 16.2232 17.9189 15.2043 16.6667 15.2043Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default Share;
