// @ts-check
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ResponsiveContext } from 'grommet';
import styled from 'styled-components';
import { useModal } from '../useModal';
import { SmsOptinPopupForm } from '../../Organisms/SmsOptinPopup/SmsOptinPopupForm';
import { trackEvent } from '../../utils/gtm';
import { TrackingEventType } from '../../utils/tracking';
import { getCookie } from '../../utils/cookie';
import { cleanUserNotifications, getUserFromTrackingId } from '../../utils/apis/users';
import clientGraphql from '../../utils/apis/graphql';
import { formatPhoneNumber } from '../../utils/number';
import CheckYourText from '../../Organisms/SmsConsentAnnouncement/CheckYourText';

const Wrapper = styled.div`
  h1,
  h2,
  h3,
  h5 {
    font-family: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }
`;

/**
 * @param {boolean} initOpen
 * @param {string} userTrackingId
 */
export function useSmsOptInModal(initOpen, userTrackingId, notificationCallback) {
  const { Modal, open, close } = useModal(initOpen);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [userId, setUserId] = useState(null);
  const [showForm, setShowForm] = useState(true);

  const handleOnClose = useCallback(() => {
    trackEvent(TrackingEventType.SMS_OPTIN.POP_UP.SMS_OPTIN_CLOSE, {
      signinType: getCookie('signin_type'),
    });
    close();
    cleanUserNotifications();
  }, [close]);

  useEffect(() => {
    if (userTrackingId) {
      clientGraphql
        .query({
          query: getUserFromTrackingId(userTrackingId),
        })
        .then(({ data }) => {
          const { users } = data;
          if (users[0]) {
            const user = users[0];
            const { phone, sms_optin: optin, properties: userProperties } = user;

            if (optin && userProperties && userProperties.smsDoubleOptin) {
              handleOnClose();
              cleanUserNotifications();
            }

            if (phone && phone.length) {
              setPhoneNumber(formatPhoneNumber(phone));
            }
            setUserId(user.id);
          }
        });
    }
  }, [handleOnClose, userTrackingId]);

  const handleShowForm = () => setShowForm(false);

  const SmsOptinModal = () => (
    <Modal
      animation="slide"
      style={{
        maxWidth: isXsmall ? 'calc(100% - 32px)' : '382px',
        width: isXsmall ? '360px' : '382px',
        padding: isXsmall ? '40px 16px' : '40px 24px',
        overflowY: 'scroll',
      }}
      onClose={handleOnClose}
    >
      <Wrapper>
        {showForm ? (
          <SmsOptinPopupForm
            handleAction={handleOnClose}
            isXsmall={isXsmall}
            notificationCallback={notificationCallback}
            showMaybeLaterSection
            trackEventLabelType="smsConsentPopup"
            userId={userId}
            userPhoneNumber={phoneNumber}
            optinType="POP_UP"
            handleOnSubmit={handleShowForm}
          />
        ) : (
          <CheckYourText height="100%" />
        )}
      </Wrapper>
    </Modal>
  );

  return { SmsOptinModal, open, handleOnClose };
}
