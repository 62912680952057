import React from 'react';
import { Box, Text } from 'grommet';
import { Slider, Slide, ButtonBack, ButtonNext, WithStore } from 'pure-react-carousel';
import ArrowLeft from '../../Atoms/Icons/ArrowLeft';
import ArrowRight from '../../Atoms/Icons/ArrowRight';
import Cards from '../../Atoms/Card/Cards';

class Carousel extends React.Component {
  render() {
    const { currentSlide, totalSlides, visibleSlides, products, size, measuredRef, renderSlide } =
      this.props;
    return (
      <>
        <Box className="carousel__navigation" direction="row" align="center" justify="end">
          <Text>
            {`Page ${Math.floor(currentSlide / visibleSlides) + 1} of ${Math.ceil(
              totalSlides / visibleSlides,
            )}`}
          </Text>
          <ButtonBack>
            <ArrowLeft color="black" />
          </ButtonBack>
          <ButtonNext>
            <ArrowRight color="black" />
          </ButtonNext>
        </Box>

        <Cards>
          <Box
            pad={size === 'xsmall' || size === 'small' ? { bottom: 'medium' } : { bottom: 'small' }}
            overflow={{ horizontal: 'hidden' }}
          >
            <Slider>
              {products.map((product, index) => (
                <Slide key={product.slug} index={index}>
                  <div ref={measuredRef}>{renderSlide(product, index)}</div>
                </Slide>
              ))}
            </Slider>
          </Box>
        </Cards>
      </>
    );
  }
}

export default WithStore(Carousel, (state) => ({
  currentSlide: state.currentSlide,
  totalSlides: state.totalSlides,
  visibleSlides: state.visibleSlides,
}));
