import { useEffect, useState } from 'react';
import { locateStoresNearLocationByCampaignId } from '../../utils/apis/campaigns';

export function useCampaignNearRetailers(campaignId) {
  const [retailers, setRetailers] = useState([]);

  useEffect(() => {
    if (campaignId) {
      const distance = 24000; // 24km
      locateStoresNearLocationByCampaignId(campaignId, distance).then((stores) => {
        if ((stores || []).length > 0) {
          setRetailers(stores);
        }
      });
    }
  }, [campaignId]);

  return retailers;
}
