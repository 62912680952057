import React, { useState } from 'react';
import CommonAddressForm from '../../Organisms/AddressForm';
import { postAddress } from '../../utils/apis/users';
import { TrackingEventType } from '../../utils/tracking';
import DuplicateAddress from '../../Organisms/AddressForm/DuplicatedAddress';
import { handleError } from '../../utils/error';
import ErrorPage from '../ErrorPage';
import { campaignTypes } from '../../utils/campaignTypes';
import { getCookie, setCookie } from '../../utils/cookie';
import { resetProductMatchCache } from '../../utils/apis/products';

export default function AddressForm(props) {
  const postAPI = '/product/acceptinvite';
  const modelType = 'Accept Invite';

  const { data } = props;
  const [errors, setErrors] = useState(null);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [showDuplicateAddressPage, setShowDuplicateAddressPage] = useState(false);

  const { sampleKitUrl, sampleKitName, campaignType, wantId, sampleKitId, campaignId, smsOptin } =
    data;
  const submitButtonLabel = `send my ${
    campaignType === campaignTypes.IN_STORE ? 'voucher' : 'product'
  }`;
  const tracking = {
    tagmanager: modelType,
    category: 'Click',
    action: modelType,
    label: sampleKitName,
  };

  const handleSubmit = (formData) => {
    const { smsOptin: smsOptinForm } = formData;
    if ((smsOptin === null || smsOptin === false) && smsOptinForm === true) {
      setCookie('showCheckYourTextModal', true, 1);
    }
    postAddress(postAPI, {
      ...formData,
      WantId: wantId,
      SampleKitId: sampleKitId,
      CampaignId: campaignId,
    })
      .then((response) => {
        resetProductMatchCache();
        if (window.dataLayer) {
          window.dataLayer.push({
            event: TrackingEventType.ADDRESS_CONFIRMATION_COMPLETE,
            parameter: {
              provider: getCookie('signin_provider'),
              signinType: getCookie('signin_type'),
              sampleKitName,
            },
          });
        }
        switch (response) {
          case 'CampaignDuplicateAddress':
            setShowDuplicateAddressPage(true);
            break;
          case 'Waitlisted':
            window.location.href = `/invitepending${sampleKitUrl}`;
            break;
          case 'CampaignFull':
          default:
            window.location.href = window.location.pathname;
        }
      })
      .catch((err) => {
        if (err.status === 400) {
          err.json().then((e) => {
            setErrors(e);
          });
          return;
        }
        handleError(errors);
        setShowErrorPage(true);
      });
  };

  if (showErrorPage) return <ErrorPage />;
  if (showDuplicateAddressPage) {
    return <DuplicateAddress />;
  }

  return (
    <CommonAddressForm
      data={data}
      onSubmit={handleSubmit}
      submitButtonLabel={submitButtonLabel}
      isAcceptReview
      tracking={tracking}
      serverErrors={errors}
      isSmsConsent
    />
  );
}
