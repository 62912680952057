import React from 'react';
import styled from 'styled-components';
import { PrimaryButton, themes } from 'socialnature-ui';
import { Box, ResponsiveContext, Image, Grommet } from 'grommet';
import { deepMerge } from 'grommet/utils';
import H from '../../Atoms/Heading';
import P from '../../Atoms/Paragraph';
import Hr from '../../Atoms/Hr';
import SocialShareButtons from './SocialShareButtons';
import ShareButton from '../../Molecules/SocialShare/Share/ShareButtonPopup';
import FBMessengerIcon from '../../Atoms/Icons/sns/FBMessenger';
import getUserId from '../../utils/userId';

const FBMessengerButtonTheme = deepMerge(themes, {
  button: {
    padding: {
      vertical: '6px',
      horizontal: '16px',
    },
    extend: ({ sizeProp, theme }) =>
      `${themes.button.extend({ sizeProp, theme })} &:hover, &:focus {color: ${
        themes.global.colors.white
      };}`,
  },
});

const ShareButtonsPosition = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const ProductHeaderWithFB = (props) => {
  const {
    wantId,
    userId,
    product,
    title,
    description,
    isEmailForm,
    isAccepted,
    dataTrackCategory,
  } = props;
  const { slug, name, featuredImage, sharingMessages } = product || {};
  const { twitter } = (sharingMessages && sharingMessages.pretrial) || {};
  const productUrl = `/${slug}`;
  const ref = `${process.env.FB_ACCEPTED_CAMPAIGN_REF_PARAM}--${getUserId()}:${wantId}`;
  const FBPageUrl = `https://m.me/SocialNature?ref=${ref}`;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <Box>
      <Box width={{ max: '1000px' }}>
        <Box
          direction={isXsmall ? 'column' : 'row'}
          align={isXsmall ? 'center' : 'start'}
          gap="medium"
          pad={{ vertical: 'xlarge' }}
          width={{ max: isXsmall ? '100%' : 'calc(100% - 100px)' }}
        >
          {featuredImage && (
            <a href={productUrl}>
              <Box
                width={isXsmall ? '128px' : '178px'}
                height={isXsmall ? '128px' : '178px'}
                round="8px"
                elevation="smallImage"
                pad="small"
                background="#FFFFFF"
              >
                <Image fit="cover" src={featuredImage.medium} alt={name} />
              </Box>
            </a>
          )}
          <Box>
            <H level={2} textAlign={isXsmall ? 'center' : 'start'}>
              {title}
            </H>
            {description && <P textAlign={isXsmall ? 'center' : 'start'}>{description}</P>}
            <Hr margin={isXsmall ? '24px 0px' : '16px 0px'} />
            {isXsmall && (
              <Box margin={{ top: 'medium' }} align={isXsmall ? 'center' : 'start'}>
                <ShareButton
                  userId={userId}
                  product={product}
                  isAccepted={isAccepted}
                  dataTrackCategory={dataTrackCategory}
                />
              </Box>
            )}
            <Hr />
            <P textAlign={isXsmall ? 'center' : 'start'} weight="bold">
              Get shipping updates directly on Facebook Messenger.
            </P>
            <Grommet theme={FBMessengerButtonTheme}>
              <Box
                direction="row"
                wrap
                margin={{ vertical: 'small' }}
                justify={isXsmall ? 'center' : 'start'}
              >
                <PrimaryButton
                  icon={<FBMessengerIcon />}
                  label="Notify Me"
                  gap="xsmall"
                  href={FBPageUrl}
                  target="_blank"
                />
              </Box>
            </Grommet>
          </Box>
        </Box>
      </Box>
      {!isXsmall && (
        <ShareButtonsPosition>
          <Box pad={{ vertical: 'xlarge' }} align="end">
            <P weight="bold">Share it:</P>
            <Box direction="column" wrap margin={{ vertical: 'xsmall' }}>
              <SocialShareButtons
                userId={userId}
                product={product}
                twitterMessage={twitter && twitter[0]}
                isEmailForm={isEmailForm}
                isAccepted={isAccepted}
                dataTrackCategory={dataTrackCategory}
                variant="round"
              />
            </Box>
          </Box>
        </ShareButtonsPosition>
      )}
    </Box>
  );
};

export default ProductHeaderWithFB;
