import React from 'react';
import { TextLink } from 'socialnature-ui';
import { Question } from 'socialnature-ui/build/icons';

/**
 * @typedef {{
 * onClick: () => void,
 * iconSize: string,
 * margin: Record<string, string>,
 * size=: number
 * showIcon: boolean
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function HowToGetCashBackButton({
  onClick,
  iconSize,
  margin,
  size,
  showIcon = true,
}) {
  return (
    <TextLink
      size={size}
      icon={
        showIcon ? (
          <Question as="span" width={iconSize} height={iconSize} color="launchpad" />
        ) : null
      }
      label="How to get cash back"
      fontWeight="bold"
      onClick={onClick}
      margin={margin}
    />
  );
}
