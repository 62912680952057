// @ts-check
import React from 'react';
import { PrimaryButton } from 'socialnature-ui';

/**
 * @typedef {{
 * href: string,
 * productId: number,
 * productSlug: string,
 * label?: string,
 * }} Props
 * @param {Props} props
 * @returns
 */
export default function SignUp(props) {
  const { href, productId, productSlug, label } = props;
  return (
    <PrimaryButton
      label={label || "Sign Up"}
      href={href}
      color="pink"
      size="small"
      gap="xsmall"
      data-track-category="Product-Button"
      data-track-action="Sign up"
      data-track-samplekit-id={productId}
      data-track-label={productSlug}
    />
  );
}
