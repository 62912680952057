// @ts-check
import React, { useState, useContext } from 'react';
import { Box, Text, themes } from 'socialnature-ui';
import { ResponsiveContext } from 'grommet';
import styled from 'styled-components';

export const buttonLabels = {
  back: 'BACK',
  next: 'NEXT',
  done: 'FINISH',
};

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  place-content: space-between;
  margin-top: ${({ isXsmall }) => (isXsmall ? '24px' : 'unset')};
  margin-bottom: 8px;
  position: sticky;
  background-color: white;
  height: 56px;
  align-items: center;
  ${({ isXsmall }) => (isXsmall ? 'bottom: 0;' : '')}
`;

const Button = styled(Text)`
  color: ${themes.global.colors.blue};
  font-weight: 700;
  cursor: pointer;
  ${({ isFirstStep }) =>
    isFirstStep &&
    `
		position: absolute;
		right: 0;
	`}
`;

const Dot = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 25px;
  background-color: ${({ active }) => (active ? themes.global.colors.brand : '#D9D9D9')};
  margin: 0 5px;
`;

const DotsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${({ isFirstStep }) =>
    isFirstStep &&
    `
		margin: 0 auto;
		height: 24px;
	`};
`;

/**
 * @typedef {{
 * steps: Array,
 * initStep?: number,
 * labels?: Labels,
 * close: () => void,
 * }} Props
 * @param {Props} props
 * @typedef {{
 * back: string,
 * next: string,
 * done: string,
 * }} Labels
 * @returns
 */

export function Instructions({ steps = [], initStep = 0, labels = buttonLabels, close }) {
  const [stepIndex, setStepIndex] = useState(initStep);
  const size = useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  if (!steps || !steps.length) {
    return null;
  }

  const isFirstStep = stepIndex === 0;
  const isLastStep = stepIndex === steps.length - 1;
  const currentStep = steps[stepIndex];

  if (!currentStep) {
    return null;
  }

  return (
    <>
      {currentStep}
      <Wrapper isXsmall={isXsmall}>
        {!isFirstStep && (
          <Button data-testid="prevButton" onClick={() => setStepIndex((prevStep) => prevStep - 1)}>
            {labels.back}
          </Button>
        )}
        <DotsWrapper isFirstStep={isFirstStep}>
          {steps.map((step, index) => (
            <Dot key={step.props.title || step.key} active={index === stepIndex} />
          ))}
        </DotsWrapper>
        <Button
          isFirstStep={isFirstStep}
          data-testid="nextButton"
          onClick={() => (isLastStep ? close() : setStepIndex((prevStep) => prevStep + 1))}
        >
          {isLastStep ? labels.done : labels.next}
        </Button>
      </Wrapper>
    </>
  );
}
