import React from 'react';
import styled from 'styled-components';
import { Button, Layer, ResponsiveContext, Box } from 'grommet';
import Cross from '../Atoms/Icons/Cross';

// to remove weird style RED color when button is focused
const StyledButton = styled(Button)`
  &#${({ id }) => id}:focus {
    background-color: transparent;
  }
`;

const SimpleModal = (props) => {
  const { open, children, full, position, margin, onClose } = props;
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';
  // 56px: Remove address bar on mobile
  const height = full ? `${window.innerHeight - 56}px` : 'auto';

  return (
    <>
      {open && (
        <Layer
          modal
          animation="fadeIn"
          responsive={false}
          onEsc={onClose}
          onClickOutside={onClose}
          position={position || 'center'}
        >
          <Box margin={margin}>
            <Wrapper isXsmall={isXsmall} height={height}>
              {children}
              <CloseButton>
                <StyledButton
                  plain
                  icon={<Cross width="32" height="32" color="black" titleAccess="close" />}
                  hoverIndicator="transparent"
                  onClick={onClose}
                  id="closeButton"
                />
              </CloseButton>
            </Wrapper>
          </Box>
        </Layer>
      )}
    </>
  );
};

export default SimpleModal;

export const ModalContentFull = ({ children }) => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return <ModalContentFullStyle isXsmall={isXsmall}>{children}</ModalContentFullStyle>;
};

const ModalContentFullStyle = styled.div`
  margin-left: ${(props) => (props.isXsmall ? '-16px' : '-24px')};
  margin-right: ${(props) => (props.isXsmall ? '-16px' : '-24px')};
`;

const Wrapper = styled.div`
  position: relative;
  width: 798px;
  max-width: calc(100% - 32px);
  min-width: 200px;
  overflow: auto;
  margin: 16px auto;
  padding: ${(props) => (props.isXsmall ? '48px 16px 24px' : '56px 12px 40px')};
  background-color: #ffffff;
  border-radius: 8px;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none; /* Firefox 64 hide scrollbars*/
`;

const CloseButton = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
`;
