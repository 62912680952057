import React from 'react';
import styled from 'styled-components';

const BadgeStyle = styled.div`
  position: relative;
  display: flex;
  height: 24px;
  align-content: center;
  align-items: center;
  white-space: nowrap;
  min-width: 72px;
  text-align: center;
  padding: 0 14px 0 8px;
  background: ${(props) => `url('/images/badge-${props.colorProp}.svg')`} center right no-repeat;
  background-size: cover;
`;

const BadgeText = styled.span`
  position: relative;
  color: #ffffff;
  font-size: 12px;
  z-index: 3;
  font-weight: bold;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  line-height: 1;
  text-transform: uppercase;
`;

const Badge = ({ text, color }) => (
  <BadgeStyle colorProp={color || 'pink'}>
    <BadgeText>{text}</BadgeText>
  </BadgeStyle>
);

export default Badge;
