import React from 'react';
import { Text } from 'socialnature-ui';
import { Box } from 'grommet';
import Container from '../../Atoms/Container';
import HeadingDescription from '../../Molecules/HeadingDescription';
import MessageBox from '../../Molecules/MessageBox';

const ErrorPage = ({ detail }) => (
  <Box pad={{ vertical: 'xxlarge' }}>
    <Container>
      <MessageBox
        imageUrl="/images/error-brand.svg"
        title="Oops! Something went wrong"
        buttonLabel="go to homepage"
        href="/"
      >
        <HeadingDescription textAlign="center">
          The page you’re looking for might have been removed, or it’s temporarily unavailable.
        </HeadingDescription>
        {!!detail && (
          <Text margin={{ top: 'small' }} textAlign="center">
            {detail}
          </Text>
        )}
      </MessageBox>
    </Container>
  </Box>
);

export default ErrorPage;
