import React from 'react';
import styled from 'styled-components';
import { Box, ResponsiveContext } from 'grommet';

const TwoColumns = ({ children, reverse }) => {
  const size = React.useContext(ResponsiveContext);
  const direction = {
    xsmall: 'column',
    small: 'column',
    medium: 'column',
    large: 'row',
  };
  const directionReverse = {
    xsmall: 'column-reverse',
    small: 'column-reverse',
    medium: 'column-reverse',
    large: 'row',
  };

  return <Box direction={reverse ? directionReverse[size] : direction[size]}>{children}</Box>;
};

export const Main = ({ children }) => <Box flex={{ grow: 1, shrink: 1 }}>{children}</Box>;

export const Side = ({ children, reverse }) => {
  const size = React.useContext(ResponsiveContext);
  const isDesktop = size === 'large';
  return (
    <Box width={{ min: '25%' }}>
      <Sidebar isDesktop={isDesktop} reverse={reverse}>
        {children}
      </Sidebar>
    </Box>
  );
};

const Sidebar = styled.div`
  margin-right: ${(props) => (props.isDesktop && !props.reverse ? '32px' : '0')};
  margin-left: ${(props) => (props.isDesktop && props.reverse ? '32px' : '0')};
  margin-bottom: ${(props) => (props.isDesktop ? '0' : '32px')};
`;

export default TwoColumns;
