import React from 'react';
import createSvgIcon from './SvgIcon';

export default createSvgIcon(
  <>
    <path d="M21 7H18.2C18.7 6.5 19 5.8 19 5C19 3.3 17.7 2 16 2C15 2 13.2 2.9 12 4.1C10.8 2.9 9 2 8 2C6.3 2 5 3.3 5 5C5 5.8 5.3 6.5 5.8 7H3C2.4 7 2 7.4 2 8V10.8C2 10.9 2.1 11 2.2 11H10.6C10.8 11 11 10.8 11 10.6V7.4C11 7.2 11.2 7 11.4 7H12.6C12.8 7 13 7.2 13 7.4V10.6C13 10.8 13.2 11 13.4 11H21.8C21.9 11 22 10.9 22 10.8V8C22 7.4 21.6 7 21 7ZM8 6C7.4 6 7 5.6 7 5C7 4.4 7.4 4 8 4C8.6 4 10.4 5.2 10.9 5.8C10.6 5.9 8 6 8 6ZM16 6C16 6 13.4 5.9 13.1 5.8C13.7 5.1 15.4 4 16 4C16.6 4 17 4.4 17 5C17 5.6 16.6 6 16 6Z" />
    <path d="M10.6001 13H3.20006C3.10006 13 3.00006 13.1 3.00006 13.2V21C3.00006 21.6 3.40006 22 4.00006 22H10.6001C10.8001 22 11.0001 21.8 11.0001 21.6V13.4C11.0001 13.2 10.8001 13 10.6001 13Z" />
    <path d="M20.8 13H13.4C13.2 13 13 13.2 13 13.4V21.6C13 21.8 13.2 22 13.4 22H20C20.6 22 21 21.6 21 21V13.2C21 13.1 20.9 13 20.8 13Z" />
  </>,
  'Gift',
);
