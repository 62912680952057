import gql from 'graphql-tag';

export const GET_QUALITY_NAME = gql`
  query ($slug: String) {
    quality(slug: $slug) @rest(type: "String", path: "quality/{args.slug}") {
      name
    }
  }
`;

export const GET_FEATURED_QUALITIES = gql`
  query {
    qualities @rest(type: "Qualities", path: "featuredqualities") {
      slug
      name
      image
    }
  }
`;
