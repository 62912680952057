import React from 'react';
import { Box, ResponsiveContext } from 'grommet';
import Container from '../../../Atoms/Container';
import H from '../../../Atoms/Heading';
import P from '../../../Atoms/Paragraph';

const AboutTryNatural = () => {
  const size = React.useContext(ResponsiveContext);
  const isXsmall = size === 'xsmall';

  return (
    <Box pad={{ vertical: 'section' }} background={{ color: '#ffffff' }}>
      <Container>
        <H level={2} textAlign={isXsmall ? 'start' : 'center'} margin={{ bottom: 'large' }}>
          About the #trynatural movement
        </H>
        <Box
          direction={isXsmall ? 'column' : 'row'}
          justify="between"
          width={{ max: '948px' }}
          margin={{ horizontal: 'auto' }}
          gap="small"
        >
          <Item
            icon={<img src="/images/freetrial-brand.svg" alt="" role="presentation" />}
            title="Become a product tester"
            copy="Discover better-for-you products for your next shopping trip and gain access to exclusive free trials in exchange for your review."
            isXsmall={isXsmall}
          />
          <Item
            icon={<img src="/images/launchpad-brand.svg" alt="" role="presentation" />}
            title="Boost emerging brands"
            copy="Support emerging brands and be the first to know when their products are available at a store near you."
            isXsmall={isXsmall}
          />
          <Item
            icon={<img src="/images/reviews-brand.svg" alt="" role="presentation" />}
            title="Browse member reviews"
            copy="Discover tried and tested products and be inspired to make better choices for you and your family."
            isXsmall={isXsmall}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default AboutTryNatural;

const Item = ({ icon, title, copy, isXsmall }) => (
  <Box
    direction={isXsmall ? 'row' : 'column'}
    width={isXsmall ? '100%' : '30%'}
    align={isXsmall ? 'start' : 'center'}
    gap="16px"
  >
    <Box basis={isXsmall ? '54px' : ''} flex={isXsmall ? { shrink: 0 } : {}}>
      {icon}
    </Box>
    <Box basis={isXsmall ? 'auto' : ''}>
      <H level={3} textAlign={isXsmall ? 'start' : 'center'}>
        {title}
      </H>
      <P textAlign={isXsmall ? 'start' : 'center'}>{copy}</P>
    </Box>
  </Box>
);
