import React from 'react';

const Pinterest = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.65331 19.8985C8.66744 20.0078 8.80826 20.034 8.87166 19.9516C8.96222 19.8339 10.1304 18.3882 10.5277 16.9444C10.6404 16.535 11.1727 14.4184 11.1727 14.4184C11.4917 15.0278 12.4234 15.5632 13.4144 15.5632C16.3634 15.5632 18.3636 12.8705 18.3636 9.26442C18.3636 6.53844 16.0586 4 12.5552 4C8.19784 4 6 7.13115 6 9.74045C6 11.3206 6.5976 12.7268 7.87793 13.2528C8.08872 13.3382 8.27688 13.2559 8.33725 13.0226C8.38001 12.86 8.47964 12.4538 8.52542 12.284C8.58579 12.0538 8.56264 11.9737 8.39367 11.7716C8.02339 11.3362 7.78747 10.7695 7.78747 9.96958C7.78747 7.64672 9.52275 5.56865 12.3037 5.56865C14.7667 5.56865 16.1211 7.07589 16.1211 9.08941C16.1211 11.7404 14.9499 13.9767 13.2133 13.9767C12.2534 13.9767 11.534 13.1819 11.7644 12.2069C12.0411 11.0424 12.5753 9.78625 12.5753 8.94562C12.5753 8.19351 12.1719 7.56645 11.3378 7.56645C10.3568 7.56645 9.568 8.5821 9.568 9.94452C9.568 10.8122 9.8618 11.3997 9.8618 11.3997C9.8618 11.3997 8.85769 15.66 8.68157 16.4068C8.3311 17.8934 8.62818 19.7151 8.65331 19.8985Z"
      fill="#FFFFFF"
    />
  </svg>
);

export default Pinterest;
