import gql from 'graphql-tag';

// TODO: create fragments
export const GET_PRODUCT = gql`
  query GetProduct($slug: String!, $reviewId: Int!) {
    samplekits(where: { slug: { _eq: $slug } }) {
      id
      name
      slug
      featured_image
      images
      is_launchpad
      featured_video
      whereToBuy
      description
      ingredients
      recipes
      banner_expiry
      banner_message
      want_disabled
      popup_enabled
      short_summary
      countries
      price_value
      banner_summary
      banner_price_value
      banner_url
      popup_discount_code
      popup_message
      popup_url
      companyData {
        enable_storefront
        name
        slug
        website
      }
      qualities {
        quality {
          id
          name
          image
          link
        }
      }
      certifications {
        certification {
          id
          name
          image
          link
        }
      }
      samplekits_stats {
        average_rating
        wants
        reviews
      }
      wants {
        id
        accepted
        campaign
        ShippedAt
        createdAt
        properties
        campaignData {
          campaign_type
          ship_date
          completeByDate: complete_by_date
          promo_codes {
            channel_name
          }
        }
      }
      reviews {
        sample_kit
      }
      sharing_messages(path: "pretrial")
      campaigns: campaigns_public(
        where: { status: { _neq: "completed" }, _and: { status: { _neq: "suspended" } } }
      ) {
        id
        campaign_type
        is_full
        country
        completeByDate: complete_by_date
        offerDiscountPercentage: offer_discount_percentage
        variants
        startDate: start_date
      }
      reviewsPublic(where: { id: { _eq: $reviewId } }) {
        avatar
        description
        firstName: first_name
        lastName: last_name
        rating
      }
    }
    users {
      id
    }
  }
`;

export const PRODUCT_ASSETS = gql`
  query GetProductAssets($slug: String!) {
    samplekits(where: { slug: { _eq: $slug } }) {
      name
      images
      featuredImage: featured_image
      video: featured_video
    }
  }
`;
