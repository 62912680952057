import React from 'react';
import { Box, ResponsiveContext } from 'grommet';

const Cards = ({ children, wrap, direction }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Box
      direction={direction || 'column'}
      justify="start"
      wrap={wrap || false}
      margin={{
        vertical: 'none',
        horizontal: 'cardWrapper',
      }}
      width={{
        max: 'none',
      }}
      pad={size === 'xsmall' || size === 'small' ? { left: 'card', right: 'card' } : {}}
    >
      {children}
    </Box>
  );
};

export default Cards;
