import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { CheckBox, Text, themes } from 'socialnature-ui';
import { errorMessages } from '../../../utils/customQuestionTypes';

const StyledText = styled.span`
  color: ${themes.global.colors.black};

  font-weight: 700;
  font-size: 17px;
  margin-left: 10px;
  padding-top: 10px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`;

const Checkbox = (props) => {
  const { name, label, error, onChange, checked } = props;

  return (
    <>
      {error && (
        <Text color="red" margin={{ bottom: '4px' }}>
          {error.message}
        </Text>
      )}
      <Wrapper>
        <CheckBox onChange={onChange} error={!!error} name={name} checked={checked} />
        <StyledText>{label}</StyledText>
      </Wrapper>
    </>
  );
};

const CustomCheckBox = (props) => {
  const { name, error, question } = props;
  const { label, required } = question;
  const { control } = useFormContext();

  if (!label) return null;

  return (
    <Controller
      name={name}
      render={({ onChange, value }) => (
        <Checkbox
          onChange={(e) => onChange(e.target.checked)}
          checked={value || false}
          name={name}
          error={error}
          label={label}
        />
      )}
      control={control}
      rules={{
        required: required && errorMessages.REQUIRED,
        validate: required && ((value) => !!value),
      }}
    />
  );
};

export default CustomCheckBox;
